import React, { useState, useEffect } from 'react';
import { Container, Table, Form, Button, InputGroup, Modal, FormControl, FormGroup, FormLabel } from 'react-bootstrap';
import EmployeeDataService from '../../services/employee';
import Puestos from './rolpuestos';
import { useSelector } from 'react-redux';
import './TablaDatos.css';

const EmployeesPuestos = () => {
  const [puestos, setPuestos] = useState([]);
  const [selectedPuestos, setSelectedPuestos] = useState([]);
  const token = useSelector(state => state.authentication.token);
  const [showModal, setShowModal] = useState(false);
  const [allRoles, setAllRoles] = useState([]);
  const [newPuesto, setNewPuesto] = useState({
    nombre: '',
    descripcion: '',
    tipo_contrato: '',
    beneficios_adicionales: '',
    responsabilidades_clave: '',
    requisitos: '',
    roles_puesto: [],
  });

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const fetchData = async () => {
    try {
      const responsePuestos = await EmployeeDataService.getAllPuestos(token);
      setPuestos(responsePuestos.data);
    } catch (error) {
      console.error('Error al obtener datos:', error);
    }
  };
  const fetchRoles = async () => {
    try {
      const responseRoles = await EmployeeDataService.getAllRolesPuesto(token);
      setAllRoles(responseRoles.data);
      console.log(responseRoles.data)

    } catch (error) {
      console.error('Error al obtener roles:', error);
    }
  };

  useEffect(() => {
    fetchData();
    fetchRoles(); // Agregar esta llamada para obtener la lista completa de roles
  }, [token]);

  const handleDeleteSelected = async () => {
    try {
      await EmployeeDataService.deletePuesto(selectedPuestos, token);
      setSelectedPuestos([]);
      fetchData();
    } catch (error) {
      console.error('Error al eliminar puestos:', error);
    }
  };

  const toggleSelectPuesto = (puestoId) => {
    if (selectedPuestos.includes(puestoId)) {
      setSelectedPuestos(selectedPuestos.filter(id => id !== puestoId));
    } else {
      setSelectedPuestos([...selectedPuestos, puestoId]);
    }
  };

  const handleCreatePuesto = async () => {
    try {
      console.log(newPuesto)
      await EmployeeDataService.createPuesto(newPuesto, token);
      toggleModal();
      setNewPuesto({
        nombre: '',
        descripcion: '',
        tipo_contrato: '',
        beneficios_adicionales: '',
        responsabilidades_clave: '',
        requisitos: '',
        roles_puesto: [],
      });
      fetchData();
    } catch (error) {
      console.error('Error al crear puesto:', error);
    }
  };

  return (
    <Container>
      <div className="tabla-datos-container">
        <br /><br /><br />
        <h2>Puestos en la Empresa</h2>
        <Button variant="primary" onClick={toggleModal}>Agregar Puesto</Button>


        {/* Modal */}
        <Modal show={showModal} onHide={toggleModal} style={{ overflowY: 'auto' }}>
          <Modal.Header closeButton>
            <Modal.Title>Agregar Puesto</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* Formulario para agregar nuevo puesto */}
            <FormGroup>
              <FormLabel>Nombre</FormLabel>
              <FormControl
                type="text"
                value={newPuesto.nombre}
                onChange={(e) => setNewPuesto({ ...newPuesto, nombre: e.target.value })}
              />
            </FormGroup>

            <FormGroup>
              <FormLabel>Descripción</FormLabel>
              <FormControl
                type="text"
                value={newPuesto.descripcion}
                onChange={(e) => setNewPuesto({ ...newPuesto, descripcion: e.target.value })}
              />
            </FormGroup>

            <FormGroup>
              <FormLabel>Tipo de Contrato</FormLabel>
              <FormControl
                type="text"
                value={newPuesto.tipo_contrato}
                onChange={(e) => setNewPuesto({ ...newPuesto, tipo_contrato: e.target.value })}
              />
            </FormGroup>

            <FormGroup>
              <FormLabel>Beneficios Adicionales</FormLabel>
              <FormControl
                type="text"
                value={newPuesto.beneficios_adicionales}
                onChange={(e) => setNewPuesto({ ...newPuesto, beneficios_adicionales: e.target.value })}
              />
            </FormGroup>

            <FormGroup>
              <FormLabel>Responsabilidades Clave</FormLabel>
              <FormControl
                type="text"
                value={newPuesto.responsabilidades_clave}
                onChange={(e) => setNewPuesto({ ...newPuesto, responsabilidades_clave: e.target.value })}
              />
            </FormGroup>

            <FormGroup>
              <FormLabel>Requisitos</FormLabel>
              <FormControl
                type="text"
                value={newPuesto.requisitos}
                onChange={(e) => setNewPuesto({ ...newPuesto, requisitos: e.target.value })}
              />
            </FormGroup>

            <FormGroup>
              <FormLabel>Roles del Puesto</FormLabel>
              <FormControl
                type="text"
                value={newPuesto.roles_puesto}
                onChange={(e) => setNewPuesto({ ...newPuesto, roles_puesto: e.target.value })}
              />
            </FormGroup>
            {/* Agregar los demás campos del formulario según tus requerimientos */}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={toggleModal}>
              Cerrar
            </Button>
            <Button variant="primary" onClick={handleCreatePuesto}>
              Crear Puesto
            </Button>
          </Modal.Footer>
        </Modal>

        <div style={{ overflowX: 'auto' }}>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Check</th>
                <th>ID</th>
                <th>Nombre</th>
                <th>Descripción</th>
                <th>Tipo de Contrato</th>
                <th>Beneficios Adicionales</th>
                <th>Responsabilidades Clave</th>
                <th>Requisitos</th>
                <th>Roles del Puesto</th>
              </tr>
            </thead>
            <tbody>
              {puestos.map(puesto => (
                <tr key={puesto.id}>
                  <td>
                    <Form.Check
                      type="checkbox"
                      checked={selectedPuestos.includes(puesto.id)}
                      onChange={() => toggleSelectPuesto(puesto.id)}
                    />
                  </td>
                  <td>{puesto.id}</td>
                  <td>{puesto.nombre}</td>
                  <td>{puesto.descripcion}</td>
                  <td>{puesto.tipo_contrato}</td>
                  <td>{puesto.beneficios_adicionales}</td>
                  <td>{puesto.responsabilidades_clave}</td>
                  <td>{puesto.requisitos}</td>
                  <td>
                    {Array.isArray(puesto.roles_puesto) ? (
                      puesto.roles_puesto.map(roleId => {
                        const role = allRoles.find(r => r.id === roleId);
                        return role ? role.nombre : '';
                      })
                    ) : (
                      // Si puesto.roles_puesto no es un array, intentamos encontrar el nombre directamente
                      // en el array de roles
                      allRoles.find(r => r.id === puesto.roles_puesto)?.nombre || ''
                    )}
                  </td>


                  {/* ... Agregar las demás columnas según tus requerimientos ... */}
                </tr>
              ))}
            </tbody>
          </Table>
          <Puestos />
        </div>

        {selectedPuestos.length > 0 && (
          <InputGroup className="mb-3">
            <Button variant="danger" onClick={handleDeleteSelected}>
              Eliminar seleccionado
            </Button>
          </InputGroup>
        )}
      </div>
    </Container>
  );
};

export default EmployeesPuestos;
