import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import "./bolsaempleo.css"; // Archivo CSS para estilos
import EmployeeDataService from "../../services/employee";
import Admins from "../../components/bolsa_empleo/admins";
import Aguinaldos from "../../components/bolsa_empleo/aguinaldos";
import Deducciones from "../../components/bolsa_empleo/deducciones";
import Empleados from "../../components/bolsa_empleo/empleados";
import Extras from "../../components/bolsa_empleo/extras";
import Impuestorenta from "../../components/bolsa_empleo/impuestorenta";
import Incapacidad from "../../components/bolsa_empleo/incapacidades";
import OfertaTrabajo from "../../components/bolsa_empleo/ofertatrabajo";
import Planillas from "../../components/bolsa_empleo/planillas";
import Puestos from "../../components/bolsa_empleo/puestos";
import Rolpuestos from "../../components/bolsa_empleo/rolpuestos";
import Vacaciones from "../../components/bolsa_empleo/vacaciones";
import EmpleosModal from "./details/empleodetail";
import logo from "../services/imagenes/logo.jpg";
import { Card, Container, Table, Form,Button, InputGroup, Modal, FormControl, FormGroup, FormLabel,} from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { NavLink, Link, useLocation } from 'react-router-dom';
import { setAuthentication } from '../../redux/actions/authActions';
import { useDispatch } from 'react-redux';
import { useMediaQuery } from "react-responsive";

function useInterval(callback, delay) {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

export { useInterval };

const BolsaEmpleo = () => {
  const [selectedTab, setSelectedTab] = useState("");
  const [ofertasTrabajo, setOfertasTrabajo] = useState([]);
  const token = useSelector((state) => state.authentication.token);
  const [selectedOfertasTrabajo, setSelectedOfertasTrabajo] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedOfertaData, setSelectedOfertaData] = useState(null);
  const [allPuesto, setAllPuesto] = useState([]);
  const [isNavbarExpanded, setIsNavbarExpanded] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredOfertasTrabajo, setFilteredOfertasTrabajo] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const { id } = useParams();
  const navigate = useNavigate();
  const [ofertaTrabajoData, setOfertaTrabajoData] = useState({
    puesto: "",
    empresa_nombre: "",
    numero_empresa: "",
    correo_empresa: "",
    nombre_contacto: "",
    ubicacion: "",
    tipo_contrato: "",
    beneficios_adicionales: "",
    responsabilidades_clave: "",
    requisitos: "",
    fecha_limite_aplicacion: null,
    // Add other fields as needed
  });
  const handleTabClick = (tab) => {
    // Verificar si el mismo tab fue clicado dos veces para expandir/cerrar
    if (selectedTab === tab) {
      setIsNavbarExpanded((prevState) => !prevState);
    } else {
      setSelectedTab(tab);
      setIsNavbarExpanded(false); // Cerrar el card al cambiar de tab
    }
  };
  const handleNavbarButtonClick = () => {
    setIsNavbarExpanded(true); // Ocultar el card cuando se hace clic en algún botón del navbar
  };

  const getSelectedOfertaTrabajo = () => {
    return ofertasTrabajo.find((oferta) => oferta.id === parseInt(id, 10));
  };
  useEffect(() => {
    const selectedOfertaTrabajo = getSelectedOfertaTrabajo();
    if (selectedOfertaTrabajo) {
      showOfertaModal(selectedOfertaTrabajo);
    }
  }, [id, ofertasTrabajo]);

  const fetchPuestos = async () => {
    try {
      const responsePuestos = await EmployeeDataService.getAllPuestos(token);
      setAllPuesto(responsePuestos.data);
      // console.log(responsePuestos.data);
    } catch (error) {
      console.error("Error al obtener roles:", error);
    }
  };


  const fetchData = async () => {
    try {
      const responseOfertasTrabajo =
        await EmployeeDataService.getAllOfertasTrabajo(token);
      setOfertasTrabajo(responseOfertasTrabajo.data);
      // console.log(responseOfertasTrabajo);
    } catch (error) {
      console.error("Error al obtener datos:", error);
    }
  };

  useEffect(() => {
    fetchData();
    fetchPuestos();
  }, [token]);

  useEffect(() => {
    
    // Inicializa filteredOfertasTrabajo solo cuando hay un término de búsqueda presente
    if (searchTerm || selectedCountry) {
      const filteredOfertasTrabajo = ofertasTrabajo.filter((ofertaTrabajo) => {
        return (
          (String(ofertaTrabajo.puesto) ?? "").toLowerCase().includes(searchTerm.toLowerCase()) ||
          (ofertaTrabajo.empresa_nombre ?? "").toLowerCase().includes(searchTerm.toLowerCase()) ||
          (ofertaTrabajo.requisitos ?? "").toLowerCase().includes(searchTerm.toLowerCase()) ||
          (ofertaTrabajo.responsabilidades_clave ?? "").toLowerCase().includes(searchTerm.toLowerCase())
        ) && ofertaTrabajo.pais === selectedCountry;
      });
      setFilteredOfertasTrabajo(filteredOfertasTrabajo);
    } else {
      // Si no hay término de búsqueda ni país seleccionado, mostrar todas las ofertas de trabajo
      setFilteredOfertasTrabajo(ofertasTrabajo);
    }
  
    setSelectedOfertasTrabajo([]);
    setCurrentPage(1);
  }, [searchTerm, ofertasTrabajo, selectedCountry]);
  
  const toggleSelectOfertaTrabajo = (ofertaTrabajoId) => {
    if (selectedOfertasTrabajo.includes(ofertaTrabajoId)) {
      setSelectedOfertasTrabajo(
        selectedOfertasTrabajo.filter((id) => id !== ofertaTrabajoId)
      );
    } else {
      setSelectedOfertasTrabajo([...selectedOfertasTrabajo, ofertaTrabajoId]);
    }
  };
  // Función para mostrar el modal
  const showOfertaModal = (ofertaTrabajo) => {
    setSelectedOfertaData(ofertaTrabajo);
    setShowModal(true);
  };

  // Función para cerrar el modal
  const closeModal = () => {
    setShowModal(false);
  };

  // Función para manejar la aplicación al hacer clic en "Aplicar"
  const handleAplicar = () => {
    if (selectedOfertaData) {
      // console.log("Puesto aplicado:", selectedOfertaData.puesto);
      // Aquí puedes realizar acciones adicionales, como enviar datos al servidor, etc.
    }
  };

  const cardsPerPage = 6;
  const totalCards = ofertasTrabajo.length;
const pageCount = Math.ceil(totalCards / cardsPerPage);


  const [currentPage, setCurrentPage] = useState(1);

  // Función para obtener los cards de la página actual
  const getCurrentPageCards = () => {
    const startIndex = (currentPage - 1) * cardsPerPage;
    const endIndex = startIndex + cardsPerPage;
    return filteredOfertasTrabajo.slice(startIndex, endIndex);
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [ofertasTrabajo]);

  const handleNextPage = () => {
    if (currentPage < pageCount) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    // console.log(searchTerm)
    if (!searchTerm) {
      // Mostrar todas las ofertas de trabajo cuando el término de búsqueda está vacío
      setFilteredOfertasTrabajo(ofertasTrabajo);
      setSelectedOfertasTrabajo([]);
      setCurrentPage(1);
      return;
    }
    // Filtrar ofertas de trabajo según el término de búsqueda
    const filteredOfertasTrabajo = ofertasTrabajo.filter((ofertaTrabajo) => {
      return (
        (String(ofertaTrabajo.puesto) ?? "").toLowerCase().includes(searchTerm.toLowerCase()) ||
        (ofertaTrabajo.empresa_nombre ?? "").toLowerCase().includes(searchTerm.toLowerCase()) ||
        (ofertaTrabajo.requisitos ?? "").toLowerCase().includes(searchTerm.toLowerCase()) ||
        (ofertaTrabajo.responsabilidades_clave ?? "").toLowerCase().includes(searchTerm.toLowerCase())
      );
    });
  
    // Actualizar el estado con las ofertas de trabajo filtradas
    setFilteredOfertasTrabajo(filteredOfertasTrabajo);
    setSelectedOfertasTrabajo([]);
    setCurrentPage(1); // Restablecer la página actual a 1 después de la búsqueda
  };
  
  const countriesToShow = [
    "MX",
    "GT",
    "BZ",
    "HN",
    "SV",
    "NI",
    "CR",
    "PA",
    "CO",
    "VE",
  ];
  const countryCodeToFullName = {
    MX: "México",
    GT: "Guatemala",
    BZ: "Belice",
    HN: "Honduras",
    SV: "El Salvador",
    NI: "Nicaragua",
    CR: "Costa Rica",
    PA: "Panamá",
    CO: "Colombia",
    VE: "Venezuela",
    // Agrega más países según sea necesario
  };
  const [selected, setSelected] = useState("CR");
  const handleCountrySelect = (code) => {
    setSelected(code);
    setSelectedCountry(code);
    const countryName = countryCodeToFullName[code];
    // console.log("País seleccionado:", countryName);
  };
  
  const user = useSelector(state => state.authentication.user);

  const dispatch = useDispatch();
  const location = useLocation();

  const [currentUser, setCurrentUser] = useState(null);
  const [showCurrentUserModal, setShowCurrentUserModal] = useState(false);
  const [loading, setLoading] = useState(true);
  
  const fetchCurrentUserData = () => {
    const currentUser = localStorage.getItem('currentUser');
    setCurrentUser(JSON.parse(currentUser));
  };
  useEffect(() => {
    fetchCurrentUserData();
  }, []);

  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    const storedUser = localStorage.getItem('user');
    if (storedToken && storedUser) {
      dispatch(setAuthentication(storedToken, storedUser));
    }
    setLoading(false);
  }, []);
  
  const [screenSize, setScreenSize] = useState('medium');

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;

      if (width <= 767) {
        setScreenSize('small');
      } else if (width <= 1023) {
        setScreenSize('medium');
      } else {
        setScreenSize('large');
      }
    };

    handleResize(); // Llamar a la función al cargar la página

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const storedCountry = localStorage.getItem("selectedCountry");
    console.log("pais del navbar", storedCountry)
    if (storedCountry) {
      setSelectedCountry(storedCountry);
    }
  }, []);
  
  const updateSelectedCountryFromLocalStorage = () => {
    const storedCountry = localStorage.getItem("selectedCountry");
    if (storedCountry) {
      setSelectedCountry(storedCountry);
    }
  };

  // Actualiza el estado inicial desde el localStorage
  useEffect(() => {
    updateSelectedCountryFromLocalStorage();
  }, []);

  // Utiliza useInterval para verificar el localStorage cada 4 segundos
  useInterval(() => {
    updateSelectedCountryFromLocalStorage();
    fetchPuestos(); // Asegúrate de que esta función recargue los datos según los nuevos valores
  }, 1000);
// console.log(currentUser)
  return (
    <div className="bolsa-empleo-container" style={{ marginTop: "7%" }}>
      {currentUser && currentUser.staff_status === 'administrator' && (
      <div className="navbar">
       
        <button
          className={`navbar-tab ${
            selectedTab === "ofertatrabajo" && "active"
          }`}
          onClick={() => handleTabClick("ofertatrabajo")}
          onDoubleClick={handleNavbarButtonClick}
        >
          Oferta de Trabajo
        </button>
       
        <button
          className={`navbar-tab ${selectedTab === "puestos" && "active"}`}
          onClick={() => handleTabClick("puestos")}
          onDoubleClick={handleNavbarButtonClick}
        >
          Puestos
        </button>
        
      </div>
 )}
      <div className="content-container">
        {!isNavbarExpanded && (
          <>
            {selectedTab === "admins" && <Admins />}
            {selectedTab === "aguinaldos" && <Aguinaldos />}
            {selectedTab === "deducciones" && <Deducciones />}
            {selectedTab === "empleados" && <Empleados />}
            {selectedTab === "extras" && <Extras />}
            {selectedTab === "impuestorenta" && <Impuestorenta />}
            {selectedTab === "incapacidad" && <Incapacidad />}
            {selectedTab === "ofertatrabajo" && <OfertaTrabajo />}
            {selectedTab === "planillas" && <Planillas />}
            {selectedTab === "puestos" && <Puestos />}
            {/* {selectedTab === 'rolpuestos' && <Rolpuestos />} */}
            {selectedTab === "vacaciones" && <Vacaciones />}
          </>
        )}
      </div>
      <br/>
      {isNavbarExpanded && (
      <div style={{ display: "flex", alignItems: "center" }}>
        <img
          src={logo}
          alt="Logo"
          style={{ maxWidth: "50%", maxHeight: "100%", objectFit: "cover" }}
        />
      </div>
        )}
        {isNavbarExpanded && (
      <div
        style={{
          position: "sticky",
          top: "0",
          zIndex: "1",
          marginBottom: "5px", 
        }}
      >
       <input
        type="text"
        placeholder="Buscar..."
        value={searchTerm}
        onChange={handleSearchChange}
        style={{
          padding: "10px",
          borderRadius: "10px",
          border: "2px solid red",
          marginBottom: "2%",
          boxShadow: "0 0 5px red",
          backgroundColor: "white",
        }}
      />
      </div>
       )}
       
      {isNavbarExpanded && (
      <Container className="card-column-layout">
      {getCurrentPageCards().map((filteredOfertaTrabajo) => (
        <Link key={filteredOfertaTrabajo.id} to={`/bolsadeempleo/${filteredOfertaTrabajo.id}`} className="custom-link">
    <Card key={filteredOfertaTrabajo.id} className="card-custom" >
            <Card.Body>
            <Card.Subtitle >
                ID: {filteredOfertaTrabajo.id}
              </Card.Subtitle>
              <Card.Title style={{ fontWeight: "bold" }}>
                {Array.isArray(filteredOfertaTrabajo.puesto)
                  ? filteredOfertaTrabajo.puesto.map((roleId) => {
                      const role = allPuesto.find((r) => r.id === roleId);
                      return role ? role.nombre : "";
                    })
                  : // Si puesto.roles_puesto no es un array, intentamos encontrar el nombre directamente
                    // en el array de roles
                    allPuesto.find((r) => r.id === filteredOfertaTrabajo.puesto)
                      ?.nombre || ""}
              </Card.Title>
              
              <Card.Subtitle className="mb-2 text-muted">
                Empresa: {filteredOfertaTrabajo.empresa_nombre}
              </Card.Subtitle>
              <Card.Subtitle className="mb-2 text-muted">
                Pais: {filteredOfertaTrabajo.pais}
              </Card.Subtitle>
              <Card.Text>
                {filteredOfertaTrabajo.requisitos.length > 100
                  ? `${filteredOfertaTrabajo.requisitos.substring(0, 100)}...`
                  : filteredOfertaTrabajo.responsabilidades_clave}
              </Card.Text>
              <Button
                variant="danger"
                onClick={() => showOfertaModal(filteredOfertaTrabajo)}
              >
                Ver Detalles
              </Button>
            </Card.Body>
          </Card>
          </Link>
        ))}
      </Container>
      )}
      <div className="pagination-container">
        <Button variant="primary" onClick={handlePrevPage}>
          Página Anterior
        </Button>
        <span className="page-number">
    {[currentPage - 2, currentPage - 1, currentPage, currentPage + 1, currentPage + 2]
      .filter(page => page > 0 && page <= pageCount)
      .map(page => (
        <Button
          key={page}
          variant={page === currentPage ? 'success' : 'outline-primary'}  
          onClick={() => setCurrentPage(page)}
          className={page === currentPage ? 'active' : ''}
        >
          {page}
        </Button>
      ))}
  </span>
        <Button variant="primary" onClick={handleNextPage}>
          Página Siguiente
        </Button>
      </div>

      <EmpleosModal
        showModal={showModal}
        closeModal={closeModal}
        selectedOfertaData={selectedOfertaData}
        allPuesto={allPuesto}
        handleAplicar={handleAplicar}
      />
   
    </div>
  );
};

export default BolsaEmpleo;
