import React, { useState, useEffect } from 'react';
import classNames from 'classnames';

export default function LogoCloud() {
  const data = [
    { label: 'No. Empresas que confían en ABCupon', value: 24 },
    { label: 'Usuarios de ABCupon', value: 120 },
    { label: 'Proyectos realizados por ABCupon', value: 165 },
    { label: 'Servicios que ofrece ABCupon', value: 24 },
    { label: 'No. Países que promocionan sus servicios en ABCupon', value: 23 },
  ];

  const [counters, setCounters] = useState(data.map(() => 0));
  const [hoverIndex, setHoverIndex] = useState(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const marginTopClass = classNames({
    'sm:mt-0': windowWidth >= 640 && windowWidth < 1024, // Pantallas medianas
    'md:mt-20 lg:mt-40': windowWidth >= 1024, // Pantallas grandes
    'mt-80': windowWidth < 640, // Pantallas pequeñas (doble de 40%)
  });

  useEffect(() => {
    if (hoverIndex !== null) {
      setCounters((prevCounters) => {
        const newCounters = [...prevCounters];
        newCounters[hoverIndex] = 0;
        return newCounters;
      });
    }
  }, [hoverIndex]);

  useEffect(() => {
    const incrementCounters = () => {
      setCounters((prevCounters) => {
        return prevCounters.map((counter, index) => {
          const targetValue = data[index].value;
          const increment = Math.ceil(targetValue / 50); // Ajusta la velocidad de incremento aquí
          const newValue =
            counter + increment <= targetValue ? counter + increment : targetValue;
          return newValue;
        });
      });
    };

    const interval = setInterval(incrementCounters, 50); // Ajusta la velocidad de la animación aquí

    return () => {
      clearInterval(interval);
    };
  }, [data]);

  return (
    <div className={`bg-transparent relative z-10 ${marginTopClass}`}>
      <br/><br/><br/><br/>
      <div className="mx-auto py-12 px-4 sm:px-6 lg:px-8 w-screen">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-8 max-w-screen-xl mx-auto">
          {data.map((item, index) => (
            <div
              key={index}
              className={`flex justify-center rounded-lg p-4 m-2 w-full border border-blue-900 bg-white ${hoverIndex === index ? 'cursor-pointer expanded' : ''}`}
              onMouseEnter={() => setHoverIndex(index)}
              onMouseLeave={() => setHoverIndex(null)}
            >
              <div className="text-center">
                <p className="text-8xl font-bold text-gold-500">{counters[index]}</p>
                <p className="text-md font-bold text-gold-500">{item.label}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
