import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Products_all from "./Directorio";
import Footer from "../../components/navigation/Footer";
import Products from "../../components/home/products";
import BolsaEmpleo from "./bolsaempleo";
import Products_Clasificado from "../../components/home/products_clasificado";
import Clasificado from "./Avisos_clasificados";
import { FaSearch, FaMicrophone } from "react-icons/fa";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.css";
import logo from "../../assets/logo_abcupon_3.jpg";
import "./products.css";
import ProductDataService from "../../services/products";
import ShippingDataService from "../../services/shipping";
import { FaShoppingCart, FaMapMarkerAlt, FaStar } from "react-icons/fa";
import slider1 from "../../assets/slider1.jpg";
import slider2 from "../../assets/slider2.jpg";
import slider3 from "../../assets/slider3.jpg";
import slider4 from "../../assets/slider4.jpg";
import slider5 from "../../assets/slider5.jpg";
import { useMediaQuery } from "react-responsive";
import proximamente from "../../assets/proximamente.jpg";
import ReactFlagsSelect from "react-flags-select";

function Home({ subproducts }) {
  const [showProducts, setShowProducts] = useState(false);
  const [showClasificados, setShowClasificados] = useState(false);
  const [showAllProducts, setShowAllProducts] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [products, setProducts] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [currentPageProducts, setCurrentPageProducts] = useState(1);
  const [currentPageSubproducts, setCurrentPageSubproducts] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [shippingProducts, setShippingProducts] = useState([]); // Agrega un estado para los productos de ShippingDataService
  const [shippingSearchResults, setShippingSearchResults] = useState([]);
  const [modalProduct, setModalProduct] = useState(null); // Agregamos estado para el producto del modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCartModalOpen, setIsCartModalOpen] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [cartItems, setCartItems] = useState([]);
  const productsSectionRef = useRef(null);
  const clasificadosSectionRef = useRef(null);
  const directorioSectionRef = useRef(null);
  const bolsaEmpleoSectionRef = useRef(null);
  const [selected, setSelected] = useState("CR");
  const [subproductsWithSameCountry, setSubproductsWithSameCountry] = useState([]);
  const [openSection, setOpenSection] = useState(null);
  const [activeSection, setActiveSection] = useState(null);
  const [vibrateButton, setVibrateButton] = useState(false);
  const [vibrate, setVibrate] = useState(false);
  const countriesToShow = [
    "MX",
    "GT",
    "BZ",
    "HN",
    "SV",
    "NI",
    "CR",
    "PA",
    "CO",
    "VE",
  ];
  const subtotal = cartItems.reduce(
    (total, item) => total + item.price * item.quantity,
    0
  );
  useEffect(() => {
    fetchProducts();
    fetchCartItems();
  }, []);

  useEffect(() => {
    const storedUser = localStorage.getItem("currentUser");
    if (storedUser) {
      setCurrentUser(JSON.parse(storedUser));
    }
  }, []);

  const fetchProducts = () => {
    // Obtén los productos de ProductDataService
    ProductDataService.getAll()
      .then((response) => {
        setProducts(response.data);
      })
      .catch((error) => {
        console.error(
          "Error fetching products from ProductDataService:",
          error
        );
      });

    // Obtén los productos de ShippingDataService
    ShippingDataService.getAllProducts()
      .then((response) => {
        setShippingProducts(response.data);
        // console.log("Productos de ShippingDataService:", response.data); // Imprime los productos en la consola
      })
      .catch((error) => {
        console.error(
          "Error fetching products from ShippingDataService:",
          error
        );
      });
  };

  const toggleProducts = () => {
    setShowProducts(!showProducts);
    setActiveSection("products");

    // Desplazarse a la sección de Tiendas en línea después de esperar 2 segundos
    if (!showProducts) {
      setTimeout(() => {
        productsSectionRef.current.scrollIntoView({ behavior: "smooth" });
      }, 1000); // 2000 milisegundos = 2 segundos
    }
  };

  const toggleClasificados = () => {
    setShowClasificados(!showClasificados);
    setActiveSection("clasificados");

    // Desplazarse a la sección de Clasificados después de esperar 2 segundos
    if (!showClasificados) {
      setTimeout(() => {
        clasificadosSectionRef.current.scrollIntoView({ behavior: "smooth" });
      }, 1000); // 2000 milisegundos = 2 segundos
    }
  };

  const toggleAllProducts = () => {
    setShowAllProducts(!showAllProducts);
    setActiveSection("allProducts");

    // Desplazarse a la sección de Directorio de comercios después de esperar 2 segundos
    if (!showAllProducts) {
      setTimeout(() => {
        directorioSectionRef.current.scrollIntoView({ behavior: "smooth" });
      }, 1000); // 2000 milisegundos = 2 segundos
    }
  };
  

  const countryCodeToFullName = {
    MX: "México",
    GT: "Guatemala",
    BZ: "Belice",
    HN: "Honduras",
    SV: "El Salvador",
    NI: "Nicaragua",
    CR: "Costa Rica",
    PA: "Panamá",
    CO: "Colombia",
    VE: "Venezuela",
    // Agrega más países según sea necesario
  };

  const normalizeString = (str) => {
    return str
      ? str
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
      : "";
  };

  const saveSubproductsToLocalStorage = (subproducts) => {
    localStorage.setItem('subproducts', JSON.stringify(subproducts));
  };
  const allSubproducts = [];
// Función para guardar en localStorage
  const handleSearchChange = async (e) => {
    try {
      const searchTerm = normalizeString(e.target.value);
      setSearchValue(searchTerm);
  
      const selectedCountryFullName = countryCodeToFullName[selected] || selected;
      // console.log("País Seleccionado:", selectedCountryFullName);
  
      const filteredResults = products.filter(async (product) => {
        const productMatches =
          normalizeString(product.name).includes(searchTerm) ||
          normalizeString(product.description).includes(searchTerm) ||
          normalizeString(product.name_url).includes(searchTerm);
          // console.log("productMatches con el:", productMatches);
          const subproductsWithSameCountry = await Promise.all(
            product.subproducts.map(async (subproduct) => {
              if (
                normalizeString(subproduct.country) ===
                  normalizeString(selectedCountryFullName) &&
                (
                  normalizeString(subproduct.name).includes(searchTerm) ||
                  normalizeString(subproduct.phone).includes(searchTerm) ||
                  normalizeString(subproduct.address).includes(searchTerm) ||
                  normalizeString(subproduct.description).includes(searchTerm) ||
                  normalizeString(subproduct.province).includes(searchTerm) ||
                  normalizeString(subproduct.comercial_activity).includes(searchTerm)
                )
              ) {
                // console.log("Subproducto con el mismo país:", subproduct);
                allSubproducts.push(subproduct);
                return subproduct;
              }
              return null;
            })
          );
          saveSubproductsToLocalStorage(allSubproducts);
        const validSubproducts = subproductsWithSameCountry.filter(Boolean);
        
        // Agregar subproductos filtrados por país al estado
        setSubproductsWithSameCountry(validSubproducts);
  

        const shippingFilteredResults = shippingProducts.filter(
          (product) =>
            normalizeString(product.name).includes(searchTerm) ||
            normalizeString(product.description).includes(searchTerm)
        );
  
        setShippingSearchResults(shippingFilteredResults);
  
        return productMatches || validSubproducts.length > 0;
      });
  
      // console.log("Subproductos filteredResults:", filteredResults);
      setSearchResults(filteredResults);
    } catch (error) {
      console.error("Error en handleSearchChange:", error);
    }
  };
  
  const savedSubproducts = JSON.parse(localStorage.getItem('subproducts')) || [];

// Verificar si los subproductos en el localStorage son diferentes a los existentes en el estado
if (JSON.stringify(savedSubproducts) !== JSON.stringify(subproductsWithSameCountry)) {
  setSubproductsWithSameCountry(savedSubproducts);
}
  


  // Función para cambiar la página de productos
  const handlePageChangeProducts = (newPage) => {
    setCurrentPageProducts(newPage);
  };

  // Función para cambiar la página de subproductos
  const handlePageChangeSubproducts = (newPage) => {
    setCurrentPageSubproducts(newPage);
  };

  // Calcula los índices de los elementos a mostrar en la página actual para productos
  const indexOfLastProduct = currentPageProducts * itemsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - itemsPerPage;
  const currentProducts = searchResults.slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );

  // Calcula los índices de los elementos a mostrar en la página actual para subproductos
  const indexOfLastSubproduct = currentPageSubproducts * itemsPerPage;
  const indexOfFirstSubproduct = indexOfLastSubproduct - itemsPerPage;
  const currentSubproducts = searchResults
    .map((result) => result.subproducts)
    .flat()
    .slice(indexOfFirstSubproduct, indexOfLastSubproduct);

  // Función para abrir el modal
  const openModal = (product) => {
    setModalProduct(product);
    setIsModalOpen(true);
  };

  // Función para cerrar el modal
  const closeModal = () => {
    setModalProduct(null);
    setIsModalOpen(false);
  };

  const handleAddToCart = (product) => {
    // Crea una copia del carrito actual
    const updatedCart = [...cartItems];

    // Verifica si el producto ya está en el carrito
    const existingItem = updatedCart.find((item) => item._id === product._id);

    if (existingItem) {
      // Si el producto ya está en el carrito, incrementa la cantidad
      existingItem.quantity += 1;
    } else {
      // Si el producto no está en el carrito, agrégalo con cantidad 1
      updatedCart.push({ ...product, quantity: 1 });
    }

    // Actualiza el estado del carrito y guarda en el localStorage
    setCartItems(updatedCart);
    localStorage.setItem("cartItems", JSON.stringify(updatedCart));

    // Muestra un mensaje de éxito o realiza otras acciones necesarias
    alert(`Producto "${product.name}" agregado al carrito.`);
  };

  const openCartModal = () => {
    setIsCartModalOpen(true);

    // Obtiene los productos del localStorage y actualiza el estado del carrito
    const storedCartItems = localStorage.getItem("cartItems");
    if (storedCartItems) {
      setCartItems(JSON.parse(storedCartItems));
    }
  };

  const closeCartModal = () => {
    setIsCartModalOpen(false);
  };

  const fetchCartItems = () => {
    const storedCartItems = localStorage.getItem("cartItems");
    if (storedCartItems) {
      setCartItems(JSON.parse(storedCartItems));
    }
  };
  const handleQuantityChange = (itemId, newQuantity) => {
    // Crea una copia del carrito actual
    const updatedCart = cartItems.map((item) =>
      item._id === itemId ? { ...item, quantity: newQuantity } : item
    );

    // Actualiza el estado del carrito y guarda en el localStorage
    setCartItems(updatedCart);
    localStorage.setItem("cartItems", JSON.stringify(updatedCart));
  };

  const handleRemoveFromCart = (itemId) => {
    // Filtra los productos para mantener solo aquellos que no coincidan con el ID
    const updatedCart = cartItems.filter((item) => item._id !== itemId);

    // Actualiza el estado del carrito y guarda en el localStorage
    setCartItems(updatedCart);
    localStorage.setItem("cartItems", JSON.stringify(updatedCart));
  };
  const handleClearCart = () => {
    // Limpia el estado del carrito y el localStorage
    setCartItems([]);
    localStorage.removeItem("cartItems");
  };

  const categoriesWithoutShipping = ["Casas Nuevas", "Casas Alquiler"];

  const handleSendOrder = () => {
    if (currentUser === null) {
      // El usuario no ha iniciado sesión
      alert("Necesita iniciar sesión para realizar un pedido");
    } else {
      // Obtén los detalles del usuario actual
      const { first_name, last_name, phone } = currentUser || {};

      // Filtra los productos para excluir las categorías sin envío
      const filteredCartItems = cartItems.filter(
        (item) => !categoriesWithoutShipping.includes(item.category)
      );

      // Calcula el subtotal
      const subtotal = filteredCartItems.reduce(
        (total, item) => total + item.price * item.quantity,
        0
      );

      // Construye el mensaje con los detalles del carrito y usuario
      let message = `Hola, soy ${first_name} ${last_name}. Mi pedido es el siguiente:\n\n`;

      // Agrega el detalle de cada producto con cantidad
      const orderDetails = filteredCartItems
        .map(
          (item) =>
            `${item.name} (${item.category}, ${item.brand}) x${
              item.quantity
            } - ₡${item.price * item.quantity}`
        )
        .join("\n");

      // Verifica si algún producto tiene categoría sin envío
      const hasCategoryWithoutShipping = cartItems.some((item) =>
        categoriesWithoutShipping.includes(item.category)
      );

      if (!hasCategoryWithoutShipping) {
        // Agrega el detalle de envío solo si no hay productos de categorías sin envío
        const shippingCost = 3500; // Costo fijo de envío por Correos de Costa Rica
        const totalAmount = correosShippingRemoved ? subtotal : subtotal + 3500;

        message += `${orderDetails}\n\nSubtotal: ₡${subtotal}\nEnvío (Correos de Costa Rica): ₡${shippingCost}\nTotal: ₡${totalAmount}`;
      } else {
        // Agrega el detalle de la orden sin envío
        message += `${orderDetails}\n\nSubtotal: ₡${subtotal}\nTotal: ₡${subtotal}`;
      }

      // Construye el enlace de WhatsApp
      const whatsappLink = `https://api.whatsapp.com/send?phone=+50687886767&text=${encodeURIComponent(
        message
      )}`;

      // Abre la aplicación de WhatsApp en una nueva ventana o pestaña
      window.open(whatsappLink, "_blank");
    }
  };

  const [correosShippingRemoved, setCorreosShippingRemoved] = useState(false);

  const [correosShippingCost, setCorreosShippingCost] = useState(3500);
  const tableStyle = {
    width: "100%",
    borderCollapse: "collapse",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    margin: "20px 0",
  };

  const thStyle = {
    background: "#f2f2f2",
    padding: "10px",
    textAlign: "left",
    borderBottom: "1px solid #ddd",
  };

  const tdStyle = {
    padding: "10px",
    textAlign: "left",
    borderBottom: "1px solid #ddd",
  };
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  // const [isCartModalOpen, setCartModalOpen] = useState(false);
  const [isPayPalIframeOpen, setPayPalIframeOpen] = useState(false); // Nuevo estado

  // ... Resto del código ...

  // Función para abrir el iframe de PayPal
  const handlePayPalPayment = () => {
    setPayPalIframeOpen(true);
  };

  // Función para cerrar el iframe de PayPal
  const closePayPalIframe = () => {
    setPayPalIframeOpen(false);
  };
  const uniqueNames = [];

  const handleButtonClick = (section) => {
    setActiveSection(activeSection === section ? null : section);
    setVibrateButton(true);
    setVibrate(true);
    // Scroll to the corresponding section
    const sectionRef =
      section === "allProducts"
        ? directorioSectionRef
        : section === "clasificados"
        ? clasificadosSectionRef
        : section === "products"
        ? productsSectionRef
        : section === "bolsaEmpleo" // Nueva sección para Bolsa de Empleo
        ? bolsaEmpleoSectionRef
        : null;
  
    if (sectionRef && sectionRef.current) {
      setTimeout(() => {
        sectionRef.current.scrollIntoView({ behavior: "smooth" });
      }, 1000);
    }
    setTimeout(() => {
      setVibrateButton(false);
    }, 200);
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setVibrateButton(null);
    }, 200);
    return () => clearTimeout(timeoutId);
  }, [vibrateButton]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setVibrateButton(activeSection);
      setTimeout(() => {
        setVibrateButton(null);
      }, 200);
    }, 2000);

    return () => clearInterval(intervalId);
  }, [activeSection]);

  const [buttonVibration, setButtonVibration] = useState({
    allProducts: false,
    clasificados: false,
    products: false,
    bolsaEmpleo: false,
  });
  
  
  return (
    <div >
      <Helmet>
        <title>ABcupon - </title>
        <meta
          name="description"
          content="Somos la primera plataforma costarricense en ofrecer servicios y productos tanto nacionales como a mercados internacionales."
        />
      </Helmet>

      <div
        style={{
          position: "fixed",
          bottom: "10px",
          left: "10px",
          display: "flex",
          justifyContent: "space-between",
          width: "20%",
        }}
      >
        <div
          style={{
            backgroundColor: "blue",
            color: "white",
            padding: "10px",
            borderRadius: "50%",
            cursor: "pointer",
            zIndex: "99999",
          }}
          onClick={openCartModal}
        >
          <FaShoppingCart size={30} />
        </div>
      </div>

      <div className="containerStyle" >
       
        {/* <img src={logo} alt="Logo" className="imageStyle" /> */}
       
        <br />
        <br />
        <div className="buttonContainerStyle" style={{  zIndex: 9999 }}>
      {isMobile ? (
        <>
          <div className="mobileButtonRow">
            <button
              className={`buttonStyle mobileButton ${activeSection === "allProducts" ? "closedButton" : "openButton"} ${vibrateButton === "allProducts" ? 'vibrating' : ''}`}
              onClick={() => handleButtonClick("allProducts")}
            >
              {activeSection === "allProducts"
                ? "Directorio General"
                : "Directorio General"}
            </button>

            <button
              className={`buttonStyle mobileButton ${activeSection === "clasificados" ? "closedButton" : "openButton"} ${vibrateButton === "clasificados" ? 'vibrating' : ''}`}
              onClick={() => handleButtonClick("clasificados")}
            >
              {activeSection === "clasificados"
                ? "Avisos Clasificados"
                : "Avisos Clasificados"}
            </button>
          </div>

          <div className="mobileButtonRow">
            <button
              className={`buttonStyle mobileButton ${activeSection === "products" ? "closedButton" : "openButton"} ${vibrateButton === "products" ? 'vibrating' : ''}`}
              onClick={() => handleButtonClick("products")}
            >
              {activeSection === "products"
                ? "Tienda en línea"
                : "Tienda en línea"}
            </button>

            <button
              className={`buttonStyle mobileButton ${activeSection === "bolsaEmpleo" ? "closedButton" : "openButton"} ${vibrateButton === "bolsaEmpleo" ? 'vibrating' : ''}`}
              onClick={() => handleButtonClick("bolsaEmpleo")}

            >
              {activeSection === "bolsaEmpleo"
                ? "Bolsa de Empleo1"
                : "Bolsa de Empleo1"}
            </button>
          </div>
        </>
      ) : (
        // Renderización normal para pantallas no móviles
        <>
          <button
            className={`buttonStyle ${activeSection === "allProducts" ? "closedButton" : "openButton"} ${vibrateButton === "allProducts" ? 'vibrating' : ''}`}
            onClick={() => handleButtonClick("allProducts")}
          >
            {activeSection === "allProducts"
              ? "Directorio General"
              : "Directorio General"}
          </button>

          <button
            className={`buttonStyle ${activeSection === "clasificados" ? "closedButton" : "openButton"} ${vibrateButton === "clasificados" ? 'vibrating' : ''}`}
            onClick={() => handleButtonClick("clasificados")}
          >
            {activeSection === "clasificados"
              ? "Avisos Clasificados"
              : "Avisos Clasificados"}
          </button>

          <button
            className={`buttonStyle ${activeSection === "products" ? "closedButton" : "openButton"} ${vibrateButton === "products" ? 'vibrating' : ''}`}
            onClick={() => handleButtonClick("products")}
          >
            {activeSection === "products"
              ? "Tienda en línea"
              : "Tienda en línea"}
          </button>

          <button
            className={`buttonStyle ${activeSection === "bolsaEmpleo" ? "closedButton" : "openButton"} ${vibrateButton === "bolsaEmpleo" ? 'vibrating' : ''}`}
            onClick={() => handleButtonClick("bolsaEmpleo")}
          >
            {activeSection === "bolsaEmpleo"
              ? "Bolsa de Empleo"
              : "Bolsa de Empleo"}
          </button>
        </>
      )}
    </div >
        <div className="imageContainerStyle" style={{  zIndex: 2 }}>
          {isMobile ? (
            <Carousel
              showThumbs={false}
              showStatus={false}
              showIndicators={false}
              autoPlay={true}
              interval={4000}
              stopOnHover={false}
              emulateTouch={true}
              infiniteLoop={true}
              selectedItem={0} // Asegura que el primer elemento seleccionado sea el original
            >
              {[...Array(10)].map((_, repeatIndex) =>
                [slider1, slider2, slider3, slider4, slider5].map(
                  (image, index) => (
                    <div key={index}>
                      <img src={image} alt={`Slide ${index + 1}`} />
                    </div>
                  )
                )
              )}
            </Carousel>
          ) : (
            <Carousel
              showThumbs={false}
              showStatus={false}
              showIndicators={false}
              autoPlay={true}
              interval={4000}
              stopOnHover={false}
              emulateTouch={true}
              centerMode={true}
              centerSlidePercentage={33.33}
              infiniteLoop={true}
              selectedItem={0} // Asegura que el primer elemento seleccionado sea el original
            >
              {[...Array(10)].map((_, repeatIndex) =>
                [slider1, slider2, slider3, slider4, slider5].map(
                  (image, index) => (
                    <div key={index}>
                      <img src={image} alt={`Slide ${index + 1}`} />
                    </div>
                  )
                )
              )}
            </Carousel>
          )}
        </div>
        <br/>
        {/* <ReactFlagsSelect
          selected={selected}
          onSelect={(code) => setSelected(code)}
          countries={countriesToShow}
          customLabels={countryCodeToFullName} 
        /> */}
        <div className="searchContainerStyle" >
          <FaSearch className="searchIconStyle" />
          <input
            type="text"
            placeholder="Buscador"
            value={searchValue}
            onChange={handleSearchChange}
            className="searchInputStyle"
          />
          <FaMicrophone className="searchIconStyle MicrophoneIcon" />
        </div>


       
      {searchValue && (
  <div style={{  zIndex: 9999 }}>
    <table>
      <thead>
        <tr>
          {searchResults.length > 0 && (
            <React.Fragment>
              <th>Nombre</th>
              <th>Descripción</th>
            </React.Fragment>
          )}
        </tr>
      </thead>
      <tbody>
        {currentProducts
          .filter((result) => result.name && result.description)
          .map((result) => (
            <tr key={result.id}>
              <td>{result.name}</td>
              <td>{result.description}</td>
            </tr>
          ))}
      </tbody>
    </table>
    <div className="pagination">
      {Array.from(
        { length: Math.ceil(searchResults.length / itemsPerPage) },
        (_, index) => (
          <button
            key={index + 1}
            onClick={() => handlePageChangeProducts(index + 1)}
          >
            {index + 1}
          </button>
        )
      )}
    </div>
  </div>
)}



{searchValue && (
  <div>
    
    <div style={{ overflowX: "auto" }}>
      <table className="responsive-table">
        <thead>
          <tr>
            {searchResults.some(
              (result) => result.subproducts.length > 0
            ) && (
              <React.Fragment>
                 
                <th>Nombre del Comercio</th>
                <th>País</th>
                <th>Teléfono</th>
                <th>Email</th>
                <th>Dirección</th>
                <th>Pagina Web</th>
                <th>Maps</th>
              </React.Fragment>
            )}
          </tr>
        </thead>
        <tbody>
          {subproductsWithSameCountry.map((subproduct) => {
            const isDuplicate = uniqueNames.includes(subproduct.name);
            if (!isDuplicate) {
              uniqueNames.push(subproduct.name);
              return (
                <React.Fragment key={subproduct.id}>
                  {normalizeString(subproduct.name).includes(searchValue) ||
                  normalizeString(subproduct.phone).includes(
                    searchValue
                  ) ? (
                    <tr>
                      
                      <td
                        className="fixed-column"
                        style={{
                          color: "black",
                          fontWeight: "bold",
                          textShadow: "1px 1px red",
                        }}
                      >
                        <Link
                          to={`/servicios/${subproduct.email}`}
                          state={{
                            subproductName: subproduct.name,
                            subproductEmail: subproduct.email,
                          }}
                          style={{ color: "black" }}
                        >
                          {subproduct.name}
                        </Link>
                      </td>
                      <td className="fixed-column" data-label="Teléfono">
                        {subproduct.country}
                      </td>

                      <td className="fixed-column" data-label="Teléfono">
                        {subproduct.phone}
                      </td>
                      <td className="fixed-column1" data-label="Email">{subproduct.email}</td>
                      <td data-label="Dirección">{subproduct.address}</td>
                      <td data-label="Página Web">{subproduct.url}</td>
                      <td className="map-column" data-label="Maps">
                        {subproduct.addressmap && (
                          <iframe
                            title="Google Maps"
                            src={subproduct.addressmap}
                            width="100%"
                            height="300px"
                            frameBorder="0"
                            style={{ border: 0 }}
                            allowFullScreen=""
                            aria-hidden="false"
                            tabIndex="0"
                          ></iframe>
                        )}
                      </td>
                    </tr>
                  ) : null}
                </React.Fragment>
              );
            }
            return null;
          })}
        </tbody>
      </table>
    </div>

    <div className="pagination">
      {currentPageSubproducts > 1 && (
        <button
          onClick={() =>
            handlePageChangeSubproducts(currentPageSubproducts - 1)
          }
        >
          Página anterior
        </button>
      )}
      {indexOfLastSubproduct <
        searchResults.map((result) => result.subproducts).flat()
          .length && (
        <button
          onClick={() =>
            handlePageChangeSubproducts(currentPageSubproducts + 1)
          }
        >
          Página siguiente
        </button>
      )}
    </div>
  </div>
)}


        {searchValue && (
          <div>
            {/* <h2>Resultados de búsqueda en ShippingDataService:</h2> */}
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              {shippingSearchResults.map((product) => {
                // Truncar el nombre y la descripción si es necesario
                const MAX_NAME_LENGTH = 30;
                const MAX_DESCRIPTION_LENGTH = 100;

                const truncatedName =
                  product.name.length > MAX_NAME_LENGTH
                    ? `${product.name.substring(0, MAX_NAME_LENGTH)}...`
                    : product.name;

                const truncatedDescription =
                  product.description.length > MAX_DESCRIPTION_LENGTH
                    ? `${product.description.substring(
                        0,
                        MAX_DESCRIPTION_LENGTH
                      )}...`
                    : product.description;

                return (
                  <div
                    key={product._id}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      border: "1px solid #ddd",
                      borderRadius: "10px",
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                      margin: "10px",
                      padding: "20px",
                      maxWidth: "300px", // Ajuste del ancho del contenedor principal
                      background: "white",
                      height: "400px",
                    }}
                  >
                    {product.image && (
                      <img
                        src={product.image}
                        alt="Product Image"
                        style={{
                          maxWidth: "100%",
                          height: "150px",
                          width: "auto",
                          borderRadius: "5px",
                          marginBottom: "10px",
                        }}
                      />
                    )}
                    <strong style={{ marginBottom: "10px" }}>
                      {truncatedName}
                    </strong>
                    <div style={{ marginBottom: "5px" }}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          marginBottom: "5px",
                          flexDirection: "row",
                        }}
                      >
                        {/* Agrega las estrellas según la calificación */}
                        {Array.from({ length: product.rating }, (_, index) => (
                          <FaStar
                            key={index}
                            color="gold"
                            style={{ marginRight: "3px" }}
                          />
                        ))}
                        Estrellas
                      </div>
                    </div>
                    <div style={{ marginBottom: "5px" }}>
                      ({product.numReviews} Vistas)
                    </div>
                    <div style={{ marginBottom: "5px" }}>₡{product.price}</div>
                    <div style={{ marginBottom: "5px" }}>
                      En bodega: {product.countInStock}
                    </div>
                    <div>
                      <button
                        onClick={() => openModal(product)}
                        style={{
                          backgroundColor: "red",
                          color: "white",
                          padding: "8px",
                          borderRadius: "5px",
                          border: "none",
                          cursor: "pointer",
                        }}
                      >
                        Ver Detalles
                      </button>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}

        {/* Modal Producto */}
        {modalProduct && isModalOpen && (
          <div
            style={{
              position: "fixed",
              top: "0",
              left: "0",
              width: "100%",
              height: "100%",
              background: "rgba(0, 0, 0, 0.5)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              overflowY: "auto",
              zIndex: 99,
            }}
          >
            <div
              style={{
                background: "white",
                padding: "20px",
                borderRadius: "10px",
                width: "90%", // Adjust width to make it responsive
                maxWidth: "600px", // Limit maximum width for larger screens
                margin: "7% auto", // Center the modal vertically
                position: "relative",
                overflow: "auto", // Add overflow to enable content scrolling
                overflowY: "auto",
                maxHeight: "90vh",
                marginTop: "30%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
              >
                <h2>{modalProduct.name}</h2>
                <button
                  onClick={closeModal}
                  style={{
                    background: "red",
                    color: "white",
                    padding: "5px", // Aumentamos el tamaño del botón
                    borderRadius: "50%",
                    border: "none",
                    cursor: "pointer",
                    position: "absolute",
                    top: "0vh",
                    right: "4px",
                    zIndex: 99, // Aumentamos el valor de z-index al máximo
                  }}
                >
                  X
                </button>
              </div>
              {modalProduct.image && (
                <img
                  src={modalProduct.image}
                  alt="Product Image"
                  style={{
                    maxWidth: "100%",
                    height: "auto",
                    borderRadius: "5px",
                    marginBottom: "10px",
                  }}
                />
              )}

              <div style={{ textAlign: "center" }}>
                <table
                  style={{
                    margin: "auto",
                    borderCollapse: "collapse",
                    width: "80%",
                    textAlign: "left",
                  }}
                >
                  <tbody>
                    <tr>
                      <td>Estrellas:</td>
                      <td>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: "row",
                          }}
                        >
                          {Array.from(
                            { length: modalProduct.rating },
                            (_, index) => (
                              <FaStar
                                key={index}
                                color="gold"
                                style={{ marginRight: "3px" }}
                              />
                            )
                          )}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Vistas:</td>
                      <td>{modalProduct.numReviews}</td>
                    </tr>
                    <tr>
                      <td>Precio:</td>
                      <td>₡{modalProduct.price}</td>
                    </tr>
                    <tr>
                      <td>Descripción:</td>
                      <td>
                        {modalProduct.description
                          .split("..")
                          .map((item, index) => (
                            <p key={index}>{item.replace(/: /g, ":")}</p>
                          ))}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <hr style={{ width: "80%", margin: "20px auto" }} />{" "}
                {/* Línea separadora */}
                <button
                  onClick={() => handleAddToCart(modalProduct)}
                  style={{
                    backgroundColor: "red",
                    color: "white",
                    padding: "8px",
                    borderRadius: "5px",
                    border: "none",
                    cursor: "pointer",
                    marginTop: "10px",
                  }}
                >
                  Agregar a mi pedido
                </button>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* Modal del Carrito */}
      {isCartModalOpen && (
        <div
          style={{
            position: "fixed",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            background: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            overflowY: "auto", // Habilita el desplazamiento vertical
          }}
        >
          <div
            style={{
              background: "white",
              padding: "20px",
              borderRadius: "10px",
              width: "90%",
              maxWidth: "90%",
              maxHeight: "80%",
              margin: "7% auto",
              position: "relative",
              overflow: "auto",
            }}
          >
            {/* Contenido del Carrito */}
            <h2 onClick={closeCartModal}> Carrito de Compras</h2>
            {/* Agrega aquí la lógica para mostrar los productos en el carrito */}
            <button
              onClick={closeCartModal}
              style={{
                background: "red",
                color: "white",
                padding: "8px",
                borderRadius: "5px",
                border: "none",
                cursor: "pointer",
                position: "absolute",
                top: "40px",
                right: "4px",
                zIndex: 9999, // Asegura que esté en la parte superior
                fontSize: "20px", // Aumenta el tamaño para dispositivos móviles
              }}
            >
              X
            </button>
            <br />
            <table style={tableStyle}>
              <thead>
                <tr>
                  <th style={thStyle}>Producto</th>
                  <th style={thStyle}>Categoría</th>
                  <th style={thStyle}>Marca</th>
                  <th style={thStyle}>Precio</th>
                  <th style={thStyle}>Cantidad</th>
                  <th style={thStyle}>Subtotal</th>
                  <th style={thStyle}>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {cartItems.map((item) => (
                  <tr key={item._id}>
                    <td style={tdStyle}>{item.name}</td>
                    <td style={tdStyle}>{item.category}</td>
                    <td style={tdStyle}>{item.brand}</td>
                    <td style={tdStyle}>₡{item.price}</td>
                    <td style={tdStyle}>
                      <input
                        type="number"
                        value={item.quantity}
                        onChange={(e) =>
                          handleQuantityChange(
                            item._id,
                            parseInt(e.target.value, 10)
                          )
                        }
                        style={{ width: "50px" }}
                      />
                    </td>
                    <td style={tdStyle}>₡{item.price * item.quantity}</td>
                    <td style={tdStyle}>
                      <button onClick={() => handleRemoveFromCart(item._id)}>
                        Eliminar
                      </button>
                    </td>
                  </tr>
                ))}
                {/* Información de envío y total */}
                <tr>
                  <td colSpan="5" style={tdStyle}>
                    Envío por Correos de Costa Rica
                  </td>
                  <td style={tdStyle}>₡{correosShippingCost}</td>
                  <td style={tdStyle}>
                    {correosShippingRemoved ? (
                      <button
                        onClick={() => {
                          setCorreosShippingRemoved(false);
                          setCorreosShippingCost(3500);
                        }}
                      >
                        Restaurar
                      </button>
                    ) : (
                      <button
                        onClick={() => {
                          setCorreosShippingRemoved(true);
                          setCorreosShippingCost(0);
                        }}
                      >
                        Eliminar
                      </button>
                    )}
                  </td>
                </tr>
                <tr>
                  <td colSpan="5" style={tdStyle}>
                    Total
                  </td>
                  <td style={tdStyle}>
                    ₡{correosShippingRemoved ? subtotal : subtotal + 3500}
                  </td>
                </tr>
              </tbody>
            </table>
            <button
              onClick={handleClearCart}
              style={{
                backgroundColor: "red",
                color: "white",
                padding: "10px",
                borderRadius: "5px",
                border: "none",
                cursor: "pointer",
                marginTop: "10px",
              }}
            >
              Vaciar Carrito
            </button>
            <button
              onClick={handleSendOrder}
              style={{
                backgroundColor: "green",
                color: "white",
                padding: "10px",
                borderRadius: "5px",
                border: "none",
                cursor: "pointer",
                marginTop: "10px",
              }}
            >
              Pagar Pedido por WhatsApp
            </button>
            <button
              onClick={handlePayPalPayment}
              style={{
                backgroundColor: "#0070BA",
                color: "white",
                padding: "10px",
                borderRadius: "5px",
                border: "none",
                cursor: "pointer",
                marginTop: "10px",
              }}
            >
              Pagar Pedido por PayPal
            </button>
            {isPayPalIframeOpen && (
              <div>
                {/* Aquí coloca la imagen */}
                <img
                  src={proximamente}
                  alt="Próximamente"
                  style={{
                    width: "100%",
                    maxWidth: "100%",
                    height: "auto",
                    borderRadius: "10px", // Esquinas redondeadas
                    boxShadow: "0px 0px 10px red", // Sombra roja
                  }}
                />
                <br />
                <br />
                {/* Botón para cerrar el iframe de PayPal */}
                <button
                  onClick={closePayPalIframe}
                  style={{
                    backgroundColor: "red", // Fondo rojo
                    color: "white", // Texto blanco
                    padding: "10px", // Espaciado interno
                    borderRadius: "5px", // Bordes redondeados
                    border: "none", // Sin borde
                    cursor: "pointer", // Cambio de cursor al pasar el mouse
                    boxShadow: "0px 0px 5px black", // Sombra negra
                  }}
                >
                  Cerrar PayPal
                </button>
              </div>
            )}

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "10px",
              }}
            ></div>
          </div>
        </div>
      )}

<div ref={productsSectionRef}></div>
      {activeSection === "products" && (
        <div data-scroll-section >
          <Products />
        </div>
      )}

<div ref={clasificadosSectionRef}></div>
      {activeSection === "clasificados" && (
        <div data-scroll-section >
          <Clasificado />
        </div>
      )}

<div ref={directorioSectionRef}></div>
      {activeSection === "allProducts" && (
        <div data-scroll-section style={{marginTop:"-5%"}} >
          <Products_all />
        </div>
      )}

<div ref={bolsaEmpleoSectionRef}></div>
{activeSection === "bolsaEmpleo" && (
   <div data-scroll-section >
      {/* Contenido de Bolsa de Empleo */}
      <BolsaEmpleo />
   </div>
)}



{activeSection !== "products" &&
  activeSection !== "clasificados" &&
  activeSection !== "allProducts" &&
  activeSection !== "bolsaEmpleo" && <Footer />}
    </div>
  );
}

export default Home;
