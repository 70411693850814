import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ShippingDataService from "../../services/shipping";
import TodoDataService from "../../services/todos";
import { useSelector } from 'react-redux';
import { Button, Modal, Form } from 'react-bootstrap';
import ModalProducto1 from './modals/1';
import ModalProducto3 from './modals/3';
import ModalProducto4 from './modals/4';
import ModalProducto5 from './modals/5';
import ModalProducto6 from './modals/6';
import ModalProducto7 from './modals/7';
import ModalProducto8 from './modals/8';
import ModalProducto9 from './modals/9';
import ModalProducto10 from './modals/10';
import ModalProducto11 from './modals/11';
import ModalProducto12 from './modals/12';
import ModalProducto13 from './modals/13';
import ModalProducto14 from './modals/14';
import ModalProducto15 from './modals/15';
import ModalProducto17 from './modals/17';
import ModalProducto18 from './modals/18';
import ModalProducto19 from './modals/19';
import ModalProducto20 from './modals/20';
import ModalProducto21 from './modals/21';
import ModalProducto22 from './modals/22';
import ModalProducto23 from './modals/23';

const productModals = [
    { id: 1, name: "Autos y Accesorios", modalComponent: ModalProducto1 },
    { id: 3, name: "Casas Lotes", modalComponent: ModalProducto3 },
    { id: 4, name: "Clinicas Salud y estetica", modalComponent: ModalProducto4 },
    { id: 5, name: "Comunicacion, tecnologia y Energia", modalComponent: ModalProducto5 },
    { id: 6, name: "Construccion Diseño", modalComponent: ModalProducto6 },
    { id: 7, name: "Cupones", modalComponent: ModalProducto7 },
    { id: 8, name: "Centro educacion", modalComponent: ModalProducto8 },
    { id: 9, name: "Entretenimiento y restaurantes", modalComponent: ModalProducto9 },
    { id: 10, name: "Ferreteria y deposito", modalComponent: ModalProducto10 },
    { id: 11, name: "Hogar, Electronica y Supermercados", modalComponent: ModalProducto11 },
    { id: 12, name: "Planes de Inversion", modalComponent: ModalProducto12 },
    { id: 13, name: "Legal y Notariado", modalComponent: ModalProducto13 },
    { id: 14, name: "Libreria", modalComponent: ModalProducto14 },
    { id: 15, name: "Catalogo, Ofertas y Subastas", modalComponent: ModalProducto15 },
    { id: 16, name: "Polizas y Seguro", modalComponent: ModalProducto17 },
    { id: 17, name: "Prestamos privados", modalComponent: ModalProducto18 },
    { id: 18, name: "Productos y servicios Cooperativos", modalComponent: ModalProducto19 },
    { id: 19, name: "Publicidad y Paginas web", modalComponent: ModalProducto20 },
    { id: 20, name: "Fundacion Eslabones", modalComponent: ModalProducto21 },
    { id: 21, name: "Hoteles y Turismo", modalComponent: ModalProducto22 },
    { id: 22, name: "Transporte Y Mensajeria", modalComponent: ModalProducto23 }
];


function Files_ecommerce() {
    const [products, setProducts] = useState([]);
    const [reviews, setReviews] = useState([]);
    const [orders, setOrders] = useState([]);
    const [orderItems, setOrderItems] = useState([]);
    const [shippingAddresses, setShippingAddresses] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(5);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    const productsToShow = products.slice(indexOfFirstItem, indexOfLastItem);
    const reviewsToShow = reviews.slice(indexOfFirstItem, indexOfLastItem);
    const ordersToShow = orders.slice(indexOfFirstItem, indexOfLastItem);
    const orderItemsToShow = orderItems.slice(indexOfFirstItem, indexOfLastItem);
    const shippingAddressesToShow = shippingAddresses.slice(indexOfFirstItem, indexOfLastItem);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [image, setImage] = useState(null);
    const [users, setUsers] = useState([]);
    const paginate = (pageNumber) => setCurrentPage(pageNumber);
    const [storedData, setStoredData] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [selectedReviews, setSelectedReviews] = useState([]);
    const [selectedOrders, setSelectedOrders] = useState([]);
    const [selectedOrderItems, setSelectedOrderItems] = useState([]);
    const [selectedShippingAddresses, setSelectedShippingAddresses] = useState([]);
    const [editingProduct, setEditingProduct] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [newProductData, setNewProductData] = useState({
        name: '',
        brand: '',
        category: '',
        subcategory: [],
        description: '',
        price: 0,
        discount: 0,
        countInStock: 0,
        // Agregar otros campos según sea necesario
    });
    const [selectedProductData, setSelectedProductData] = useState({
        name: '',
        brand: '',
        category: '',
        subcategory: '',
        description: '',
        price: 0,
        discount: 0,
        countInStock: 0,
    });

    useEffect(() => {
        fetchProducts();
        fetchReviews();
        fetchOrders();
        fetchOrderItems();
        fetchShippingAddresses();
        fetchUsers();
    }, []);
    const fetchUsers = () => {
        TodoDataService.getUserList(token)
            .then((response) => {
                const modifiedData = response.data.map((user) => ({
                    ...user,
                    full_name: `${user.first_name} ${user.last_name}`,
                }));
                console.log(modifiedData)
                setUsers(modifiedData);
                setStoredData(modifiedData);
            })
            .catch((e) => {
                console.log(e);
            });
    };
    const fetchProducts = () => {
        ShippingDataService.getAllProducts()
            .then((response) => {
                setProducts(response.data);
                console.log(response, 'productos')
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const fetchReviews = () => {
        ShippingDataService.getAllReviews()
            .then((response) => {
                setReviews(response.data);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const fetchOrders = () => {
        ShippingDataService.getAllOrders()
            .then((response) => {
                setOrders(response.data);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const fetchOrderItems = () => {
        ShippingDataService.getAllOrderItems()
            .then((response) => {
                setOrderItems(response.data);
            })
            .catch((error) => {
                console.error("Error fetching order items:", error);
            });
    };

    const fetchShippingAddresses = () => {
        ShippingDataService.getAllShippingAddresses()
            .then((response) => {
                setShippingAddresses(response.data);
            })
            .catch((error) => {
                console.error("Error fetching shipping addresses:", error);
            });
    };

    // Estilos para los botones de paginación
    const paginationButtonStyle = {
        backgroundColor: "red",
        color: "white",
        borderRadius: "5px",
        cursor: "pointer",
        margin: "0 5px",
        border: "none",
        padding: "5px 10px",
        height: "30px", // Ajusta la altura deseada para los botones
    };

    const paginationButtonHoverStyle = {
        backgroundColor: "black",
        color: "white",
    };
    const tableContainerStyle = {
        overflowX: "auto",
        maxWidth: "100%",
    };
    const tableStyle = {
        width: "100%",
        borderCollapse: "collapse",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        margin: "20px 0",
        backgroundColor: "white",
    };

    const thStyle = {
        background: "#f2f2f2",
        padding: "10px",
        textAlign: "left",
        borderBottom: "1px solid #ddd",
    };

    const tdStyle = {
        padding: "10px",
        textAlign: "left",
        borderBottom: "1px solid #ddd",
    };


    const handleMouseOver = (e) => {
        e.target.style.backgroundColor = paginationButtonHoverStyle.backgroundColor;
        e.target.style.color = paginationButtonHoverStyle.color;
    };

    const handleMouseOut = (e) => {
        e.target.style.backgroundColor = paginationButtonStyle.backgroundColor;
        e.target.style.color = paginationButtonStyle.color;
    };
    const token = useSelector(state => state.authentication.token);
    const user = useSelector(state => state.authentication.user);

    // Función para acortar la descripción del producto a 30 palabras
    const truncateDescription = (description) => {
        const words = description.split(' ');
        if (words.length > 10) {
            return words.slice(0, 10).join(' ') + '...';
        }
        return description;
    };

    // Función para manejar la apertura del modal
    const [selectedProduct, setSelectedProduct] = useState([]);

    const openModal = (product) => {
        console.log("Selected Product:", product);
        setSelectedProduct(product);
        setEditingProduct(product);
        setNewProductData({
            name: product.name,
            brand: product.brand,
            category: product.category,
            subcategory: product.subcategory,
            description: product.description,
            price: product.price,
            discount: product.discount,
            countInStock: product.countInStock,
        });
        setShowModal(true); // Cambiado de setIsModalOpen(true) a setShowModal(true)
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setEditingProduct(null); // Restablece el producto que se está editando
        setNewProductData({
            name: '',
            brand: '',
            category: '',
            subcategory: '',
            description: '',
            price: 0,
            discount: 0,
            countInStock: 0,
        }); // Restablece los datos del nuevo producto
        setImage(null); // Restablece la imagen seleccionada
    };
    const getUserDisplayName = (userId) => {
        const user = users.find((u) => u.id === userId);
        return user ? `${user.first_name} ${user.last_name}` : "Usuario Desconocido";
    };

    const translateToSpanish = (column) => {
        const translations = {
            _id: 'ID',
            name: 'Nombre',
            image: 'Imagen',
            brand: 'Marca',
            category: 'Categoría',
            subcategory: 'Subcategoría',
            description: 'Descripción',
            rating: 'Calificación',
            numReviews: 'Número de Reseñas',
            price: 'Precio',
            discount: 'Descuento',
            countInStock: 'Cantidad en Stock',
            createdAt: 'Fecha de Creación',
            user: 'Usuario',
            // Agrega más traducciones según sea necesario
        };

        return translations[column] || column;
    };

    const handleCheckboxChange = (productId) => {
        if (selectedProducts.includes(productId)) {
            setSelectedProducts(selectedProducts.filter((id) => id !== productId));
            setEditingProduct(null); // Asegúrate de que setEditingProduct se llame con null al desmarcar
        } else {
            setSelectedProducts([...selectedProducts, productId]);
            setEditingProduct(products.find((product) => product._id === productId));
        }
    };

    // Function to handle double click on delete button
    const handleDeleteDoubleClick = async () => {
        try {
            // Verificar si hay productos seleccionados
            if (selectedProducts.length > 0) {
                // Iterar sobre los productos seleccionados y eliminar cada uno
                for (const productId of selectedProducts) {
                    await ShippingDataService.deleteProduct(productId, token);
                }

                // Limpiar la selección después de eliminar
                setSelectedProducts([]);

                // Actualizar la lista de productos después de eliminar
                fetchProducts();
            }
        } catch (error) {
            console.error("Error al eliminar productos:", error);
        }
    };
    const handleOpenModal = () => {
        setShowModal(true);
    };

    const openEditModal = (product) => {
        // Actualiza el estado con los datos del producto seleccionado
        setSelectedProductData({
            name: product.name,
            brand: product.brand,
            category: product.category,
            subcategory: product.subcategory,
            description: product.description,
            price: product.price,
            discount: product.discount,
            countInStock: product.countInStock,
        });

        // Abre el modal
        handleOpenModal();
    };


    const handleImageChange = (e) => {
        const selectedImage = e.target.files[0];
        setImage(selectedImage);
    };

    const handleCreateProduct = () => {
        // Verifica si se ha seleccionado una imagen
        if (!image) {
            alert('Seleccione una imagen para el producto.');
            return;
        }

        // Crea un objeto FormData para enviar datos con la imagen
        const formData = new FormData();
        formData.append('name', newProductData.name);
        formData.append('brand', newProductData.brand);
        formData.append('category', newProductData.category);
        formData.append('subcategory', newProductData.subcategory);
        formData.append('description', newProductData.description);
        formData.append('price', newProductData.price);
        formData.append('discount', newProductData.discount);
        formData.append('countInStock', newProductData.countInStock);
        formData.append('rating', 5);
        formData.append('image', image);  // Agrega la imagen al FormData

        // Llama a la API para crear el producto con la imagen
        ShippingDataService.createProduct(formData, token)
            .then((response) => {
                console.log('Producto creado exitosamente:', response.data);
                fetchProducts();

            })
            .catch((error) => {
                console.error('Error al crear el producto:', error);
            });
    };


    const handleEditButtonClick = () => {
        // Abre el modal en modo de edición al hacer clic en el botón de editar
        console.log("Editing Product:", editingProduct);
        if (editingProduct) {
            setIsModalOpen(true);
        }
    };

    const handleEditProduct = () => {
        // Verifica si se ha seleccionado una imagen
        if (!image) {
            alert('Seleccione una imagen para el producto.');
            return;
        }

        // Crea un objeto FormData para enviar datos con la imagen
        const formData = new FormData();
        formData.append('name', newProductData.name);
        formData.append('brand', newProductData.brand);
        formData.append('category', newProductData.category);
        formData.append('subcategory', newProductData.subcategory);
        formData.append('description', newProductData.description);
        formData.append('price', newProductData.price);
        formData.append('discount', newProductData.discount);
        formData.append('countInStock', newProductData.countInStock);
        formData.append('rating', 5);
        formData.append('image', image); // Agrega la imagen al FormData

        // Llama a la API para actualizar el producto con la imagen
        ShippingDataService.updateSubProduct(editingProduct._id, formData, token)
            .then((response) => {
                console.log('Producto editado exitosamente:', response.data);
                fetchProducts();

            })
            .catch((error) => {
                console.error('Error al editar el producto:', error);
            });
    };



    // Función para manejar la selección de elementos en las tablas
    const handleTableCheckboxChange = (itemId, tableName) => {
        switch (tableName) {
            case 'reviews':
                handleReviewCheckboxChange(itemId);
                break;
            case 'orders':
                handleOrderCheckboxChange(itemId);
                break;
            case 'orderItems':
                handleOrderItemCheckboxChange(itemId);
                break;
            case 'shippingAddresses':
                handleShippingAddressCheckboxChange(itemId);
                break;
            default:
                break;
        }
    };

    // Función para manejar la selección de elementos en la tabla de reviews
    const handleReviewCheckboxChange = (reviewId) => {
        if (selectedReviews.includes(reviewId)) {
            setSelectedReviews(selectedReviews.filter((id) => id !== reviewId));
        } else {
            setSelectedReviews([...selectedReviews, reviewId]);
        }
    };

    const handleDeleteSelectedReviews = async () => {
        try {
            if (selectedReviews.length > 0) {
                for (const reviewId of selectedReviews) {
                    await ShippingDataService.deleteReview(reviewId, token);
                }
                setSelectedReviews([]);
                fetchReviews();
            }
        } catch (error) {
            console.error("Error al eliminar revisiones:", error);
        }
    };
    // Función para manejar la selección de elementos en la tabla de orders
    const handleOrderCheckboxChange = (orderId) => {
        if (selectedOrders.includes(orderId)) {
            setSelectedOrders(selectedOrders.filter((id) => id !== orderId));
        } else {
            setSelectedOrders([...selectedOrders, orderId]);
        }
    };

    const handleDeleteSelectedOrders = async () => {
        try {
            if (selectedOrders.length > 0) {
                for (const orderId of selectedOrders) {
                    await ShippingDataService.deleteOrder(orderId, token);
                }
                setSelectedOrders([]);
                fetchOrders();
            }
        } catch (error) {
            console.error("Error al eliminar órdenes:", error);
        }
    };
    // Función para manejar la selección de elementos en la tabla de order items
    const handleOrderItemCheckboxChange = (orderItemId) => {
        if (selectedOrderItems.includes(orderItemId)) {
            setSelectedOrderItems(selectedOrderItems.filter((id) => id !== orderItemId));
        } else {
            setSelectedOrderItems([...selectedOrderItems, orderItemId]);
        }
    };

    const handleDeleteSelectedOrderItems = async () => {
        try {
            if (selectedOrderItems.length > 0) {
                for (const orderItemId of selectedOrderItems) {
                    await ShippingDataService.deleteOrderItem(orderItemId, token);
                }
                setSelectedOrderItems([]);
                fetchOrderItems();
            }
        } catch (error) {
            console.error("Error al eliminar items de orden:", error);
        }
    };

    // Función para manejar la selección de elementos en la tabla de shipping addresses
    const handleShippingAddressCheckboxChange = (shippingAddressId) => {
        if (selectedShippingAddresses.includes(shippingAddressId)) {
            setSelectedShippingAddresses(selectedShippingAddresses.filter((id) => id !== shippingAddressId));
        } else {
            setSelectedShippingAddresses([...selectedShippingAddresses, shippingAddressId]);
        }
    };

    const handleDeleteSelectedShippingAddresses = async () => {
        try {
            if (selectedShippingAddresses.length > 0) {
                for (const shippingAddressId of selectedShippingAddresses) {
                    await ShippingDataService.deleteShippingAddress(shippingAddressId, token);
                }
                setSelectedShippingAddresses([]);
                fetchShippingAddresses();
            }
        } catch (error) {
            console.error("Error al eliminar direcciones de envío:", error);
        }
    };
    // Función para eliminar elementos seleccionados en las tablas
    const handleDeleteSelectedItems = async (tableName) => {
        try {
            let selectedIds = [];

            switch (tableName) {
                case 'reviews':
                    selectedIds = selectedReviews;
                    break;
                case 'orders':
                    selectedIds = selectedOrders;
                    break;
                case 'orderItems':
                    selectedIds = selectedOrderItems;
                    break;
                case 'shippingAddresses':
                    selectedIds = selectedShippingAddresses;
                    break;
                default:
                    break;
            }

            // Verificar si hay elementos seleccionados
            if (selectedIds.length > 0) {
                // Iterar sobre los elementos seleccionados y eliminar cada uno
                for (const itemId of selectedIds) {
                    switch (tableName) {
                        case 'reviews':
                            await ShippingDataService.deleteReview(itemId, token);
                            break;
                        case 'orders':
                            await ShippingDataService.deleteOrder(itemId, token);
                            break;
                        case 'orderItems':
                            await ShippingDataService.deleteOrderItem(itemId, token);
                            break;
                        case 'shippingAddresses':
                            await ShippingDataService.deleteShippingAddress(itemId, token);
                            break;
                        default:
                            break;
                    }
                }

                // Limpiar la selección después de eliminar
                switch (tableName) {
                    case 'reviews':
                        setSelectedReviews([]);
                        break;
                    case 'orders':
                        setSelectedOrders([]);
                        break;
                    case 'orderItems':
                        setSelectedOrderItems([]);
                        break;
                    case 'shippingAddresses':
                        setSelectedShippingAddresses([]);
                        break;
                    default:
                        break;
                }

                // Actualizar la lista después de eliminar
                switch (tableName) {
                    case 'reviews':
                        fetchReviews();
                        break;
                    case 'orders':
                        fetchOrders();
                        break;
                    case 'orderItems':
                        fetchOrderItems();
                        break;
                    case 'shippingAddresses':
                        fetchShippingAddresses();
                        break;
                    default:
                        break;
                }
            }
        } catch (error) {
            console.error(`Error al eliminar elementos en la tabla ${tableName}:`, error);
        }
    };

    // Función para manejar cambios en las subcategorías
    const handleSubcategoryChange = (e) => {
        const selectedOptions = Array.from(e.target.selectedOptions, (option) => option.value);
        setNewProductData({ ...newProductData, subcategory: selectedOptions });
    };

    const handleCreateOrEditProduct = () => {

        // Verifica si se ha seleccionado una imagen
        if (!image) {
            alert('Seleccione una imagen para el producto.');
            return;
        }

        // Crea un objeto FormData para enviar datos con la imagen
        const formData = new FormData();
        formData.append('name', newProductData.name);
        formData.append('brand', newProductData.brand);
        formData.append('category', newProductData.category);
        formData.append('subcategory', newProductData.subcategory);
        formData.append('description', newProductData.description);
        formData.append('price', newProductData.price);
        formData.append('discount', newProductData.discount);
        formData.append('countInStock', newProductData.countInStock);
        formData.append('rating', 5);
        formData.append('image', image); // Agrega la imagen al FormData

        // Decide whether to create or edit based on the presence of editingProduct
        if (editingProduct) {
            // Llama a la API para actualizar el producto con la imagen
            ShippingDataService.updateSubProduct(editingProduct._id, formData, token)
                .then((response) => {
                    console.log('Producto editado exitosamente:', response.data);
                    fetchProducts();
                    handleCloseModal(); // Close the modal after successful edit
                })
                .catch((error) => {
                    console.error('Error al editar el producto:', error);
                });
        } else {
            // Llama a la API para crear el producto con la imagen
            ShippingDataService.createProduct(formData, token)
                .then((response) => {
                    console.log('Producto creado exitosamente:', response.data);
                    fetchProducts();
                    handleCloseModal(); // Close the modal after successful creation
                })
                .catch((error) => {
                    console.error('Error al crear el producto:', error);
                });
        }
    };

    const [showSubcategories, setShowSubcategories] = useState(false);

    const handleCategoryChange = (e) => {
        const selectedCategory = e.target.value;
        setNewProductData({ ...newProductData, category: selectedCategory });
        // Mostrar las subcategorías solo si se selecciona una categoría específica
        setShowSubcategories(selectedCategory !== '');
    };

    const handleShowModal = () => {
        setShowModal(true);
    };

    const [modalState, setModalState] = useState({});

    useEffect(() => {
        const initialModalState = {};
        productModals.forEach((modal) => {
            initialModalState[modal.name] = false;
        });
        setModalState(initialModalState);
    }, []);

    const openModal1 = (modal) => {
        const updatedModalState = { ...modalState };
        updatedModalState[modal.name] = true;
        setModalState(updatedModalState);
    };
    const handleCloseModal1 = (modal) => {
        const updatedModalState = { ...modalState };
        updatedModalState[modal.name] = false;
        setModalState(updatedModalState);
    };

    const [selectedImages, setSelectedImages] = useState([]);
    const [isImageModalOpen, setIsImageModalOpen] = useState(false);
    const [selectedProductName, setSelectedProductName] = useState('');
    const [selectedImage, setSelectedImage] = useState('');

    const openImageModal = (productId, productName) => {
        // Llamar al método para obtener las imágenes de ProductImage
        ShippingDataService.getProductImages(productId, token)
          .then((response) => {
            console.log('Datos de las imágenes obtenidos con éxito:', response.data);
      
            // Comprobar si response.data es un objeto o una matriz
            if (Array.isArray(response.data)) {
              // Filtrar las imágenes que tienen el mismo productId
              const filteredImages = response.data.filter((image) => image.product === productId);
              setSelectedImages(filteredImages); // Establecer las imágenes filtradas en el estado
            } else if (typeof response.data === 'object' && response.data.product === productId) {
              // Si response.data es un objeto y tiene el mismo productId, convertirlo en una matriz
              setSelectedImages([response.data]);
            } else {
              setSelectedImages([]); // Establecer un arreglo vacío si no hay imágenes correspondientes
            }
      
            setSelectedProductName(productName); // Establecer el nombre del producto
            setSelectedProductId(productId); // Establecer el nombre del producto
            setIsImageModalOpen(true); // Abrir el modal
          })
          .catch((error) => {
            console.error('Error al obtener las imágenes:', error);
            // Si hay un error al obtener las imágenes, aún puedes abrir el modal sin imágenes.
            setSelectedImages([]); // Establecer un arreglo vacío para que el modal se abra sin imágenes
            setSelectedProductName(productName); // Establecer el nombre del producto
            setSelectedProductId(productId); // Establecer el nombre del producto
            setIsImageModalOpen(true); // Abrir el modal
          });
      };
      
      


    const [selectedProductId, setSelectedProductId] = useState(null);
    const [selectedImageFile, setSelectedImageFile] = useState(null);
    const [selectedImageComment, setSelectedImageComment] = useState('');

    const handleAddImage = async () => {
        try {
            if (!selectedProductId || !selectedImageFile) {
                console.error('Por favor, seleccione un producto y una imagen.');
                return;
            }

            // Crea un objeto FormData para enviar la imagen al servidor
            const formData = new FormData();
            formData.append('product', selectedProductId); // Utiliza el ID correcto del producto
            formData.append('image', selectedImageFile); // Utiliza el archivo de imagen seleccionado
            formData.append('comment', selectedImageComment); // Utiliza el comentario deseado

            // Agrega el siguiente console.log para verificar el contenido de formData
            console.log('Contenido de formData:');
            for (const pair of formData.entries()) {
                console.log(pair[0] + ', ' + pair[1]);
            }

            // Llama al servicio para agregar la imagen al producto
            const response = await ShippingDataService.addProductImage(selectedProductId, formData, token);

            // Verifica si la respuesta es exitosa y maneja la lógica adicional según sea necesario
            if (response.status === 201) {
                // La imagen se ha agregado correctamente, puedes actualizar el estado o realizar otras acciones aquí
                console.log('Imagen agregada con éxito.');
                setIsImageModalOpen(false); // Cierra el modal después de agregar la imagen si es necesario
            } else {
                // Manejar errores si es necesario
                console.error('Error al agregar la imagen.');
            }
            openImageModal(selectedProductId, selectedProductName);
        } catch (error) {
            // Manejar errores de red u otros errores
            console.error('Error al agregar la imagen:', error);
        }
    };


    const handleImageFileChange = (e) => {
        // Aquí puedes manejar el cambio en el archivo de imagen
        const file = e.target.files[0];
        setSelectedImageFile(file);
    };

    const handleImageSelect = (imageId) => {
        // Comprueba si la imagen ya está seleccionada
        const isSelected = selectedImages.includes(imageId);

        // Si la imagen está seleccionada, quítala del conjunto; de lo contrario, agrégala
        if (isSelected) {
            setSelectedImages(selectedImages.filter((id) => id !== imageId));
        } else {
            setSelectedImages([...selectedImages, imageId]);
        }
    };

    const [selectedImageIds, setSelectedImageIds] = useState([]);
    const handleImageCheckboxChange = (e) => {
        const imageId = parseInt(e.target.value); // Convierte el valor del checkbox a un número

        if (e.target.checked) {
            // Si el checkbox se marca, agrega el ID a la lista de IDs seleccionados
            setSelectedImageIds([imageId]);
            console.log(`Checkbox seleccionado con ID: ${imageId}`);
        } else {
            // Si el checkbox se desmarca, quita el ID de la lista de IDs seleccionados
            setSelectedImageIds(selectedImageIds.filter((id) => id !== imageId));
            console.log(`Checkbox deseleccionado con ID: ${imageId}`);
        }
    };
    const handleDeleteImages = async () => {
        try {
            if (selectedImageIds.length === 0) {
                console.error('Por favor, selecciona al menos una imagen para eliminar.');
                return;
            }

            // Llama al servicio para eliminar las imágenes seleccionadas
            const response = await ShippingDataService.deleteProductImage(selectedImageIds, token);

            // Verifica si la respuesta es exitosa y maneja la lógica adicional según sea necesario
            if (response.every((res) => res.status === 204)) {
                // Todas las imágenes se han eliminado correctamente
                console.log('Imágenes eliminadas con éxito.');
                // Actualiza la lista de imágenes en el estado después de eliminarlas
                setSelectedImages((prevImages) =>
                    prevImages.filter((image) => !selectedImageIds.includes(image.id))
                );
                // Limpia los IDs seleccionados
                setSelectedImageIds([]);
            } else {
                // Manejar errores si es necesario
                console.error('Error al eliminar las imágenes.');
            }
            openImageModal(selectedProductId, selectedProductName);
        } catch (error) {
            // Manejar errores de red u otros errores
            console.error('Error al eliminar las imágenes:', error);
        }
    };




    return (
        <div style={{ marginTop: '10%' }}>

            <h1> Crear Productos</h1>
            {/* <Button variant="danger" onClick={handleShowModal}>
            Crear Producto
        </Button> */}

            {/* Renderizar un botón para cada modal */}
            {productModals.map((modal) => (
                <Button key={modal.id} onClick={() => openModal1(modal)} style={{ display: 'inline-block', marginRight: '10px' }}>
                    {modal.name}
                </Button>
            ))}
            {productModals.map((modal) => (
                <modal.modalComponent
                    key={modal.id}
                    showModal={modalState[modal.name]}
                    handleCloseModal={() => handleCloseModal1(modal)}
                    newProductData={newProductData}
                    setNewProductData={setNewProductData}
                    handleImageChange={handleImageChange}
                    handleCategoryChange={handleCategoryChange}
                    handleSubcategoryChange={handleSubcategoryChange}
                    handleCreateOrEditProduct={handleCreateOrEditProduct}
                />
            ))}


            <ModalProducto1
                showModal={showModal}
                handleCloseModal={handleCloseModal}
                newProductData={newProductData}
                setNewProductData={setNewProductData}
                handleImageChange={handleImageChange}
                handleCategoryChange={handleCategoryChange}
                handleSubcategoryChange={handleSubcategoryChange}
                handleCreateOrEditProduct={handleCreateOrEditProduct}
            />



            <Modal show={isImageModalOpen} onHide={() => setIsImageModalOpen(false)} size="lg"
                style={{ overflowX: 'auto', overflowY: 'auto' }}>
                <Modal.Header closeButton>
                    <Modal.Title>{selectedProductName}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="text-center">
                        {selectedImage && <img src={selectedImage} alt="Selected Product" className="img-fluid" />}
                    </div>
                    <hr />
                    <h4>Imágenes de ProductImage</h4>
                    <div className="row">
                    {Array.isArray(selectedImages) && selectedImages.map((imageData) => (
    <div key={imageData._id} className="col-md-3">
        <div className="text-center">
            <input
                type="checkbox"
                value={imageData._id} // Convierte el ID a una cadena
                onChange={(e) => handleImageCheckboxChange(e, imageData)} // Pasa imageData como argumento
            />
            {imageData.image && <img src={imageData.image} alt="Selected Product" className="img-fluid" />}
            <p>Comentario: {imageData.comment}</p>
        </div>
    </div>
))}

                        <div className="mt-4">
                            <h5>Eliminar Imágenes</h5>
                            <div className="form-group">
                                <button className="btn btn-danger" onClick={handleDeleteImages}>
                                    Eliminar Imágenes Seleccionadas
                                </button>
                            </div>
                        </div>

                    </div>
                    {/* Agregar imagen */}
                    <div className="mt-4">
                        <h5>Agregar Imagen</h5>
                        <div className="form-group">
                            <input type="file" accept="image/*" onChange={handleImageFileChange} />
                        </div>
                        <div className="form-group">
                            <input
                                type="text"
                                placeholder="Comentario"
                                value={selectedImageComment}
                                onChange={(e) => {
                                    setSelectedImageComment(e.target.value);
                                    // console.log("Valor de selectedImageComment:", e.target.value);
                                }}
                            />

                        </div>
                        <div className="form-group">
                            <button className="btn btn-primary" onClick={handleAddImage}>
                                Agregar Imagen
                            </button>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setIsImageModalOpen(false)}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>





            <div style={tableContainerStyle}>
                <table style={tableStyle}>
                    <thead>
                        <tr >
                            <th style={thStyle}>
                                {/* Checkbox for selection */}
                                <input
                                    type="checkbox"
                                    onChange={() => handleCheckboxChange('selectAll')}
                                    checked={selectedProducts.length === productsToShow.length}
                                />
                            </th>
                            <th style={thStyle}>Usuario</th>
                            <th style={thStyle}>Nombre</th>
                            <th style={thStyle}>Imagen</th>
                            <th style={thStyle}>Marca</th>
                            <th style={thStyle}>Categoria</th>
                            <th style={thStyle}>Subcategoria</th>
                            <th style={thStyle}>Descripcion</th>
                            <th style={thStyle}>Calificacion</th>
                            <th style={thStyle}>Num Visualizaciones</th>
                            <th style={thStyle}>Precio</th>
                            <th style={thStyle}>Descuento</th>
                            <th style={thStyle}>Cantidad en bodega</th>
                            <th style={thStyle}>Fecha de creacion</th>
                        </tr>
                    </thead>
                    <tbody>
                        {productsToShow.map((product) => (
                            <tr key={product._id} style={{ borderBottom: "1px solid #ddd" }}>
                                <td style={tdStyle}>
                                    {/* Checkbox for each product */}
                                    <input
                                        type="checkbox"
                                        onChange={() => handleCheckboxChange(product._id)}
                                        checked={selectedProducts.includes(product._id)}
                                    />
                                </td>
                                <td style={tdStyle}>{getUserDisplayName(product.user)}</td>
                                <td style={tdStyle}>
                                    {/* Abre el modal al hacer clic en el nombre */}
                                    <button
                                        style={{ background: "none", border: "none", cursor: "pointer" }}
                                        onClick={() => openModal(product)}
                                    >
                                        {product.name}
                                    </button>
                                </td>
                                <td style={tdStyle}>
                                    {product.image && (
                                        <img
                                            src={product.image}
                                            alt="Product Image"
                                            style={{ maxWidth: "50px" }}
                                            onClick={() => openImageModal(product._id, product.name)}
                                        />
                                    )}
                                </td>
                                <td style={tdStyle}>{product.brand}</td>
                                <td style={tdStyle}>{product.category}</td>
                                <td style={tdStyle}>{product.subcategory}</td>
                                <td style={tdStyle}>{truncateDescription(product.description)}</td>
                                <td style={tdStyle}>{product.rating}</td>
                                <td style={tdStyle}>{product.numReviews}</td>
                                <td style={tdStyle}>₡{product.price}</td>
                                <td style={tdStyle}>{product.discount}</td>
                                <td style={tdStyle}>{product.countInStock}</td>
                                <td style={tdStyle}>{new Date(product.createdAt).toLocaleDateString("es-ES")}</td>

                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            {/* Button to delete selected items */}
            {selectedProducts.length > 0 && (
                <Button variant="danger" onClick={handleDeleteDoubleClick}>
                    Eliminar Producto
                </Button>
            )}

            {/* Paginación para la tabla de productos */}
            <div>
                {products.length > itemsPerPage && (
                    <div>
                        {Array.from({ length: Math.ceil(products.length / itemsPerPage) }, (_, index) => (
                            <button
                                key={index}
                                style={paginationButtonStyle}
                                onMouseOver={handleMouseOver}
                                onMouseOut={handleMouseOut}
                                onClick={() => paginate(index + 1)}
                            >
                                {index + 1}
                            </button>
                        ))}
                    </div>
                )}
            </div>

            <h1>Reviews</h1>
            <div style={tableContainerStyle}>
                <table style={tableStyle}>
                    <thead>
                        <tr>
                            <th style={thStyle}>
                                <input
                                    type="checkbox"
                                    onChange={() => handleTableCheckboxChange('selectAll', 'reviews')}
                                    checked={selectedReviews.length === reviewsToShow.length}
                                />

                            </th>
                            <th style={thStyle}>Usuario</th>
                            <th style={thStyle}>Producto</th>
                            <th style={thStyle}>Nombre</th>
                            <th style={thStyle}>Imagen</th>
                            <th style={thStyle}>Calificacion</th>
                            <th style={thStyle}>Comentarios</th>
                            <th style={thStyle}>Fecha de creacion</th>
                        </tr>
                    </thead>
                    <tbody>
                        {reviewsToShow.map((review) => (
                            <tr key={review._id}>
                                <td style={tdStyle}>
                                    <input
                                        type="checkbox"
                                        onChange={() => handleReviewCheckboxChange(review._id)}
                                        checked={selectedReviews.includes(review._id)}
                                    />
                                </td>
                                <td style={tdStyle}>{getUserDisplayName(review.user)}</td>
                                <td style={tdStyle}>{review.product}</td>
                                <td style={tdStyle}>{review.name}</td>
                                <td style={tdStyle}>
                                    {review.image && (
                                        <img
                                            src={review.image}
                                            alt="Review Image"
                                            style={{ maxWidth: "50px" }}
                                        />
                                    )}
                                </td>
                                <td style={tdStyle}>{review.rating}</td>
                                <td style={tdStyle}>{review.comment}</td>
                                <td style={tdStyle}>{new Date(review.createdAt).toLocaleDateString("es-ES")}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            <Button variant="danger" onClick={handleDeleteSelectedReviews}>
                Eliminar Reviews Seleccionados
            </Button>
            {/* Paginación para la tabla de reviews */}
            <div>
                {reviews.length > itemsPerPage && (
                    <div>
                        {Array.from({ length: Math.ceil(reviews.length / itemsPerPage) }, (_, index) => (
                            <button
                                key={index}
                                style={paginationButtonStyle}
                                onMouseOver={handleMouseOver}
                                onMouseOut={handleMouseOut}
                                onClick={() => paginate(index + 1)}
                            >
                                {index + 1}
                            </button>
                        ))}
                    </div>
                )}
            </div>

            <h1>Orders</h1>
            <div style={tableContainerStyle}>
                <table style={tableStyle}>
                    <thead>
                        <tr>
                            <th style={thStyle}>
                                <input
                                    type="checkbox"
                                    onChange={() => handleTableCheckboxChange('selectAll', 'orders')}
                                    checked={selectedOrders.length === ordersToShow.length}
                                />
                            </th>
                            <th style={thStyle}>Usuario</th>
                            <th style={thStyle}>Metodo de Pago</th>
                            <th style={thStyle}>Impuesto</th>
                            <th style={thStyle}>Precio de Envio</th>
                            <th style={thStyle}>Total</th>
                            <th style={thStyle}>Esta Pago</th>
                            <th style={thStyle}>Fecha de pago</th>
                            <th style={thStyle}>Entregado</th>
                            <th style={thStyle}>Fecha de entrega</th>
                            <th style={thStyle}>Fecha de creacion</th>
                        </tr>
                    </thead>
                    <tbody>
                        {ordersToShow.map((order) => (
                            <tr key={order._id}>
                                <td style={tdStyle}>
                                    <input
                                        type="checkbox"
                                        onChange={() => handleOrderCheckboxChange(order._id)}
                                        checked={selectedOrders.includes(order._id)}
                                    />
                                </td>
                                <td style={tdStyle}>{getUserDisplayName(order.user)}</td>
                                <td style={tdStyle}>{order.paymentMethod}</td>
                                <td style={tdStyle}>₡{order.taxPrice}</td>
                                <td style={tdStyle}>₡{order.shippingPrice}</td>
                                <td style={tdStyle}>₡{order.totalPrice}</td>
                                <td style={tdStyle}>{order.isPaid ? "Paid" : "Not Paid"}</td>
                                <td style={tdStyle}>{new Date(order.paidAt).toLocaleDateString("es-ES")}</td>
                                <td style={tdStyle}>{order.isDeliver ? "Delivered" : "Not Delivered"}</td>
                                <td style={tdStyle}>{new Date(order.deliveredAt).toLocaleDateString("es-ES")}</td>
                                <td style={tdStyle}>{new Date(order.createdAt).toLocaleDateString("es-ES")}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <Button variant="danger" onClick={() => handleDeleteSelectedItems('orders')}>
                Eliminar Orders Seleccionados
            </Button>
            {/* Paginación para la tabla de orders */}
            <div>
                {orders.length > itemsPerPage && (
                    <div>
                        {Array.from({ length: Math.ceil(orders.length / itemsPerPage) }, (_, index) => (
                            <button
                                key={index}
                                style={paginationButtonStyle}
                                onMouseOver={handleMouseOver}
                                onMouseOut={handleMouseOut}
                                onClick={() => paginate(index + 1)}
                            >
                                {index + 1}
                            </button>
                        ))}
                    </div>
                )}
            </div>

            <h1>Items mas solicitados</h1>
            <table style={tableStyle}>
                <thead>
                    <tr>
                        <th style={thStyle}>
                            <input
                                type="checkbox"
                                onChange={() => handleTableCheckboxChange('selectAll', 'orderItems')}
                                checked={selectedOrderItems.length === orderItemsToShow.length}
                            />
                        </th>
                        <th style={thStyle}>Orden</th>
                        <th style={thStyle}>Producto</th>

                        <th style={thStyle}>Cliente</th>
                        <th style={thStyle}>Cantidad</th>
                        <th style={thStyle}>Precio</th>
                    </tr>
                </thead>
                <tbody>
                    {orderItemsToShow.map((orderItem) => (
                        <tr key={orderItem._id}>
                            <td style={tdStyle}>
                                <input
                                    type="checkbox"
                                    onChange={() => handleOrderItemCheckboxChange(orderItem._id)}
                                    checked={selectedOrderItems.includes(orderItem._id)}
                                />
                            </td>
                            <td style={tdStyle}>{orderItem.order}</td>
                            <td style={tdStyle}>{orderItem.product}</td>

                            <td style={tdStyle}>{orderItem.name}</td>
                            <td style={tdStyle}>{orderItem.qty}</td>
                            <td style={tdStyle}>₡{orderItem.price}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <Button variant="danger" onClick={handleDeleteSelectedOrderItems}>
                Eliminar Items Seleccionados
            </Button>
            {/* Paginación para la tabla de order items */}
            <div>

                {orderItems.length > itemsPerPage && (
                    <div>
                        {Array.from({ length: Math.ceil(orderItems.length / itemsPerPage) }, (_, index) => (
                            <button
                                key={index}
                                style={paginationButtonStyle}
                                onMouseOver={handleMouseOver}
                                onMouseOut={handleMouseOut}
                                onClick={() => paginate(index + 1)}
                            >
                                {index + 1}
                            </button>
                        ))}
                    </div>
                )}
            </div>

            <h1>Shipping Addresses</h1>
            <div style={tableContainerStyle}>
                <table style={tableStyle}>
                    <thead>
                        <tr>
                            <th style={thStyle}>
                                <input
                                    type="checkbox"
                                    onChange={() => handleTableCheckboxChange('selectAll', 'shippingAddresses')}
                                    checked={selectedShippingAddresses.length === shippingAddressesToShow.length}
                                />
                            </th>
                            <th style={thStyle}>Orden</th>
                            <th style={thStyle}>Codigo Postal</th>
                            <th style={thStyle}>Direccion</th>
                            <th style={thStyle}>Ciudad</th>
                            <th style={thStyle}>Costo de Envio</th>
                        </tr>
                    </thead>
                    <tbody>
                        {shippingAddressesToShow.map((shippingAddress) => (
                            <tr key={shippingAddress._id}>
                                <td style={tdStyle}>
                                    <input
                                        type="checkbox"
                                        onChange={() => handleShippingAddressCheckboxChange(shippingAddress._id)}
                                        checked={selectedShippingAddresses.includes(shippingAddress._id)}
                                    />
                                </td>
                                <td style={tdStyle}>{shippingAddress.order}</td>
                                <td style={tdStyle}>{shippingAddress.postalCode}</td>
                                <td style={tdStyle}>{shippingAddress.address}</td>
                                <td style={tdStyle}>{shippingAddress.city}</td>
                                <td style={tdStyle}>{shippingAddress.shippingPrice}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <Button variant="danger" onClick={() => handleDeleteSelectedItems('shippingAddresses')}>
                    Eliminar Shipping Addresses Seleccionados
                </Button>
                {/* Paginación para la tabla de shipping addresses */}
                <div>
                    {shippingAddresses.length > itemsPerPage && (
                        <div>
                            {Array.from({ length: Math.ceil(shippingAddresses.length / itemsPerPage) }, (_, index) => (
                                <button
                                    key={index}
                                    style={paginationButtonStyle}
                                    onMouseOver={handleMouseOver}
                                    onMouseOut={handleMouseOut}
                                    onClick={() => paginate(index + 1)}
                                >
                                    {index + 1}
                                </button>
                            ))}
                        </div>
                    )}
                </div>
            </div>



        </div>
    );
}

export default Files_ecommerce;
