import React, { useState, useEffect } from 'react';
import Boton from './iframe';
import HomeScreen from '../ecommerce/Homescreen';
import Footer from "../../components/navigation/Footer";
import image1 from './imagenes/1.jpg';
import image2 from '../../assets/categorias/ABCupon.jpg';


const BolsaDeEmpleo = () => {
  const allowedCategories = [
    'Vehiculos Nuevos',
    'Venta de Motocicletas',
    'Venta de Camiones',
    'Venta de Cuadriciclos',
    'Venta de Lanchas, Botes, Jetsky', 
    'Venta de Campers y Carretas', 
    'Venta de Aeronáutica', 
    'Taller de Vehículos',
    'Taller de Motocicletas', 
    'Taller de Lanchas, Botes',
    'Taller de Aeronáutica', 
    'Repuestos de Vehículos', 
    'Repuestos de Motocicletas', 
    'Repuestos de Camiones', 
    'Repuestos de Cuadriciclos', 
    'Repuestos de Lanchas, Botes, Jetsky', 
    'Repuestos de Campers y Carretas', 
    'Servicio de Grua', 
  ];

  const [inputValue, setInputValue] = useState('');
  const [botones, setBotones] = useState([]);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const filteredBotones = botones.filter((boton) =>
    boton.label.toLowerCase().includes(inputValue.toLowerCase())
  );

  // Hook useEffect para manejar el tamaño de la ventana
  useEffect(() => {
    const handleWindowResize = () => {
      const windowWidth = window.innerWidth;
      const marginTop = windowWidth <= 768 ? '20vh' : '15vh'; // Cambio de valores en dispositivos móviles
      const marginBottom = windowWidth <= 768 ? '10vh' : '0'; // Cambio de valores en dispositivos móviles
      document.documentElement.style.setProperty('--marginTop', marginTop);
      document.documentElement.style.setProperty('--marginBottom', marginBottom);
    };

    // Agregar el listener para el cambio de tamaño de ventana
    window.addEventListener('resize', handleWindowResize);

    // Llamada inicial para establecer el valor correcto
    handleWindowResize();

    // Limpieza del listener al desmontar el componente
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  return (
    <div style={{ marginTop: 'var(--marginTop)' }}>
      <input
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        placeholder="Buscar pagina..."
        style={{
          marginRight: "10px",
          padding: "8px",
          borderRadius: "5px",
          border: "1px solid red",
        }}
      />

      {filteredBotones.map((boton) => (
        <Boton key={boton.label} url={boton.url} label={boton.label} />
      ))}

      <div >
        {/* Imágenes en la parte superior ocupando la mitad de la pantalla */}
        <div style={{ display: 'flex', justifyContent: 'left', height: '10vh', marginBottom: 'var(--marginBottom)' }}>
          <img src={image1} alt="Imagen 1" style={{ maxWidth: '50%', maxHeight: '100%', objectFit: 'cover' }} />
          <img src={image2} alt="Imagen 2" style={{ maxWidth: '50%', maxHeight: '100%', objectFit: 'cover' }} />
        </div>

        {/* Renderizar el componente HomeScreen debajo de las imágenes */}
        <HomeScreen allowedCategories={allowedCategories} />
        <Footer />
      </div>
    </div>
  );
};

export default BolsaDeEmpleo;
