import React from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

function ModalProducto(props) {
    const {
        showModal,
        handleCloseModal,
        newProductData,
        setNewProductData,
        handleImageChange,
        handleCreateOrEditProduct,
        handleCategoryChange,
        handleSubcategoryChange
    } = props;

    return (
        <Modal show={showModal} onHide={handleCloseModal} style={{ overflowX: 'auto', overflowY: 'auto' }}>
            <Modal.Header closeButton>
                <Modal.Title>Crear Construccion, Diseño y supervision</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <button

                    style={{
                        background: "red",
                        color: "white",
                        padding: "8px",
                        borderRadius: "5px",
                        border: "none",
                        cursor: "pointer",
                        position: "absolute",
                        top: "4px",
                        right: "4px",
                    }}
                    onClick={handleCloseModal}
                >
                    X
                </button>
                <Form>
                    {/* Agregar aquí los campos del formulario */}
                    {/* Ejemplo para el campo 'Nombre' */}
                    <Form.Group controlId="formName">
                        <Form.Label>Nombre</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Ingrese el nombre"
                            value={newProductData.name}
                            onChange={(e) =>
                                setNewProductData({ ...newProductData, name: e.target.value })
                            }
                        />
                    </Form.Group>


                    <Form.Group controlId="formBrand">
                        <Form.Label>Marca</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Ingrese la marca"
                            value={newProductData.brand}
                            onChange={(e) => setNewProductData({ ...newProductData, brand: e.target.value })}
                        />
                    </Form.Group>

                    <Form.Group controlId="formCategory">
                        <Form.Label>Categoría</Form.Label>
                        <Form.Select
                            value={newProductData.category}
                            onChange={handleCategoryChange}
                        >
                            <option value="">Seleccione una Categoría</option>

                            <optgroup label="CONSTRUCCIÓN">
                                <option value="Constructoras">Constructoras</option>
                                <option value="Remodelación de Interiores">Remodelación de Interiores</option>
                                <option value="Construcción de Viviendas">Construcción de Viviendas</option>
                                <option value="Construcción de Obras Públicas">Construcción de Obras Públicas</option>
                            </optgroup>

                            <optgroup label="DISEÑO">
                                <option value="Diseño de Interiores">Diseño de Interiores</option>
                                <option value="Diseño Gráfico">Diseño Gráfico</option>
                                <option value="Diseño de Moda">Diseño de Moda</option>
                                <option value="Diseño de Sitios Web">Diseño de Sitios Web</option>
                            </optgroup>

                            <optgroup label="SUPERVISIÓN">
                                <option value="Supervisión de Obras">Supervisión de Obras</option>
                                <option value="Supervisión de Proyectos de Construcción">Supervisión de Proyectos de Construcción</option>
                                <option value="Supervisión de Diseño Gráfico">Supervisión de Diseño Gráfico</option>
                                <option value="Supervisión de Diseño de Interiores">Supervisión de Diseño de Interiores</option>
                            </optgroup>
                        </Form.Select>


                    </Form.Group>
                    <Form.Group controlId="formSubcategory">
                        <Form.Label>Subcategoría</Form.Label>
                        <Form.Select
                            multiple  // Habilita la selección múltiple
                            value={newProductData.subcategory}
                            onChange={handleSubcategoryChange}
                            style={{ height: '50vh' }}
                        >
                            <option value="">Seleccione una Subcategoría</option>

                            <optgroup label="Constructoras">
                                <option value="Construcción de Edificios">Construcción de Edificios</option>
                                <option value="Construcción de Carreteras">Construcción de Carreteras</option>
                                <option value="Construcción de Puentes">Construcción de Puentes</option>
                            </optgroup>

                            <optgroup label="Remodelación de Interiores">
                                <option value="Renovación de Cocinas">Renovación de Cocinas</option>
                                <option value="Reformas de Baños">Reformas de Baños</option>
                                <option value="Decoración de Interiores">Decoración de Interiores</option>
                            </optgroup>

                            <optgroup label="Construcción de Viviendas">
                                <option value="Casas Residenciales">Casas Residenciales</option>
                                <option value="Apartamentos">Apartamentos</option>
                                <option value="Construcción de Casas Prefabricadas">Construcción de Casas Prefabricadas</option>
                            </optgroup>

                            <optgroup label="Construcción de Obras Públicas">
                                <option value="Carreteras y Autopistas">Carreteras y Autopistas</option>
                                <option value="Puentes y Viaductos">Puentes y Viaductos</option>
                                <option value="Construcción de Hospitales">Construcción de Hospitales</option>
                            </optgroup>

                            <optgroup label="Diseño de Interiores">
                                <option value="Diseño de Espacios Comerciales">Diseño de Espacios Comerciales</option>
                                <option value="Diseño de Oficinas">Diseño de Oficinas</option>
                                <option value="Diseño de Restaurantes">Diseño de Restaurantes</option>
                            </optgroup>

                            <optgroup label="Supervisión de Obras">
                                <option value="Supervisión de Construcción Residencial">Supervisión de Construcción Residencial</option>
                                <option value="Supervisión de Obras de Infraestructura">Supervisión de Obras de Infraestructura</option>
                                <option value="Supervisión de Proyectos de Ingeniería">Supervisión de Proyectos de Ingeniería</option>
                            </optgroup>

                            <optgroup label="Supervisión de Proyectos de Construcción">
                                <option value="Supervisión de Proyectos de Arquitectura">Supervisión de Proyectos de Arquitectura</option>
                                <option value="Supervisión de Proyectos de Diseño Industrial">Supervisión de Proyectos de Diseño Industrial</option>
                                <option value="Supervisión de Proyectos de Ingeniería Civil">Supervisión de Proyectos de Ingeniería Civil</option>
                            </optgroup>
                            
                            <optgroup label="Supervisión de Diseño de Interiores">
                                <option value="Supervisión de Diseño de Espacios Comerciales">Supervisión de Diseño de Espacios Comerciales</option>
                                <option value="Supervisión de Diseño de Oficinas">Supervisión de Diseño de Oficinas</option>
                                <option value="Supervisión de Diseño de Restaurantes">Supervisión de Diseño de Restaurantes</option>
                            </optgroup>

                        </Form.Select>
                    </Form.Group>




                    <Form.Group controlId="formDescription">
                        <Form.Label>Descripción</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            placeholder="Ingrese la descripción"
                            value={newProductData.description}
                            onChange={(e) => setNewProductData({ ...newProductData, description: e.target.value })}
                        />
                    </Form.Group>

                    <Form.Group controlId="formPrice">
                        <Form.Label>Precio</Form.Label>
                        <Form.Control
                            type="number"
                            placeholder="Ingrese el precio"
                            value={newProductData.price}
                            onChange={(e) => setNewProductData({ ...newProductData, price: e.target.value })}
                        />
                    </Form.Group>

                    <Form.Group controlId="formDiscount">
                        <Form.Label>Descuento</Form.Label>
                        <Form.Control
                            type="number"
                            placeholder="Ingrese el descuento"
                            value={newProductData.discount}
                            onChange={(e) => setNewProductData({ ...newProductData, discount: e.target.value })}
                        />
                    </Form.Group>

                    <Form.Group controlId="formCountInStock">
                        <Form.Label>Cantidad en Stock</Form.Label>
                        <Form.Control
                            type="number"
                            placeholder="Ingrese la cantidad en stock"
                            value={newProductData.countInStock}
                            onChange={(e) => setNewProductData({ ...newProductData, countInStock: e.target.value })}
                        />
                    </Form.Group>

                    <Form.Group controlId="formImage">
                        <Form.Label>Imagen</Form.Label>
                        <Form.Control type="file" onChange={handleImageChange} />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseModal}>
                    Cerrar
                </Button>
                <Button variant="primary" onClick={handleCreateOrEditProduct}>
                    Guardar
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default ModalProducto;
