import React, { useState, useEffect, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import {
  FaShoppingCart,
  FaMapMarkerAlt,
  FaSearch,
  FaStar,
} from "react-icons/fa";
import ShippingDataService from "../../services/shipping";
import TodoDataService from "../../services/todos";
import { useSelector } from "react-redux";
import Footer from "../../components/navigation/Footer";
import CurrentUserContext from "../../components/backend/CurrentUserContext";

function useInterval(callback, delay) {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

export { useInterval };


// Componente principal
function HomeScreen(props) {
  // Estado para productos
  const [products, setProducts] = useState([]);
  const [userList, setUserList] = useState([]);
  const [storedData, setStoredData] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [minStars, setMinStars] = useState(0);
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(80000000);
  const [modalProduct, setModalProduct] = useState(null);
  const [reviews, setReviews] = useState([]);
  const [orders, setOrders] = useState([]);
  const [orderItems, setOrderItems] = useState([]);
  const [shippingAddresses, setShippingAddresses] = useState([]);
  const [users, setUsers] = useState([]);
  const [productreviews, setProductReviews] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;
  const [cartItems, setCartItems] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const subtotal = cartItems.reduce(
    (total, item) => total + item.price * item.quantity,
    0
  );

  // Lógica de paginación
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = productreviews.slice(indexOfFirstItem, indexOfLastItem);
  const currentOrders = orders.slice(indexOfFirstItem, indexOfLastItem);
  const [isCartModalOpen, setIsCartModalOpen] = useState(false);
  const [isMapModalOpen, setIsMapModalOpen] = useState(false);
  const { allowedCategories } = props;

  // Funciones de apertura y cierre de modales
  const openCartModal = () => {
    setIsCartModalOpen(true);

    // Obtiene los productos del localStorage y actualiza el estado del carrito
    const storedCartItems = localStorage.getItem("cartItems");
    if (storedCartItems) {
      setCartItems(JSON.parse(storedCartItems));
    }
  };

  const closeCartModal = () => {
    setIsCartModalOpen(false);
  };

  const openMapModal = () => {
    setIsMapModalOpen(true);
  };

  const closeMapModal = () => {
    setIsMapModalOpen(false);
  };

  // Función de paginación
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Estado y efecto para usuarios
  const [currentPageProducts, setCurrentPageProducts] = useState(1);
  const productsPerPage = 12;

  useEffect(() => {
    const storedUser = localStorage.getItem("currentUser");
    if (storedUser) {
      setCurrentUser(JSON.parse(storedUser));
    }
  }, []);

  useEffect(() => {
    fetchProducts();
    fetchReviews();
    fetchOrders();
    fetchOrderItems();
    fetchShippingAddresses();
    // fetchUsers();
    fetchCartItems();
  }, [props.token]);

  const fetchCartItems = () => {
    const storedCartItems = localStorage.getItem("cartItems");
    if (storedCartItems) {
      setCartItems(JSON.parse(storedCartItems));
    }
  };

  useEffect(() => {
    const storedCountry = localStorage.getItem("selectedCountry");
    console.log("pais del navbar", storedCountry)
    if (storedCountry) {
      setSelectedCountry(storedCountry);
    }
  }, []);
  
  const updateSelectedCountryFromLocalStorage = () => {
    const storedCountry = localStorage.getItem("selectedCountry");
    if (storedCountry) {
      setSelectedCountry(storedCountry);
    }
  };

  // Actualiza el estado inicial desde el localStorage
  useEffect(() => {
    updateSelectedCountryFromLocalStorage();
  }, []);

  // Utiliza useInterval para verificar el localStorage cada 4 segundos
  useInterval(() => {
    updateSelectedCountryFromLocalStorage();
    fetchProducts(); // Asegúrate de que esta función recargue los datos según los nuevos valores
  }, 1000);
  
  // const fetchUsers = () => {
  //   TodoDataService.getUserList(token)
  //     .then((response) => {
  //       const modifiedData = response.data.map((user) => ({
  //         ...user,
  //         full_name: `${user.first_name} ${user.last_name}`,
  //       }));
  //       console.log(modifiedData)
  //       setUsers(modifiedData);
  //       setStoredData(modifiedData);
  //     })
  //     .catch((e) => {
  //       console.log(e);
  //     });
  // };
  // Funciones para obtener datos
  const fetchReviews = () => {
    ShippingDataService.getAllReviews()
      .then((response) => {
        setReviews(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const fetchOrders = () => {
    ShippingDataService.getAllOrders()
      .then((response) => {
        setOrders(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const fetchOrderItems = () => {
    ShippingDataService.getAllOrderItems()
      .then((response) => {
        setOrderItems(response.data);
      })
      .catch((error) => {
        console.error("Error fetching order items:", error);
      });
  };

  const fetchShippingAddresses = () => {
    ShippingDataService.getAllShippingAddresses()
      .then((response) => {
        setShippingAddresses(response.data);
      })
      .catch((error) => {
        console.error("Error fetching shipping addresses:", error);
      });
  };

  // useEffect(() => {
  //   fetchProducts();
  // }, [selectedCategory, searchText, minStars, minPrice, maxPrice]);

  const fetchProducts = () => {
    ShippingDataService.getAllProducts()
      .then((response) => {
        setProducts(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // Lógica de categorías únicas
  const uniqueCategories = [
    ...new Set(products.map((product) => product.category)),
  ];

  // Función para filtrar productos
  const filterProducts = () => {
    return products.filter((product) => {
      const nameMatch = product.name
        .toLowerCase()
        .includes(searchText.toLowerCase());
      const categoryMatch =
        !selectedCategory || product.category === selectedCategory;
      const isAllowedCategory = allowedCategories.includes(product.category);
      const starsMatch = product.rating >= minStars;
      const numericMinPrice = parseFloat(minPrice) || 0;
      const numericMaxPrice = parseFloat(maxPrice) || 0;
      const priceMatch =
        product.price >= numericMinPrice && product.price <= numericMaxPrice;

      return (
        nameMatch &&
        categoryMatch &&
        isAllowedCategory &&
        starsMatch &&
        priceMatch
      );
    });
  };

  const filteredProducts = filterProducts();

  // Lógica de paginación para productos
  const indexOfLastProduct = currentPageProducts * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = filteredProducts.slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );

  const paginateProducts = (pageNumber) => {
    setCurrentPageProducts(pageNumber);
  };
  const openImageModal = (productId, productName) => {
    // Llamar al método para obtener las imágenes de ProductImage
    ShippingDataService.getProductImages(productId, token)
      .then((response) => {
        console.log('Datos de las imágenes obtenidos con éxito:', response.data);
  
        // Filtrar las imágenes que tienen el mismo productId
        const filteredImages = response.data.filter((image) => image.product === productId);
  
        setSelectedImages(filteredImages); // Establecer las imágenes filtradas en el estado
        setSelectedProductName(productName); // Establecer el nombre del producto
        setSelectedProductId(productId); // Establecer el nombre del producto
        setCurrentImageIndex(0); // Inicializar el índice de imagen actual en 0
        setIsImageModalOpen(true);
        setIsImageExpanded(false); // Inicializar la imagen como no expandida
      })
      .catch((error) => {
        console.error('Error al obtener las imágenes:', error);
      });
  };
  

  // Función para mostrar la imagen anterior
  const showPreviousImage = () => {
    if (currentImageIndex > 0) {
      setCurrentImageIndex(currentImageIndex - 1);
    }
  };

  // Función para mostrar la imagen siguiente
  const showNextImage = () => {
    if (currentImageIndex < selectedImages.length - 1) {
      setCurrentImageIndex(currentImageIndex + 1);
    }
  };

  // Función para alternar la expansión de la imagen
  const toggleImageExpansion = () => {
    setIsImageExpanded(!isImageExpanded);
  };

  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedProductName, setSelectedProductName] = useState('');
  const [selectedProductId, setSelectedProductId] = useState('');
  const [currentImageIndex, setCurrentImageIndex] = useState(0); // Índice de la imagen actual
  const [isImageExpanded, setIsImageExpanded] = useState(false); // Estado para la imagen expandida

  // Funciones de apertura y cierre del modal de producto
  const openModal = (product) => {
    setModalProduct(product);
    console.log(product);
    console.log(`id: ${product._id}`);
    console.log(`name: ${product.name}`);
    console.log(`image: ${product.image}`);
    console.log(`brand: ${product.brand}`);
    console.log(`category: ${product.category}`);
  
    const productReviews = reviews.filter(
      (review) => review.product === product._id
    );
  
    setProductReviews(productReviews);
  
    // Llamar a ShippingDataService para actualizar numReviews
    const productId = product._id; // Obtener el ID del producto
    ShippingDataService.updateNumReviews(productId, token)
      .then((response) => {
        console.log("Número de revisiones actualizado correctamente:", response.data);
        // Actualizar la vista o realizar cualquier otra acción necesaria
      })
      .catch((error) => {
        console.error("Error al actualizar el número de revisiones:", error);
        // Manejo de errores
      });
  };

  const closeModal = () => {
    setModalProduct(null);
      fetchProducts();
      fetchReviews();
      fetchOrders();
      fetchOrderItems();
      fetchShippingAddresses();
      // fetchUsers();
      fetchCartItems();

  };

  // Función para obtener el nombre de usuario
  const getUserDisplayName = (userId) => {
    console.log("User ID in getUserDisplayName:", userId); // Debugging log
    const user = users.find((u) => u.id === userId);
    return user
      ? `${user.first_name} ${user.last_name}`
      : "Usuario Desconocido";
  };

  // Estilos para los botones de paginación
  const paginationButtonStyle = {
    backgroundColor: "red",
    color: "white",
    borderRadius: "5px",
    cursor: "pointer",
    margin: "0 5px",
    border: "none",
    padding: "5px 10px",
    height: "30px", // Ajusta la altura deseada para los botones
  };

  const paginationButtonHoverStyle = {
    backgroundColor: "black",
    color: "white",
  };
  const tableStyle = {
    width: "100%",
    borderCollapse: "collapse",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    margin: "20px 0",
  };

  const thStyle = {
    background: "#f2f2f2",
    padding: "10px",
    textAlign: "left",
    borderBottom: "1px solid #ddd",
  };

  const tdStyle = {
    padding: "10px",
    textAlign: "left",
    borderBottom: "1px solid #ddd",
  };

  const handleMouseOver = (e) => {
    e.target.style.backgroundColor = paginationButtonHoverStyle.backgroundColor;
    e.target.style.color = paginationButtonHoverStyle.color;
  };

  const handleMouseOut = (e) => {
    e.target.style.backgroundColor = paginationButtonStyle.backgroundColor;
    e.target.style.color = paginationButtonStyle.color;
  };
  const token = useSelector((state) => state.authentication.token);
  const user = useSelector((state) => state.authentication.user);

  const getUserList = () => {
    TodoDataService.getUserList(token)
      .then((response) => {
        // Assuming the API response includes the user's first_name and last_name
        const modifiedData = response.data.map((user) => ({
          ...user,
          full_name: `${user.first_name} ${user.last_name}`,
        }));

        setUserList(modifiedData);
        setStoredData(modifiedData);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  // Tamaño máximo para el nombre y descripción
  const MAX_NAME_LENGTH = 20;
  const MAX_DESCRIPTION_LENGTH = 50;

  // Tamaño máximo del contenedor para cada producto
  const MAX_CONTAINER_WIDTH = 220;

  // Porcentaje adicional para el contenedor principal
  const ADDITIONAL_WIDTH_PERCENTAGE = 10;

  // Calcula el ancho final del contenedor principal
  const CONTAINER_WIDTH =
    MAX_CONTAINER_WIDTH * (1 + ADDITIONAL_WIDTH_PERCENTAGE / 100);

  const handleAddToCart = (product) => {
    // Crea una copia del carrito actual
    const updatedCart = [...cartItems];

    // Verifica si el producto ya está en el carrito
    const existingItem = updatedCart.find((item) => item._id === product._id);

    if (existingItem) {
      // Si el producto ya está en el carrito, incrementa la cantidad
      existingItem.quantity += 1;
    } else {
      // Si el producto no está en el carrito, agrégalo con cantidad 1
      updatedCart.push({ ...product, quantity: 1 });
    }

    // Actualiza el estado del carrito y guarda en el localStorage
    setCartItems(updatedCart);
    localStorage.setItem("cartItems", JSON.stringify(updatedCart));

    // Muestra un mensaje de éxito o realiza otras acciones necesarias
    alert(`Producto "${product.name}" agregado al carrito.`);
  };

  const handleQuantityChange = (itemId, newQuantity) => {
    // Crea una copia del carrito actual
    const updatedCart = cartItems.map((item) =>
      item._id === itemId ? { ...item, quantity: newQuantity } : item
    );

    // Actualiza el estado del carrito y guarda en el localStorage
    setCartItems(updatedCart);
    localStorage.setItem("cartItems", JSON.stringify(updatedCart));
  };

  const handleRemoveFromCart = (itemId) => {
    // Filtra los productos para mantener solo aquellos que no coincidan con el ID
    const updatedCart = cartItems.filter((item) => item._id !== itemId);

    // Actualiza el estado del carrito y guarda en el localStorage
    setCartItems(updatedCart);
    localStorage.setItem("cartItems", JSON.stringify(updatedCart));
  };
  const handleClearCart = () => {
    // Limpia el estado del carrito y el localStorage
    setCartItems([]);
    localStorage.removeItem("cartItems");
  };

  const categoriesWithoutShipping = ["Casas Nuevas", "Casas Alquiler"];

  const handleSendOrder = () => {
    if (currentUser === null) {
      // El usuario no ha iniciado sesión
      alert("Necesita iniciar sesión para realizar un pedido");
    } else {
      // Obtén los detalles del usuario actual
      const { first_name, last_name, phone } = currentUser || {};

      // Filtra los productos para excluir las categorías sin envío
      const filteredCartItems = cartItems.filter(
        (item) => !categoriesWithoutShipping.includes(item.category)
      );

      // Calcula el subtotal
      const subtotal = filteredCartItems.reduce(
        (total, item) => total + item.price * item.quantity,
        0
      );

      // Construye el mensaje con los detalles del carrito y usuario
      let message = `Hola, soy ${first_name} ${last_name}. Mi pedido es el siguiente:\n\n`;

      // Agrega el detalle de cada producto con cantidad
      const orderDetails = filteredCartItems
        .map(
          (item) =>
            `${item.name} (${item.category}, ${item.brand}) x${
              item.quantity
            } - ₡${item.price * item.quantity}`
        )
        .join("\n");

      // Verifica si algún producto tiene categoría sin envío
      const hasCategoryWithoutShipping = cartItems.some((item) =>
        categoriesWithoutShipping.includes(item.category)
      );

      if (!hasCategoryWithoutShipping) {
        // Agrega el detalle de envío solo si no hay productos de categorías sin envío
        const shippingCost = 3500; // Costo fijo de envío por Correos de Costa Rica
        const totalAmount = correosShippingRemoved ? subtotal : subtotal + 3500;

        message += `${orderDetails}\n\nSubtotal: ₡${subtotal}\nEnvío (Correos de Costa Rica): ₡${shippingCost}\nTotal: ₡${totalAmount}`;
      } else {
        // Agrega el detalle de la orden sin envío
        message += `${orderDetails}\n\nSubtotal: ₡${subtotal}\nTotal: ₡${subtotal}`;
      }

      // Construye el enlace de WhatsApp
      const whatsappLink = `https://api.whatsapp.com/send?phone=+50687886767&text=${encodeURIComponent(
        message
      )}`;

      // Abre la aplicación de WhatsApp en una nueva ventana o pestaña
      window.open(whatsappLink, "_blank");
    }
  };

  const [selectedSubcategory, setSelectedSubcategory] = useState(null);

  const [correosShippingRemoved, setCorreosShippingRemoved] = useState(false);

  const [correosShippingCost, setCorreosShippingCost] = useState(3500);

  return (
    <div>
      <div
        style={{
          backgroundColor: "red",
          color: "white",
          padding: "10px",
          textAlign: "center",
        }}
      >
        {/* <h2>Categorías de Productos</h2> */}

        <div style={{ overflowX: "auto" }}>
          <div style={{ display: "flex", justifyContent: "flex-start" }}>
            <button
              key="Todas"
              style={{
                margin: "5px",
                fontWeight: "bold", // Agregar negrita
                boxShadow: "0px 0px 5px black", // Agregar sombreado negro
              }}
              onClick={() => setSelectedCategory(null)} // Limpiar la selección
            >
              Todas las Categorías 
            </button>
            {uniqueCategories
              .filter((category) => allowedCategories.includes(category))
              .map((category) => (
                <button
                  key={category}
                  style={{ margin: "5px" }}
                  onClick={() => setSelectedCategory(category)}
                >
                  {category}
                </button>
              ))}
          </div>
        </div>

        {/* Subcategoria */}
        <div style={{ display: "flex", justifyContent: "flex-start" }}>
          {/* <button
      key="Todas"
      style={{
        margin: "5px",
        fontWeight: "bold", // Agregar negrita
        boxShadow: "0px 0px 5px black", // Agregar sombreado negro
      }}
  onClick={() => setSelectedSubcategory(null)}
>
  Todas las Subcategorías
</button> */}

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginLeft: "20px",
            }}
          >
            {selectedCategory && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: "20px",
                }}
              >
                <button
                  key="Todas"
                  style={{
                    margin: "5px",
                    fontWeight: "bold",
                    boxShadow: "0px 0px 5px black",
                  }}
                  onClick={() => setSelectedSubcategory(null)}
                >
                  Todas las Subcategorías
                </button>
                <h3>Subcategorías de {selectedCategory}</h3>
                {products
                  .filter((product) => product.category === selectedCategory)
                  .map((product) => (
                    <div key={product.subcategory}>
                      {product.subcategory.split(",").map((subcategory) => (
                        <button
                          key={subcategory}
                          style={{ margin: "5px" }}
                          onClick={() => setSelectedSubcategory(subcategory)}
                        >
                          {subcategory}
                        </button>
                      ))}
                    </div>
                  ))}
              </div>
            )}
          </div>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          marginTop: "20px",
          padding: "10px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          borderRadius: "10px",
        }}
      >
        <div style={{ marginRight: "20px" }}>
          <h3>Filtrar por Nombre</h3>
          <div style={{ display: "flex" }}>
            <input
              type="text"
              placeholder="Buscar por nombre"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              style={{
                marginRight: "10px",
                padding: "8px",
                borderRadius: "5px",
                border: "1px solid red",
              }}
            />
            <FaSearch size={20} style={{ alignSelf: "center" }} />
          </div>
        </div>

        <div style={{ marginRight: "20px" }}>
          <h3>Filtrar por Estrellas</h3>
          <div style={{ display: "flex" }}>
            <input
              type="number"
              placeholder="Estrellas mínimas"
              value={minStars}
              onChange={(e) => setMinStars(e.target.value)}
              style={{
                marginRight: "10px",
                padding: "8px",
                borderRadius: "5px",
                border: "1px solid red",
              }}
            />
            <FaStar size={20} style={{ alignSelf: "center" }} />
          </div>
        </div>

        <div>
          <h3>Filtrar por Precio</h3>
          <div style={{ display: "flex" }}>
            <input
              type="number"
              placeholder="Precio mínimo"
              value={minPrice}
              onChange={(e) => setMinPrice(e.target.value)}
              style={{
                marginRight: "10px",
                padding: "8px",
                borderRadius: "5px",
                border: "1px solid red",
              }}
            />
            <input
              type="number"
              placeholder="Precio máximo"
              value={maxPrice}
              onChange={(e) => setMaxPrice(e.target.value)}
              style={{
                marginRight: "10px",
                padding: "8px",
                borderRadius: "5px",
                border: "1px solid red",
              }}
            />
          </div>
        </div>

        <style>
          {`
      @media (max-width: 600px) {
        div {
          flex-direction: column;
        }
      }
    `}
        </style>
      </div>

      <h1>Productos</h1>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-around",
        }}
      >
        {currentProducts.map((product) => {
          // Truncar el nombre y la descripción si es necesario
          const truncatedName =
            product.name.length > MAX_NAME_LENGTH
              ? `${product.name.substring(0, MAX_NAME_LENGTH)}...`
              : product.name;

          const truncatedDescription =
            product.description.length > MAX_DESCRIPTION_LENGTH
              ? `${product.description.substring(0, MAX_DESCRIPTION_LENGTH)}...`
              : product.description;

          return (
            <div
              key={product._id}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                border: "1px solid #ddd",
                borderRadius: "10px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                margin: "10px",
                padding: "20px",
                maxWidth: CONTAINER_WIDTH + "px", // Ajuste del ancho del contenedor principal
                background: "white",
                height: "400px",
              }}
            >
              {product.image && (
                <img
                  src={product.image}
                  alt="Product Image"
                  style={{
                    maxWidth: "100%",
                    height: "150px",
                    width: "auto",
                    borderRadius: "5px",
                    marginBottom: "10px",
                  }}
                />
              )}
              <strong style={{ marginBottom: "10px" }}>{truncatedName}</strong>
              <div style={{ marginBottom: "5px" }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginBottom: "5px",
                    flexDirection: "row", // Añadir esta línea para forzar la dirección horizontal
                  }}
                >
                  {Array.from({ length: product.rating }, (_, index) => (
                    <FaStar
                      key={index}
                      color="gold"
                      style={{ marginRight: "3px" }}
                    />
                  ))}
                  Estrellas
                </div>
              </div>
              <div style={{ marginBottom: "5px" }}>
                ({product.numReviews} Vistas)
              </div>
              <div style={{ marginBottom: "5px" }}>₡{product.price}</div>
              <div style={{ marginBottom: "5px" }}>
                En bodega: {product.countInStock}
              </div>
              <div>
                <button
                  onClick={() => openModal(product)}
                  style={{
                    backgroundColor: "red",
                    color: "white",
                    padding: "8px",
                    borderRadius: "5px",
                    border: "none",
                    cursor: "pointer",
                  }}
                >
                  Ver Detalles
                </button>
              </div>
            </div>
          );
        })}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "10px",
        }}
      >
        <button
          onClick={() => paginateProducts(currentPageProducts - 1)}
          disabled={currentPageProducts === 1}
          style={paginationButtonStyle}
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
        >
          Anterior
        </button>
        <p style={{ margin: "0 10px" }}>Página {currentPageProducts}</p>
        <button
          onClick={() => paginateProducts(currentPageProducts + 1)}
          disabled={indexOfLastProduct >= filteredProducts.length}
          style={paginationButtonStyle}
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
        >
          Siguiente
        </button>
      </div>

      <div
        style={{
          position: "fixed",
          bottom: "10px",
          left: "10px",
          display: "flex",
          justifyContent: "space-between",
          width: "20%",
        }}
      >
        <div
          style={{
            backgroundColor: "blue",
            color: "white",
            padding: "10px",
            borderRadius: "50%",
            cursor: "pointer",
          }}
          onClick={openCartModal}
        >
          <FaShoppingCart size={30} />
        </div>
        {/* <div
          style={{
            backgroundColor: "blue",
            color: "white",
            padding: "10px",
            borderRadius: "50%",
            cursor: "pointer",
          }}
          onClick={openMapModal}
        >
          <FaMapMarkerAlt size={30} />
        </div> */}
      </div>

      {/* Modal Producto */}
      {modalProduct && (
        <div
          style={{
            position: "fixed",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            background: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            overflowY: "auto",
            zIndex: 99,
          }}
        >
          <div
            style={{
              background: "white",
              padding: "20px",
              borderRadius: "10px",
              width: "90%", // Adjust width to make it responsive
              maxWidth: "600px", // Limit maximum width for larger screens
              margin: "7% auto", // Center the modal vertically
              position: "relative",
              overflow: "auto", // Add overflow to enable content scrolling
              overflowY: "auto",
              maxHeight: "90vh",
              marginTop: "30%",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginBottom: "10px",
              }}
            >
              <h2>{modalProduct.name}</h2>
              <button
                onClick={closeModal}
                style={{
                  background: "red",
                  color: "white",
                  padding: "5px", // Aumentamos el tamaño del botón
                  borderRadius: "50%",
                  border: "none",
                  cursor: "pointer",
                  position: "absolute",
                  top: "0vh",
                  right: "4px",
                  zIndex: 99, // Aumentamos el valor de z-index al máximo
                }}
              >
                X
              </button>
            </div>
            {modalProduct.image && (
              <img
                src={modalProduct.image}
                alt="Product Image"
                style={{
                  maxWidth: "100%",
                  height: "auto",
                  borderRadius: "5px",
                  marginBottom: "10px",
                }}
              />
            )}
            <button
              onClick={() =>
                openImageModal(modalProduct._id, modalProduct.name)
              }
              style={{
                background: "blue",
                color: "white",
                padding: "5px",
                borderRadius: "5px",
                border: "none",
                cursor: "pointer",
                marginTop: "10px",
              }}
            >
              Ver imágenes adicionales
            </button>
            <div style={{ textAlign: "center" }}>
              <table
                style={{
                  margin: "auto",
                  borderCollapse: "collapse",
                  width: "80%",
                  textAlign: "left",
                }}
              >
                <tbody>
                  <tr>
                    <td>Estrellas:</td>
                    <td>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          flexDirection: "row",
                        }}
                      >
                        {Array.from(
                          { length: modalProduct.rating },
                          (_, index) => (
                            <FaStar
                              key={index}
                              color="gold"
                              style={{ marginRight: "3px" }}
                            />
                          )
                        )}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Vistas:</td>
                    <td>{modalProduct.numReviews}</td>
                  </tr>
                  <tr>
                    <td>Precio:</td>
                    <td>₡{modalProduct.price}</td>
                  </tr>
                  <tr>
                    <td>Descripción:</td>
                    <td>
                      {modalProduct.description
                        .split("..")
                        .map((item, index) => (
                          <p key={index}>{item.replace(/: /g, ":")}</p>
                        ))}
                    </td>
                  </tr>
                </tbody>
              </table>
              <hr style={{ width: "80%", margin: "20px auto" }} />{" "}
              {/* Línea separadora */}
              <button
                onClick={() => handleAddToCart(modalProduct)}
                style={{
                  backgroundColor: "red",
                  color: "white",
                  padding: "8px",
                  borderRadius: "5px",
                  border: "none",
                  cursor: "pointer",
                  marginTop: "10px",
                }}
              >
                Agregar a mi pedido
              </button>
            </div>

            {/* <div style={{ width: "100%", overflowX: "auto", margin: "20px 0" }}>
              <table style={tableStyle}>
                <thead>
                  <tr>
                    <th style={thStyle}>Usuario</th>
                    <th style={thStyle}>Commentario</th>
                    <th style={thStyle}>Calificacion</th>
                    <th style={thStyle}>Fecha de comentario</th>
                    <th style={thStyle}>Imagen</th>
                  </tr>
                </thead>
                <tbody>
                  {productreviews.map((review) => (
                    <tr
                      key={review._id}
                      style={{ borderBottom: "1px solid #ddd" }}
                    >
                      <td style={tdStyle}>{getUserDisplayName(review.user)}</td>
                      <td style={tdStyle}>{review.comment}</td>
                      <td style={tdStyle}>{review.rating}</td>
                      <td style={tdStyle}>
                        {new Date(review.createdAt).toLocaleDateString("es-ES")}
                      </td>
                      <td style={tdStyle}>
                        {review.image && (
                          <img
                            src={review.image}
                            alt="Review Image"
                            style={{
                              maxWidth: "50px",
                              cursor: "pointer",
                              transition: "max-width 0.3s ease-in-out",
                            }}
                            onClick={(e) => {
                              // Toggle image size on click
                              e.target.style.maxWidth =
                                e.target.style.maxWidth === "50px"
                                  ? "100%"
                                  : "50px";
                            }}
                          />
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "10px",
              }}
            >
              <button
                onClick={() => paginate(currentPage - 1)}
                disabled={currentPage === 1}
                style={paginationButtonStyle}
                onMouseOver={handleMouseOver}
                onMouseOut={handleMouseOut}
              >
                Anterior
              </button>
              <p style={{ margin: "0 10px" }}>Página {currentPage}</p>
              <button
                onClick={() => paginate(currentPage + 1)}
                disabled={indexOfLastItem >= productreviews.length}
                style={paginationButtonStyle}
                onMouseOver={handleMouseOver}
                onMouseOut={handleMouseOut}
              >
                Siguiente
              </button>
            </div> */}
          </div>
        </div>
      )}

      {/* Modal de imágenes adicionales */}
      <Modal
      show={isImageModalOpen}
      onHide={() => setIsImageModalOpen(false)}
      size="lg"
      style={{ overflowX: "auto", overflowY: "auto" }}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <span style={{ fontSize: '300%', fontWeight: 'bold' }}>
            {selectedProductName}
          </span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <div className="text-center">
  {selectedImages.length > 0 && (
    <img
      src={selectedImages[currentImageIndex].image}
      alt="Selected Product"
      className={`img-fluid ${isImageExpanded ? 'expanded' : ''}`}
      onClick={toggleImageExpansion}
      style={{
        cursor: isImageExpanded ? 'zoom-out' : 'zoom-in',
        position: isImageExpanded ? 'fixed' : 'static',
        top: isImageExpanded ? '0' : 'auto',
        left: isImageExpanded ? '0' : 'auto',
        width: isImageExpanded ? '100vw' : 'auto',
        height: isImageExpanded ? '100vh' : 'auto',
        zIndex: isImageExpanded ? '999999' : 'auto',
      }}
    />
  )}
</div>

        <hr />
        <div className="text-left">
        {selectedImages.length > 0 && (
          <p style={{ fontSize: '200%', fontWeight: 'bold' }}>
            Descripcion: {selectedImages[currentImageIndex].comment}
          </p>
          )}
        </div>
        <hr />
        <div className="row">
          <div className="col-md-2">
            {/* Botón "Imagen anterior" */}
            <button
              onClick={showPreviousImage}
              style={{
                backgroundColor: 'blue',
                color: 'white',
                padding: '5px',
                borderRadius: '5px',
                border: 'none',
                cursor: 'pointer',
                marginTop: '10px',
                width: '100%',
              }}
              disabled={currentImageIndex === 0}
            >
              Imagen anterior
            </button>
          </div>

          <div className="col-md-2">
            {/* Botón "Imagen siguiente" */}
            <button
              onClick={showNextImage}
              style={{
                backgroundColor: 'blue',
                color: 'white',
                padding: '5px',
                borderRadius: '5px',
                border: 'none',
                cursor: 'pointer',
                marginTop: '10px',
                width: '100%',
              }}
              disabled={currentImageIndex === selectedImages.length - 1}
            >
              Imagen siguiente
            </button>
          </div>
        </div>
        <hr />
        {/* Botón de cerrar el modal */}
        <div className="text-center">
          <button
            onClick={() => setIsImageModalOpen(false)}
            style={{
              background: "red",
              color: "white",
              padding: "5px",
              borderRadius: "50%",
              border: "none",
              cursor: "pointer",
              position: "absolute",
              top: "0vh",
              right: "4px",
              zIndex: 99999,
            }}
          >
            X
          </button>
        </div>
      </Modal.Body>
    </Modal>

      {/* Modal del Carrito */}
      {isCartModalOpen && (
        <div
          style={{
            position: "fixed",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            background: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            overflowY: "auto", // Habilita el desplazamiento vertical
          }}
        >
          <div
            style={{
              background: "white",
              padding: "20px",
              borderRadius: "10px",
              width: "90%",
              maxWidth: "90%",
              maxHeight: "80%",
              margin: "7% auto",
              position: "relative",
              overflow: "auto",
            }}
          >
            {/* Contenido del Carrito */}
            <h2 onClick={closeCartModal}> Carrito de Compras</h2>
            {/* Agrega aquí la lógica para mostrar los productos en el carrito */}
            <button
              onClick={closeCartModal}
              style={{
                background: "red",
                color: "white",
                padding: "8px",
                borderRadius: "5px",
                border: "none",
                cursor: "pointer",
                position: "absolute",
                top: "40px",
                right: "4px",
                zIndex: 9999, // Asegura que esté en la parte superior
                fontSize: "20px", // Aumenta el tamaño para dispositivos móviles
              }}
            >
              X
            </button>
            <br />
            <table style={tableStyle}>
              <thead>
                <tr>
                  <th style={thStyle}>Producto</th>
                  <th style={thStyle}>Categoría</th>
                  <th style={thStyle}>Marca</th>
                  <th style={thStyle}>Precio</th>
                  <th style={thStyle}>Cantidad</th>
                  <th style={thStyle}>Subtotal</th>
                  <th style={thStyle}>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {cartItems.map((item) => (
                  <tr key={item._id}>
                    <td style={tdStyle}>{item.name}</td>
                    <td style={tdStyle}>{item.category}</td>
                    <td style={tdStyle}>{item.brand}</td>
                    <td style={tdStyle}>₡{item.price}</td>
                    <td style={tdStyle}>
                      <input
                        type="number"
                        value={item.quantity}
                        onChange={(e) =>
                          handleQuantityChange(
                            item._id,
                            parseInt(e.target.value, 10)
                          )
                        }
                        style={{ width: "50px" }}
                      />
                    </td>
                    <td style={tdStyle}>₡{item.price * item.quantity}</td>
                    <td style={tdStyle}>
                      <button onClick={() => handleRemoveFromCart(item._id)}>
                        Eliminar
                      </button>
                    </td>
                  </tr>
                ))}
                {/* Información de envío y total */}
                <tr>
                  <td colSpan="5" style={tdStyle}>
                    Envío por Correos de Costa Rica
                  </td>
                  <td style={tdStyle}>₡{correosShippingCost}</td>
                  <td style={tdStyle}>
                    {correosShippingRemoved ? (
                      <button
                        onClick={() => {
                          setCorreosShippingRemoved(false);
                          setCorreosShippingCost(3500);
                        }}
                      >
                        Restaurar
                      </button>
                    ) : (
                      <button
                        onClick={() => {
                          setCorreosShippingRemoved(true);
                          setCorreosShippingCost(0);
                        }}
                      >
                        Eliminar
                      </button>
                    )}
                  </td>
                </tr>
                <tr>
                  <td colSpan="5" style={tdStyle}>
                    Total
                  </td>
                  <td style={tdStyle}>
                    ₡{correosShippingRemoved ? subtotal : subtotal + 3500}
                  </td>
                </tr>
              </tbody>
            </table>
            <button
              onClick={handleClearCart}
              style={{
                backgroundColor: "red",
                color: "white",
                padding: "10px",
                borderRadius: "5px",
                border: "none",
                cursor: "pointer",
                marginTop: "10px",
              }}
            >
              Vaciar Carrito
            </button>
            <button
              onClick={handleSendOrder}
              style={{
                backgroundColor: "green",
                color: "white",
                padding: "10px",
                borderRadius: "5px",
                border: "none",
                cursor: "pointer",
                marginTop: "10px",
              }}
            >
              Enviar Pedido por WhatsApp
            </button>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "10px",
              }}
            >
              <button
                onClick={() => paginate(currentPage - 1)}
                disabled={currentPage === 1}
                style={paginationButtonStyle}
                onMouseOver={handleMouseOver}
                onMouseOut={handleMouseOut}
              >
                Anterior
              </button>
              <p style={{ margin: "0 10px" }}>Página {currentPage}</p>
              <button
                onClick={() => paginate(currentPage + 1)}
                disabled={indexOfLastItem >= orders.length}
                style={paginationButtonStyle}
                onMouseOver={handleMouseOver}
                onMouseOut={handleMouseOut}
              >
                Siguiente
              </button>
            </div>
          </div>
        </div>
      )}

      {/* {isMapModalOpen && (
  <div
    style={{
      position: "fixed",
      top: "0",
      left: "0",
      width: "100%",
      height: "100%",
      background: "rgba(0, 0, 0, 0.5)",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      overflowY: "auto",
    }}
  >
    <div
      style={{
        background: "white",
        padding: "20px",
        borderRadius: "10px",
        width: "90%",
        maxWidth: "600px",
        margin: "7% auto",
        position: "relative",
        overflow: "auto",
      }}
    >
      
      <h2>Ubicaciones Agregadas</h2>
      <table style={tableStyle}>
        <thead>
          <tr>
            <th style={thStyle}>Orden</th>
            <th style={thStyle}>Pais</th>
            <th style={thStyle}>Ubicacion</th>
            <th style={thStyle}>Ciudad</th>
            <th style={thStyle}>Codigo Postal</th>
            <th style={thStyle}>Precio de envio</th>
          </tr>
        </thead>
        <tbody>
          {shippingAddresses.map((shippingAddress) => (
            <tr key={shippingAddress._id}>
              <td style={tdStyle}>{shippingAddress.order}</td>
              <td style={tdStyle}>{shippingAddress.country}</td>
              <td style={tdStyle}>{shippingAddress.address}</td>
              <td style={tdStyle}>{shippingAddress.city}</td>
              <td style={tdStyle}>{shippingAddress.postalCode}</td>
              <td style={tdStyle}>{shippingAddress.shippingPrice}</td>
              
            </tr>
          ))}
        </tbody>
      </table>
      <button
        onClick={closeMapModal}
        style={{
          background: "red",
          color: "white",
          padding: "8px",
          borderRadius: "5px",
          border: "none",
          cursor: "pointer",
          position: "absolute",
          top: "4px",
          right: "4px",
        }}
      >
        X
      </button>
    </div>
  </div>
)} */}
    </div>
  );
}

export default HomeScreen;
