import React, { useState, useEffect } from 'react';
import { Dialog, DialogContent, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import FileDataService from '../../services/files';

function MyComponent() {
  const [expandedIndex, setExpandedIndex] = useState(-1);
  const [selectedNews, setSelectedNews] = useState([]);
  const [activeCategory, setActiveCategory] = useState('all');
  const [currentSlide, setCurrentSlide] = useState(0);
  const [sliderWidth, setSliderWidth] = useState(0);
  const isSmallScreen = window.innerWidth <= 768;

  const handleExpand = (index) => {
    setExpandedIndex(index);
  };

  const handleClose = () => {
    setExpandedIndex(-1);
  };

  const [newsPosts, setNewsPosts] = useState([]);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    fetchNewsPosts();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % newsPosts.length);
    }, 3000);

    return () => clearInterval(interval);
  }, [newsPosts]);

  const fetchNewsPosts = () => {
    FileDataService.getAllPost()
      .then((response) => {
        const sortedPosts = response.data.sort((a, b) => {
          if (a.category === b.category) {
            if (a.content && !b.content) {
              return -1;
            } else if (!a.content && b.content) {
              return 1;
            }
            return 0;
          }
          return a.category.localeCompare(b.category);
        });

        setNewsPosts(sortedPosts);

        const uniqueCategories = [...new Set(sortedPosts.map((post) => post.category))];
        setCategories(uniqueCategories);

        setSliderWidth(sortedPosts.length * 50); // 50% por clasificado
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const renderPreview = (contentType, content) => {
    if (contentType === 'image') {
      return <img src={content} alt="Preview" style={{ maxWidth: '100px', maxHeight: '100px' }} />;
    } else if (contentType === 'pdf') {
      return (
        <iframe
          src={`${content}#toolbar=0&navpanes=0&view=Fit`}
          title="PDF Preview"
          width="100%"
          height="400"
          frameBorder="0"
        />
      );
    } else if (contentType === 'video') {
      return (
        <video width="300" height="200" controls>
          <source src={content} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      );
    } else {
      return <span>No preview available</span>;
    }
  };

  const toggleSelectNews = (postId) => {
    if (selectedNews.includes(postId)) {
      setSelectedNews(selectedNews.filter((id) => id !== postId));
    } else {
      setSelectedNews([...selectedNews, postId]);
    }
  };

  const handleDownload = (url) => {
    window.location.href = url;
  };

  const handleCategoryFilter = (category) => {
    setActiveCategory(category);
  };

  const handleNextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % newsPosts.length);
  };

  const handlePrevSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === 0 ? newsPosts.length - 1 : prevSlide - 1
    );
  };

  const sliderStyle = {
    width: `${sliderWidth}%`,
    transform: `translateX(-${(currentSlide * 50) / newsPosts.length}%)`,
    transition: 'transform 0.5s ease',
  };

  return (
    <div style={{ fontFamily: "'Times New Roman', serif", background: '#f5f5f5', padding: '20px' }}>
      <div className="bg-white px-4 sm:px-6 lg:px-8">
        <div className="max-w-7xl mx-auto py-16">
          <div className={`text-center ${isSmallScreen ? 'mt-4' : 'mt-12'}`} style={{ marginTop: isSmallScreen ? '40vh' : '5%' }}>
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Clasificados recientes</h2>
          </div>

          <div className="mt-12 relative">
            {isSmallScreen ? (
              <div className="text-center mt-4" style={{ marginTop: '30%' }}>
                <Link to="/news" className="text-base font-semibold text-indigo-600 hover:text-indigo-500">
                  Para ver los clasificados, presiona aquí<span aria-hidden="true"> →</span>
                </Link>
              </div>
            ) : (
              <>
                <div className="overflow-hidden" style={{ width: '100%' }}>
                  <div className="flex space-x-4" style={sliderStyle}>
                    {newsPosts.map((post, index) => (
                      (activeCategory === 'all' || post.category === activeCategory) && (
                        <div
                          key={post.title}
                          className="w-50% h-1/2 bg-white rounded-lg shadow-md overflow-hidden"
                          onClick={() => handleExpand(index)}
                          style={{
                            cursor: 'pointer',
                            background: '#f5f5f5',
                            width: '50%' 
                          }}
                        >
                          <div className="flex-shrink-0 border-b-2 border-gray-200">
                            {post.content && (
                              <div className="h-60 w-full bg-cover">
                                <img
                                  src={post.content}
                                  alt="Preview"
                                  className="object-contain w-full h-full"
                                  style={{ maxHeight: '100%', maxWidth: '100%', background: '#f5f5f5' }}
                                />
                              </div>
                            )}
                          </div>

                          <div className="p-6">
                            <div className="flex items-baseline">
                              <span
                                className={`inline-block px-2 py-1 leading-none ${
                                  post.category ? post.category.color : 'text-gray-600 bg-gray-200'
                                } rounded-full uppercase tracking-wide text-xs font-semibold`}
                              >
                                {post.category ? post.category.name : 'Uncategorized'}
                              </span>
                            </div>
                            <h3 className="mt-2 text-xl font-semibold text-gray-900">{post.category}</h3>
                            <h3 className="mt-2 text-xl font-semibold text-gray-900">{post.title}</h3>
                            <p className="mt-2 text-gray-600">{post.description}</p>
                            <p className="mt-2 text-gray-600">{post.datetime}</p>
                            <p className="mt-2 text-gray-600">{post.phone_number}</p>
                            <p className="mt-2 text-gray-600">{post.whatsapp}</p>
                            <p className="mt-2 text-gray-600">{post.url}</p>
                            <div className="mt-4">
                              <Link
                                to={post.href}
                                className="text-base font-semibold text-indigo-600 hover:text-indigo-500"
                              >
                                Leer más<span aria-hidden="true"> →</span>
                              </Link>
                            </div>
                          </div>
                        </div>
                      )
                    ))}
                  </div>
                </div>

                <button
                  className="absolute top-1/2 transform -translate-y-1/2 left-0 bg-white p-2 rounded-full shadow-md"
                  onClick={handlePrevSlide}
                >
                  &lt;
                </button>
                <button
                  className="absolute top-1/2 transform -translate-y-1/2 right-0 bg-white p-2 rounded-full shadow-md"
                  onClick={handleNextSlide}
                >
                  &gt;
                </button>
              </>
            )}
          </div>
        </div>
      </div>

      <Dialog open={expandedIndex !== -1} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogContent>
          {expandedIndex !== -1 && (
            <div>
              <Typography variant="h5" component="div" gutterBottom>
                {newsPosts[expandedIndex].title}
              </Typography>
              {newsPosts[expandedIndex].content && (
                <div className="h-50 w-full bg-cover">
                  <img
                    src={newsPosts[expandedIndex].content}
                    alt="Preview"
                    className="object-cover w-full h-full"
                  />
                </div>
              )}
              <Typography variant="body1" gutterBottom>
                {newsPosts[expandedIndex].description}
              </Typography>
            </div>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default MyComponent;
