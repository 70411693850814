import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.css";
import Header from "../../components/home/Header";
import Footer from "../../components/navigation/Footer";
import Navbar from "../../components/navigation/Navbar";
import { useMediaQuery } from "react-responsive";
import Distributors from './directorio_comercial/directorio_comercial';

import "./directorio.css";

function Home() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  return (
    <div>
      <Helmet>
        <title>Directorio Comercial de ABCupon</title>
        <meta
          name="description"
          content="Somos una plataforma virtual que reúne a numerosos proveedores cuidadosamente seleccionados, ofreciendo servicios con altos estándares de excelencia. En ABcupon.com, nos esforzamos por hacer que su experiencia de compra sea cómoda, segura y satisfactoria."
        />
      </Helmet>
      <div style={{ marginTop: '8%' }}>
        <h1 className="text-4xl sm:text-6xl lg:text-6xl xl:text-6xl text-black font-bold tracking-tight pb-0 text-center"
          style={{ textShadow: '0 0 2px white', color: 'black' }}>
        </h1>
        <Distributors />
      </div>

      <Footer />
    </div>
  );
}

export default Home;
