import React, { useState, useEffect } from 'react';
import { Container, Table, Form, Button, InputGroup, Modal, FormControl, FormGroup, FormLabel } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import EmployeeDataService from '../../services/employee';
import './TablaDatos.css';

const EmployeesPlanillas = () => {
  const [planillas, setPlanillas] = useState([]);
  const [selectedPlanillas, setSelectedPlanillas] = useState([]);
  const token = useSelector(state => state.authentication.token);
  const [showModal, setShowModal] = useState(false);
  const [newPlanillaData, setNewPlanillaData] = useState({
    empleado: '',
    salario_planilla: 0,
    monto_horas_extra: 0,
    monto_feriado: 0,
    monto_ausencia: 0,
    monto_incapacidad: 0,
    salario_bruto: 0,
    salario_neto: 0,
    mes: '',
    anio: 0,
    deducciones: [],
    aumentos: [],
    admin: [],
  });
  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const fetchData = async () => {
    try {
      const responsePlanillas = await EmployeeDataService.getAllPlanillas(token);
      setPlanillas(responsePlanillas.data);
    } catch (error) {
      console.error('Error al obtener datos:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [token]);

  const handleDeleteSelected = async () => {
    try {
      await EmployeeDataService.deletePlanillas(selectedPlanillas, token);
      setSelectedPlanillas([]);
      fetchData();
    } catch (error) {
      console.error('Error al eliminar planillas:', error);
    }
  };

  const toggleSelectPlanilla = (planillaId) => {
    if (selectedPlanillas.includes(planillaId)) {
      setSelectedPlanillas(selectedPlanillas.filter(id => id !== planillaId));
    } else {
      setSelectedPlanillas([...selectedPlanillas, planillaId]);
    }
  };
  const handleCreatePlanillas = async () => {
    try {
      const newData = {
        ...newPlanillaData,
        deducciones: Array.isArray(newPlanillaData.deducciones) ? newPlanillaData.deducciones : [],
        aumentos: Array.isArray(newPlanillaData.aumentos) ? newPlanillaData.aumentos : [],
        admin: Array.isArray(newPlanillaData.admin) ? newPlanillaData.admin : [],
      };
      
      console.log("datos crear" , newData)
      await EmployeeDataService.createPlanillas(newData, token);
      toggleModal();
      fetchData();
    } catch (error) {
      console.error('Error al crear planilla:', error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewPlanillaData((prevData) => ({
      ...prevData,
      [name]: name === 'deducciones' || name === 'aumentos' || name === 'admin' 
        ? value.split('\n').map(item => parseInt(item.trim(), 10)) 
        : value,
    }));
  };
  

  

  return (
    <Container>
      <div className="tabla-datos-container">
      <br/><br/><br/>
      <h2>Planillas de Empleados</h2>
      <Button variant="primary" onClick={toggleModal}>Agregar Planilla</Button>

       {/* Modal */}
       <Modal show={showModal} onHide={toggleModal}>
          <Modal.Header closeButton>
            <Modal.Title>Agregar Planilla</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormGroup>
              <FormLabel>Empleado</FormLabel>
              <FormControl
                type="text"
                name="empleado"
                value={newPlanillaData.empleado}
                onChange={handleInputChange}
              />
            </FormGroup>

            <FormGroup>
              <FormLabel>Salario Planilla</FormLabel>
              <FormControl
                type="number"
                name="salario_planilla"
                value={newPlanillaData.salario_planilla}
                onChange={handleInputChange}
              />
            </FormGroup>

            <FormGroup>
              <FormLabel>Monto Horas Extra</FormLabel>
              <FormControl
                type="number"
                name="monto_horas_extra"
                value={newPlanillaData.monto_horas_extra}
                onChange={handleInputChange}
              />
            </FormGroup>

            <FormGroup>
              <FormLabel>Monto Feriado</FormLabel>
              <FormControl
                type="number"
                name="monto_feriado"
                value={newPlanillaData.monto_feriado}
                onChange={handleInputChange}
              />
            </FormGroup>

            <FormGroup>
              <FormLabel>Monto Ausencia</FormLabel>
              <FormControl
                type="number"
                name="monto_ausencia"
                value={newPlanillaData.monto_ausencia}
                onChange={handleInputChange}
              />
            </FormGroup>

            <FormGroup>
              <FormLabel>Monto Incapacidad</FormLabel>
              <FormControl
                type="number"
                name="monto_incapacidad"
                value={newPlanillaData.monto_incapacidad}
                onChange={handleInputChange}
              />
            </FormGroup>

            <FormGroup>
              <FormLabel>Salario Bruto</FormLabel>
              <FormControl
                type="number"
                name="salario_bruto"
                value={newPlanillaData.salario_bruto}
                onChange={handleInputChange}
              />
            </FormGroup>

            <FormGroup>
              <FormLabel>Salario Neto</FormLabel>
              <FormControl
                type="number"
                name="salario_neto"
                value={newPlanillaData.salario_neto}
                onChange={handleInputChange}
              />
            </FormGroup>

            <FormGroup>
              <FormLabel>Mes</FormLabel>
              <FormControl
                type="text"
                name="mes"
                value={newPlanillaData.mes}
                onChange={handleInputChange}
              />
            </FormGroup>

            <FormGroup>
              <FormLabel>Año</FormLabel>
              <FormControl
                type="number"
                name="anio"
                value={newPlanillaData.anio}
                onChange={handleInputChange}
              />
            </FormGroup>

            <FormGroup>
  <FormLabel>Deducciones</FormLabel>
  <FormControl
    as="textarea"
    name="deducciones"
    value={newPlanillaData.deducciones}
    onChange={handleInputChange}
  />
</FormGroup>

<FormGroup>
  <FormLabel>Aumentos</FormLabel>
  <FormControl
    as="textarea"
    name="aumentos"
    value={newPlanillaData.aumentos}
    onChange={handleInputChange}
  />
</FormGroup>

<FormGroup>
  <FormLabel>Admin</FormLabel>
  <FormControl
    as="textarea"
    name="admin"
    value={newPlanillaData.admin}
    onChange={handleInputChange}
  />
</FormGroup>


          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={toggleModal}>
              Cerrar
            </Button>
            <Button variant="primary" onClick={handleCreatePlanillas}>
              Guardar
            </Button>
          </Modal.Footer>
        </Modal>


      <div style={{ overflowX: 'auto' }}>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Check</th>
              <th>ID</th>
              <th>Empleado</th>
              <th>Salario Planilla</th>
              <th>Monto Horas Extra</th>
              <th>Monto Feriado</th>
              <th>Monto Ausencia</th>
              <th>Monto Incapacidad</th>
              <th>Salario Bruto</th>
              <th>Salario Neto</th>
              <th>Mes</th>
              <th>Año</th>
              <th>Deducciones</th>
              <th>Aumentos</th>
              <th>Admin</th>
              {/* ... Agregar las demás columnas según tus requerimientos ... */}
            </tr>
          </thead>
          <tbody>
            {planillas.map(planilla => (
              <tr key={planilla.id}>
                <td>
                  <Form.Check
                    type="checkbox"
                    checked={selectedPlanillas.includes(planilla.id)}
                    onChange={() => toggleSelectPlanilla(planilla.id)}
                  />
                </td>
                <td>{planilla.id}</td>
                <td>{planilla.empleado}</td>
                <td>{planilla.salario_planilla}</td>
                <td>{planilla.monto_horas_extra}</td>
                <td>{planilla.monto_feriado}</td>
                <td>{planilla.monto_ausencia}</td>
                <td>{planilla.monto_incapacidad}</td>
                <td>{planilla.salario_bruto}</td>
                <td>{planilla.salario_neto}</td>
                <td>{planilla.mes}</td>
                <td>{planilla.anio}</td>
                <td>{planilla.deducciones}</td>
                <td>{planilla.aumentos}</td>
                <td>{planilla.admin}</td>
                {/* ... Agregar las demás columnas según tus requerimientos ... */}
              </tr>
            ))}
          </tbody>
        </Table>
      </div>

      {selectedPlanillas.length > 0 && (
        <InputGroup className="mb-3">
          <Button variant="danger" onClick={handleDeleteSelected}>
            Eliminar seleccionado
          </Button>
        </InputGroup>
      )}
      </div>
    </Container>
  );
};

export default EmployeesPlanillas;
