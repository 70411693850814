import React, { useState, useEffect } from 'react';
import { Container, Table, Form, Button, InputGroup, Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import EmployeeDataService from '../../services/employee';
import './TablaDatos.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { format, parse, isDate } from 'date-fns';
import es from 'date-fns/locale/es';


const EmployeesList = () => {
  const [empleados, setEmpleados] = useState([]);
  const [selectedEmpleados, setSelectedEmpleados] = useState([]);
  const token = useSelector(state => state.authentication.token);
  const [showModal, setShowModal] = useState(false);
  const [startDate, setStartDate] = useState(new Date()); // Para fecha de nacimiento
  const [ingresoDate, setIngresoDate] = useState(new Date()); // Para fecha de ingreso
  const [salidaDate, setSalidaDate] = useState(new Date()); // Para fecha de salida
  const tipoIdentificacionOptions = ["Cedula de Identidad", "Pasaporte"];
  const tipoArea_trabajoOptions = ["Administrativo", "Operativo", "Ventas"];
  const tipoForma_pagoOptions = ["Mensual", "Quincenal", "Semanal", "Horas"];
  const tipoJornadaOptions = ["Diurna", "Mixta", "Nocturna", "4X3"];

  const [initialFormData, setInitialFormData] = useState({});
  const [formData, setFormData] = useState({
    foto: null, // Para el campo de archivo (imagen)
    nombre_completo: '',
    tipo_identificacion: 'Cedula de Identidad',
    numero_identificacion: '',
    fecha_nacimiento: '',
    telefono: '',
    correo_personal: '',
    cargo_o_puesto: '',
    area_trabajo: '',
    fecha_ingreso: '',
    fecha_salida: '',
    en_caso_emergencia_llamar_a: '',
    numero_emergencia: '',
    forma_pago: 'Mensual',
    tipo_jornada: 'Diurna',
    horas_laboradas: '',
    vacaciones_acumuladas: '',
    salario_mensual: '',
    salario_quincenal: '',
    salario_diario: '',
    salario_hora_ordinaria: '',
    salario_hora_extraordinaria: '',
    salario_hora_doble: '',
    salario_feriado_laborado: '',
    proyecto: '',
    documento: null, // Para el campo de archivo (documento)
  });

  const toggleModal = () => {
    // Si hay datos iniciales, úsalos
    if (Object.keys(initialFormData).length > 0) {
      setFormData({ ...initialFormData });
    }

    setShowModal(!showModal);
  };
  const fetchData = async () => {
    try {
      const responseEmpleados = await EmployeeDataService.getAllEmployees(token);
      setEmpleados(responseEmpleados.data);

      const detallesPromises = responseEmpleados.data.map(async (empleado) => {
        const detalleResponse = await EmployeeDataService.getAllEmployees(token);
        return { [empleado.id]: detalleResponse.data };
      });

      const detallesEmpleados = await Promise.all(detallesPromises);
      const empleadosDetalleObj = Object.assign({}, ...detallesEmpleados);
      // console.log("detalles", empleadosDetalleObj);
    } catch (error) {
      console.error('Error al obtener datos:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [token]);

  const handleDeleteSelected = async () => {
    try {
      await EmployeeDataService.deleteEmployee(selectedEmpleados, token);
      setSelectedEmpleados([]);
      fetchData();
    } catch (error) {
      console.error('Error al eliminar empleados:', error);
    }
  };

  const toggleSelectEmpleado = (empleadoId) => {
    if (selectedEmpleados.includes(empleadoId)) {
      setSelectedEmpleados(selectedEmpleados.filter(id => id !== empleadoId));
    } else {
      setSelectedEmpleados([...selectedEmpleados, empleadoId]);
    }
  };

  const handleCreateEmployee = async () => {
    try {
      const formattedStartDate = startDate ? format(startDate, 'yyyy-MM-dd', { locale: es }) : '';
      const formattedIngresoDate = ingresoDate ? format(ingresoDate, 'yyyy-MM-dd', { locale: es }) : '';
      const formattedSalidaDate = salidaDate ? format(salidaDate, 'yyyy-MM-dd', { locale: es }) : '';

      console.log("formattedStartDate", formattedStartDate)
      console.log("formattedIngresoDate", formattedIngresoDate)
      console.log("formattedSalidaDate", formattedSalidaDate)
      // Formatear el número de teléfono
      const formattedPhoneNumber = formData.telefono ? `+${formData.telefono}` : '';

      // Crear un objeto FormData para enviar archivos
      const formDataWithFiles = new FormData();

      // Agregar el resto de los campos al FormData
      Object.entries(formData).forEach(([key, value]) => {
        if (key === 'foto' || key === 'documento') {
          // Verificar si hay un archivo adjunto antes de agregar al FormData
          if (value) {
            formDataWithFiles.append(key, value, value.name);
          }
        } else {
          formDataWithFiles.append(key, value);
        }
      });

      // Actualizar las fechas en el FormData
      formDataWithFiles.set('fecha_nacimiento', formattedStartDate);
      formDataWithFiles.set('fecha_ingreso', formattedIngresoDate);
      formDataWithFiles.set('fecha_salida', formattedSalidaDate);

      console.log('Empleado creado:', formDataWithFiles);

      // Enviar la solicitud con el FormData actualizado
      await EmployeeDataService.createEmployee(formDataWithFiles, token);

      toggleModal();
      setFormData({});
      fetchData();
      setInitialFormData({ ...formData });
    } catch (error) {
      console.error('Error al crear empleado:', error);
    }
  };

  return (
    <Container>
      <div className="tabla-datos-container">
        <br /><br /><br />
        <h2>Listado de Empleados</h2>
        <Button variant="primary" onClick={toggleModal}>Agregar Empleado</Button>
        {/* Modal */}
        <Modal show={showModal} onHide={toggleModal} dialogClassName="modal-scrollable" style={{ overflowY: 'auto' }}>
          <Modal.Header closeButton>

          </Modal.Header>
          <Modal.Body style={{ overflowY: 'auto', overflowX: 'auto' }}>
            {/* Formulario para agregar empleado */}
            <Form>

              <Table striped bordered>
                <tbody>
                  <tr style={{ marginTop: '2%', marginBottom: '2%', fontWeight: 'bold', color: 'blue' }}>
                    <Modal.Title style={{ marginTop: '6%', marginBottom: '6%', fontWeight: 'bold', color: 'blue' }}>Datos Personales</Modal.Title>
                  </tr>
                  {/* Sección Datos Personales */}
                  {Object.entries(formData).map(([key, value]) => (
                    (key !== 'forma_pago' && key !== 'tipo_jornada' && key !== 'horas_laboradas' && key !== 'vacaciones_acumuladas' &&
                      key !== 'salario_mensual' && key !== 'salario_quincenal' && key !== 'salario_diario' && key !== 'salario_hora_ordinaria' &&
                      key !== 'salario_hora_extraordinaria' && key !== 'salario_hora_doble' && key !== 'salario_feriado_laborado' &&
                      key !== 'proyecto' && key !== 'documento') &&
                    <tr key={key}>
                      <td>
                        <Form.Label>
                          {key === 'en_caso_emergencia_llamar_a' ? 'En Caso de Emergencia Llamar a' : key.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase()).replace('O', 'o')}
                        </Form.Label>
                      </td>
                      <td>
                        {key === 'fecha_nacimiento' || key === 'fecha_ingreso' || key === 'fecha_salida' ? (
                          <DatePicker
                            selected={key === 'fecha_nacimiento' ? startDate : key === 'fecha_ingreso' ? ingresoDate : salidaDate}
                            onChange={(date) => {
                              if (key === 'fecha_nacimiento') {
                                setStartDate(date);
                                setFormData({ ...formData, [key]: isDate(date) ? format(date, 'yyyy-MM-dd') : date });
                              } else if (key === 'fecha_ingreso') {
                                setIngresoDate(date);
                                setFormData({ ...formData, [key]: isDate(date) ? format(date, 'yyyy-MM-dd') : date });
                              } else if (key === 'fecha_salida') {
                                setSalidaDate(date);
                                setFormData({ ...formData, [key]: isDate(date) ? format(date, 'yyyy-MM-dd') : date });
                              }
                            }}
                            dateFormat="dd LLLL yyyy"
                            showYearDropdown
                            scrollableYearDropdown
                            yearDropdownItemNumber={15}
                            locale={es}
                          />
                        ) : key === 'foto' || key === 'documento' ? (
                          <Form.Control
                            type="file"
                            accept={key === 'foto' ? 'image/*' : '.pdf,.doc,.docx'}
                            onChange={(e) => setFormData({ ...formData, [key]: e.target.files[0] })}
                          />
                        ) : key === 'tipo_identificacion' ? (
                          <Form.Control
                            as="select"
                            value={value}
                            onChange={(e) => setFormData({ ...formData, [key]: e.target.value })}
                          >
                            {tipoIdentificacionOptions.map((option) => (
                              <option key={option} value={option}>
                                {option}
                              </option>
                            ))}
                          </Form.Control>
                        ) : key === 'area_trabajo' ? (
                          <Form.Control
                            as="select"
                            value={value}
                            onChange={(e) => setFormData({ ...formData, [key]: e.target.value })}
                          >
                            {tipoArea_trabajoOptions.map((option) => (
                              <option key={option} value={option}>
                                {option}
                              </option>
                            ))}
                          </Form.Control>


                        ) : key === 'telefono' ? (
                          <PhoneInput
                            placeholder="Número de teléfono"
                            value={value}
                            onChange={(phone) => setFormData({ ...formData, [key]: phone })}
                          />
                        ) : key === 'numero_emergencia' ? (
                          <PhoneInput
                            placeholder="Número de Emergencia"
                            value={formData.numero_emergencia}
                            onChange={(phone) => setFormData({ ...formData, numero_emergencia: phone })}
                          />
                        ) : key === 'cargo_o_puesto' ? (
                          <Form.Control
                            type="text"
                            placeholder={`Ingrese ${key.replace(/_/g, ' ')}`}
                            value={value}
                            onChange={(e) => setFormData({ ...formData, [key]: e.target.value })}
                          />
                        ) : (
                          <Form.Control
                            type="text"
                            placeholder={`Ingrese ${key.replace(/_/g, ' ')}`}
                            value={key === 'en_caso_emergencia_llamar_a' ? value : value.toUpperCase()}
                            onChange={(e) => setFormData({ ...formData, [key]: e.target.value })}
                          />
                        )}
                      </td>
                    </tr>
                  ))}

                  {/* Sección Información Salarial */}
                  <tr>
                    <Modal.Title style={{ marginTop: '6%', marginBottom: '6%', fontWeight: 'bold', color: 'blue' }}>Informacion Salarial</Modal.Title>
                  </tr>
                  {Object.entries(formData).map(([key, value]) => (
  (key === 'forma_pago' || key === 'tipo_jornada' || key === 'horas_laboradas' || key === 'vacaciones_acumuladas' ||
    key === 'salario_mensual' || key === 'salario_quincenal' || key === 'salario_diario' || key === 'salario_hora_ordinaria' ||
    key === 'salario_hora_extraordinaria' || key === 'salario_hora_doble' || key === 'salario_feriado_laborado' ||
    key === 'proyecto' || key === 'documento') &&
  <tr key={key}>
    <td>
      <Form.Label>
        {key.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase()).replace('O', 'o')}
      </Form.Label>
    </td>
    <td>
      {key === 'documento' ? (
        <Form.Control
          type="file"
          accept=".pdf,.doc,.docx"
          onChange={(e) => setFormData({ ...formData, [key]: e.target.files[0] })}
        />
      ) : key === 'forma_pago' || key === 'tipo_jornada' ? (
        <Form.Control
          as="select"
          value={value}
          onChange={(e) => setFormData({ ...formData, [key]: e.target.value })}
        >
          {key === 'forma_pago' ? (
            tipoForma_pagoOptions.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))
          ) : (
            tipoJornadaOptions.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))
          )}
        </Form.Control>
      ) : (
        <Form.Control
          type="text"
          placeholder={`Ingrese ${key.replace(/_/g, ' ')}`}
          value={value}
          onChange={(e) => setFormData({ ...formData, [key]: e.target.value })}
        />
      )}
    </td>
  </tr>
))}

                </tbody>
              </Table>
            </Form>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="secondary" onClick={toggleModal}>
              Cerrar
            </Button>
            <Button variant="primary" onClick={handleCreateEmployee}>
              Crear Empleado
            </Button>
          </Modal.Footer>
        </Modal>

        <div style={{ overflowX: 'auto' }}>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Check</th>
                <th>ID</th>
                <th>Nombre Completo</th>
                <th>Tipo de Identificación</th>
                <th>Número de Identificación</th>
                <th>Fecha de Nacimiento</th>
                <th>Cargo o Puesto</th>
                <th>Fecha de Ingreso</th>
                <th>Teléfono</th>
                <th>Correo Personal</th>
                <th>Foto</th>
                <th>Forma de Pago</th>
                <th>Tipo de Jornada</th>
                <th>Área de Trabajo</th>
                <th>Horas Laboradas</th>
                <th>Vacaciones Acumuladas</th>
                <th>Salario Mensual</th>
                <th>Salario Quincenal</th>
                <th>Salario Diario</th>
                <th>Salario Hora Ordinaria</th>
                <th>Salario Hora Extraordinaria</th>
                <th>Salario Hora Doble</th>
                <th>Salario Feriado Laborado</th>
                <th>En Caso de Emergencia Llamar a</th>
                <th>Número de Emergencia</th>
                <th>Proyecto</th>
                <th>Documento</th>
                <th>Fecha de Salida</th>
              </tr>
            </thead>
            <tbody>
              {empleados.map(empleado => (
                <tr key={empleado.id}>
                  <td>
                    <Form.Check
                      type="checkbox"
                      checked={selectedEmpleados.includes(empleado.id)}
                      onChange={() => toggleSelectEmpleado(empleado.id)}
                    />
                  </td>
                  <td>{empleado.id}</td>
                  <td>{empleado.nombre_completo}</td>
                  <td>{empleado.tipo_identificacion}</td>
                  <td>{empleado.numero_identificacion}</td>
                  <td>{empleado.fecha_nacimiento}</td>
                  <td>{empleado.cargo_o_puesto}</td>
                  <td>{empleado.fecha_ingreso}</td>
                  <td>{empleado.telefono}</td>
                  <td>{empleado.correo_personal}</td>
                  <td>
                    {/* Mostrar la foto si existe */}
                    {empleado.foto && (
                      <img
                        src={empleado.foto}
                        alt={`Foto de ${empleado.nombre_completo}`}
                        style={{ width: '50px', height: '50px' }}
                      />
                    )}
                  </td>

                  <td>{empleado.forma_pago}</td>
                  <td>{empleado.tipo_jornada}</td>
                  <td>{empleado.area_trabajo}</td>
                  <td>{empleado.horas_laboradas}</td>
                  <td>{empleado.vacaciones_acumuladas}</td>
                  <td>{empleado.salario_mensual}</td>
                  <td>{empleado.salario_quincenal}</td>
                  <td>{empleado.salario_diario}</td>
                  <td>{empleado.salario_hora_ordinaria}</td>
                  <td>{empleado.salario_hora_extraordinaria}</td>
                  <td>{empleado.salario_hora_doble}</td>
                  <td>{empleado.salario_feriado_laborado}</td>
                  <td>{empleado.en_caso_emergencia_llamar_a ? empleado.en_caso_emergencia_llamar_a.toLowerCase() : ''}</td>
                  <td>{empleado.numero_emergencia}</td>
                  <td>{empleado.proyecto}</td>
                  <td>
                    {/* Mostrar el enlace al documento si existe */}
                    {empleado.documento && (
                      <a
                        href={empleado.documento}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Ver documento
                      </a>
                    )}
                  </td>
                  <td>{empleado.fecha_salida}</td>
                  {/* ... Agregar las demás columnas según tus requerimientos ... */}
                </tr>
              ))}
            </tbody>
          </Table>
        </div>

        {selectedEmpleados.length > 0 && (
          <InputGroup className="mb-3">
            <Button variant="danger" onClick={handleDeleteSelected}>
              Eliminar seleccionado
            </Button>
          </InputGroup>
        )}
      </div>
    </Container>
  );
};

export default EmployeesList;
