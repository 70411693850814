import React from 'react';
import proximamente from './proximamente.png';

const BolsaDeEmpleo = () => {
  return (
    <div style={{ width: '100vw', height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <img src={proximamente} alt="Próximamente" style={{ width: '90%', height: 'auto' }} />
    </div>
  );
};

export default BolsaDeEmpleo;
