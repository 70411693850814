// Home.jsx
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import CTA from "../../components/home/CTA";
import Header from "../../components/home/Header";
import Home_text from "../../components/home/Home_text";
import Product from "../../components/home/product";
import Products from "../../components/home/products";
import Whytdm from "../../components/home/why_tdm";
import LogoCloud from "../../components/home/LogoCloud";
import UseCases from "../../components/home/UseCases";
import Footer from "../../components/navigation/Footer";
import Navbar from "../../components/navigation/Navbar";
import Scroll from "../../components/SmoothScrollbar";

function Home() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Helmet>
        <title>ABcupon - </title>

                <meta
          name="description"
          content="Somos la primer plataforma costarricense en ofrecer servicios y productos tanto nacionales como a mercados internacionales."/>
      </Helmet>
      <Navbar />
      <div data-scroll-section className="pt-28">
        <Header />
        <Products />
        <LogoCloud />
        <Home_text />
        {/* <Product /> */}
        <UseCases />
        <CTA />
        {/* <Whytdm /> */}
        
      </div>
      <Footer />
    </div>
  );
}
export default Home;
