import React from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

function ModalProducto(props) {
    const {
        showModal,
        handleCloseModal,
        newProductData,
        setNewProductData,
        handleImageChange,
        handleCreateOrEditProduct,
        handleCategoryChange,
        handleSubcategoryChange
    } = props;

    return (
        <Modal show={showModal} onHide={handleCloseModal} style={{ overflowX: 'auto', overflowY: 'auto' }}>
            <Modal.Header closeButton>
                <Modal.Title>Crear Autos y Accesorios</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <button

                    style={{
                        background: "red",
                        color: "white",
                        padding: "8px",
                        borderRadius: "5px",
                        border: "none",
                        cursor: "pointer",
                        position: "absolute",
                        top: "4px",
                        right: "4px",
                    }}
                    onClick={handleCloseModal}
                >
                    X
                </button>
                <Form>
                    {/* Agregar aquí los campos del formulario */}
                    {/* Ejemplo para el campo 'Nombre' */}
                    <Form.Group controlId="formName">
                        <Form.Label>Nombre</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Ingrese el nombre"
                            value={newProductData.name}
                            onChange={(e) =>
                                setNewProductData({ ...newProductData, name: e.target.value })
                            }
                        />
                    </Form.Group>


                    <Form.Group controlId="formBrand">
                        <Form.Label>Marca</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Ingrese la marca"
                            value={newProductData.brand}
                            onChange={(e) => setNewProductData({ ...newProductData, brand: e.target.value })}
                        />
                    </Form.Group>

                    <Form.Group controlId="formCategory">
                        <Form.Label>Categoría</Form.Label>
                        <Form.Select
                            value={newProductData.category}
                            onChange={handleCategoryChange}
                        >
                            <option value="">Seleccione una categoría</option>
                            <optgroup label="VENTA">
                                <option value="Vehiculos Nuevos">VENTA DE VEHÍCULOS</option>
                                <option value="Venta de Motocicletas">VENTA DE MOTOCICLETAS</option>
                                <option value="Venta de Camiones">VENTA DE CAMIONES</option>
                                <option value="Venta de Cuadriciclos">VENTA DE CUADRACICLOS</option>
                                <option value="Venta de Lanchas, Botes, Jetsky">VENTA DE LANCHAS, BOTES, JETSKY</option>
                                <option value="Venta de Campers y Carretas">VENTA DE CAMPERS Y CARRETAS</option>
                                <option value="Venta de Aeronáutica">VENTA DE AERONAUTICA</option>
                            </optgroup>
                            <optgroup label="TALLER">
                                <option value="Taller de Vehículos">TALLER DE VEHÍCULOS</option>
                                <option value="Taller de Motocicletas">TALLER DE MOTOS</option>
                                <option value="Taller de Lanchas, Botes">TALLER DE LANCHAS, BOTES</option>
                                <option value="Taller de Aeronáutica">TALLER DE AERONAUTICA</option>
                            </optgroup>
                            <optgroup label="REPUESTOS">
                                <option value="Repuestos de Vehículos">REPUESTOS DE VEHÍCULOS</option>
                                <option value="Repuestos de Motocicletas">REPUESTOS DE MOTOCICLETAS</option>
                                <option value="Repuestos de Camiones">REPUESTOS DE CAMIONES</option>
                                <option value="Repuestos de Cuadriciclos">REPUESTOS DE CUADRACICLOS</option>
                                <option value="Repuestos de Lanchas, Botes, Jetsky">REPUESTOS DE LANCHAS, BOTES, JETSKY</option>
                                <option value="Repuestos de Campers y Carretas">REPUESTOS DE CAMPERS Y CARRETAS</option>
                            </optgroup>
                            <optgroup label="SERVICIOS">
                                <option value="Servicio de Grua">SERVICIO DE GRUA</option>
                            </optgroup>
                        </Form.Select>


                    </Form.Group>
                    <Form.Group controlId="formSubcategory">
                        <Form.Label>Subcategoría</Form.Label>
                        <Form.Select
                            multiple  // Habilita la selección múltiple
                            value={newProductData.subcategory}
                            onChange={handleSubcategoryChange}
                            style={{ height: '50vh' }}
                        >
                            <option value="">Seleccione una Subcategoría</option>
                            <optgroup label="VENTA DE VEHÍCULOS">
                                <option value="Abarth">Abarth</option>
                                <option value="Alfa Romeo">Alfa Romeo</option>
                                <option value="Aro">Aro</option>
                                <option value="Asia">Asia</option>
                                <option value="Asia Motors">Asia Motors</option>
                                <option value="Aston Martin">Aston Martin</option>
                                <option value="Audi">Audi</option>
                                <option value="Austin">Austin</option>
                                <option value="Auverland">Auverland</option>
                                <option value="Bentley">Bentley</option>
                                <option value="Bertone">Bertone</option>
                                <option value="Bmw">Bmw</option>
                                <option value="Cadillac">Cadillac</option>
                                <option value="Chevrolet">Chevrolet</option>
                                <option value="Chrysler">Chrysler</option>
                                <option value="Citroen">Citroen</option>
                                <option value="Corvette">Corvette</option>
                                <option value="Dacia">Dacia</option>
                                <option value="Daewoo">Daewoo</option>
                                <option value="Daf">Daf</option>
                                <option value="Daihatsu">Daihatsu</option>
                                <option value="Daimler">Daimler</option>
                                <option value="Dodge">Dodge</option>
                                <option value="Ferrari">Ferrari</option>
                                <option value="Fiat">Fiat</option>
                                <option value="Ford">Ford</option>
                                <option value="Galloper">Galloper</option>
                                <option value="Gmc">Gmc</option>
                                <option value="Honda">Honda</option>
                                <option value="Hummer">Hummer</option>
                                <option value="Hyundai">Hyundai</option>
                                <option value="Infiniti">Infiniti</option>
                                <option value="Innocenti">Innocenti</option>
                                <option value="Isuzu">Isuzu</option>
                                <option value="Iveco">Iveco</option>
                                <option value="Iveco-pegaso">Iveco-pegaso</option>
                                <option value="Jaguar">Jaguar</option>
                                <option value="Jeep">Jeep</option>
                                <option value="Kia">Kia</option>
                                <option value="Lada">Lada</option>
                                <option value="Lamborghini">Lamborghini</option>
                                <option value="Lancia">Lancia</option>
                                <option value="Land-rover">Land Rover</option>
                                <option value="Ldv">Ldv</option>
                                <option value="Lexus">Lexus</option>
                                <option value="Lotus">Lotus</option>
                                <option value="Mahindra">Mahindra</option>
                                <option value="Maserati">Maserati</option>
                                <option value="Maybach">Maybach</option>
                                <option value="Mazda">Mazda</option>
                                <option value="Mercedes-benz">Mercedes-Benz</option>
                                <option value="Mg">Mg</option>
                                <option value="Mini">Mini</option>
                                <option value="Mitsubishi">Mitsubishi</option>
                                <option value="Morgan">Morgan</option>
                                <option value="Nissan">Nissan</option>
                                <option value="Opel">Opel</option>
                                <option value="Peugeot">Peugeot</option>
                                <option value="Pontiac">Pontiac</option>
                                <option value="Porsche">Porsche</option>
                                <option value="Renault">Renault</option>
                                <option value="Rolls-royce">Rolls-Royce</option>
                                <option value="Rover">Rover</option>
                                <option value="Saab">Saab</option>
                                <option value="Santana">Santana</option>
                                <option value="Seat">Seat</option>
                                <option value="Skoda">Skoda</option>
                                <option value="Smart">Smart</option>
                                <option value="Ssangyong">Ssangyong</option>
                                <option value="Subaru">Subaru</option>
                                <option value="Suzuki">Suzuki</option>
                                <option value="Talbot">Talbot</option>
                                <option value="Tata">Tata</option>
                                <option value="Toyota">Toyota</option>
                                <option value="Umm">Umm</option>
                                <option value="Vaz">Vaz</option>
                                <option value="Volkswagen">Volkswagen</option>
                                <option value="Volvo">Volvo</option>
                                <option value="Wartburg">Wartburg</option>
                            </optgroup>
                            <optgroup label="VENTA DE MOTOCICLETAS">

                                <option value="Honda">Honda</option>
                                <option value="Agrale">Agrale</option>
                                <option value="Bmw">BMW</option>
                                <option value="Suzuki">Suzuki</option>
                                <option value="Lifan">Lifan</option>
                                <option value="Mahindra">Mahindra</option>
                                <option value="Shineray">Shineray</option>
                                <option value="Kasinski">Kasinski</option>
                                <option value="Yamaha">Yamaha</option>
                                <option value="Garinni">Garinni</option>
                                <option value="Sundown">Sundown</option>
                                <option value="Kawasaki">Kawasaki</option>
                                <option value="Polaris">Polaris</option>
                                <option value="Adly">Adly</option>
                                <option value="Amazonas">Amazonas</option>
                                <option value="Aprilia">Aprilia</option>
                                <option value="Atala">Atala</option>
                                <option value="Bajaj">Bajaj</option>
                                <option value="Benelli">Benelli</option>
                                <option value="Beta">Beta</option>
                                <option value="Bimota">Bimota</option>
                                <option value="Brandy">Brandy</option>
                                <option value="Brava">Brava</option>
                                <option value="Brp">BRP</option>
                                <option value="Buell">Buell</option>
                                <option value="Bueno">Bueno</option>
                                <option value="Cagiva">Cagiva</option>
                                <option value="Mobilete">Mobilete</option>
                                <option value="Daelim">Daelim</option>
                                <option value="Dafra">Dafra</option>
                                <option value="Dayang">Dayang</option>
                                <option value="Dayun">Dayun</option>
                                <option value="Derbi">Derbi</option>
                                <option value="Ducati">Ducati</option>
                                <option value="Emme">Emme</option>
                                <option value="Fym">FYM</option>
                                <option value="Gas Gas">Gas Gas</option>
                                <option value="Green">Green</option>
                                <option value="Haobao">Haobao</option>
                                <option value="Harley-Davidson">Harley-Davidson</option>
                                <option value="Hartford">Hartford</option>
                                <option value="Hero">Hero</option>
                                <option value="Husaberg">Husaberg</option>
                                <option value="Husqvarna">Husqvarna</option>
                                <option value="Iros">Iros</option>
                                <option value="Jiapeng Volcano">Jiapeng Volcano</option>
                                <option value="Johnnypag">Johnnypag</option>
                                <option value="Jonny">Jonny</option>
                                <option value="Kahena">Kahena</option>
                                <option value="Kimco">Kimco</option>
                                <option value="Laquila">Laquila</option>
                                <option value="Landum">Landum</option>
                                <option value="Lavrale">Lavrale</option>
                                <option value="Lerivo">Lerivo</option>
                                <option value="Lon-V">Lon-V</option>
                                <option value="Triciclo">Triciclo</option>
                                <option value="Malaguti">Malaguti</option>
                                <option value="Miza">Miza</option>
                                <option value="Moto Guzzi">Moto Guzzi</option>
                                <option value="Mrx">Mrx</option>
                                <option value="Mv Augusta">Mv Augusta</option>
                                <option value="Mvk">MVK</option>
                                <option value="Orca">Orca</option>
                                <option value="Pegassi">Pegassi</option>
                                <option value="Piaggio">Piaggio</option>
                                <option value="Regal Raptor">Regal Raptor</option>
                                <option value="Sanyang">Sanyang</option>
                                <option value="Siamoto">Siamoto</option>
                                <option value="Targos">Targos</option>
                                <option value="Traxx">Traxx</option>
                                <option value="Vento">Vento</option>
                                <option value="Wuyang">Wuyang</option>
                                <option value="Garra">Garra</option>
                                <option value="X Motos">X Motos</option>
                                <option value="Tricker">Tricker</option>
                                <option value="Lambretta">Lambretta</option>
                                <option value="Outros">Outros</option>
                                <option value="Scooter">Scooter</option>
                                <option value="Zongshen">Zongshen</option>
                                <option value="Birelli">Birelli</option>
                                <option value="Walk Machine">Walk Machine</option>
                                <option value="Fbm">FBM</option>
                                <option value="Ariel">Ariel</option>
                                <option value="Ducar">Ducar</option>
                                <option value="Ditally">Ditally</option>
                                <option value="Marva">Marva</option>
                                <option value="Wolver">Wolver</option>
                                <option value="Ktm">KTM</option>
                                <option value="Leopard">Leopard</option>
                                <option value="Jawa">Jawa</option>
                                <option value="Bull">Bull</option>
                                <option value="Can-Am">Can-Am</option>
                                <option value="Acellera">Acellera</option>
                                <option value="Victory">Victory</option>
                                <option value="Indian">Indian</option>
                                <option value="Bravax">Bravax</option>
                                <option value="Garelli">Garelli</option>

                            </optgroup>
                            <optgroup label="VENTA DE CAMIONES">
                                <option value="Scania">Scania</option>
                                <option value="Iveco">Iveco</option>
                                <option value="Renault Trucks">Renault Trucks</option>
                                <option value="Volvo">Volvo</option>
                                <option value="Man">Man</option>
                                <option value="Mercedes-benz">Mercedes-benz</option>
                                <option value="Mitsubishi">Mitsubishi</option>

                            </optgroup>
                            <optgroup label="Venta de Cuadriciclos">
                                <option value="KTM">KTM</option>
                                <option value="BAJAJ">BAJAJ</option>
                                <option value="HERO">HERO</option>
                                <option value="POLARIS">POLARIS</option>
                                <option value="SUSUKI">SUSUKI</option>
                                <option value="CAN-AM">CAN-AM</option>
                                <option value="ALPINE STARS">ALPINE STARS</option>
                            </optgroup>
                            <optgroup label="VENTA DE LANCHAS, BOTES, JETSKY">
                                <option value="LANCHAS">Lanchas</option>
                                <option value="BOTES">Botes</option>
                                <option value="JETSKY">Jetsky</option>
                            </optgroup>
                            <optgroup label="VENTA DE CAMPERS Y CARRETAS">
                                <option value="CAMPERS">Campers</option>
                                <option value="CARRETAS">Carretas</option>
                            </optgroup>
                            <optgroup label="VENTA DE AERONAUTICA">
                                <option value="Aviones">Aviones</option>
                                <option value="Helicópteros">Helicópteros</option>
                                <option value="Drones">Drones</option>
                                <option value="Motores">Motores</option>
                                <option value="Instrumentos de Vuelo">Instrumentos de Vuelo</option>
                                <option value="Simuladores de Vuelo">Simuladores de Vuelo</option>
                                <option value="Cursos de Piloto">Cursos de Piloto</option>
                                <option value="Manuales de Aviación">Manuales de Aviación</option>
                                <option value="Documentos Técnicos">Documentos Técnicos</option>
                            </optgroup>
                            <optgroup label="TALLER DE VEHÍCULOS">
                                <option value="Mecánico">Mecánico</option>
                                <option value="Enderezado y Pintura">Enderezado y Pintura</option>
                                <option value="Electricidad">Electricidad</option>
                                <option value="Neumáticos">Neumáticos</option>
                                <option value="Alineación y Balanceo">Alineación y Balanceo</option>
                                <option value="Frenos">Frenos</option>
                                <option value="Aceite y Lubricantes">Aceite y Lubricantes</option>
                                <option value="Suspensión">Suspensión</option>
                                <option value="Sistema de Escape">Sistema de Escape</option>
                                <option value="Transmisión">Transmisión</option>
                                <option value="Dirección">Dirección</option>
                                <option value="Reparación de Parabrisas">Reparación de Parabrisas</option>
                                <option value="Reparación de Asientos">Reparación de Asientos</option>
                            </optgroup>
                            <optgroup label="TALLER DE MOTOS">
                                <option value="Mecánico">Mecánico</option>
                                <option value="Enderezado y Pintura">Enderezado y Pintura</option>
                                <option value="Electricidad">Electricidad</option>
                                <option value="Neumáticos">Neumáticos</option>
                                <option value="Alineación y Balanceo">Alineación y Balanceo</option>
                                <option value="Frenos">Frenos</option>
                                <option value="Aceite y Lubricantes">Aceite y Lubricantes</option>
                                <option value="Suspensión">Suspensión</option>
                                <option value="Sistema de Escape">Sistema de Escape</option>
                                <option value="Transmisión">Transmisión</option>
                                <option value="Dirección">Dirección</option>
                                <option value="Reparación de Parabrisas">Reparación de Parabrisas</option>
                                <option value="Reparación de Asientos">Reparación de Asientos</option>
                            </optgroup>

                            <optgroup label="TALLER DE LANCHAS, BOTES, JETSKY">
                                <option value="Mantenimiento General">Mantenimiento General</option>
                                <option value="Reparación de Fibra de Vidrio">Reparación de Fibra de Vidrio</option>
                                <option value="Pintura de Botes">Pintura de Botes</option>
                                <option value="Reparación de Motores">Reparación de Motores</option>
                                <option value="Instalación de Equipamiento">Instalación de Equipamiento</option>
                                <option value="Invernaje">Invernaje</option>
                                <option value="Alquiler de Botes">Alquiler de Botes</option>
                            </optgroup>

                            <optgroup label="TALLER DE AERONAUTICA">
                                <option value="Mantenimiento de Aviones">Mantenimiento de Aviones</option>
                                <option value="Reparación de Aviones">Reparación de Aviones</option>
                                <option value="Inspección de Aviones">Inspección de Aviones</option>
                                <option value="Fabricación de Componentes Aeronáuticos">Fabricación de Componentes Aeronáuticos</option>
                                <option value="Diseño y Ingeniería Aeronáutica">Diseño y Ingeniería Aeronáutica</option>
                                <option value="Pintura de Aeronaves">Pintura de Aeronaves</option>
                                <option value="Alquiler de Hangares">Alquiler de Hangares</option>
                                <option value="Otros Servicios Aeronáuticos">Otros Servicios Aeronáuticos</option>
                            </optgroup>

                            <optgroup label="REPUESTOS DE VEHÍCULOS">
                                <option value="Filtros">Filtros</option>
                                <option value="Aceites y Lubricantes">Aceites y Lubricantes</option>
                                <option value="Frenos y Partes de Frenos">Frenos y Partes de Frenos</option>
                                <option value="Suspensión y Dirección">Suspensión y Dirección</option>
                                <option value="Sistema de Escape">Sistema de Escape</option>
                                <option value="Motor y Partes del Motor">Motor y Partes del Motor</option>
                                <option value="Transmisión y Partes de la Transmisión">Transmisión y Partes de la Transmisión</option>
                                <option value="Electrónica y Sensores">Electrónica y Sensores</option>
                                <option value="Luces y Lámparas">Luces y Lámparas</option>
                                <option value="Neumáticos y Ruedas">Neumáticos y Ruedas</option>
                                <option value="Baterías">Baterías</option>
                                <option value="Sistemas de Climatización">Sistemas de Climatización</option>
                                <option value="Accesorios Interiores">Accesorios Interiores</option>
                                <option value="Accesorios Exteriores">Accesorios Exteriores</option>
                                <option value="Herramientas y Equipamiento">Herramientas y Equipamiento</option>
                            </optgroup>
                            <optgroup label="REPUESTOS DE MOTOCICLETAS">
                                <option value="Filtros">Filtros</option>
                                <option value="Aceites y Lubricantes">Aceites y Lubricantes</option>
                                <option value="Frenos y Partes de Frenos">Frenos y Partes de Frenos</option>
                                <option value="Suspensión y Dirección">Suspensión y Dirección</option>
                                <option value="Sistema de Escape">Sistema de Escape</option>
                                <option value="Motor y Partes del Motor">Motor y Partes del Motor</option>
                                <option value="Transmisión y Partes de la Transmisión">Transmisión y Partes de la Transmisión</option>
                                <option value="Electrónica y Sensores">Electrónica y Sensores</option>
                                <option value="Luces y Lámparas">Luces y Lámparas</option>
                                <option value="Neumáticos y Ruedas">Neumáticos y Ruedas</option>
                                <option value="Baterías">Baterías</option>
                                <option value="Sistemas de Escape">Sistemas de Escape</option>
                                <option value="Accesorios para Motocicletas">Accesorios para Motocicletas</option>
                                <option value="Herramientas y Equipamiento">Herramientas y Equipamiento</option>
                                <option value="Otros Repuestos">Otros Repuestos</option>
                            </optgroup>
                            <optgroup label="REPUESTOS DE CAMIONES">
                                <option value="Filtros">Filtros</option>
                                <option value="Aceites y Lubricantes">Aceites y Lubricantes</option>
                                <option value="Frenos y Partes de Frenos">Frenos y Partes de Frenos</option>
                                <option value="Suspensión y Dirección">Suspensión y Dirección</option>
                                <option value="Sistema de Escape">Sistema de Escape</option>
                                <option value="Motor y Partes del Motor">Motor y Partes del Motor</option>
                                <option value="Transmisión y Partes de la Transmisión">Transmisión y Partes de la Transmisión</option>
                                <option value="Electrónica y Sensores">Electrónica y Sensores</option>
                                <option value="Luces y Lámparas">Luces y Lámparas</option>
                                <option value="Neumáticos y Ruedas">Neumáticos y Ruedas</option>
                                <option value="Baterías">Baterías</option>
                                <option value="Sistemas de Escape">Sistemas de Escape</option>
                                <option value="Accesorios para Camiones">Accesorios para Camiones</option>
                                <option value="Herramientas y Equipamiento">Herramientas y Equipamiento</option>
                                <option value="Otros Repuestos">Otros Repuestos</option>
                            </optgroup>
                            <optgroup label="REPUESTOS DE CUADRACICLOS">
                                <option value="Suspensión y Dirección">Suspensión y Dirección</option>
                                <option value="Sistema de Escape">Sistema de Escape</option>
                                <option value="Motor y Partes del Motor">Motor y Partes del Motor</option>
                                <option value="Transmisión y Partes de la Transmisión">Transmisión y Partes de la Transmisión</option>
                                <option value="Electrónica y Sensores">Electrónica y Sensores</option>
                                <option value="Luces y Lámparas">Luces y Lámparas</option>
                                <option value="Neumáticos y Ruedas">Neumáticos y Ruedas</option>
                                <option value="Baterías">Baterías</option>
                                <option value="Sistemas de Escape">Sistemas de Escape</option>
                                <option value="Accesorios para Cuatriciclos">Accesorios para Cuatriciclos</option>
                                <option value="Herramientas y Equipamiento">Herramientas y Equipamiento</option>
                                <option value="Otros Repuestos Especializados">Otros Repuestos Especializados</option>
                            </optgroup>
                            <optgroup label="REPUESTOS DE LANCHAS, BOTES, JETSKY">
                                <option value="Motores y Partes del Motor">Motores y Partes del Motor</option>
                                <option value="Hélices y Propulsores">Hélices y Propulsores</option>
                                <option value="Sistemas Eléctricos y Electrónicos">Sistemas Eléctricos y Electrónicos</option>
                                <option value="Casco y Estructura">Casco y Estructura</option>
                                <option value="Sistema de Combustible">Sistema de Combustible</option>
                                <option value="Sistema de Dirección">Sistema de Dirección</option>
                                <option value="Sistema de Navegación">Sistema de Navegación</option>
                                <option value="Asientos y Tapicería">Asientos y Tapicería</option>
                                <option value="Accesorios para Lanchas, Botes y Jetskis">Accesorios para Lanchas, Botes y Jetskis</option>
                                <option value="Herramientas y Equipamiento">Herramientas y Equipamiento</option>
                                <option value="Otros Repuestos">Otros Repuestos</option>
                            </optgroup>
                            <optgroup label="REPUESTOS DE CAMPERS Y CARRETAS">
                                <option value="Estructura y Chasis">Estructura y Chasis</option>
                                <option value="Sistemas de Agua y Plomería">Sistemas de Agua y Plomería</option>
                                <option value="Sistemas de Electricidad">Sistemas de Electricidad</option>
                                <option value="Sistemas de Gas">Sistemas de Gas</option>
                                <option value="Mobiliario y Equipamiento">Mobiliario y Equipamiento</option>
                                <option value="Toldos y Coberturas">Toldos y Coberturas</option>
                                <option value="Accesorios para Campers y Carretas">Accesorios para Campers y Carretas</option>
                                <option value="Herramientas y Equipamiento">Herramientas y Equipamiento</option>
                            </optgroup>
                            <optgroup label="SERVICIO DE GRUA">
                                <option value="Gruas para Vehículos Ligeros">Gruas para Vehículos Ligeros</option>
                                <option value="Gruas para Vehículos Pesados">Gruas para Vehículos Pesados</option>
                                <option value="Gruas para Remolque de Emergencia">Gruas para Remolque de Emergencia</option>
                                <option value="Gruas para Transporte de Maquinaria">Gruas para Transporte de Maquinaria</option>
                                <option value="Gruas para Transporte de Barcos">Gruas para Transporte de Barcos</option>
                                <option value="Gruas para Trabajos en Altura">Gruas para Trabajos en Altura</option>
                                <option value="Gruas para Servicios de Rescate">Gruas para Servicios de Rescate</option>
                                <option value="Gruas para Servicios de Construcción">Gruas para Servicios de Construcción</option>
                            </optgroup>


                        </Form.Select>
                    </Form.Group>




                    <Form.Group controlId="formDescription">
                        <Form.Label>Descripción</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            placeholder="Ingrese la descripción"
                            value={newProductData.description}
                            onChange={(e) => setNewProductData({ ...newProductData, description: e.target.value })}
                        />
                    </Form.Group>

                    <Form.Group controlId="formPrice">
                        <Form.Label>Precio</Form.Label>
                        <Form.Control
                            type="number"
                            placeholder="Ingrese el precio"
                            value={newProductData.price}
                            onChange={(e) => setNewProductData({ ...newProductData, price: e.target.value })}
                        />
                    </Form.Group>

                    <Form.Group controlId="formDiscount">
                        <Form.Label>Descuento</Form.Label>
                        <Form.Control
                            type="number"
                            placeholder="Ingrese el descuento"
                            value={newProductData.discount}
                            onChange={(e) => setNewProductData({ ...newProductData, discount: e.target.value })}
                        />
                    </Form.Group>

                    <Form.Group controlId="formCountInStock">
                        <Form.Label>Cantidad en Stock</Form.Label>
                        <Form.Control
                            type="number"
                            placeholder="Ingrese la cantidad en stock"
                            value={newProductData.countInStock}
                            onChange={(e) => setNewProductData({ ...newProductData, countInStock: e.target.value })}
                        />
                    </Form.Group>

                    <Form.Group controlId="formImage">
                        <Form.Label>Imagen</Form.Label>
                        <Form.Control type="file" onChange={handleImageChange} />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseModal}>
                    Cerrar
                </Button>
                <Button variant="primary" onClick={handleCreateOrEditProduct}>
                    Guardar
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default ModalProducto;
