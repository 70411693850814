import axios from 'axios';

class EmployeeDataService {
  // Métodos para el modelo Empleado
  getAllEmployees(token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    return axios.get('https://abcupon-backend.com/employee/empleados/');
  }

  createEmployee(data, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    return axios.post("https://abcupon-backend.com/employee/empleados/", data);
  }

  updateEmployee(id, data, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    return axios.put(`https://abcupon-backend.com/employee/empleados/${id}/`, data);
  }

  deleteEmployee(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    return axios.delete(`https://abcupon-backend.com/employee/empleados/${id}/`);
  }

  // Métodos para el modelo Vacaciones
  getAllVacaciones(token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    return axios.get('https://abcupon-backend.com/employee/vacaciones/');
  }

  createVacacion(data, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    return axios.post("https://abcupon-backend.com/employee/vacaciones/", data);
  }

  updateVacacion(id, data, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    return axios.put(`http://localhost:8000/employee/vacaciones/${id}/`, data);
  }

  deleteVacacion(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    return axios.delete(`https://abcupon-backend.com/employee/vacaciones/${id}/`);
  }

  // Métodos para el modelo Incapacidades
  getAllIncapacidades(token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    return axios.get('https://abcupon-backend.com/employee/incapacidades/');
  }

  createIncapacidad(data, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    return axios.post("https://abcupon-backend.com/employee/incapacidades/", data);
  }

  updateIncapacidad(id, data, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    return axios.put(`https://abcupon-backend.com/employee/incapacidades/${id}/`, data);
  }

  deleteIncapacidad(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    return axios.delete(`https://abcupon-backend.com/employee/incapacidades/${id}/`);
  }

  // Métodos para el modelo Impuestos
  getAllImpuestos(token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    return axios.get('https://abcupon-backend.com/employee/impuestos/');
  }
  createImpuestos(data, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    return axios.post("https://abcupon-backend.com/employee/impuestos/", data);
  }

  updateImpuestos(id, data, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    return axios.put(`https://abcupon-backend.com/employee/impuestos/${id}/`, data);
  }

  deleteImpuestos(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    return axios.delete(`https://abcupon-backend.com/employee/impuestos/${id}/`);
  }

 // Métodos para el modelo Planilla
 getAllPlanillas(token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    return axios.get('https://abcupon-backend.com/employee/planillas/');
  }
  createPlanillas(data, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    return axios.post("https://abcupon-backend.com/employee/planillas/", data);
  }

  updatePlanillas(id, data, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    return axios.put(`https://abcupon-backend.com/employee/planillas/${id}/`, data);
  }

  deletePlanillas(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    return axios.delete(`https://abcupon-backend.com/employee/planillas/${id}/`);
  }
  
   // Métodos para el modelo Deducciones
 getAllDeducciones(token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    return axios.get('https://abcupon-backend.com/employee/deducciones/');
  }
  createDeducciones(data, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    return axios.post("https://abcupon-backend.com/employee/deducciones/", data);
  }

  updateDeducciones(id, data, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    return axios.put(`https://abcupon-backend.com/employee/deducciones/${id}/`, data);
  }

  deleteDeducciones(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    return axios.delete(`https://abcupon-backend.com/employee/deducciones/${id}/`);
  }

     // Métodos para el modelo Extras
 getAllExtras(token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    return axios.get('https://abcupon-backend.com/employee/extras/');
  }
  createExtras(data, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    return axios.post("https://abcupon-backend.com/employee/extras/", data);
  }

  updateExtras(id, data, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    return axios.put(`https://abcupon-backend.com/employee/extras/${id}/`, data);
  }

  deleteExtras(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    return axios.delete(`https://abcupon-backend.com/employee/extras/${id}/`);
  }

       // Métodos para el modelo Aguinaldo
 getAllAguinaldo(token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    return axios.get('https://abcupon-backend.com/employee/aguinaldos/');
  }
  createAguinaldo(data, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    return axios.post("https://abcupon-backend.com/employee/aguinaldos/", data);
  }

  updateAguinaldo(id, data, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    return axios.put(`https://abcupon-backend.com/employee/aguinaldos/${id}/`, data);
  }

  deleteAguinaldo(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    return axios.delete(`https://abcupon-backend.com/employee/aguinaldos/${id}/`);
  }

  // Métodos para el modelo Admin
 getAllAdmin(token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    return axios.get('https://abcupon-backend.com/employee/admin/');
  }
  createAdmin(data, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    return axios.post("https://abcupon-backend.com/employee/admin/", data);
  }

  updateAdmin(id, data, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    return axios.put(`https://abcupon-backend.com/employee/admin/${id}/`, data);
  }

  deleteAdmin(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    return axios.delete(`https://abcupon-backend.com/employee/admin/${id}/`);
  }

  

  // Métodos para el modelo RolPuesto
  getAllRolesPuesto(token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    return axios.get('https://abcupon-backend.com/employee/roles-puesto/');
  }

  createRolPuesto(data, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    return axios.post("https://abcupon-backend.com/employee/roles-puesto/", data);
  }

  updateRolPuesto(id, data, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    return axios.put(`https://abcupon-backend.com/employee/roles-puesto/${id}/`, data);
  }

  deleteRolPuesto(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    return axios.delete(`https://abcupon-backend.com/employee/roles-puesto/${id}/`);
  }

  // Métodos para el modelo Puesto
  getAllPuestos(token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    return axios.get('https://abcupon-backend.com/employee/puestos/');
  }

  createPuesto(data, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    return axios.post("https://abcupon-backend.com/employee/puestos/", data);
  }

  updatePuesto(id, data, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    return axios.put(`https://abcupon-backend.com/employee/puestos/${id}/`, data);
  }

  deletePuesto(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    return axios.delete(`https://abcupon-backend.com/employee/puestos/${id}/`);
  }

  // Métodos para el modelo OfertaTrabajo
  getAllOfertasTrabajo(token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    return axios.get('https://abcupon-backend.com/employee/ofertas-trabajo/');
  }

  createOfertaTrabajo(data, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    return axios.post("https://abcupon-backend.com/employee/ofertas-trabajo/", data);
  }

  updateOfertaTrabajo(id, data, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    return axios.put(`https://abcupon-backend.com/employee/ofertas-trabajo/${id}/`, data);
  }

  deleteOfertaTrabajo(id, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    return axios.delete(`https://abcupon-backend.com/employee/ofertas-trabajo/${id}/`);
  }

}

export default new EmployeeDataService();
