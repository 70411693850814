import { Typewriter } from "react-simple-typewriter";
import { Link } from "react-router-dom";
import PhoneIcon from "@mui/icons-material/Phone";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import EmailIcon from "@mui/icons-material/Email";
import { Container, Form, Pagination } from "react-bootstrap";
import React, { useState, useEffect, useRef } from "react";
import DistributorDataService from "../../../services/products";
import "./distributors.css";
import directions from "./direction.json";
import { useMediaQuery } from "react-responsive";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { debounce } from "lodash";

function useInterval(callback, delay) {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

export { useInterval };

function Distributors() {
  const [expandedImage, setExpandedImage] = useState(null);
  const [distributors, setDistributors] = useState([]);
  const [showMap, setShowMap] = useState([]);
  const [showDetails, setShowDetails] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedDistributor, setSelectedDistributor] = useState(null);
  const [isWhatsappModalOpen, setIsWhatsappModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedProvince, setSelectedProvince] = useState("");
  const [selectedCanton, setSelectedCanton] = useState("");
  const [selectedDistrict, setSelectedDistrict] = useState("");
  const [selectedDirectory, setSelectedDirectory] = useState("");
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const [vibrateButton, setVibrateButton] = useState(false);
  const [vibrate, setVibrate] = useState(false);
  const [activeSection, setActiveSection] = useState(null);
  const [allServices, setAllServices] = useState([]);
  const mapContainerRef = useRef(null);
  const [selectedComercialActivity, setSelectedComercialActivity] =
    useState("");
  const [selectedService, setSelectedService] = useState("");
  const [isMapVisible, setIsMapVisible] = useState(true);
  const [isMapOpen, setIsMapOpen] = useState(false);
  const isFirstMapActive = useRef(false);
  const handleNavbarItemClick = (comercialActivity) => {
    setSelectedComercialActivity(comercialActivity);
  };

  useEffect(() => {
    fetchAllSubProducts();
  }, []);
  
  useEffect(() => {
    fetchDistributors();
  }, [selectedCountry, selectedProvince, selectedCanton, selectedDistrict, selectedDirectory, selectedComercialActivity]);
  



  const fetchAllSubProducts = async () => {
    try {
      const allSubProductsResponse =
        await DistributorDataService.getAllSubProduct();
      const allSubProducts = allSubProductsResponse.data;
      const servicesMap = {};
      const updatedAllServices = [];

      for (const subProduct of allSubProducts) {
        // console.log(`Subproducto: ${subProduct.name}`);

        const servicesResponse =
          await DistributorDataService.getAllServicesForSubProduct(
            subProduct.id
          );
        const services = servicesResponse.data;
        servicesMap[subProduct.id] = services;
        allServices.push(...services);
        const modifiedServices = services.map((service) => ({
          ...service,
          subproductId: subProduct.id,
        }));
        // console.log("servicios", modifiedServices)
        updatedAllServices.push(...services);
      }
      // console.log("todos los servicios", updatedAllServices)
      // Actualiza el estado una vez que se hayan recopilado todos los servicios
      setAllServices(allServices);
    } catch (error) {
      console.error("Error al obtener los subproductos y servicios:", error);
    }
  };

  const fetchDistributors = () => {
    DistributorDataService.getAllSubProduct()
      .then((response) => {
        // Filtra distribuidores basándose en los filtros seleccionados
        const filteredDistributors = response.data.filter((distributor) => {
          const normalize = (str) => {
            return str?.toLowerCase()?.normalize("NFD")?.replace(/[\u0300-\u036f]/g, "") || "";
          };
          
          
  
          return (
            (!selectedCountry ||
              normalize(distributor.country) === normalize(selectedCountry)) &&
            (!selectedProvince ||
              normalize(distributor.province) === normalize(selectedProvince)) &&
            (!selectedCanton ||
              (distributor.canton &&
                normalize(distributor.canton) === normalize(selectedCanton))) &&
            (!selectedDistrict ||
              normalize(distributor.distrito) === normalize(selectedDistrict)) &&
            (selectedCountry ||
              normalize(distributor.country1) === normalize(selectedCountry)) &&
            (selectedDirectory !== "comercios" ||
              !distributor.products.includes(19)) &&
            (selectedDirectory !== "cooperativas" ||
              distributor.products.includes(19)) &&
            (selectedDirectory !== "asociaciones" ||
              distributor.products.includes(25)) &&
            (!selectedComercialActivity ||
              normalize(distributor.comercial_activity) ===
                normalize(selectedComercialActivity))
          );
        });
  
        setDistributors(filteredDistributors);
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const handleResetComercialActivity = () => {
    setSelectedComercialActivity("");
  };

  const handleButtonClick = (directory) => {
    setSelectedDirectory(directory);
    fetchDistributors();
    setVibrateButton(true);
    setVibrate(true);
    setTimeout(() => {
      setVibrateButton(false);
    }, 4000);
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setVibrateButton(null);
    }, 200);
    return () => clearTimeout(timeoutId);
  }, [vibrateButton]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setVibrate(true);
  
      setTimeout(() => {
        setVibrate(false);
      }, 200);
    }, 4000);
  
    return () => {
      clearInterval(intervalId);
      setVibrate(false);
    };
  }, []);
  

  const [buttonVibration, setButtonVibration] = useState({
    allProducts: false,
    clasificados: false,
    products: false,
    bolsaEmpleo: false,
  });
  const handleToggleMap = (index) => {
    // Crear un nuevo array con todos los elementos establecidos en false
    const updatedShowMap = Array(showMap.length).fill(false);

    // Si el mapa actual está abierto, ciérralo; de lo contrario, ábrelo
    updatedShowMap[index] = showMap[index] ? false : true;

    setShowMap(updatedShowMap);
    setIsMapOpen(updatedShowMap.some((value) => value));

    // Desplazar la pantalla al final del componente después de un breve tiempo
    setTimeout(() => {
      const iframeId = `mapIframe${index}`;
      const iframeElement = document.getElementById(iframeId);
      if (iframeElement && updatedShowMap[index]) {
        iframeElement.scrollIntoView({
          behavior: "smooth",
          block: "end",
          inline: "nearest",
        });
      }
    }, 100); // Ajusta el tiempo según sea necesario
  };

  const handleToggleDetails = async (index) => {
    // console.log("index", index);
    // Fetch services for the selected distributor
    setSelectedComercialActivity(
      showDetails === index ? null : distributors[index].comercial_activity
    );
    try {
      const servicesResponse = await DistributorDataService.getAllSubProduct(
        distributors[index].id
      );
      // console.log("servicesResponse", servicesResponse);
      // console.log("Services Data:", servicesResponse.data);
      const updatedDistributors = [...distributors];
      updatedDistributors[index].services = servicesResponse.data;
      // console.log("updatedDistributors", servicesResponse.data);
      setDistributors(updatedDistributors);
      setSelectedDistributor(
        showDetails === index ? null : distributors[index]
      );
    } catch (error) {
      console.error(error);
    }

    setShowDetails(showDetails === index ? null : index);
  };


  const delayedSearch = useRef(
    debounce((value) => {
      setSearchTerm(value);
    }, 500)
  ).current;
  
  const handleSearchTermChange = (event) => {
    delayedSearch(event.target.value);
  };

  const filteredDistributors = distributors.filter(
    (distributor) =>
      (distributor.name &&
        distributor.name.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (distributor.country &&
        distributor.country.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (distributor.address &&
        distributor.address.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (distributor.phone_number &&
        distributor.phone_number.includes(searchTerm)) ||
        (distributor.country &&
          distributor.country.includes(searchTerm))||
        (distributor.province &&
          distributor.province.includes(searchTerm))||
        (distributor.canton &&
          distributor.canton.includes(searchTerm))||
        (distributor.comercial_activity &&
          distributor.comercial_activity.includes(searchTerm))||
          (distributor.email &&
            distributor.email.includes(searchTerm))
  );

  const handleFormSubmit = (e) => {
    e.preventDefault();
  
    // Filtra los distribuidores por país, provincia y cantón seleccionados
    const filteredByCanton = distributors.filter((distributor) => {
      const normalize = (str) => {
        if (str && typeof str === 'string') {
          return str
            .toLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "");
        }
        return "";
      };
  
      return (
        distributor &&
        distributor.canton &&
        normalize(distributor.canton) === normalize(selectedCanton.toLowerCase())
      );
    });
  
    // Actualiza la lista de distribuidores con el filtro por cantón
    setDistributors(filteredByCanton);
  
    // Cierra el dropdown y reinicia el cantón seleccionado
    setIsDropdownOpen(false);
    setSelectedCanton("");
  };

  const [currentPage, setCurrentPage] = useState(1);
  const distributorsPerPage = 6;

  // Calculate the index of the last distributor on the current page
  const indexOfLastDistributor = currentPage * distributorsPerPage;
  // Calculate the index of the first distributor on the current page
  const indexOfFirstDistributor = indexOfLastDistributor - distributorsPerPage;
  // Get the distributors for the current page
  const currentDistributors = filteredDistributors.slice(
    indexOfFirstDistributor,
    indexOfLastDistributor
  );

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => (prevPage - 1 >= 1 ? prevPage - 1 : prevPage));
  };
  const uniqueComercialActivities = new Set();

  const handleCountrySelection = (selectedCountry) => {
    let confirmationMessage = "";
    if (selectedDirectory === "comercios") {
      confirmationMessage = `Has seleccionado el Directorio de Comercios del país: ${selectedCountry}.`;
    } else if (selectedDirectory === "cooperativas") {
      confirmationMessage = `Has seleccionado el Directorio de Cooperativas del país: ${selectedCountry}.`;
    } else if (selectedDirectory === "asociaciones") {
      confirmationMessage = `Has seleccionado el Directorio de Asociaciones del país: ${selectedCountry}.`;
    } else {
      confirmationMessage = `Has seleccionado el Directorio General del país: ${selectedCountry}.`;
    }
    
    window.alert(confirmationMessage);
  
    // Add any additional logic related to the country selection if needed
  };
  

  return (
    <main style={{ width: "100%" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          backgroundColor: "white",
          zIndex: 999,
          maxWidth: "100%",
          margin: "0 auto",
          padding: "20px",
          borderRadius: "10px",
        }}
      >
       <Form
  onSubmit={(e) => handleFormSubmit(e)}
  style={{
    width: "60%",
    margin: "0 auto",
    backgroundColor: "#f2f2f2",
    padding: "20px",
    borderRadius: "10px",
  }}
>
<div
  className="buttonContainerStyle"
  style={{
    width: isMobile ? "50%" : "auto", // Ajusta el ancho según sea necesario
    margin: isMobile ? "0 auto" : "", // Centra el div horizontalmente en dispositivos móviles
    textAlign: isMobile ? "center" : "", // Centra el contenido dentro del div en dispositivos móviles
  }}
>
      <button
        className={`buttonStyle1 ${
          selectedDirectory === "comercios" && vibrate ? "logo-vibrating" : ""
        } ${selectedDirectory === "comercios" ? "selectedButton" : ""}`}
        onClick={() => handleButtonClick("comercios")}
        style={isMobile ? { fontSize: "1em", padding: "5px" } : {}}
      >
        {/* <span className="buttonText">Directorio de Comercios</span> */}
        Directorio de Comercios
      </button>
      <button
        className={`buttonStyle1 ${
          selectedDirectory === "cooperativas" && vibrate
            ? "logo-vibrating"
            : ""
        } ${selectedDirectory === "cooperativas" ? "selectedButton" : ""}`}
        onClick={() => handleButtonClick("cooperativas")}
        style={isMobile ? { fontSize: "1em", padding: "5px" } : {}}
      >
        Directorio de Cooperativas
      </button>
      <button
        className={`buttonStyle1 ${
          selectedDirectory === "asociaciones" && vibrate
            ? "logo-vibrating"
            : ""
        } ${selectedDirectory === "asociaciones" ? "selectedButton" : ""}`}
        onClick={() => handleButtonClick("asociaciones")}
        style={isMobile ? { fontSize: "1em", padding: "5px" } : {}}
      >
        Directorio de Asociaciones
      </button>
    </div>

  <table style={{ width: "100%", borderCollapse: "collapse" }}>
    <thead>
      <tr>
      <th
  colSpan="2"
  style={{
    fontSize: "2em",
    color: "red",
    textShadow: "2px 2px 2px #000",
    fontWeight: "bold",
    textAlign: "center",
    borderBottom: "2px solid #ccc",
    padding: "10px",
  }}
>
  {selectedDirectory === "comercios" && (
    <span>Buscar en Directorio de Comercios:</span>
  )}
  {selectedDirectory === "cooperativas" && (
    <span>Buscar en Directorio de Cooperativas:</span>
  )}
  {selectedDirectory === "asociaciones" && (
    <span>Buscar en Directorio de Asociaciones:</span>
  )}
  {!selectedDirectory && <span>Buscar en Directorio General:</span>}
</th>

      </tr>
    </thead>
    <tbody>
      <tr>
        <td colSpan="2">
          <input
            type="text"
            placeholder="Busque aqui su contacto por nombre, provincia o municipio, distrito, servicio..."
            value={searchTerm}
            onChange={handleSearchTermChange}
            className=" border-0 text-black"
            style={{ width: "100%", marginBottom: "10px", padding: "8px" }}
          />
        </td>
      </tr>
      <tr>
<td style={{ width: "50%", background: "#f2f2f2", padding: "8px" }}>
    <label style={{ marginRight: "5px", display: "block", textAlign: "right" }}>País:</label>
  </td>
  <td style={{ width: "50%", background:  "#f2f2f2", padding: "8px" }}>
    <Form.Select
      onChange={(e) => {
        setSelectedCountry(e.target.value);
        setSelectedProvince("");
        setSelectedCanton("");
        setSelectedDistrict("");
        handleCountrySelection(e.target.value); 
      }}
      style={{ width: "100%", padding: "8px" }}
      value={selectedCountry}

    >
      
      <option value="">Seleccione el País</option>
      {Object.keys(directions).map((country) => (
        <option key={country} value={country}>
          {country}
        </option>
      ))}
    </Form.Select>
  </td>
</tr>
      
      <tr>
  <td style={{ width: "50%", background: "#e6e6e6", padding: "8px" }}>
    <label style={{ marginRight: "5px", display: "block", textAlign: "right" }}>Servicio o Producto:</label>
  </td>
  <td style={{ width: "50%", background: "#e6e6e6", padding: "8px" }}>
    <select
      onChange={(e) => {
        const selectedIndex = e.target.selectedIndex - 1;
        setSelectedService(
          selectedIndex >= 0 ? allServices[selectedIndex] : null
        );
      }}
      value={
        selectedService
          ? `${selectedService.subproduct} - ${selectedService.name}`
          : ""
      }
      style={{ width: "100%", padding: "8px" }}
    >
      <option value="">No importa</option>
      {Array.from(
        new Set(
          allServices.map(
            (service) => `${service.subproduct} - ${service.name}`
          )
        )
      )
        .filter(
          (service) =>
            service !== undefined &&
            service !== null &&
            service !== ""
        )
        .map((service, index) => (
          <option key={index} value={service}>
            {service}
          </option>
        ))}
    </select>
  </td>
</tr>

<tr>
  <td style={{ width: "50%", background: "#f2f2f2", padding: "8px" }}>
    <label style={{ marginRight: "5px", display: "block", textAlign: "right" }}>Provincia:</label>
  </td>
  <td style={{ width: "50%", background: "#f2f2f2", padding: "8px" }}>
    <Form.Select
      onChange={(e) => {
        setSelectedProvince(e.target.value);
        setSelectedCanton("");
        setSelectedDistrict("");
      }}
      style={{ width: "100%", padding: "8px" }}
    >
      <option value="">No importa</option>
      {selectedCountry &&
        directions[selectedCountry] &&
        Object.keys(directions[selectedCountry].provincias).map(
          (province) => (
            <option key={province} value={province}>
              {province}
            </option>
          )
        )}
    </Form.Select>
  </td>
</tr>
<tr>
  <td style={{ width: "50%", background: "#e6e6e6", padding: "8px" }}>
    <label style={{ marginRight: "5px", display: "block", textAlign: "right" }}>Cantón:</label>
  </td>
  <td style={{ width: "50%", background: "#e6e6e6", padding: "8px" }}>
    <Form.Select
      onChange={(e) => {
        setSelectedCanton(e.target.value);
        setSelectedDistrict("");
      }}
      style={{ width: "100%", padding: "8px" }}
    >
      <option value="">No importa</option>
      {selectedProvince &&
        directions[selectedCountry]?.provincias[selectedProvince] &&
        Object.keys(
          directions[selectedCountry].provincias[selectedProvince]
            .cantones
        ).map((canton) => (
          <option key={canton} value={canton}>
            {canton}
          </option>
        ))}
    </Form.Select>
  </td>
</tr>
<tr>
  <td style={{ width: "50%", background: "#f2f2f2", padding: "8px" }}>
    <label style={{ marginRight: "5px", display: "block", textAlign: "right" }}>Distrito:</label>
  </td>
  <td style={{ width: "50%", background: "#f2f2f2", padding: "8px" }}>
    <Form.Select
      onChange={(e) => {
        setSelectedDistrict(e.target.value);
      }}
      style={{ width: "100%", padding: "8px" }}
    >
      <option value="">No importa</option>
      {selectedCanton &&
        directions[selectedCountry]?.provincias[selectedProvince]?.cantones[
          selectedCanton
        ]?.distritos &&
        directions[selectedCountry].provincias[
          selectedProvince
        ].cantones[selectedCanton].distritos.map((district, index) => (
          <option
            key={district}
            value={district}
            style={{
              background: index % 2 === 0 ? "#f2f2f2" : "#e6e6e6",
            }}
          >
            {district}
          </option>
        ))}
    </Form.Select>
  </td>
</tr>
<tr>
        <td style={{ width: "50%", background: "#e6e6e6", padding: "8px" }}>
          <label style={{ marginRight: "5px", display: "block", textAlign: "right" }}>Actividad Comercial:</label>
        </td>
        <td style={{ width: "50%", background: "#e6e6e6", padding: "8px" }}>
          <select
            onChange={(e) => handleNavbarItemClick(e.target.value)}
            style={{ width: "100%", padding: "8px" }}
          >
            <option value="">No importa</option>
            {[...new Set(distributors.map((distributor) => distributor.comercial_activity))]
              .filter((activity) => activity !== undefined && activity !== null && activity !== "")
              .sort()
              .map((activity, index) => (
                <option key={index} value={activity}>
                  {activity}
                </option>
              ))}
          </select>
        </td>
      </tr>
    </tbody>
  </table>
</Form>

        <br />
      </div>

      <main style={{ display: "flex", flexWrap: "wrap" }}>
        <div style={{ display: "flex", width: "100%", overflow: "auto" }}>
        {currentDistributors.length === 0 ? (
  <div style={{ textAlign: 'center', marginTop: '20px' }}>
    <button
      onClick={() => window.location.href = 'https://abcupon.com/#/directorio_comercial'}
      className="bg-red-500 hover:bg-red-600 text-white font-semibold py-2 px-4 rounded"
    >
      ¿No encuentras el directorio buscado? por favor visita nuestro Directorio General de Comercios
    </button>
  </div>
) : (
          <table
            style={{
              margin: "0 auto", // Centra la tabla
              width: isMobile ? "90%" : "90%", // Ajusta el ancho de la tabla según el dispositivo
              border: "2px solid black", // Borde negro
              borderRadius: "10px",
              overflow: "hidden",
              backgroundColor: "white",
              boxShadow: "0 0 10px rgba(255, 0, 0, 0.3)", // Sombreado rojo
            }}
          >
            <thead>
              <tr style={{ backgroundColor: "black", color: "white" }}>
                <th
                  style={{
                    padding: "10px",
                    textAlign: "left",
                    fontWeight: "bold",
                    fontSize: isMobile ? "0.7em" : "1em",
                  }}
                >
                  Actividad Comercial
                </th>

                <th
                  style={{
                    padding: "10px",
                    textAlign: "left",
                    fontWeight: "bold",
                    fontSize: isMobile ? "0.7em" : "1em",
                  }}
                >
                  Nombre de empresa
                </th>
                <th
                  style={{
                    padding: "10px",
                    textAlign: "left",
                    fontWeight: "bold",
                    fontSize: isMobile ? "0.7em" : "1em",
                  }}
                >
                  Pais
                </th>
                <th
                  style={{
                    padding: "10px",
                    textAlign: "left",
                    fontWeight: "bold",
                    fontSize: isMobile ? "0.7em" : "1em",
                  }}
                >
                  Provincia
                </th>
                <th
                  style={{
                    padding: "10px",
                    textAlign: "left",
                    fontWeight: "bold",
                    fontSize: isMobile ? "0.7em" : "1em",
                  }}
                >
                  Descripcion
                </th>
                <th
                  style={{
                    padding: "10px",
                    textAlign: "left",
                    fontWeight: "bold",
                    fontSize: isMobile ? "0.7em" : "1em",
                  }}
                >
                  Acción
                </th>
              </tr>
            </thead>
            <tbody>
              {currentDistributors.map((distributor, index) => (
                <tr
                  key={index}
                  style={{ borderRadius: "10px", border: "1px solid gray" }}
                >
                  <td
                    style={{
                      padding: "10px",
                      fontSize: isMobile ? "0.7em" : "1em",
                    }}
                  >
                    <h2
                      style={{
                        color: "red",
                        fontWeight: "bold",
                        fontSize: "2em",
                        textShadow: "2px 2px 4px #000",
                        textTransform: "none",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {distributor.comercial_activity}
                    </h2>
                  </td>

                  <td
                    style={{
                      padding: "10px",
                      fontSize: isMobile ? "0.7em" : "1em",
                    }}
                  >
                    <h2
                      style={{
                        color: "red",
                        fontWeight: "bold",
                        fontSize: "2em",
                        textShadow: "2px 2px 4px #000",
                        textTransform: "none",
                        overflow: "hidden",
                        whiteSpace: "normal",
                        lineClamp: 1,
                      }}
                    >
                      <Link
                        to={`/servicios/${distributor.email}`}
                        state={{
                          subproductName: distributor.name,
                          subproductEmail: distributor.email,
                        }}
                        style={{ color: "red" }}
                      >
                        {distributor.name}
                      </Link>
                    </h2>
                  </td>
                  <td
                    style={{
                      padding: "10px",
                      fontSize: isMobile ? "0.7em" : "1em",
                    }}
                  >
                    <p className="mb-4">{distributor.country}</p>
                  </td>
                  <td
                    style={{
                      padding: "10px",
                      fontSize: isMobile ? "0.7em" : "1em",
                    }}
                  >
                    {distributor.canton ? (
                      <p className="mb-4"> {distributor.province}</p>
                    ) : (
                      <p>No hay dirección adicional.</p>
                    )}
                  </td>
                  <td
                    style={{
                      padding: "10px",
                      fontSize: isMobile ? "0.7em" : "1em",
                    }}
                  >
                    <p className="mb-4">
                      {" "}
                      {distributor.description &&
                      distributor.description.length > 120
                        ? `${distributor.description.substring(0, 120)}...`
                        : distributor.description}
                    </p>
                  </td>
                  <td
                    style={{
                      padding: "10px",
                      fontSize: isMobile ? "0.7em" : "1em",
                    }}
                  >
                    <div>
                      <button
                        className="bg-red-500 hover:bg-red-600 text-white font-semibold py-2 px-4 rounded mb-2"
                        onClick={() => {
                          handleToggleDetails(index);
                          setIsModalOpen(true);
                        }}
                        style={{ marginBottom: "10px" }}
                      >
                        <Link
                          to={`/servicios/${distributor.email}`}
                          state={{
                            subproductName: distributor.name,
                            subproductEmail: distributor.email,
                          }}
                          style={{ color: "white" }}
                        >
                          Ver Detalles
                        </Link>
                      </button>
                      <button
                        className="bg-red-500 hover:bg-red-600 text-white font-semibold py-2 px-4 rounded"
                        onClick={() => handleToggleMap(index)}
                      >
                        {isMapOpen ? "Cerrar" : "Ver Dirección"}
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        
)}
        </div>

        {showMap.map(
          (value, index) =>
            value && (
              <div
  key={index}
  style={{ position: "relative", textAlign: "center" }}
>
  {isFirstMapActive.current && (
    <div
      style={{
        marginLeft: isMobile ? "10%" : "50%",
        width: isMobile ? "90%" : "200%",
      }}
    >
      {currentDistributors[index].addressmap ? (
        <>
          <iframe
            title="Google Maps"
            id={`mapIframe${index}`}
            src={currentDistributors[index].addressmap}
            width="100%"
            height="400"
            frameBorder="0"
            style={{
              border: "2px solid red",
              boxShadow: "0 0 10px black",
              width: "100%",
            }}
            allowFullScreen=""
            aria-hidden="false"
            tabIndex="0"
          ></iframe>
          <span
            style={{
              display: "inline-block",
              background: "white",
              padding: "10px",
              borderRadius: "10px",
              border: "2px solid red",
              boxShadow: "0 0 10px black",
              marginTop: "10px",
              fontWeight: "bold",
              fontSize: "1.5em",
            }}
          >
            {currentDistributors[index].address}
          </span>
        </>
      ) : (
        <p>No hay datos disponibles</p>
      )}
    </div>
  )}
  {/* Botón Cerrar */}
  <button
    className="bg-red-500 hover:bg-red-600 text-white font-semibold py-2 px-4 rounded mt-2"
    onClick={() => handleToggleMap(index)}
    style={{
      position: "absolute",
      top: "10px",
      left: isMobile ? "80%" : "220%",
    }}
  >
    {isFirstMapActive.current ? "Cerrar" : "Ver Dirección"}
  </button>
  {isFirstMapActive.current || (isFirstMapActive.current = true)}
</div>

            )
        )}
      </main>

      <br />
      <br />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Pagination.Prev
          onClick={handlePrevPage}
          disabled={currentPage === 1}
          style={{
            backgroundColor: "red",
            color: "white",
            borderRadius: "10px",
            width: "100px",
            margin: "0 10px",
          }}
        />

        {Array.from(
          {
            length: Math.min(
              5,
              Math.ceil(filteredDistributors.length / distributorsPerPage)
            ),
          },
          (_, i) => {
            const totalPages = Math.ceil(
              filteredDistributors.length / distributorsPerPage
            );
            const midPoint = Math.floor(5 / 2);
            let startPage = Math.max(1, currentPage - midPoint);
            let endPage = Math.min(startPage + 4, totalPages);

            if (endPage - startPage < 4) {
              startPage = Math.max(1, endPage - 4);
            }

            const pageNumber = startPage + i;

            return (
              <Pagination.Item
                key={i}
                onClick={() => setCurrentPage(pageNumber)}
                active={pageNumber === currentPage}
                style={{
                  backgroundColor: "red",
                  color: "white",
                  borderRadius: "10px",
                  width: "100px",
                  margin: "0 5px",
                  display:
                    pageNumber >= startPage && pageNumber <= endPage
                      ? "block"
                      : "none",
                }}
              >
                {pageNumber}
              </Pagination.Item>
            );
          }
        )}

        <Pagination.Next
          onClick={handleNextPage}
          disabled={indexOfLastDistributor >= filteredDistributors.length}
          style={{
            backgroundColor: "red",
            color: "white",
            borderRadius: "10px",
            width: "100px",
            margin: "0 10px",
          }}
        />
      </div>
    </main>
  );
}

export default Distributors;
