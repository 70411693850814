import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Container, Table, Form, Button, InputGroup, Modal, FormControl, FormGroup, FormLabel } from 'react-bootstrap';
import EmployeeDataService from '../../services/employee';
import './TablaDatos.css';

const EmployeesAguinaldos = () => {
  const [aguinaldos, setAguinaldos] = useState([]);
  const [selectedAguinaldos, setSelectedAguinaldos] = useState([]);
  const token = useSelector(state => state.authentication.token);
  const [showModal, setShowModal] = useState(false);
  const [nuevoAguinaldo, setNuevoAguinaldo] = useState({
    empleado: '',
    anio: '',
    enero: '0',
    febrero: '0',
    marzo: '0',
    abril: '0',
    mayo: '0',
    junio: '0',
    julio: '0',
    agosto: '0',
    septiembre: '0',
    octubre: '0',
    noviembre: '0',
    diciembre: '0',
  });

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const fetchData = async () => {
    try {
      const responseAguinaldos = await EmployeeDataService.getAllAguinaldo(token);
      setAguinaldos(responseAguinaldos.data);
    } catch (error) {
      console.error('Error al obtener datos:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [token]);

  const handleDeleteSelected = async () => {
    try {
      await EmployeeDataService.deleteAguinaldo(selectedAguinaldos, token);
      setSelectedAguinaldos([]);
      fetchData();
    } catch (error) {
      console.error('Error al eliminar aguinaldos:', error);
    }
  };

  const toggleSelectAguinaldo = (aguinaldoId) => {
    if (selectedAguinaldos.includes(aguinaldoId)) {
      setSelectedAguinaldos(selectedAguinaldos.filter(id => id !== aguinaldoId));
    } else {
      setSelectedAguinaldos([...selectedAguinaldos, aguinaldoId]);
    }
  };

  const handleCrearAguinaldo = async () => {
    try {
      await EmployeeDataService.createAguinaldo(nuevoAguinaldo, token);
      toggleModal();
      fetchData();
    } catch (error) {
      console.error('Error al crear aguinaldo:', error);
    }
  };

  return (
    <Container>
      <div className="tabla-datos-container">
      <br/><br/><br/>
      <h2>Aguinaldos de Empleados</h2>
      <Button variant="primary" onClick={toggleModal}>Crear Aguinaldo</Button>

       {/* Modal */}
       <Modal show={showModal} onHide={toggleModal} dialogClassName="modal-scrollable" style={{overflowY: 'auto' }}>
  <Modal.Header closeButton>
    <Modal.Title>Crear Aguinaldo</Modal.Title>
  </Modal.Header>
  <Modal.Body >
    <Form>
      <FormGroup>
        <FormLabel>Empleado ID</FormLabel>
        <FormControl
          type="text"
          value={nuevoAguinaldo.empleado}
          onChange={(e) => setNuevoAguinaldo({ ...nuevoAguinaldo, empleado: e.target.value })}
        />
      </FormGroup>
      <FormGroup>
        <FormLabel>Año</FormLabel>
        <FormControl
          type="text"
          value={nuevoAguinaldo.anio}
          onChange={(e) => setNuevoAguinaldo({ ...nuevoAguinaldo, anio: e.target.value })}
        />
      </FormGroup>
      <FormGroup>
        <FormLabel>Enero</FormLabel>
        <FormControl
          type="text"
          value={nuevoAguinaldo.enero}
          onChange={(e) => setNuevoAguinaldo({ ...nuevoAguinaldo, enero: e.target.value })}
        />
      </FormGroup>
      <FormGroup>
        <FormLabel>Febrero</FormLabel>
        <FormControl
          type="text"
          value={nuevoAguinaldo.febrero}
          onChange={(e) => setNuevoAguinaldo({ ...nuevoAguinaldo, febrero: e.target.value })}
        />
      </FormGroup>
      <FormGroup>
        <FormLabel>Marzo</FormLabel>
        <FormControl
          type="text"
          value={nuevoAguinaldo.marzo}
          onChange={(e) => setNuevoAguinaldo({ ...nuevoAguinaldo, marzo: e.target.value })}
        />
      </FormGroup>
      <FormGroup>
        <FormLabel>Abril</FormLabel>
        <FormControl
          type="text"
          value={nuevoAguinaldo.abril}
          onChange={(e) => setNuevoAguinaldo({ ...nuevoAguinaldo, abril: e.target.value })}
        />
      </FormGroup>
      <FormGroup>
        <FormLabel>Mayo</FormLabel>
        <FormControl
          type="text"
          value={nuevoAguinaldo.mayo}
          onChange={(e) => setNuevoAguinaldo({ ...nuevoAguinaldo, mayo: e.target.value })}
        />
      </FormGroup>
      <FormGroup>
        <FormLabel>Junio</FormLabel>
        <FormControl
          type="text"
          value={nuevoAguinaldo.junio}
          onChange={(e) => setNuevoAguinaldo({ ...nuevoAguinaldo, junio: e.target.value })}
        />
      </FormGroup>
      <FormGroup>
        <FormLabel>Julio</FormLabel>
        <FormControl
          type="text"
          value={nuevoAguinaldo.julio}
          onChange={(e) => setNuevoAguinaldo({ ...nuevoAguinaldo, julio: e.target.value })}
        />
      </FormGroup>
      <FormGroup>
        <FormLabel>Agosto</FormLabel>
        <FormControl
          type="text"
          value={nuevoAguinaldo.agosto}
          onChange={(e) => setNuevoAguinaldo({ ...nuevoAguinaldo, agosto: e.target.value })}
        />
      </FormGroup>
      <FormGroup>
        <FormLabel>Septiembre</FormLabel>
        <FormControl
          type="text"
          value={nuevoAguinaldo.septiembre}
          onChange={(e) => setNuevoAguinaldo({ ...nuevoAguinaldo, septiembre: e.target.value })}
        />
      </FormGroup>
      <FormGroup>
        <FormLabel>Octubre</FormLabel>
        <FormControl
          type="text"
          value={nuevoAguinaldo.octubre}
          onChange={(e) => setNuevoAguinaldo({ ...nuevoAguinaldo, octubre: e.target.value })}
        />
      </FormGroup>
      <FormGroup>
        <FormLabel>Noviembre</FormLabel>
        <FormControl
          type="text"
          value={nuevoAguinaldo.noviembre}
          onChange={(e) => setNuevoAguinaldo({ ...nuevoAguinaldo, noviembre: e.target.value })}
        />
      </FormGroup>
      <FormGroup>
        <FormLabel>Diciembre </FormLabel>
        <FormControl
          type="text"
          value={nuevoAguinaldo.diciembre}
          onChange={(e) => setNuevoAguinaldo({ ...nuevoAguinaldo, diciembre: e.target.value })}
        />
      </FormGroup>
    </Form>
  </Modal.Body>
  <Modal.Footer>
    <Button variant="secondary" onClick={toggleModal}>
      Cerrar
    </Button>
    <Button variant="primary" onClick={handleCrearAguinaldo}>
      Crear Aguinaldo
    </Button>
  </Modal.Footer>
</Modal>


      {aguinaldos.length > 0 ? (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Check</th>
              <th>ID</th>
              <th>Empleado</th>
              <th>Año</th>
              <th>Enero</th>
              <th>Febrero</th>
              <th>Marzo</th>
              <th>Abril</th>
              <th>Mayo</th>
              <th>Junio</th>
              <th>Julio</th>
              <th>Agosto</th>
              <th>Septiembre</th>
              <th>Octubre</th>
              <th>Noviembre</th>
              <th>Diciembre</th>
            </tr>
          </thead>
          <tbody>
            {aguinaldos.map(aguinaldo => (
              <tr key={aguinaldo.id}>
                <td>
                  <Form.Check
                    type="checkbox"
                    checked={selectedAguinaldos.includes(aguinaldo.id)}
                    onChange={() => toggleSelectAguinaldo(aguinaldo.id)}
                  />
                </td>
                <td>{aguinaldo.id}</td>
                <td>{aguinaldo.empleado}</td>
                <td>{aguinaldo.anio}</td>
                <td>{aguinaldo.enero}</td>
                <td>{aguinaldo.febrero}</td>
                <td>{aguinaldo.marzo}</td>
                <td>{aguinaldo.abril}</td>
                <td>{aguinaldo.mayo}</td>
                <td>{aguinaldo.junio}</td>
                <td>{aguinaldo.julio}</td>
                <td>{aguinaldo.agosto}</td>
                <td>{aguinaldo.septiembre}</td>
                <td>{aguinaldo.octubre}</td>
                <td>{aguinaldo.noviembre}</td>
                <td>{aguinaldo.diciembre}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <p>No hay datos de aguinaldos disponibles.</p>
      )}

      {selectedAguinaldos.length > 0 && (
        <InputGroup className="mb-3">
          <Button variant="danger" onClick={handleDeleteSelected}>
            Eliminar seleccionado
          </Button>
        </InputGroup>
      )}
    </div>
    </Container>
  );
};

export default EmployeesAguinaldos;
