import React, { useState, useEffect } from 'react';
import './technology.css';
import Footer from '../../components/navigation/Footer';
import Navbar from '../../components/navigation/Navbar';
import TechnologyBot from '../../components/home/technology_bot';
import Products from '../../components/home/products';
import Whytdm from '../../components/home/why_tdm';
import FileDataService from '../../services/files';
import { useSelector } from 'react-redux';

function Technology() {
    const [expandedImage, setExpandedImage] = useState(null);
    const [storyImage, setStoryImage] = useState('');
    const [visionImage, setVisionImage] = useState('');
    const [missionImage, setMissionImage] = useState('');
    const token = useSelector(state => state.authentication.token);

    useEffect(() => {
        fetchImages();
    }, []);

    const fetchImages = () => {
        FileDataService.getAll(token)
            .then(response => {
                const firstImage = response.data.find(file => file.name === 'First');
                const secondImage = response.data.find(file => file.name === 'Second');
                const thirdImage = response.data.find(file => file.name === 'Third');
                if (firstImage) {
                    setStoryImage(firstImage.file);
                }
                if (secondImage) {
                    setVisionImage(secondImage.file);
                }
                if (thirdImage) {
                    setMissionImage(thirdImage.file);
                }
            })
            .catch(error => {
                console.error(error);
            });
    };

    const handleImageClick = (image) => {
        setExpandedImage(image);
    };

    const handleCloseImage = () => {
        setExpandedImage(null);
    };

    return (
        <div>
            <Navbar />
            <section className="about__story">
                <div className="container about__story-container">
                    <div className="about__section-image">
                        <img src={storyImage} alt="Our_Story_Image" onClick={() => handleImageClick(storyImage)} />
                        {expandedImage === storyImage && (
                            <div className="expanded-image">
                                <img src={storyImage} alt="Expanded_Image" />
                                <button className="close-button" onClick={handleCloseImage}>X</button>
                            </div>
                        )}
                    </div>
                    <div className="about__section-content">
                        <h1>Nuestros Asociados</h1>
                        <p>Aquí en nuestra organización, nos enorgullece contar con una amplia red de asociados que comparten nuestra visión y misión de hacer del mundo un lugar mejor. Trabajamos de la mano con empresas y organizaciones de diversos sectores para lograr un impacto significativo en nuestras comunidades.
                        </p>
                        <p>Si estás interesado en convertirte en uno de nuestros valiosos asociados, no dudes en contactarnos. Estaremos encantados de explorar las oportunidades de colaboración y construir un futuro mejor juntos.
                        </p>
                    </div>
                </div>
            </section>

            <section className="about__Vision">
                <div className="container about__vision-container">
                    <div className="about__section-content">
                    <h1>Únase</h1>
                        <p>En nuestra organización, valoramos y apreciamos la colaboración de empresas y organizaciones comprometidas en hacer del mundo un lugar mejor. Si compartes nuestra visión de generar un impacto positivo y deseas ser parte de un movimiento global de cambio, ¡te invitamos a unirte a nosotros!
                        </p>
                    </div>
                    <div className="about__section-image">
                        <img src={visionImage} alt="Our_Vision_Image" onClick={() => handleImageClick(visionImage)} />
                        {expandedImage === visionImage && (
                            <div className="expanded-image">
                                <img src={visionImage} alt="Expanded_Image" />
                                <button className="close-button" onClick={handleCloseImage}>X</button>
                            </div>
                        )}
                    </div>
                </div>
            </section>

            <section className="about__mission">
                <div className="container about__mission-container">
                    <div className="about__section-image">
                        <img src={missionImage} alt="Our_Mission_Image" onClick={() => handleImageClick(missionImage)} />
                        {expandedImage === missionImage && (
                            <div className="expanded-image">
                                <img src={missionImage} alt="Expanded_Image" />
                                <button className="close-button" onClick={handleCloseImage}>X</button>
                            </div>
                        )}
                    </div>
                    <div className="about__section-content">
                        <p>
                        Si deseas ser parte de nuestra red de asociados y unirte a nosotros en nuestra misión de generar un cambio positivo, no dudes en contactarnos. Estaremos encantados de podemos trabajar juntos y construir un futuro mejor.
                        </p>
                    </div>
                </div>
            </section>
            {/* <TechnologyBot /> */}
            {/* <Whytdm /> */}
            {/* <Products /> */}
            <Footer />
        </div>
    );
}

export default Technology;
