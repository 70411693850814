import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Table, Modal, Form } from 'react-bootstrap';
import ProductDataService from '../../services/products';
import TodoDataService from '../../services/todos';
import moment from 'moment';
import Papa from 'papaparse';
import { Newspaper } from '@mui/icons-material';

const CharacteristicsTable = (props) => {
  const [products, setProducts] = useState([]);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [name, setName] = useState('');
  const [url, setUrl] = useState('');
  const [description, setDescription] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [userList, setUserList] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);
  const token = useSelector(state => state.authentication.token);
  const [characteristicsList, setCharacteristicsList] = useState([]);
  const [subproductList, setSubproductList] = useState([]);
  const [showCreateModalM, setShowCreateModalM] = useState(false);
  const [showCreateModalS, setShowCreateModalS] = useState(false);
  const [showEditModalM, setShowEditModalM] = useState(false);
  const [selectedCharacteristicM, setSelectedCharacteristicM] = useState(null);
  const [nameM, setNameM] = useState('');
  const [descriptionM, setDescriptionM] = useState('');
  const [nameS, setNameS] = useState('');
  const [phoneS, setPhoneS] = useState('');
  const [subproductModalShow, setSubproductModalShow] = useState(false);
  const [showCreateSubproductModal, setShowCreateSubproductModal] = useState(false);
  const [selectedSubproducts, setSelectedSubproducts] = useState([]);
  const [selectedSubproduct, setSelectedSubproduct] = useState(null);
  const [editSubproductName, setEditSubproductName] = useState('');
  const [editSubproductPhone, setEditSubproductPhone] = useState('');
  const [currentProductsPage, setCurrentProductsPage] = useState(1);
  const [currentCharacteristicsPage, setCurrentCharacteristicsPage] = useState(1);
  const [currentSubproductsPage, setCurrentSubproductsPage] = useState(1);
  const [isEditing, setIsEditing] = useState(false);
  const itemsPerPage = 4;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentProducts = products.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(products.length / itemsPerPage);
  const [csvFile, setCsvFile] = useState(null);
  const [showServicesModal, setShowServicesModal] = useState(false);
  const [selectedSubproductServices, setSelectedSubproductServices] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const moment = require('moment');

  const renderPaginationButtons = (currentPage, totalPages, setCurrentPage) => {
    const buttons = [];
    for (let i = 1; i <= totalPages; i++) {
      buttons.push(
        <Button
          key={i}
          variant={i === currentPage ? 'primary' : 'secondary'}
          onClick={() => setCurrentPage(i)}
        >
          {i}
        </Button>
      );
    }
    return buttons;
  };
  const renderCharacteristicsPaginationButtons = () => {
    const buttons = [];
    for (let i = 1; i <= Math.ceil(characteristicsList.length / itemsPerPage); i++) {
      buttons.push(
        <Button
          key={i}
          variant={i === currentPage ? 'primary' : 'secondary'}
          onClick={() => setCurrentPage(i)}
        >
          {i}
        </Button>
      );
    }
    return buttons;
  };
  const renderSubproductsPaginationButtons = () => {
    const buttons = [];
    for (let i = 1; i <= Math.ceil(subproducts.length / itemsPerPage); i++) {
      buttons.push(
        <Button
          key={i}
          variant={i === currentPage ? 'primary' : 'secondary'}
          onClick={() => setCurrentPage(i)}
        >
          {i}
        </Button>
      );
    }
    return buttons;
  };

  const fetchCharacteristicsM = async () => {
    try {
      const response = await ProductDataService.getAllCharacteristics();
      setCharacteristicsList(response.data);
    } catch (error) {
      console.error('Error al recuperar características:', error);
    }
  };

  const fetchSubProduct = async () => {
    try {
      const response = await ProductDataService.getAllSubProduct();
      setSubproductList(response.data);
    } catch (error) {
      console.error('Error al recuperar subproductos:', error);
    }
  };




  const handleCloseEditModalM = () => {
    setShowEditModalM(false);
    setSelectedCharacteristicM(null);
    setNameM('');
    setDescriptionM('');
  };

  const handleCreateM = () => {
    setShowCreateModalM(true);
  };
  const handleCreateS = () => {
    setShowCreateModalS(true);
  };

  const handleCloseCreateModalM = () => {
    setShowCreateModalM(false);
    setNameM('');
    setDescriptionM('');
  };


  const handleCloseCreateModalS = () => {
    setShowCreateModalS(false);
    setSubproductName('');
    setSubproductPhone('');
    setSubproductEmail('');
    setSubproductAddress('');
    setSubproductAddressmap('');
    setSubproductUrl('');
    setSubproductServices('');
    setFile3(null); // Asegúrate de reiniciar el estado del archivo relacionado con el subproducto
  };
  const handleEditM = (characteristic) => {
    setSelectedCharacteristicM(characteristic);
    setNameM(characteristic.name);
    setDescriptionM(characteristic.description);
    setShowEditModalM(true);
  };

  const handleDeleteM = async (id) => {
    try {
      await ProductDataService.deleteCharacteristic(id);
      fetchCharacteristicsM();
    } catch (error) {
      console.error('Error al eliminar característica:', error);
    }
  };

  const handleSubmitCreateM = async () => {
    const newCharacteristic = { name: nameM, description: descriptionM };
    try {
      await ProductDataService.createCharacteristic(newCharacteristic, token); // Pasa el token a la función
      setShowCreateModalM(false);
      fetchCharacteristicsM();
    } catch (error) {
      console.error('Error al crear característica:', error);
    }
  };

  const handleSubmitEditM = async () => {
    if (!selectedCharacteristicM) {
      console.error('Selected characteristic is null or undefined');
      return;
    }

    const updatedCharacteristic = { name: nameM, description: descriptionM };
    try {
      await ProductDataService.updateCharacteristic(selectedCharacteristicM.id, updatedCharacteristic);
      setShowEditModalM(false);
      fetchCharacteristicsM();
    } catch (error) {
      console.error('Error al actualizar la característica:', error);
    }
  };

  useEffect(() => {
    fetchCharacteristicsM();
  }, []);

  useEffect(() => {
    fetchSubProduct();
  }, []);


  const [editData, setEditData] = useState({
    id: null,
    name: '',
    description: '',
    name_url: '',
    characteristics: [],
    subproducts: [],
  });
  // const [showEditModal, setShowEditModal] = useState(false);
  const handleEditNameUrlChange = (e) => {
    const newNameUrl = e.target.value;
    setEditData({ ...editData, name_url: newNameUrl });
  };

  const getUserList = () => {
    if (props.token) {
      TodoDataService.getUserList(props.token)
        .then((response) => {
          setUserList(response.data);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  useEffect(() => {
    getUserList();
  }, [props.token]);

  useEffect(() => {
    if (userList.length > 0 && props.user) {
      const currentUser = userList.find((user) => user.email === props.user);
      setCurrentUser(currentUser);
      localStorage.setItem('currentUser', JSON.stringify(currentUser));
    }
  }, [userList, props.user]);

  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    try {
      const response = await ProductDataService.getAll();
      setProducts(response.data);

      // Update the subproducts state with all subproducts
      const allSubproducts = response.data.reduce((acc, product) => {
        if (product.subproducts && product.subproducts.length > 0) {
          acc.push(...product.subproducts);
        }
        return acc;
      }, []);

      setSubproducts(allSubproducts);
      console.log('All Subproducts:', allSubproducts);
    } catch (error) {
      console.error('Error al recuperar productos:', error);
    }
  };
  const handleCreate = () => {
    setSelectedCharacteristics([]); // Reset selected characteristics when opening the create modal
    setShowCreateModal(true);
  };

  const handleCloseCreateModal = () => {
    setShowCreateModal(false);
    setName('');
    setDescription('');
    setFile(null);
    setFile1(null);
    setSelectedCharacteristics([]);
  };

  const handleEdit = (product) => {
    setSelectedProduct(product);
    setEditData({
      id: product.id,
      name: product.name,
      name_url: product.name_url,
      description: product.description,
      characteristics: product.characteristics,
      subproducts: product.subproducts,
    });

    setShowEditModal(true);
  };


  const handleSaveEdit = async () => {
    if (!selectedProduct) {
      console.error('Selected product is null or undefined');
      return;
    }

    // Validar si algún campo requerido está vacío
    if (!editData.name.trim() || !editData.description.trim()) {
      console.error('Name and description are required fields');
      return;
    }

    // Crear un objeto FormData para manejar los datos del formulario
    const formData = new FormData();
    formData.append('id', selectedProduct.id); // Agrega el ID del producto a editar
    formData.append('name', editData.name);
    formData.append('name_url', editData.name_url);
    formData.append('description', editData.description);

    // Agrega los archivos si existen
    if (file) {
      formData.append('file', file);
    }

    if (file1) {
      formData.append('file1', file1);
    }

    try {
      const response = await ProductDataService.updateProduct(selectedProduct.id, formData, token);

      setShowEditModal(false);

      fetchProducts();
    } catch (error) {
      console.error('Error updating product:', error);
    }
  };

  const handleEditNameChange = (e) => {
    const newName = e.target.value;
    setEditData({ ...editData, name: newName });
    setName(newName); // Update the name state as well
  };

  const handleEditDescriptionChange = (e) => {
    const newDescription = e.target.value;
    setEditData({ ...editData, description: newDescription });
    setDescription(newDescription); // Update the description state as well
  };
  const handleCloseEditModal = () => {
    setSelectedProduct(null);
    setShowEditModal(false);
    setName('');
    setDescription('');
    setFile(null);
    setFile1(null);
    setSelectedCharacteristics([]);
  };

  const [file, setFile] = useState(null);
  const [file1, setFile1] = useState(null);
  const [file3, setFile3] = useState(null);
  const [characteristicOptions, setCharacteristicOptions] = useState([]);
  const [selectedCharacteristics, setSelectedCharacteristics] = useState([]);
  const [subproducts, setSubproducts] = useState([]);
  const [subproductName, setSubproductName] = useState('');
  const [subproductPhone, setSubproductPhone] = useState('');
  const [subproductEmail, setSubproductEmail] = useState('');
  const [subproductAddress, setSubproductAddress] = useState('');
  const [subproductAddressmap, setSubproductAddressmap] = useState('');

  const [subproductUrl, setSubproductUrl] = useState('');
  const [subproductServices, setSubproductServices] = useState('');
  const [subproductImage, setSubproductImage] = useState('');
  const [nameUrl, setNameUrl] = useState('');

  const [subproductDescription, setSubproductDescription] = useState("");
  const [subproductCountry, setSubproductCountry] = useState("");
  const [subproductProvince, setSubproductProvince] = useState("");
  const [subproductCanton, setSubproductCanton] = useState("");
  const [subproductDistrito, setSubproductDistrito] = useState("");
  const [subproductContactName, setSubproductContactName] = useState("");
  const [subproductPhoneNumber, setSubproductPhoneNumber] = useState("");
  const [subproductComercialActivity, setSubproductComercialActivity] = useState("");
  const [subproductPayMethod, setSubproductPayMethod] = useState("");
  const [subproductConstitucion, setSubproductConstitucion] = useState("");
  const [subproductLogo, setSubproductLogo] = useState(null);
  const [subproductFile, setSubproductFile] = useState(null);
  const [combos, setCombos] = useState([]);
  const [services, setServices] = useState([]);
  const [selectedComboIds, setSelectedComboIds] = useState([]);
  const [newComboData, setNewComboData] = useState({
    name: '',
    description: '',
    price: '',
    selectedServiceIds: [],
  });
  useEffect(() => {
    fetchCharacteristicsOptions();
    fetchCombos();
    fetchServices();
  }, []);

  const fetchCharacteristicsOptions = async () => {
    try {
      const response = await ProductDataService.getAllCharacteristics();
      setCharacteristicOptions(response.data);
    } catch (error) {
      console.error('Error al recuperar características:', error);
    }
  };
  const fetchCombos= async () => {
    try {
      const response = await ProductDataService.getAllCombos();
      console.log("Combos iniciales", response.data);    
      setCombos(response.data);
    } catch (error) {
      console.error('Error al recuperar características:', error);
    }
  };
  const fetchServices = async () => {
    try {
      const response = await ProductDataService.getAllServices();
      console.log("Servicios iniciales", response.data);    
      setServices(response.data);
    } catch (error) {
      console.error('Error al recuperar servicios:', error);
    }
  };
  
  const handleSave = async () => {
    try {
      const formData = new FormData();
      formData.append('name', name);
      formData.append('name_url', nameUrl);
      formData.append('description', description);
      const selectedCharacteristicIds = selectedCharacteristics.map(char => char.id);
      selectedCharacteristicIds.forEach(id => formData.append('characteristics', id));

      if (file) {
        formData.append('file', file);
      }
      if (file1) {
        formData.append('file1', file1);
      }
      const response = await ProductDataService.createProduct(formData, token);

      // Log the server response
      console.log('Server response:', response);
      handleCloseCreateModal();
      fetchProducts();
    } catch (error) {
      console.error('Error creating product:', error);
    }
  };



  const handleEditCharacteristicsChange = (e) => {
    const selectedCharacteristicStrings = Array.from(e.target.selectedOptions, option => option.value);
    const selectedCharacteristics = characteristicOptions.filter(char =>
      selectedCharacteristicStrings.includes(JSON.stringify(char))
    );
    setEditData((prevEditData) => ({ ...prevEditData, characteristics: selectedCharacteristics }));
  };

  const handleDelete = async (id) => {
    try {
      await ProductDataService.deleteProduct(id, token);
      // Fetch updated products list
      fetchProducts();
    } catch (error) {
      console.error('Error al eliminar el producto:', error);
    }
  };

  const handleDeleteS = async (id) => {
    try {
      await ProductDataService.deleteSubProduct(id, token);
      // Fetch updated products list
      fetchProducts();
    } catch (error) {
      console.error('Error al eliminar el producto:', error);
    }
  };


  const formatDate = (date) => {
    return moment(date).format('YYYY-MM-DD HH:mm:ss');
  };



  const handleSaveSubproducts = async () => {
    const newSubProduct = new FormData(); // Crea un objeto FormData


    const constitucion = subproductConstitucion
  ? moment(subproductConstitucion).format('YYYY-MM-DD')
  : '';

    console.log("constitucion:", constitucion);


    // Agrega los campos de texto al formulario
    newSubProduct.append("name", subproductName);
    newSubProduct.append("phone", subproductPhone);
    newSubProduct.append("email", subproductEmail);
    newSubProduct.append("address", subproductAddress);
    newSubProduct.append("addressmap", subproductAddressmap);
    newSubProduct.append("url", subproductUrl);
    newSubProduct.append("description", subproductDescription);
    newSubProduct.append("country", subproductCountry);
    newSubProduct.append("province", subproductProvince);
    newSubProduct.append("contact_name", subproductContactName);
    newSubProduct.append("phone_number", subproductPhoneNumber);
    newSubProduct.append("constitucion", constitucion);
    newSubProduct.append("comercial_activity", subproductComercialActivity);
    newSubProduct.append("pay_method", subproductPayMethod);

    // Agrega el archivo de imagen (logo) si existe
    if (subproductLogo) {
      newSubProduct.append("logo", subproductLogo);
    }

    // Agrega el archivo (file) si existe
    if (subproductFile) {
      newSubProduct.append("file", subproductFile);
    }

    // Agrega los productos seleccionados
    selectedProducts.forEach((product) => {
      newSubProduct.append("products", product.id);
    });

    try {
      console.log("Nuevo producto");
      newSubProduct.forEach((value, key) => {
        console.log(`${key}: ${value}`);
      });
      await ProductDataService.createSubProduct(newSubProduct, token);
      setShowCreateModalS(false);
      fetchSubProduct();
    } catch (error) {
      console.error('Error creating subproducto:', error);
    }

    setSubproductModalShow(false);
    setEditingSubproduct(false);
    setEditSubproductData({
      id: null,
      name: '',
      phone: '',
      email: '',
      address: '',
      addressmap: '',
      url: '',
      services: '',
      image: '',
    });
  };


  const handleCsvUpload = async () => {
    if (!csvFile) {
      console.error('No se ha seleccionado ningún archivo CSV.');
      return;
    }

    try {
      Papa.parse(csvFile, {
        header: true,
        delimiter: ';',
        encoding: 'UTF-8',
        complete: async function (result) {
          const subproductsData = result.data;

          // Mostrar nombres de las columnas
          console.log('Nombres de las columnas:', Object.keys(subproductsData[0]));

          for (const [index, subproduct] of subproductsData.entries()) {
            // Limpiar campos que puedan tener saltos de línea
            const cleanedTelefono = subproduct.Telefono ? subproduct.Telefono.replace(/[\r\n]/g, '') : '';
            const cleanedDireccion = subproduct.Direccion ? subproduct.Direccion.replace(/[\r\n]/g, '') : '';

            // Mostrar contenido de cada columna
            console.log(`Fila ${index + 1}:`);
            Object.entries(subproduct).forEach(([columnName, columnValue]) => {
              console.log(`${columnName}: ${columnValue}`);
            });

            // Validar que los campos requeridos estén presentes en cada fila del CSV

            try {
              // Fetch all products and handle any errors
              const constitucion = subproduct.Constitucion
                ? moment(subproduct.Constitucion, 'DD/MM/YYYY').format('YYYY-MM-DD')
                : '';
              console.log("Fecha de constitucion servidor:", constitucion);

              const allProductsResponse = await ProductDataService.getAll();
              const allProducts = allProductsResponse.data;
              console.log("Todos los productos", allProducts);

              // Check if allProducts is an array before using find
              if (Array.isArray(allProducts)) {
                const existingProduct = allProducts.find(product => product.name === subproduct.Producto);

                console.log("Productos de existingProduct ", existingProduct);
                // Validar que se encuentre el producto asociado en la base de datos
                if (!existingProduct) {
                  console.error('El producto asociado no se encuentra en la base de datos.');
                  continue; // Pasar a la siguiente fila si no se encuentra el producto
                }

                const formattedData = new FormData();

                // Agregar los campos de texto al formulario
                formattedData.append("name", subproduct.Nombre || '');
                formattedData.append("phone", subproduct.Telefono || '');
                formattedData.append("email", subproduct.Correo || '');
                formattedData.append("address", subproduct.Direccion || '');
                formattedData.append("url", subproduct.Url || '');
                formattedData.append("products", existingProduct ? [existingProduct.id] : '');

                // Agregar campos adicionales
                formattedData.append("country", subproduct.Pais || '');
                formattedData.append("province", subproduct.Provincia || '');
                formattedData.append("canton", subproduct.canton || '');
                formattedData.append("distrito", subproduct.distrito || '');
                formattedData.append("contact_name", subproduct.Contacto || '');
                formattedData.append("phone_number", subproduct.TelefonoNumero || '');
                formattedData.append("comercial_activity", subproduct.ActividadComercial || '');
                formattedData.append("pay_method", subproduct.MetodoPago || '');
                formattedData.append("constitucion", constitucion);
                formattedData.append("constitucion", constitucion);

                // Agregar el archivo de imagen (logo) si existe
                if (subproduct.Logo) {
                  formattedData.append("logo", subproduct.Logo);
                }

                // Agregar el archivo (file) si existe
                if (subproduct.File) {
                  formattedData.append("file", subproduct.File);
                }

                console.log("Archivos para servidor", formattedData);

                try {
                  await ProductDataService.createSubProduct(formattedData, token);
                } catch (error) {
                  console.error('Error al enviar los datos formateados al servidor:', error);
                }
              } else {
                console.error('Error: Product data is not an array.');
              }
            } catch (error) {
              console.error('Error al obtener datos de productos:', error);
            }
          }

          fetchSubProduct();
        },
      });
    } catch (error) {
      console.error('Error al cargar el archivo CSV:', error);
    }
  };











  const handleSubproductsSelection = (e) => {
    const selectedOptions = Array.from(e.target.selectedOptions, option =>
      JSON.parse(option.value)
    );

    setSelectedSubproducts(selectedOptions);
  };


  const handleDoubleClick = () => {
    setSelectedSubproducts([]);
  };

  const handleCharacteristicsSelection = (e) => {
    const selectedOptions = Array.from(e.target.selectedOptions, option =>
      JSON.parse(option.value)
    );
    setSelectedCharacteristics(selectedOptions);
  };

  const handleCharacteristicsDoubleClick = () => {
    setSelectedCharacteristics([]);
  };

  const [selectedProducts, setSelectedProducts] = useState([]);

  const handleProductsSelection = (e) => {
    const selectedOptions = Array.from(e.target.selectedOptions, option =>
      JSON.parse(option.value)
    );
    setSelectedProducts(selectedOptions);
  };

  const handleProductsDoubleClick = () => {
    setSelectedProducts([]);
  };

  const handleEditSubproduct = (subproduct) => {
    setEditingSubproduct(true);
    setIsEditing(true);
    setEditSubproductData({
      id: subproduct.id,
      name: subproduct.name,
      phone: subproduct.phone,
      email: subproduct.email,
      address: subproduct.address,
      url: subproduct.url,
      services: subproduct.services,
      addressmap: subproduct.addressmap,
      image: subproduct.image,
    });
    setShowEditModalS(true);
  };

  const handleCloseEditModalS = () => {
    setEditingSubproduct(false);
    setEditSubproductData({
      id: null,
      name: '',
      phone: '',
      email: '',
      address: '',
      url: '',
      services: '',
      image: '',
    });
    setShowEditModalS(false);
  };



  const [editSubproductData, setEditSubproductData] = useState({
    id: null,
    name: '',
    phone: '',
    email: '',
    address: '',
    url: '',
    services: '',
    image: '',
  });

  const handleEditStart = (subproduct) => {
    setIsEditing(true);
    setEditSubproductData({
      name: subproduct.name,
      phone: subproduct.phone,
      email: subproduct.email,
      address: subproduct.address,
      addressmap: subproduct.addressmap,
      url: subproduct.url,
      products: selectedProducts.map(product => product.id),
    });

  };

  const [editingSubproduct, setEditingSubproduct] = useState(false);
  const [showEditModalS, setShowEditModalS] = useState(false);

  const handleEditSubproducts = async () => {
    // Crear un objeto FormData para la edición
    const editedSubProduct = new FormData();

    // Agregar los campos de texto al formulario
    editedSubProduct.append("name", editSubproductData.name);
    editedSubProduct.append("phone", editSubproductData.phone);
    editedSubProduct.append("email", editSubproductData.email);
    editedSubProduct.append("address", editSubproductData.address);
    editedSubProduct.append("url", editSubproductData.url);
    editedSubProduct.append("country", editSubproductData.country);
    editedSubProduct.append("province", editSubproductData.province);
    editedSubProduct.append("canton", editSubproductData.canton);
    editedSubProduct.append("canton", editSubproductData.distrito);
    editedSubProduct.append("contact_name", editSubproductData.contact_name);
    editedSubProduct.append("phone_number", editSubproductData.phone_number);
    editedSubProduct.append("comercial_activity", editSubproductData.comercial_activity);
    editedSubProduct.append("pay_method", editSubproductData.pay_method);

    // Agregar los productos seleccionados
    selectedProducts.forEach((product) => {
      editedSubProduct.append("products", product.id);
    });

    try {
      // Agregar el archivo de imagen (logo) si existe
      if (editSubproductData.logo) {
        editedSubProduct.append("logo", editSubproductData.logo);
      }

      // Agregar el archivo (file) si existe
      if (editSubproductData.file) {
        editedSubProduct.append("file", editSubproductData.file);
      }

      // Realizar la solicitud de actualización con el objeto FormData
      await ProductDataService.updateSubProduct(
        editSubproductData.id,
        editedSubProduct,
        token
      );

      // Actualizar la lista de subproductos
      fetchSubProduct();
    } catch (error) {
      console.error("Error updating subproduct:", error);
    }

    // Restablecer los estados
    setShowEditModalS(false);
    setSubproductModalShow(false);
    setEditingSubproduct(false);
    setEditSubproductData({
      id: null,
      name: "",
      phone: "",
      email: "",
      address: "",
      url: "",
      country: "",
      province: "",
      canton: "",
      distrito: "",
      contact_name: "",
      phone_number: "",
      comercial_activity: "",
      pay_method: "",
    });
  };


  const [selectedSubproductData, setSelectedSubproductData] = useState({
    id: null,
    name: '',
    phone: '',
    email: '',
    address: '',
    url: '',
    services: '',
  });

  const handleShowServicesModal = async (subproductId) => {
    // const selectedSubproduct = subproducts.find(subproduct => subproduct.id === subproductId);
    setSelectedSubproduct(subproductId);

    setShowServicesModal(true);

    try {
      console.log("id", subproductId.id)
      const response = await ProductDataService.getAllServicesForSubProduct(subproductId.id);
      console.log("response", response.data)
      setSelectedSubproductServices(response.data);
      setShowServicesModal(true);
    } catch (error) {
      console.error("Error fetching services:", error);
    }

  };
  const handleShowCombosModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  const handleServiceCheckboxChange = (serviceId) => {
    const selectedServiceIds = [...newComboData.selectedServiceIds];

    if (selectedServiceIds.includes(serviceId)) {
      // Si el servicio ya está seleccionado, quítalo
      const index = selectedServiceIds.indexOf(serviceId);
      selectedServiceIds.splice(index, 1);
    } else {
      // Si el servicio no está seleccionado, agrégalo
      selectedServiceIds.push(serviceId);
    }

    setNewComboData({ ...newComboData, selectedServiceIds });
  };

  const handleCheckboxChange = (comboId) => {
    // Toggle selected state for the combo
    setSelectedComboIds((prevSelectedIds) => {
      if (prevSelectedIds.includes(comboId)) {
        return prevSelectedIds.filter((id) => id !== comboId);
      } else {
        return [...prevSelectedIds, comboId];
      }
    });
  };

  const handleDeleteSelectedCombos = async () => {
    try {
      // Iterar sobre los combos seleccionados y eliminarlos
      for (const comboId of selectedComboIds) {
        await ProductDataService.deleteCombo(comboId, token);
      }  

      // Limpiar los combos seleccionados después de eliminar
      setSelectedComboIds([]);
      fetchCombos();
    } catch (error) {
      console.error('Error al eliminar combos:', error);
    }
  };

  const handleCreateCombo = async () => {
    console.log('Selected Service IDs:', newComboData.selectedServiceIds); // Agregar este log

    try {
      const response = await ProductDataService.createCombo(
        {
          ...newComboData,
          selectedServices: newComboData.selectedServiceIds.map(serviceId => ({ id: serviceId })),
        },
        token
      );
  
      console.log('Create Combo Response:', response.data);
  
      fetchCombos();
      handleCloseModal();
    } catch (error) {
      console.error('Error creating combo:', error);
    }
  };
    
  


  const resetModalData = () => {
    setSelectedSubproductServices([]);
    setSelectedSubproduct(null);
  };

  const [newServiceName, setNewServiceName] = useState(''); // Estado para el nombre del nuevo servicio
  const [newServiceDescription, setNewServiceDescription] = useState(''); // Estado para la descripción del nuevo servicio
  const [newServicePrice, setNewServicePrice] = useState(''); // Estado para el precio del nuevo servicio

  const handleAddService = async () => {
    try {
      // Verificar si se ha seleccionado un subproducto
      if (!selectedSubproduct) {
        console.error('No se ha seleccionado un subproducto para agregar el servicio.');
        return;
      }

      // Validar que los campos requeridos no estén vacíos
      if (!newServiceName.trim() || !newServiceDescription.trim() || !newServicePrice.trim()) {
        console.error('Nombre, descripción y precio son campos obligatorios.');
        return;
      }

      // Crear un nuevo servicio con los datos proporcionados
      const newService = {
        name: newServiceName,
        description: newServiceDescription,
        price: parseFloat(newServicePrice), // Convertir el precio a número decimal
        subproduct: selectedSubproduct.id,
      };
      console.log("servicios", newService)
      // Enviar la solicitud para agregar el nuevo servicio al subproducto seleccionado
      const response = await ProductDataService.createServiceForSubProduct(selectedSubproduct.id, newService);

      // Actualizar la lista de servicios del subproducto
      setSelectedSubproductServices([...selectedSubproductServices, response.data]);

      // Limpiar los campos de entrada
      setNewServiceName('');
      setNewServiceDescription('');
      setNewServicePrice('');
    } catch (error) {
      console.error('Error al agregar el servicio:', error);
    }
  };

  const handleDeleteService = async (serviceId) => {
    try {
      // Verificar si se ha seleccionado un subproducto
      if (!selectedSubproduct) {
        console.error('No se ha seleccionado un subproducto para eliminar el servicio.');
        return;
      }

      // Enviar la solicitud para eliminar el servicio con el ID proporcionado
      await ProductDataService.deleteServiceForSubProduct(selectedSubproduct.id, serviceId);

      // Actualizar la lista de servicios del subproducto eliminando el servicio correspondiente
      const updatedServices = selectedSubproductServices.filter((service) => service.id !== serviceId);
      setSelectedSubproductServices(updatedServices);
    } catch (error) {
      console.error('Error al eliminar el servicio:', error);
    }
  };

  return (
    <div>
      <br /><br /><br /><br /><br />
      <h1>Archivos de pagina Servicios</h1>
      <Button variant="primary" onClick={handleCreate}>Crear un Servicio</Button>
      <br />
      <div style={{ overflowX: 'auto' }}>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Nombre del producto</th>
              <th>Visualizacion</th>
              <th>Caracteristicas</th>
              <th>Drescripcion</th>
              <th>Creado por:</th>
              <th>Creado en:</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {currentProducts.map(product => (
              <tr key={product.id}>
                <td>{product.name}</td>
                <td>
                  {product.file && (
                    <div>

                      <br />
                      <img
                        src={product.file}
                        alt="Vista previa"
                        style={{ maxWidth: '100px', maxHeight: '100px' }}
                      />
                    </div>
                  )}
                  {product.file1 && (
                    <div>
                      <br />
                      <img
                        src={product.file1}
                        alt="Vista previa"
                        style={{ maxWidth: '100px', maxHeight: '100px' }}
                      />
                    </div>
                  )}
                </td>
                <td>
                  {product.characteristics.map(characteristic => (
                    <div key={characteristic.id}>
                      <strong>{characteristic.name}</strong>: {characteristic.description}
                    </div>
                  ))}
                </td>
                <td>{product.description}</td>
                <td>{product.user}</td>
                <td>{formatDate(product.created_at)}</td>
                <td>
                  <Button variant="info" onClick={() => handleEdit(product)}>Editar</Button>{' '}
                  <Button variant="danger" onClick={() => handleDelete(product.id)}>Eliminar</Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      {/* Paginación para la tabla de productos */}
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Button
          variant="secondary"
          onClick={() => setCurrentPage(currentPage - 1)}
          disabled={currentPage === 1}
        >
          Página Anterior
        </Button>{' '}
        {/* {renderPaginationButtons()} */}
        <Button
          variant="secondary"
          onClick={() => setCurrentPage(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          Página Siguiente
        </Button>
      </div>

      <br /><br />
      <Button variant="primary" onClick={handleCreateS}>
        Crear subproducto
      </Button>
      <Form.Group controlId="csvFile">
        <Form.Label>Subir archivo CSV para subproductos</Form.Label>
        <Form.Control
          type="file"
          accept=".csv"
          onChange={(e) => setCsvFile(e.target.files[0])}
        />
      </Form.Group>
      <Button onClick={handleCsvUpload}>Subir CSV</Button>

      <div style={{ overflowX: 'auto' }}>
        <Table striped bordered hover style={{ width: '100%' }}>
          <thead>
            <tr>
              <th>Nombre</th>
              <th>Telefono</th>
              <th>Email</th>
              <th>Direccion</th>
              <th>Pagina web</th>
              <th>Direccion Maps</th>
            </tr>
          </thead>
          <tbody>
            {subproducts
              .filter((subproduct, index, self) => {
                // Filtrar para que solo se muestren elementos con nombres y correos únicos
                return (
                  self.findIndex(
                    s => s.name === subproduct.name && s.email === subproduct.email
                  ) === index
                );
              })
              .slice((currentSubproductsPage - 1) * itemsPerPage, currentSubproductsPage * itemsPerPage)
              .map(subproduct => (
                <tr key={subproduct.id}>
                  <td>
                    <button onClick={() => handleShowServicesModal(subproduct)}>
                      {subproduct.name}
                    </button>
                  </td>
                  <td>{subproduct.phone}</td>
                  <td>{subproduct.email}</td>
                  <td>{subproduct.address}</td>
                  <td>{subproduct.url}</td>
                  <td>{subproduct.addressmap}</td>
                  <td>
                    <Button variant="info" onClick={() => handleEditSubproduct(subproduct)}>
                      Editar
                    </Button>
                    <Button variant="danger" onClick={() => handleDeleteS(subproduct.id)}>
                      Eliminar
                    </Button>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      </div>

      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Button
          variant="secondary"
          onClick={() => setCurrentSubproductsPage(currentSubproductsPage - 1)}
          disabled={currentSubproductsPage === 1}
        >
          Página Anterior
        </Button>{' '}
        {/* {renderPaginationButtons(currentSubproductsPage, Math.ceil(subproducts.length / itemsPerPage), setCurrentSubproductsPage)} */}
        <Button
          variant="secondary"
          onClick={() => setCurrentSubproductsPage(currentSubproductsPage + 1)}
          disabled={currentSubproductsPage === Math.ceil(subproducts.length / itemsPerPage)}
        >
          Página Siguiente
        </Button>
      </div>
      {/* Modal para mostrar los servicios */}
      <Modal show={showServicesModal}
        onHide={() => {
          setShowServicesModal(false);
          resetModalData(); // Reiniciar los datos del modal al cerrarlo
        }}
        dialogClassName="modal-lg"
        style={{overflowY: "auto"}}
      >
        <Modal.Header closeButton>
          <Modal.Title>Servicios de {selectedSubproduct && selectedSubproduct.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table striped bordered hover style={{ width: '100%' }}>
            <thead>
              <tr>
                <th>Codigo</th>
                <th>Nombre</th>
                <th>Descripción</th>
                <th>Precio</th>
                <th>Duracion</th>
                <th>Acciones</th> 
              </tr>
            </thead>
            <tbody>
              {selectedSubproductServices.map(service => (
                <tr key={service.id}>
                  <td>{service.code}</td>
                  <td>{service.name}</td>
                  <td>{service.description}</td>
                  <td>{service.price}</td>
                  <td>{service.duration}</td>
                  <td>
                    <button onClick={() => handleDeleteService(service.id)} className="btn btn-danger">
                      Eliminar
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          {/* Agregar inputs para agregar servicios */}
          <div className="mb-3">
            <input
              type="text"
              className="form-control"
              placeholder="Nombre del servicio"
              value={newServiceName}
              onChange={e => setNewServiceName(e.target.value)}
            />
          </div>
          <div className="mb-3">
            <input
              type="text"
              className="form-control"
              placeholder="Descripción del servicio"
              value={newServiceDescription}
              onChange={e => setNewServiceDescription(e.target.value)}
            />
          </div>
          <div className="mb-3">
            <input
              type="number"
              className="form-control"
              placeholder="Precio del servicio"
              value={newServicePrice}
              onChange={e => setNewServicePrice(e.target.value)}
            />
          </div>
          <button onClick={handleAddService} className="btn btn-primary">
            Agregar
          </button>

        </Modal.Body>
      </Modal>

      <Modal show={showEditModalS} onHide={handleCloseEditModalS} style={{overflowY: "auto"}}>
        <Modal.Header closeButton>
          <Modal.Title>Editar Subproducto</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formSubProductName">
              <Form.Label>Nombre</Form.Label>
              <Form.Control
                type="text"
                value={isEditing ? editSubproductData.name : subproductName}
                onChange={(e) => {
                  if (isEditing) {
                    setEditSubproductData({
                      ...editSubproductData,
                      name: e.target.value,
                    });
                  } else {
                    setSubproductName(e.target.value);
                  }
                }}
              />
            </Form.Group>
            <Form.Group controlId="formSubProductPhone">
              <Form.Label>Teléfono</Form.Label>
              <Form.Control
                type="text"
                value={isEditing ? editSubproductData.phone : subproductPhone}
                onChange={(e) => {
                  if (isEditing) {
                    setEditSubproductData({
                      ...editSubproductData,
                      phone: e.target.value,
                    });
                  } else {
                    setSubproductPhone(e.target.value);
                  }
                }}
              />
            </Form.Group>
            <Form.Group controlId="formSubProductEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                value={isEditing ? editSubproductData.email : subproductEmail}
                onChange={(e) => {
                  if (isEditing) {
                    setEditSubproductData({
                      ...editSubproductData,
                      email: e.target.value,
                    });
                  } else {
                    setSubproductEmail(e.target.value);
                  }
                }}
              />
            </Form.Group>

            <Form.Group controlId="formSubProductAddress">
              <Form.Label>Dirección</Form.Label>
              <Form.Control
                type="text"
                value={isEditing ? editSubproductData.address : subproductAddress}
                onChange={(e) => {
                  if (isEditing) {
                    setEditSubproductData({
                      ...editSubproductData,
                      address: e.target.value,
                    });
                  } else {
                    setSubproductAddress(e.target.value);
                  }
                }}
              />
            </Form.Group>

            <Form.Group controlId="formSubProductUrl">
              <Form.Label>Página web</Form.Label>
              <Form.Control
                type="text"
                value={isEditing ? editSubproductData.url : subproductUrl}
                onChange={(e) => {
                  if (isEditing) {
                    setEditSubproductData({
                      ...editSubproductData,
                      url: e.target.value,
                    });
                  } else {
                    setSubproductUrl(e.target.value);
                  }
                }}
              />
            </Form.Group>
            <Form.Group controlId="formSubProductAddressmap">
              <Form.Label>Dirección Maps</Form.Label>
              <Form.Control
                type="text"
                value={isEditing ? editSubproductData.addressmap : subproductAddressmap}
                onChange={(e) => {
                  if (isEditing) {
                    setEditSubproductData({
                      ...editSubproductData,
                      addressmap: e.target.value,
                    });
                  } else {
                    setSubproductAddressmap(e.target.value);
                  }
                }}
              />
            </Form.Group>

            <Form.Group controlId="formProducts">
              <Form.Label>Seleccionar Productos</Form.Label>
              <Form.Control
                as="select"
                multiple
                value={selectedProducts.map(product => JSON.stringify(product))}
                onChange={(e) => handleProductsSelection(e)}
                onDoubleClick={handleProductsDoubleClick}
              >
                {products.map(product => (
                  <option
                    key={product.id}
                    value={JSON.stringify(product)}
                    disabled={selectedProducts.some(
                      selectedProduct => selectedProduct.id === product.id
                    )}
                  >
                    {product.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>


          </Form>

        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseEditModalS}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={handleEditSubproducts}>
            Guardar Cambios
          </Button>
        </Modal.Footer>
      </Modal>
      <br /><br /><br />



      <Modal show={showEditModalS} onHide={handleCloseEditModalS} style={{overflowY: "auto"}}>
        <Modal.Header closeButton>
          <Modal.Title>Editar Subproducto</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formSubProductName">
              <Form.Label>Nombre</Form.Label>
              <Form.Control
                type="text"
                value={isEditing ? editSubproductData.name : subproductName}
                onChange={(e) => {
                  if (isEditing) {
                    setEditSubproductData({
                      ...editSubproductData,
                      name: e.target.value,
                    });
                  } else {
                    setSubproductName(e.target.value);
                  }
                }}
              />
            </Form.Group>
            <Form.Group controlId="formSubProductPhone">
              <Form.Label>Teléfono</Form.Label>
              <Form.Control
                type="text"
                value={isEditing ? editSubproductData.phone : subproductPhone}
                onChange={(e) => {
                  if (isEditing) {
                    setEditSubproductData({
                      ...editSubproductData,
                      phone: e.target.value,
                    });
                  } else {
                    setSubproductPhone(e.target.value);
                  }
                }}
              />
            </Form.Group>
            <Form.Group controlId="formSubProductEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                value={isEditing ? editSubproductData.email : subproductEmail}
                onChange={(e) => {
                  if (isEditing) {
                    setEditSubproductData({
                      ...editSubproductData,
                      email: e.target.value,
                    });
                  } else {
                    setSubproductEmail(e.target.value);
                  }
                }}
              />
            </Form.Group>

            <Form.Group controlId="formSubProductAddress">
              <Form.Label>Dirección</Form.Label>
              <Form.Control
                type="text"
                value={isEditing ? editSubproductData.address : subproductAddress}
                onChange={(e) => {
                  if (isEditing) {
                    setEditSubproductData({
                      ...editSubproductData,
                      address: e.target.value,
                    });
                  } else {
                    setSubproductAddress(e.target.value);
                  }
                }}
              />
            </Form.Group>

            <Form.Group controlId="formSubProductUrl">
              <Form.Label>Página web</Form.Label>
              <Form.Control
                type="text"
                value={isEditing ? editSubproductData.url : subproductUrl}
                onChange={(e) => {
                  if (isEditing) {
                    setEditSubproductData({
                      ...editSubproductData,
                      url: e.target.value,
                    });
                  } else {
                    setSubproductUrl(e.target.value);
                  }
                }}
              />
            </Form.Group>
            <Form.Group controlId="formSubProductAddressmap">
              <Form.Label>Dirección Maps</Form.Label>
              <Form.Control
                type="text"
                value={isEditing ? editSubproductData.addressmap : subproductAddressmap}
                onChange={(e) => {
                  if (isEditing) {
                    setEditSubproductData({
                      ...editSubproductData,
                      addressmap: e.target.value,
                    });
                  } else {
                    setSubproductAddressmap(e.target.value);
                  }
                }}
              />
            </Form.Group>

            <Form.Group controlId="formProducts">
              <Form.Label>Seleccionar Productos</Form.Label>
              <Form.Control
                as="select"
                multiple
                value={selectedProducts.map(product => JSON.stringify(product))}
                onChange={(e) => handleProductsSelection(e)}
                onDoubleClick={handleProductsDoubleClick}
              >
                {products.map(product => (
                  <option
                    key={product.id}
                    value={JSON.stringify(product)}
                    disabled={selectedProducts.some(
                      selectedProduct => selectedProduct.id === product.id
                    )}
                  >
                    {product.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>


          </Form>

        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseEditModalS}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={handleEditSubproducts}>
            Guardar Cambios
          </Button>
        </Modal.Footer>
      </Modal>
      <br /><br /><br />
      <Button variant="primary" onClick={handleShowCombosModal}>
        Crear Combo
      </Button>

      <Modal show={showModal} onHide={handleCloseModal} style={{overflowY: "auto"}}>
      <Modal.Header closeButton>
        <Modal.Title>Crear Combo</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="formComboName">
            <Form.Label>Nombre del Combo</Form.Label>
            <Form.Control
              type="text"
              placeholder="Ingrese el nombre del combo"
              value={newComboData.name}
              onChange={(e) => setNewComboData({ ...newComboData, name: e.target.value })}
            />
          </Form.Group>

          <Form.Group controlId="formComboDescription">
            <Form.Label>Descripción del Combo</Form.Label>
            <Form.Control
              type="text"
              placeholder="Ingrese la descripción del combo"
              value={newComboData.description}
              onChange={(e) => setNewComboData({ ...newComboData, description: e.target.value })}
            />
          </Form.Group>

          <Form.Group controlId="formComboPrice">
            <Form.Label>Precio del Combo</Form.Label>
            <Form.Control
              type="text"
              placeholder="Ingrese el precio del combo"
              value={newComboData.price}
              onChange={(e) => setNewComboData({ ...newComboData, price: e.target.value })}
            />
          </Form.Group>

            <Table striped bordered hover>
          <thead>
            <tr>
              <th>ID</th>
              <th>Nombre del Servicio</th>
              <th>Descripción</th>
              <th>Precio</th>
            </tr>
          </thead>
          <tbody>
            {services.map(service => (
              <tr key={service.id}>
                <td>{service.id}</td>
                <td>{service.name}</td>
                <td>{service.description}</td>
                <td>{service.price}</td>
                <td>
                      <Form.Check
                        type="checkbox"
                        id={`checkbox-${service.id}`}
                        checked={newComboData.selectedServiceIds.includes(service.id)}
                        onChange={() => handleServiceCheckboxChange(service.id)}
                      />
                    </td>
              </tr>
            ))}
          </tbody>
        </Table>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseModal}>
          Cancelar
        </Button>
        <Button variant="primary" onClick={handleCreateCombo}>
          Crear Combo
        </Button>
      </Modal.Footer>
    </Modal>

      <div>
      <h1>Combos</h1>
      <button onClick={handleDeleteSelectedCombos}>Eliminar Combos Seleccionados</button>
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Nombre</th>
            <th>Descripción</th>
            <th>Precio</th>
            <th>Servicios</th>
            <th>Eliminar</th>
          </tr>
        </thead>
        <tbody>
          {combos.map((combo) => (
            <tr key={combo.id}>
              <td>{combo.id}</td>
              <td>{combo.name}</td>
              <td>{combo.description}</td>
              <td>{combo.price}</td>
              <td>
                <ul>
                  {combo.services.map((service) => (
                    <li key={service.id}>
                     {service.id} - {service.name} - {service.description} - ${service.price}
                    </li>
                  ))}
                </ul>
              </td>
              <td>
                <input
                  type="checkbox"
                  checked={selectedComboIds.includes(combo.id)}
                  onChange={() => handleCheckboxChange(combo.id)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
          <br /><br /><br />
      <Button variant="primary" onClick={handleCreateM}>Crear Caracteristica</Button>
      <div style={{ overflowX: 'auto' }}>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>ID</th>
              <th>Nombre</th>
              <th>Descripcion</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {characteristicsList
              .slice((currentCharacteristicsPage - 1) * itemsPerPage, currentCharacteristicsPage * itemsPerPage)
              .map(characteristic => (
                <tr key={characteristic.id}>
                  <td>{characteristic.id}</td>
                  <td>{characteristic.name}</td>
                  <td>{characteristic.description}</td>
                  <td>
                    <Button variant="info" onClick={() => handleEditM(characteristic)}>
                      Editar
                    </Button>
                    <Button variant="danger" onClick={() => handleDeleteM(characteristic.id)}>
                      Eliminar
                    </Button>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>

        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Button
            variant="secondary"
            onClick={() => setCurrentCharacteristicsPage(currentCharacteristicsPage - 1)}
            disabled={currentCharacteristicsPage === 1}
          >
            Página Anterior
          </Button>{' '}
          {/* {renderPaginationButtons(currentCharacteristicsPage, Math.ceil(characteristicsList.length / itemsPerPage), setCurrentCharacteristicsPage)} */}
          <Button
            variant="secondary"
            onClick={() => setCurrentCharacteristicsPage(currentCharacteristicsPage + 1)}
            disabled={currentCharacteristicsPage === Math.ceil(characteristicsList.length / itemsPerPage)}
          >
            Página Siguiente
          </Button>
        </div>
      </div>
      <Modal show={showCreateModalM} onHide={handleCloseCreateModalM} style={{overflowY: "auto"}}>
        <Modal.Header closeButton>
          <Modal.Title>Crear característica</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Nombre</Form.Label>
              <Form.Control type="text" value={nameM} onChange={(e) => setNameM(e.target.value)} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Descripción</Form.Label>
              <Form.Control as="textarea" rows={3} value={descriptionM} onChange={(e) => setDescriptionM(e.target.value)} />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseCreateModalM}>Cancelar</Button>
          <Button variant="primary" onClick={handleSubmitCreateM}>Crear</Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showCreateModalS} onHide={handleCloseCreateModalS} style={{ overflowY: 'auto' }}>
        <Modal.Header closeButton>
          <Modal.Title>{editingSubproduct ? 'Editar Subproducto' : 'Crear Subproducto'}</Modal.Title>
        </Modal.Header>
        <Modal.Body >
          <Form>
            <Form.Group>
              <Form.Label>Nombre</Form.Label>
              <Form.Control
                type="text"
                value={subproductName}
                onChange={(e) => setSubproductName(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Telefono</Form.Label>
              <Form.Control
                type="text"
                value={subproductPhone}
                onChange={(e) => setSubproductPhone(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Email</Form.Label>
              <Form.Control type="email" value={subproductEmail} onChange={(e) => setSubproductEmail(e.target.value)} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Direccion</Form.Label>
              <Form.Control type="text" value={subproductAddress} onChange={(e) => setSubproductAddress(e.target.value)} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Pagina web</Form.Label>
              <Form.Control type="text" value={subproductUrl} onChange={(e) => setSubproductUrl(e.target.value)} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Direccion Maps</Form.Label>
              <Form.Control type="text" value={subproductAddressmap} onChange={(e) => setSubproductAddressmap(e.target.value)} />
            </Form.Group>

            <Form.Group controlId="formProducts">
              <Form.Label>Seleccionar Productos</Form.Label>
              <Form.Control
                as="select"
                multiple
                value={selectedProducts.map(product => JSON.stringify(product))}
                onChange={(e) => handleProductsSelection(e)}
                onDoubleClick={handleProductsDoubleClick}
              >
                {products.map(product => (
                  <option
                    key={product.id}
                    value={JSON.stringify(product)}
                    disabled={selectedProducts.some(
                      selectedProduct => selectedProduct.id === product.id
                    )}
                  >
                    {product.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            <Form.Group>
              <Form.Label>Descripción</Form.Label>
              <Form.Control
                as="textarea"
                rows={4}
                value={subproductDescription}
                onChange={(e) => setSubproductDescription(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>País</Form.Label>
              <Form.Control
                type="text"
                value={subproductCountry}
                onChange={(e) => setSubproductCountry(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Provincia</Form.Label>
              <Form.Control
                type="text"
                value={subproductProvince}
                onChange={(e) => setSubproductProvince(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Canton</Form.Label>
              <Form.Control
                type="text"
                value={subproductCanton}
                onChange={(e) => setSubproductCanton(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Distrito</Form.Label>
              <Form.Control
                type="text"
                value={subproductDistrito}
                onChange={(e) => setSubproductDistrito(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Nombre de Contacto</Form.Label>
              <Form.Control
                type="text"
                value={subproductContactName}
                onChange={(e) => setSubproductContactName(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Número de Teléfono</Form.Label>
              <Form.Control
                type="text"
                value={subproductPhoneNumber}
                onChange={(e) => setSubproductPhoneNumber(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Actividad Comercial</Form.Label>
              <Form.Control
                type="text"
                value={subproductComercialActivity}
                onChange={(e) => setSubproductComercialActivity(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Fecha de Constitucion</Form.Label>
              <Form.Control
                type="date"
                value={subproductConstitucion}
                onChange={(e) => setSubproductConstitucion(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Método de Pago</Form.Label>
              <Form.Control
                type="text"
                value={subproductPayMethod}
                onChange={(e) => setSubproductPayMethod(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Logo</Form.Label>
              <Form.Control
                type="file"
                accept="image/*"
                onChange={(e) => setSubproductLogo(e.target.files[0])}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Archivo</Form.Label>
              <Form.Control
                type="file"
                accept=".pdf, .jpg, .jpeg, .png, .gif, .mp4, .avi, .mkv, .doc, .docx, .xls, .xlsx"
                onChange={(e) => setSubproductFile(e.target.files[0])}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseCreateModalS}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={editingSubproduct ? handleEditSubproducts : handleSaveSubproducts}>
            {editingSubproduct ? 'Guardar Cambios' : 'Crear'}
          </Button>
        </Modal.Footer>
      </Modal>
      {selectedCharacteristicM && (
        <Modal show={showEditModalM} onHide={handleCloseEditModalM} style={{overflowY: "auto"}}>
          <Modal.Header closeButton>
            <Modal.Title>Editar característica</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group>
                <Form.Label>Nombre</Form.Label>
                <Form.Control type="text" value={nameM} onChange={(e) => setNameM(e.target.value)} />
              </Form.Group>
              <Form.Group>
                <Form.Label>Descripción</Form.Label>
                <Form.Control as="textarea" rows={3} value={descriptionM} onChange={(e) => setDescriptionM(e.target.value)} />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseEditModalM}>Cancelar</Button>
            <Button variant="primary" onClick={handleSubmitEditM}>Guardar los cambios</Button>
          </Modal.Footer>
        </Modal>
      )}

      <Modal show={showCreateModal} onHide={handleCloseCreateModal} style={{overflowY: "auto"}}>
        <Modal.Header closeButton>
          <Modal.Title>Crear Servicios</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formName">
              <Form.Label>Nombre</Form.Label>
              <Form.Control type="text" value={name} onChange={(e) => setName(e.target.value)} />
            </Form.Group>
            <Form.Group controlId="formUrl">
              <Form.Label>URL</Form.Label>
              <Form.Control type="text" value={nameUrl} onChange={(e) => setNameUrl(e.target.value)} />
            </Form.Group>
            <Form.Group controlId="formDescription">
              <Form.Label>Descripción</Form.Label>
              <Form.Control as="textarea" rows={3} value={description} onChange={(e) => setDescription(e.target.value)} />
            </Form.Group>
            <Form.Group controlId="formFile">
              <Form.Label>Archivo</Form.Label>
              <Form.Control type="file" onChange={(e) => setFile(e.target.files[0])} />
            </Form.Group>
            <Form.Group controlId="formFile1">
              <Form.Label>Archivo característica</Form.Label>
              <Form.Control type="file" onChange={(e) => setFile1(e.target.files[0])} />
            </Form.Group>
            <Form.Group controlId="formCharacteristics">
              <Form.Label>Características</Form.Label>
              <Form.Control
                as="select"
                multiple
                value={selectedCharacteristics.map(char => JSON.stringify(char))}
                onChange={(e) => handleCharacteristicsSelection(e)}
                onDoubleClick={handleCharacteristicsDoubleClick}
              >
                {characteristicOptions.map(char => (
                  <option
                    key={char.id}
                    value={JSON.stringify(char)}
                    disabled={selectedCharacteristics.some(
                      selectedChar => selectedChar.id === char.id
                    )}
                  >
                    {char.name}
                    <br /><br /><br /><br /><br />
                    {char.description}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseCreateModal}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={handleSave}>
            Crear
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showEditModal} onHide={handleCloseEditModal} style={{overflowY: "auto"}}>
        <Modal.Header closeButton>
          <Modal.Title>Editar Servicio</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="editFormName">
              <Form.Label>Nombre</Form.Label>
              <Form.Control type="text" value={editData.name} onChange={handleEditNameChange} />
            </Form.Group>

            <Form.Group controlId="editFormDescription">
              <Form.Label>Descripcion</Form.Label>
              <Form.Control as="textarea" rows={3} value={editData.description} onChange={handleEditDescriptionChange} />
            </Form.Group>
            <Form.Group controlId="editFormNameUrl">
              <Form.Label>Nombre URL</Form.Label>
              <Form.Control type="text" value={editData.name_url} onChange={handleEditNameUrlChange} />
            </Form.Group>

            <Form.Group controlId="formFile">
              <Form.Label>Archivo</Form.Label>
              <Form.Control type="file" onChange={(e) => setFile(e.target.files[0])} />
            </Form.Group>
            <Form.Group controlId="formFile1">
              <Form.Label>Archivo caracteristica</Form.Label>
              <Form.Control type="file" onChange={(e) => setFile1(e.target.files[0])} />
            </Form.Group>
            <Form.Group controlId="formCharacteristics">
              <Form.Label>Características</Form.Label>
              <Form.Control
                as="select"
                multiple
                value={editData.characteristics.map(char => JSON.stringify(char))}
                onChange={(e) => handleCharacteristicsSelection(e)}
                onDoubleClick={handleCharacteristicsDoubleClick}
              >
                {characteristicOptions.map(char => (
                  <option
                    key={char.id}
                    value={JSON.stringify(char)}
                    disabled={editData.characteristics.some(
                      selectedChar => selectedChar.id === char.id
                    )}
                  >
                    {char.name}
                    <br /><br /><br /><br /><br />
                    {char.description}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseEditModal}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={handleSaveEdit}>
            Guardar Cambios
          </Button>
        </Modal.Footer>

      </Modal>

    </div>


  );
};

export default CharacteristicsTable;