import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Container, Table, Form, Button, InputGroup, Modal } from 'react-bootstrap';
import EmployeeDataService from '../../services/employee';
import './TablaDatos.css';

const EmployeesDeducciones = () => {
  const [deducciones, setDeducciones] = useState([]);
  const [selectedDeducciones, setSelectedDeducciones] = useState([]);
  const token = useSelector(state => state.authentication.token);
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    planillas: [""],
    empleados_seleccionados: [""],
    nombre_deduccion: "Deduccion por",
    salario_mensual: "",
    salario_diario: "",
    salario_hora: "",
    monto: "",
    porcentaje: false,
    cantidad_total_cuotas: "",
    cantidad_actual_cuotas: "",
    interes_cuotas: "",
    mes: "Febrero",
    anio: "2024",
    codigo_deduccion: ""
  });
  

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const fetchData = async () => {
    try {
      const responseDeducciones = await EmployeeDataService.getAllDeducciones(token);
      setDeducciones(responseDeducciones.data);
    } catch (error) {
      console.error('Error al obtener datos:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [token]);


  const handleDeleteSelected = async () => {
    try {
      await EmployeeDataService.deleteDeducciones(selectedDeducciones, token);
      setSelectedDeducciones([]);
      fetchData();
    } catch (error) {
      console.error('Error al eliminar deducciones:', error);
    }
  };

  const toggleSelectDeduccion = (deduccionId) => {
    if (selectedDeducciones.includes(deduccionId)) {
      setSelectedDeducciones(selectedDeducciones.filter(id => id !== deduccionId));
    } else {
      setSelectedDeducciones([...selectedDeducciones, deduccionId]);
    }
  };

  const handleCrearDeducciones = async () => {
    try {
      console.log("informacion de formdata", formData);
      await EmployeeDataService.createDeducciones(formData, token);
      toggleModal();
      fetchData();
    } catch (error) {
      console.error('Error al crear deducción:', error);
      console.log('Respuesta del servidor:', error.response.data);
    }
  };

  return (
    <Container>
      <div className="tabla-datos-container">
        <br /><br /><br />
        <h2>Deducciones de Empleados</h2>
        <Button variant="primary" onClick={toggleModal}>Crear Deducción</Button>

        {/* Modal */}
        <Modal show={showModal} onHide={toggleModal} style={{ overflowY: 'auto' }}>
          <Modal.Header closeButton>
            <Modal.Title>Crear Deducción</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              {/* Agrega los campos del formulario según tu modelo */}
              <Form.Group controlId="nombreDeduccion">
  <Form.Label>Nombre de la Deducción</Form.Label>
  <Form.Control
    type="text"
    name="nombre_deduccion"
    value={formData.nombre_deduccion}
    onChange={(e) => setFormData({ ...formData, nombre_deduccion: e.target.value })}
  />
</Form.Group>
              <Form.Group controlId="empleadosSeleccionados">
                <Form.Label>Empleados Seleccionados</Form.Label>
                <Form.Control
                  type="text"
                  value={formData.empleados_seleccionados}
                  onChange={(e) => setFormData({ ...formData, empleados_seleccionados: e.target.value })}
                />
              </Form.Group>
              <Form.Group controlId="planillas">
                <Form.Label>Planilla</Form.Label>
                <Form.Control
                  type="text"
                  name="planillas"
                  value={formData.planillas}
                  onChange={(e) => setFormData({ ...formData, planillas: e.target.value })}
                />
              </Form.Group>
              <Form.Group controlId="salarioMensual">
                <Form.Label>Salario Mensual</Form.Label>
                <Form.Control
                  type="number"
                  name="salario_mensual"
                  value={formData.salario_mensual}
                  onChange={(e) => setFormData({ ...formData, salario_mensual: e.target.value })}
                />
              </Form.Group>

              <Form.Group controlId="salarioDiario">
                <Form.Label>Salario Diario</Form.Label>
                <Form.Control
                  type="number"
                  name="salario_diario"
                  value={formData.salario_diario}
                  onChange={(e) => setFormData({ ...formData, salario_diario: e.target.value })}
                />
              </Form.Group>

              <Form.Group controlId="salarioHora">
                <Form.Label>Salario Hora</Form.Label>
                <Form.Control
                  type="number"
                  name="salario_hora"
                  value={formData.salario_hora}
                  onChange={(e) => setFormData({ ...formData, salario_hora: e.target.value })}
                />
              </Form.Group>

              <Form.Group controlId="monto">
                <Form.Label>Monto</Form.Label>
                <Form.Control
                  type="number"
                  name="monto"
                  value={formData.monto}
                  onChange={(e) => setFormData({ ...formData, monto: e.target.value })}
                />
              </Form.Group>

              <Form.Group controlId="porcentaje">
  <Form.Check
    type="checkbox"
    label="Porcentaje"
    name="porcentaje"
    checked={formData.porcentaje}
    onChange={(e) => setFormData({ ...formData, porcentaje: e.target.checked })}
  />
</Form.Group>

              <Form.Group controlId="cantidadTotalCuotas">
                <Form.Label>Cantidad Total Cuotas</Form.Label>
                <Form.Control
                  type="number"
                  name="cantidad_total_cuotas"
                  value={formData.cantidad_total_cuotas}
                  onChange={(e) => setFormData({ ...formData, cantidad_total_cuotas: e.target.value })}
                />
              </Form.Group>

              <Form.Group controlId="cantidadActualCuotas">
                <Form.Label>Cantidad Actual Cuotas</Form.Label>
                <Form.Control
                  type="number"
                  name="cantidad_actual_cuotas"
                  value={formData.cantidad_actual_cuotas}
                  onChange={(e) => setFormData({ ...formData, cantidad_actual_cuotas: e.target.value })}
                />
              </Form.Group>

              <Form.Group controlId="interesCuotas">
                <Form.Label>Interés Cuotas</Form.Label>
                <Form.Control
                  type="number"
                  name="interes_cuotas"
                  value={formData.interes_cuotas}
                  onChange={(e) => setFormData({ ...formData, interes_cuotas: e.target.value })}
                />
              </Form.Group>

              <Form.Group controlId="mes">
                <Form.Label>Mes</Form.Label>
                <Form.Control
                  type="text"
                  name="mes"
                  value={formData.mes}
                  onChange={(e) => setFormData({ ...formData, mes: e.target.value })}
                />
              </Form.Group>

              <Form.Group controlId="anio">
                <Form.Label>Año</Form.Label>
                <Form.Control
                  type="number"
                  name="anio"
                  value={formData.anio}
                  onChange={(e) => setFormData({ ...formData, anio: e.target.value })}
                />
              </Form.Group>

              <Form.Group controlId="codigoDeduccion">
                <Form.Label>Código Deducción</Form.Label>
                <Form.Control
                  type="text"
                  name="codigo_deduccion"
                  value={formData.codigo_deduccion}
                  onChange={(e) => setFormData({ ...formData, codigo_deduccion: e.target.value })}
                />
              </Form.Group>


              <Button variant="primary" onClick={handleCrearDeducciones}>
                Guardar
              </Button>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={toggleModal}>
              Cerrar
            </Button>
          </Modal.Footer>
        </Modal>

        {deducciones.length > 0 ? (
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Check</th>
                <th>ID</th>
                <th>Empleado</th>
                <th>Planillas</th>
                <th>Nombre de la Deducción</th>
                <th>Salario Mensual</th>
                <th>Salario Diario</th>
                <th>Salario Hora</th>
                <th>Monto</th>
                <th>Porcentaje</th>
                <th>Cantidad Total Cuotas</th>
                <th>Cantidad Actual Cuotas</th>
                <th>Interés Cuotas</th>
                <th>Mes</th>
                <th>Año</th>
                <th>Código Deducción</th>
              </tr>
            </thead>
            <tbody>
              {deducciones.map(deduccion => (
                <tr key={deduccion.id}>
                  <td>
                    <Form.Check
                      type="checkbox"
                      checked={selectedDeducciones.includes(deduccion.id)}
                      onChange={() => toggleSelectDeduccion(deduccion.id)}
                    />
                  </td>
                  <td>{deduccion.id}</td>
                  <td>{deduccion.employeeName}</td>
                  <td>{deduccion.planillas}</td>
                  <td>{deduccion.nombre_deduccion}</td>
                  <td>{deduccion.salario_mensual}</td>
                  <td>{deduccion.salario_diario}</td>
                  <td>{deduccion.salario_hora}</td>
                  <td>{deduccion.monto}</td>
                  <td>{deduccion.porcentaje}</td>
                  <td>{deduccion.cantidad_total_cuotas}</td>
                  <td>{deduccion.cantidad_actual_cuotas}</td>
                  <td>{deduccion.interes_cuotas}</td>
                  <td>{deduccion.mes}</td>
                  <td>{deduccion.anio}</td>
                  <td>{deduccion.codigo_deduccion}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <p>No hay datos de deducciones disponibles.</p>
        )}

        {selectedDeducciones.length > 0 && (
          <InputGroup className="mb-3">
            <Button variant="danger" onClick={handleDeleteSelected}>
              Eliminar seleccionado
            </Button>
          </InputGroup>
        )}
      </div>
    </Container>
  );
};

export default EmployeesDeducciones;
