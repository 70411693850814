import React from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

function ModalProducto(props) {
    const {
        showModal,
        handleCloseModal,
        newProductData,
        setNewProductData,
        handleImageChange,
        handleCreateOrEditProduct,
        handleCategoryChange,
        handleSubcategoryChange
    } = props;

    return (
        <Modal show={showModal} onHide={handleCloseModal} style={{ overflowX: 'auto', overflowY: 'auto' }}>
            <Modal.Header closeButton>
                <Modal.Title>Crear Productos Servicios Cooperativos</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <button

                    style={{
                        background: "red",
                        color: "white",
                        padding: "8px",
                        borderRadius: "5px",
                        border: "none",
                        cursor: "pointer",
                        position: "absolute",
                        top: "4px",
                        right: "4px",
                    }}
                    onClick={handleCloseModal}
                >
                    X
                </button>
                <Form>
                    {/* Agregar aquí los campos del formulario */}
                    {/* Ejemplo para el campo 'Nombre' */}
                    <Form.Group controlId="formName">
                        <Form.Label>Nombre</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Ingrese el nombre"
                            value={newProductData.name}
                            onChange={(e) =>
                                setNewProductData({ ...newProductData, name: e.target.value })
                            }
                        />
                    </Form.Group>


                    <Form.Group controlId="formBrand">
                        <Form.Label>Marca</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Ingrese la marca"
                            value={newProductData.brand}
                            onChange={(e) => setNewProductData({ ...newProductData, brand: e.target.value })}
                        />
                    </Form.Group>

                    <Form.Group controlId="formCategory">
                        <Form.Label>Categoría</Form.Label>
                        <Form.Select
                            value={newProductData.category}
                            onChange={handleCategoryChange}
                        >
                            <option value="">Seleccione una categoría</option>
                            <optgroup >
                                <option value="Productos Cooperativos">Productos Cooperativos</option>
                                <option value="Servicios Cooperativos">Servicios Cooperativos</option>
                                <option value="Cooperativas de Trabajo">Cooperativas de Trabajo</option>
                                <option value="Apoyo y Asesoramiento">Apoyo y Asesoramiento</option>

                            </optgroup>

                        </Form.Select>


                    </Form.Group>
                    <Form.Group controlId="formSubcategory">
                        <Form.Label>Subcategoría</Form.Label>
                        <Form.Select
                            multiple  // Habilita la selección múltiple
                            value={newProductData.subcategory}
                            onChange={handleSubcategoryChange}
                            style={{ height: '50vh' }}
                        >
                            <option value="">Seleccione una Subcategoría</option>
                            <optgroup label="Productos Cooperativos">
                                <option value="Alimentos y Agricultura">Alimentos y Agricultura</option>
                                <option value="Servicios Financieros">Servicios Financieros</option>
                                <option value="Energía y Medio Ambiente">Energía y Medio Ambiente</option>
                                <option value="Salud y Bienestar">Salud y Bienestar</option>
                                <option value="Educación y Capacitación">Educación y Capacitación</option>
                            </optgroup>

                            <optgroup label="Servicios Cooperativos">
                                <option value="Banca Cooperativa">Banca Cooperativa</option>
                                <option value="Cooperativas de Vivienda">Cooperativas de Vivienda</option>
                                <option value="Cooperativas de Crédito">Cooperativas de Crédito</option>
                                <option value="Cooperativas de Consumidores">Cooperativas de Consumidores</option>
                                <option value="Cooperativas de Agricultores">Cooperativas de Agricultores</option>
                            </optgroup>

                            <optgroup label="Cooperativas de Trabajo">
                                <option value="Cooperativas de Agricultura">Cooperativas de Agricultura</option>
                                <option value="Cooperativas de Servicios">Cooperativas de Servicios</option>
                                <option value="Cooperativas de Producción">Cooperativas de Producción</option>
                            </optgroup>

                            <optgroup label="Apoyo y Asesoramiento">
                                <option value="Asesoramiento Cooperativo">Asesoramiento Cooperativo</option>
                                <option value="Promoción de Cooperativas">Promoción de Cooperativas</option>
                                <option value="Educación Cooperativa">Educación Cooperativa</option>
                            </optgroup>


                        </Form.Select>
                    </Form.Group>




                    <Form.Group controlId="formDescription">
                        <Form.Label>Descripción</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            placeholder="Ingrese la descripción"
                            value={newProductData.description}
                            onChange={(e) => setNewProductData({ ...newProductData, description: e.target.value })}
                        />
                    </Form.Group>

                    <Form.Group controlId="formPrice">
                        <Form.Label>Precio</Form.Label>
                        <Form.Control
                            type="number"
                            placeholder="Ingrese el precio"
                            value={newProductData.price}
                            onChange={(e) => setNewProductData({ ...newProductData, price: e.target.value })}
                        />
                    </Form.Group>

                    <Form.Group controlId="formDiscount">
                        <Form.Label>Descuento</Form.Label>
                        <Form.Control
                            type="number"
                            placeholder="Ingrese el descuento"
                            value={newProductData.discount}
                            onChange={(e) => setNewProductData({ ...newProductData, discount: e.target.value })}
                        />
                    </Form.Group>

                    <Form.Group controlId="formCountInStock">
                        <Form.Label>Cantidad en Stock</Form.Label>
                        <Form.Control
                            type="number"
                            placeholder="Ingrese la cantidad en stock"
                            value={newProductData.countInStock}
                            onChange={(e) => setNewProductData({ ...newProductData, countInStock: e.target.value })}
                        />
                    </Form.Group>

                    <Form.Group controlId="formImage">
                        <Form.Label>Imagen</Form.Label>
                        <Form.Control type="file" onChange={handleImageChange} />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseModal}>
                    Cerrar
                </Button>
                <Button variant="primary" onClick={handleCreateOrEditProduct}>
                    Guardar
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default ModalProducto;
