import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Container, Table, Form, Button, InputGroup, Modal } from 'react-bootstrap';
import EmployeeDataService from '../../services/employee';
import './TablaDatos.css';
import { BsCheck, BsX } from 'react-icons/bs';


const EmployeesAdmin = () => {
  const [admin, setAdmin] = useState([]);
  const [selectedAdmin, setSelectedAdmin] = useState([]);
  const token = useSelector(state => state.authentication.token);
  const [showModal, setShowModal] = useState(false);
  const [newAdminData, setNewAdminData] = useState({
    nombre_decision: 'ajuste de salario',
    accion: 'deduccion',
    porcentual: true,
    monto: null,
    empleados_seleccionados: [],
    seleccionar_todos: false,
  });

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const fetchData = async () => {
    try {
      const responseAdmin = await EmployeeDataService.getAllAdmin(token);
      setAdmin(responseAdmin.data);
    } catch (error) {
      console.error('Error al obtener datos:', error);
    }
  };

  const handleCreateAdmin = async () => {
    try {
      await EmployeeDataService.createAdmin(newAdminData, token);
      toggleModal();
      fetchData();
    } catch (error) {
      console.error('Error al crear administrador:', error);
    }
  };



  useEffect(() => {
    fetchData();
  }, [token]);

  const handleDeleteSelected = async () => {
    try {
      await EmployeeDataService.deleteAdmin(selectedAdmin, token);
      setSelectedAdmin([]);
      fetchData();
    } catch (error) {
      console.error('Error al eliminar administradores:', error);
    }
  };

  const toggleSelectAdmin = (adminId) => {
    if (selectedAdmin.includes(adminId)) {
      setSelectedAdmin(selectedAdmin.filter(id => id !== adminId));
    } else {
      setSelectedAdmin([...selectedAdmin, adminId]);
    }
  };

  return (
    <Container>
      <div className="tabla-datos-container">
      <br/><br/><br/>
      <h2>Administradores de Empleados</h2>
      <Button variant="primary" onClick={toggleModal}>Crear Administrador</Button>

     {/* Modal */}
        <Modal show={showModal} onHide={toggleModal} style={{overflowY: 'auto' }}>
          <Modal.Header closeButton>
            <Modal.Title>Crear Administrador</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="nombreDecision">
                <Form.Label>Nombre de la Decisión</Form.Label>
                <Form.Control
                  type="text"
                  value={newAdminData.nombre_decision}
                  onChange={(e) => setNewAdminData({ ...newAdminData, nombre_decision: e.target.value })}
                />
              </Form.Group>
              <Form.Group controlId="accion">
                <Form.Label>Accion</Form.Label>
                <Form.Control
                  as="select"
                  value={newAdminData.accion}
                  onChange={(e) => setNewAdminData({ ...newAdminData, accion: e.target.value })}
                >
                  <option value="deduccion">Deduccion</option>
                  <option value="aumento">Aumento</option>
                </Form.Control>
              </Form.Group>
              <Form.Group controlId="porcentual">
                <Form.Check
                  type="checkbox"
                  label="Porcentual"
                  checked={newAdminData.porcentual}
                  onChange={(e) => setNewAdminData({ ...newAdminData, porcentual: e.target.checked })}
                />
              </Form.Group>
              <Form.Group controlId="monto">
                <Form.Label>Monto</Form.Label>
                <Form.Control
                  type="number"
                  value={newAdminData.monto}
                  onChange={(e) => setNewAdminData({ ...newAdminData, monto: e.target.value })}
                />
              </Form.Group>
              <Form.Group controlId="empleadosSeleccionados">
                <Form.Label>Empleados Seleccionados</Form.Label>
                <Form.Control
                  type="text"
                  value={newAdminData.empleados_seleccionados}
                  onChange={(e) => setNewAdminData({ ...newAdminData, empleados_seleccionados: e.target.value })}
                />
              </Form.Group>
              <Form.Group controlId="seleccionarTodos">
                <Form.Check
                  type="checkbox"
                  label="Seleccionar Todos"
                  checked={newAdminData.seleccionar_todos}
                  onChange={(e) => setNewAdminData({ ...newAdminData, seleccionar_todos: e.target.checked })}
                />
              </Form.Group>
              <Button variant="primary" onClick={handleCreateAdmin}>
                Crear Administrador
              </Button>
            </Form>
          </Modal.Body>
        </Modal>

        {admin.length > 0 ? (
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Check</th>
                <th>ID</th>
                <th>Nombre de la Decisión</th>
                <th>Porcentual</th>
                <th>Monto</th>
                <th>Empleados Seleccionados</th>
                <th>Todos los Usuarios</th>
              </tr>
            </thead>
            <tbody>
              {admin.map(administrator => (
                <tr key={administrator.id}>
                  <td>
                    <Form.Check
                      type="checkbox"
                      checked={selectedAdmin.includes(administrator.id)}
                      onChange={() => toggleSelectAdmin(administrator.id)}
                    />
                  </td>
                  <td>{administrator.id}</td>
                  <td>{administrator.nombre_decision}</td>
                  <td>{administrator.porcentual ? <BsCheck color="green" size={20} style={{ fontWeight: 'bold' }}/> : <BsX color="red" size={20} style={{ fontWeight: 'bold' }}/>}</td>
                  <td>{administrator.monto}</td>
                  <td>{administrator.empleados_seleccionados}</td>
                  <td>{administrator.seleccionar_todos ? <BsCheck color="green" size={20} style={{ fontWeight: 'bold' }}/> : <BsX color="red" size={20} style={{ fontWeight: 'bold' }}/>}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <p>No hay datos de administradores disponibles.</p>
        )}

      {selectedAdmin.length > 0 && (
        <InputGroup className="mb-3">
          <Button variant="danger" onClick={handleDeleteSelected}>
            Eliminar seleccionado
          </Button>
        </InputGroup>
      )}
      </div>
    </Container>
  );
};

export default EmployeesAdmin;
