import React, { createContext, useState, useEffect } from 'react';
import { HashRouter as Router, Route, Routes, useLocation, Navigate } from 'react-router-dom';
import Error404 from '../src/containers/errors/Error404';
import Home from '../src/containers/pages/Home';
import Technology from '../src/containers/pages/Technology';
import Products from '../src/containers/pages/Products';
import Services from '../src/containers/pages/Services';
import News from '../src/containers/pages/News';
import Blog from '../src/components/manager/manage_blog';
import Contactenos from '../src/containers/pages/Contactenos';
import Calendar from '../src/components/backend/calendar';
import Login from '../src/components/login/login';
import Sidebar from '../src/components/navigation/sidebar';
import Navbar from '../src/components/navigation/Navbar';
// import Dashboard from '../src/components/manager/dashboard';
// import Team from '../src/components/manager/manage_team';
import Contacts from '../src/components/manager/contacts_info';
// import FAQ from '../src/components/manager/faq';
import Signup from '../src/components/login/signup';

// import ManageProduct from '../src/components/manager/manage_product';
import ManageBlog from '../src/components/manager/manage_blog';
import BulkMails from '../src/components/manager/bulk_mails';
import ResetPassword from '../src/components/home/reset_password';
import ResetPasswordConfirm from '../src/components/home/ResetPasswordConfirm';
import ResetPasswordUser from './components/login/resetpassworduser';
import { AuthProvider } from './components/login/AuthContext';
import TodoDataService from './services/todos';
import UserList1 from './components/CurrentUser';
import { Provider } from 'react-redux';
import Files from '../src/components/backend/files';
import Files_frontend from '../src/components/backend/files_frontend.js';
import UserList from './components/backend/usuarios';
import store from './redux/store';
import Register from './components/backend/register';
import { Helmet } from 'react-helmet';
import Files_home from './components/backend/files_home';
import Files_technology from './components/backend/files_technology';
import Files_products from './components/backend/files_products';
import Files_services from './components/backend/files_services';
import Files_news from './components/backend/files_news';
import Files_contact from './components/backend/files_contact';
import Files_aplication from './components/backend/files_aplications';
import SubproductDetails from '../src/containers/pages/details/subproductdetails';

import Bolsadeempleo from './containers/services/2bolsadeempleo.js';
import Casasylotes from './containers/services/3casasylotes.js';
import Centroseducativos from './containers/services/8centroseducativos.js';
import Clinicassaludyestetica from './containers/services/4clinicassaludyestetica.js';
import Comunicaciontecnologiayenergia from './containers/services/5comunicaciontecnologiayenergia.js';
import Construcciondisenoysupervicion from './containers/services/6construcciondisenoysupervicion.js';
import Cuponesdedescuento from './containers/services/7cuponesdedescuento.js';
import Entretenimientorestaurantesyturismo from './containers/services/9entretenimientorestaurantesyturismo.js';
import Ferreteriaydeposito from './containers/services/10ferreteriaydeposito.js';
import Fundacioneslabones from './containers/services/21fundacioneslabones.js';
import Hogartiendayelectronica from './containers/services/11hogartiendayelectronica.js';
import Legalynotariado from './containers/services/13legalynotariado.js';
import Libreriayeditoriales from './containers/services/14libreriayeditoriales.js';
import Noticiasyavisosclasificados from './containers/services/16noticiasyavisosclasificados.js';
import Ofertasysubastas from './containers/services/15ofertasysubastas.js';
import Plataformadeinversiones from './containers/services/12plataformadeinversiones.js';
import Polizayseguros from './containers/services/17polizayseguros.js';
import Prestamosyrescatesobrepropiedades from './containers/services/18prestamosyrescatesobrepropiedades.js';
import Productosyservicioscooperativos from './containers/services/19productosyservicioscooperativos.js';
import Publicidadypaginasweb from './containers/services/20publicidadypaginasweb.js';
import HotelesTurismo from './containers/services/22hoteles_turismo.js';
import Transporteymensajeria from './containers/services/23transporteymensajeria.js';
import Vehiculosrepuestosytalleres from './containers/services/1vehiculosrepuestosytalleres.js';
import Precios from '../src/containers/pages/Precios';
import Homescreen from './containers/ecommerce/Homescreen.jsx';
import Files_ecommerce from './components/backend/files_ecommerce.js';
import Bolsaempleo from './containers/pages/bolsaempleo.jsx';
import Tienda from './containers/services/tienda.js';
import Directorio from './containers/pages/Directorio.jsx';
import AvisosClasificados from './containers/pages/Avisos_clasificados.jsx';
import Noticias from './containers/pages/Noticias.jsx';
import Cotizador from './containers/pages/Cotizador.jsx';
import './App.css'; 

// Componente ScrollToTop para desplazarse al principio de la página en cada cambio de ruta
function ScrollToTop() {
  const { pathname } = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

export const AuthContext = createContext();

function App() {
  const [isSidebar, setIsSidebar] = useState(true);
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(null);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    const storedToken = localStorage.getItem('token');
    if (storedUser && storedToken) {
      setUser(storedUser);
      setToken(storedToken);
    }
    setLoading(false);
  }, []);

  const login = (user = null) => {
    TodoDataService.login(user)
      .then(response => {
        setToken(response.data.token);
        setUser(user.username);
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('user', user.username);
        setError('');
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch(e => {
        console.log('login', e);
        setError(e.toString());
      });
  };

  const logout = () => {
    setToken('');
    setUser('');
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('currentUser');
    localStorage.clear();
    window.location.reload();
  };

  const signup = (user = null) => {
    TodoDataService.signup(user)
      .then(response => {
        login(user);
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('user', user.username);
      })
      .catch(e => {
        console.log(e);
        setError(e.toString());
      });
  };

  return (
    <Provider store={store}>
      <AuthProvider value={{ token, login, logout }}>
        <Router>
          <ScrollToTop /> {/* Agregar el componente ScrollToTop */}
          <Sidebar isSidebar={isSidebar} />
          <Navbar user={user} token={token} logout={logout} setIsSidebar={setIsSidebar} />
          <Routes>
            <Route path="*" element={<Error404 />} />
            <Route
              path="/"
              element={<><Products /></>} />
            <Route path="/abcupon" element={<><Home /></>} />
            <Route path="/technology" element={<> <Technology /> </>} />
            <Route path="/servicios" element={<><Products  /></>} />
            <Route path="/servicios/:subproductId" element={<SubproductDetails />} />
            
            <Route path="/services" element={<> <Services /> </>} />
            <Route path="/news" element={<> <News /> </>} />
            <Route path="/blog" element={<><Blog /> </>} />
            <Route path="/contacto" element={<> <Contactenos /> </>} />
            <Route path="/calendar" element={<Calendar />} />
            <Route path="/login" element={<Login login={login} />} />
            <Route path="/signup" element={<Signup signup={signup} />} />
            <Route path="/current_user" element={<UserList1 token={token} user={user} />} />
            <Route path="/register" element={<Register signup={signup} />} />
            {/* <Route path="/dashboard" element={<Dashboard />} /> */}
            {/* <Route path="/team" element={<Team />} /> */}
            <Route path="/customer" element={<> <Contacts token={token} user={user} /></>} />
            {/* <Route path="/faq" element={<FAQ />} /> */}
            {/* <Route path="/manage_product" element={<ManageProduct />} /> */}
            <Route path="/manage_blog" element={<ManageBlog token={token} user={user} />} />
            <Route path="/bulk_mails" element={<BulkMails token={token} user={user} />} />
            <Route exact path="/reset_password" element={<ResetPassword />} />
            <Route exact path="/password/reset/confirm/:uid/:token" element={<ResetPasswordConfirm />} />
            {/* <Route exact path="/resetpassworduser" element={<ResetPasswordUser />} /> */}
            <Route exact path="/reset_password_user/:reset_token" element={<ResetPasswordUser />} />
            <Route path="/precios" element={ <Precios /> } />
            <Route path="/files" element={<Files />} />
            <Route path="/files_frontend" element={<Files_frontend />} />
            <Route path="/files_home" element={<Files_home />} />
            <Route path="/files_technology" element={<Files_technology />} />
            <Route path="/files_products" element={<Files_products />} />
            <Route path="/files_services" element={<Files_services />} />
            <Route path="/files_news" element={<Files_news />} />
            <Route path="/files_contact" element={<Files_contact />} />
            <Route path="/files_application" element={<Files_aplication />} />
            <Route path="/files_ecommerce" element={<Files_ecommerce />} />
            
            <Route path="/user" element={<UserList />} />

            {/* Rutas de servicios */}
            <Route path="/bolsadeempleo" element={<Bolsaempleo />} />
            <Route path="/bolsadeempleo/:id" element={<Bolsaempleo />} />
            <Route path="/casasylotes" element={<Casasylotes />} />
            <Route path="/centroseducativos" element={<Centroseducativos />} />
            <Route path="/clinicassaludyestetica" element={<Clinicassaludyestetica />} />
            <Route path="/comunicaciontecnologiayenergia" element={<Comunicaciontecnologiayenergia />} />
            <Route path="/construcciondisenoysupervicion" element={<Construcciondisenoysupervicion />} />
            <Route path="/cuponesdedescuento" element={<Cuponesdedescuento />} />
            <Route path="/entretenimientorestaurantesyturismo" element={<Entretenimientorestaurantesyturismo />} />
            <Route path="/ferreteriaydeposito" element={<Ferreteriaydeposito />} />
            <Route path="/fundacioneslabones" element={<Fundacioneslabones />} />
            <Route path="/hogartiendayelectronica" element={<Hogartiendayelectronica />} />
            <Route path="/legalynotariado" element={<Legalynotariado />} />
            <Route path="/libreriayeditoriales" element={<Libreriayeditoriales />} />
            <Route path="/noticiasyavisosclasificados" element={<Noticiasyavisosclasificados />} />
            <Route path="/ofertasysubastas" element={<Ofertasysubastas />} />
            <Route path="/plataformadeinversiones" element={<Plataformadeinversiones />} />
            <Route path="/polizayseguros" element={<Polizayseguros />} />
            <Route path="/prestamosyrescatesobrepropiedades" element={<Prestamosyrescatesobrepropiedades />} />
            <Route path="/productosyservicioscooperativos" element={<Productosyservicioscooperativos />} />
            <Route path="/publicidadypaginasweb" element={<Publicidadypaginasweb />} />
            <Route path="/hotelesturismo" element={<HotelesTurismo />} />
            <Route path="/transporteymensajeria" element={<Transporteymensajeria />} />
            <Route path="/vehiculosrepuestosytalleres" element={<Vehiculosrepuestosytalleres  />} />
            <Route path="/ecommerce" element={<Homescreen />} />
            <Route path="/tienda" element={<Tienda  />} />
            <Route path="/directorio_comercial" element={<Directorio   />} />
            <Route path="/avisos_clasificados" element={<AvisosClasificados   />} />
            <Route path="/avisos_clasificados/:id" element={<AvisosClasificados   />} />
            <Route path="/noticias" element={<Noticias   />} />
            <Route path="/cotizador" element={<Cotizador   />} />
          </Routes>
        </Router>
      </AuthProvider>
    </Provider>
  );
}

export default App;
