import React, { useState, useEffect } from 'react';
import { Container, Table, Form, Button, InputGroup, Modal, FormControl, FormGroup, FormLabel } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import EmployeeDataService from '../../services/employee';
import './TablaDatos.css';

const EmployeesImpuestosRenta = () => {
  const [impuestosRenta, setImpuestosRenta] = useState([]);
  const [selectedImpuestosRenta, setSelectedImpuestosRenta] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [newImpuestoRenta, setNewImpuestoRenta] = useState({
    empleado: '', // Replace with the actual employee ID
    salario_mensual: 0,
    impuesto_renta: 0,
    monto_planilla: 0,
  });

  const token = useSelector(state => state.authentication.token);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const fetchData = async () => {
    try {
      const responseImpuestosRenta = await EmployeeDataService.getAllImpuestos(token);
      setImpuestosRenta(responseImpuestosRenta.data);
    } catch (error) {
      console.error('Error al obtener datos:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [token]);

  const handleDeleteSelected = async () => {
    try {
      await EmployeeDataService.deleteImpuestos(selectedImpuestosRenta, token);
      setSelectedImpuestosRenta([]);
      fetchData();
    } catch (error) {
      console.error('Error al eliminar impuestos de renta:', error);
    }
  };

  const toggleSelectImpuestoRenta = (impuestoRentaId) => {
    if (selectedImpuestosRenta.includes(impuestoRentaId)) {
      setSelectedImpuestosRenta(selectedImpuestosRenta.filter(id => id !== impuestoRentaId));
    } else {
      setSelectedImpuestosRenta([...selectedImpuestosRenta, impuestoRentaId]);
    }
  };

  const handleCreateImpuestoRenta = async () => {
    try {
      console.log('New Impuesto Renta Data:', newImpuestoRenta);
      await EmployeeDataService.createImpuestos(newImpuestoRenta, token);
      toggleModal();
      fetchData();
    } catch (error) {
      console.error('Error al crear impuesto de renta:', error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewImpuestoRenta({ ...newImpuestoRenta, [name]: value });
  };

  return (
    <Container>
      <div className="tabla-datos-container">
        <br/><br/><br/>
        <h2>Impuestos de Renta de Empleados</h2>
        <Button variant="primary" onClick={toggleModal}>Agregar Impuesto de Renta</Button>

        {/* Modal */}
        <Modal show={showModal} onHide={toggleModal} style={{ overflowY: 'auto' }}>
          <Modal.Header closeButton>
            <Modal.Title>Agregar Impuesto de Renta</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormGroup>
              <FormLabel>Empleado</FormLabel>
              <FormControl
                type="text"
                name="empleado"
                value={newImpuestoRenta.empleado}
                onChange={handleInputChange}
              />
            </FormGroup>
            <FormGroup>
              <FormLabel>Salario Mensual</FormLabel>
              <FormControl
                type="number"
                name="salario_mensual"
                value={newImpuestoRenta.salario_mensual}
                onChange={handleInputChange}
              />
            </FormGroup>
            <FormGroup>
              <FormLabel>Impuesto de Renta</FormLabel>
              <FormControl
                type="number"
                name="impuesto_renta"
                value={newImpuestoRenta.impuesto_renta}
                onChange={handleInputChange}
              />
            </FormGroup>
            <FormGroup>
              <FormLabel>Monto en Planilla</FormLabel>
              <FormControl
                type="number"
                name="monto_planilla"
                value={newImpuestoRenta.monto_planilla}
                onChange={handleInputChange}
              />
            </FormGroup>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={toggleModal}>
              Cancelar
            </Button>
            <Button variant="primary" onClick={handleCreateImpuestoRenta}>
              Guardar
            </Button>
          </Modal.Footer>
        </Modal>

        <div style={{ overflowX: 'auto' }}>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Check</th>
                <th>ID</th>
                <th>Empleado</th>
                <th>Salario Mensual</th>
                <th>Impuesto de Renta</th>
                <th>Monto en Planilla</th>
                {/* ... Agregar las demás columnas según tus requerimientos ... */}
              </tr>
            </thead>
            <tbody>
              {impuestosRenta.map(impuestoRenta => (
                <tr key={impuestoRenta.id}>
                  <td>
                    <Form.Check
                      type="checkbox"
                      checked={selectedImpuestosRenta.includes(impuestoRenta.id)}
                      onChange={() => toggleSelectImpuestoRenta(impuestoRenta.id)}
                    />
                  </td>
                  <td>{impuestoRenta.id}</td>
                  <td>{impuestoRenta.empleado}</td>
                  <td>{impuestoRenta.salario_mensual}</td>
                  <td>{impuestoRenta.impuesto_renta}</td>
                  <td>{impuestoRenta.monto_planilla}</td>
                  {/* ... Agregar las demás columnas según tus requerimientos ... */}
                </tr>
              ))}
            </tbody>
          </Table>
        </div>

        {selectedImpuestosRenta.length > 0 && (
          <InputGroup className="mb-3">
            <Button variant="danger" onClick={handleDeleteSelected}>
              Eliminar seleccionado
            </Button>
          </InputGroup>
        )}
      </div>
    </Container>
  );
};

export default EmployeesImpuestosRenta;
