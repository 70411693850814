import React from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

function ModalProducto(props) {
    const {
        showModal,
        handleCloseModal,
        newProductData,
        setNewProductData,
        handleImageChange,
        handleCreateOrEditProduct,
        handleCategoryChange,
        handleSubcategoryChange
    } = props;

    return (
        <Modal show={showModal} onHide={handleCloseModal} style={{ overflowX: 'auto', overflowY: 'auto' }}>
            <Modal.Header closeButton>
                <Modal.Title>Crear Publicidad y Paginas Web</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <button

                    style={{
                        background: "red",
                        color: "white",
                        padding: "8px",
                        borderRadius: "5px",
                        border: "none",
                        cursor: "pointer",
                        position: "absolute",
                        top: "4px",
                        right: "4px",
                    }}
                    onClick={handleCloseModal}
                >
                    X
                </button>
                <Form>
                    {/* Agregar aquí los campos del formulario */}
                    {/* Ejemplo para el campo 'Nombre' */}
                    <Form.Group controlId="formName">
                        <Form.Label>Nombre</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Ingrese el nombre"
                            value={newProductData.name}
                            onChange={(e) =>
                                setNewProductData({ ...newProductData, name: e.target.value })
                            }
                        />
                    </Form.Group>


                    <Form.Group controlId="formBrand">
                        <Form.Label>Marca</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Ingrese la marca"
                            value={newProductData.brand}
                            onChange={(e) => setNewProductData({ ...newProductData, brand: e.target.value })}
                        />
                    </Form.Group>

                    <Form.Group controlId="formCategory">
                        <Form.Label>Categoría</Form.Label>
                        <Form.Select
                            value={newProductData.category}
                            onChange={handleCategoryChange}
                        >
                            <option value="">Seleccione una categoría</option>
                            <optgroup >
                                <option value="Diseño Gráfico">Diseño Gráfico</option>
                                <option value="Desarrollo Web">Desarrollo Web</option>
                                <option value="Publicidad en Línea">Publicidad en Línea</option>
                                <option value="Marketing y Estrategia">Marketing y Estrategia</option>

                            </optgroup>

                        </Form.Select>


                    </Form.Group>
                    <Form.Group controlId="formSubcategory">
                        <Form.Label>Subcategoría</Form.Label>
                        <Form.Select
                            multiple  // Habilita la selección múltiple
                            value={newProductData.subcategory}
                            onChange={handleSubcategoryChange}
                            style={{ height: '50vh' }}
                        >
                            <option value="">Seleccione una Subcategoría</option>
                            <optgroup label="Diseño Gráfico">
                                <option value="Logotipos y Branding">Logotipos y Branding</option>
                                <option value="Diseño de Folletos">Diseño de Folletos</option>
                                <option value="Diseño de Tarjetas de Visita">Diseño de Tarjetas de Visita</option>
                                <option value="Diseño de Flyers y Posters">Diseño de Flyers y Posters</option>
                                <option value="Diseño de Publicidad Impresa">Diseño de Publicidad Impresa</option>
                            </optgroup>

                            <optgroup label="Desarrollo Web">
                                <option value="Diseño y Desarrollo de Páginas Web">Diseño y Desarrollo de Páginas Web</option>
                                <option value="E-commerce y Tiendas en Línea">E-commerce y Tiendas en Línea</option>
                                <option value="Desarrollo de Aplicaciones Web">Desarrollo de Aplicaciones Web</option>
                                <option value="Mantenimiento de Sitios Web">Mantenimiento de Sitios Web</option>
                                <option value="Optimización de SEO">Optimización de SEO</option>
                            </optgroup>

                            <optgroup label="Publicidad en Línea">
                                <option value="Publicidad en Redes Sociales">Publicidad en Redes Sociales</option>
                                <option value="Publicidad en Google Ads">Publicidad en Google Ads</option>
                                <option value="Marketing por Correo Electrónico">Marketing por Correo Electrónico</option>
                                <option value="Publicidad en Display">Publicidad en Display</option>
                                <option value="Publicidad en Video">Publicidad en Video</option>
                            </optgroup>

                            <optgroup label="Marketing y Estrategia">
                                <option value="Estrategias de Marketing Digital">Estrategias de Marketing Digital</option>
                                <option value="Análisis de Datos y Métricas">Análisis de Datos y Métricas</option>
                                <option value="Consultoría de Marketing">Consultoría de Marketing</option>
                            </optgroup>

                        </Form.Select>
                    </Form.Group>




                    <Form.Group controlId="formDescription">
                        <Form.Label>Descripción</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            placeholder="Ingrese la descripción"
                            value={newProductData.description}
                            onChange={(e) => setNewProductData({ ...newProductData, description: e.target.value })}
                        />
                    </Form.Group>

                    <Form.Group controlId="formPrice">
                        <Form.Label>Precio</Form.Label>
                        <Form.Control
                            type="number"
                            placeholder="Ingrese el precio"
                            value={newProductData.price}
                            onChange={(e) => setNewProductData({ ...newProductData, price: e.target.value })}
                        />
                    </Form.Group>

                    <Form.Group controlId="formDiscount">
                        <Form.Label>Descuento</Form.Label>
                        <Form.Control
                            type="number"
                            placeholder="Ingrese el descuento"
                            value={newProductData.discount}
                            onChange={(e) => setNewProductData({ ...newProductData, discount: e.target.value })}
                        />
                    </Form.Group>

                    <Form.Group controlId="formCountInStock">
                        <Form.Label>Cantidad en Stock</Form.Label>
                        <Form.Control
                            type="number"
                            placeholder="Ingrese la cantidad en stock"
                            value={newProductData.countInStock}
                            onChange={(e) => setNewProductData({ ...newProductData, countInStock: e.target.value })}
                        />
                    </Form.Group>

                    <Form.Group controlId="formImage">
                        <Form.Label>Imagen</Form.Label>
                        <Form.Control type="file" onChange={handleImageChange} />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseModal}>
                    Cerrar
                </Button>
                <Button variant="primary" onClick={handleCreateOrEditProduct}>
                    Guardar
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default ModalProducto;
