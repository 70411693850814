import React, { useState, useEffect } from 'react';
import { Container, Table, Form, Button, InputGroup, Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import EmployeeDataService from '../../services/employee';
import './TablaDatos.css';

const EmployeesIncapacidades = () => {
  const [incapacidades, setIncapacidades] = useState([]);
  const [selectedIncapacidades, setSelectedIncapacidades] = useState([]);
  const token = useSelector(state => state.authentication.token);
  const [showModal, setShowModal] = useState(false);
  const [newIncapacidad, setNewIncapacidad] = useState({
    empleado: "",  // Initialize with an empty string or default employee ID
    fecha_inicio: "",
    fecha_fin: "",
    dias_incapacidad: 0,
    salario_diario: 0,
    porcentaje_pago: 0,
    monto_pago: 0,
  });
  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const fetchData = async () => {
    try {
      const responseIncapacidades = await EmployeeDataService.getAllIncapacidades(token);
      setIncapacidades(responseIncapacidades.data);
    } catch (error) {
      console.error('Error al obtener datos:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [token]);

  const handleDeleteSelected = async () => {
    try {
      await EmployeeDataService.deleteIncapacidad(selectedIncapacidades, token);
      setSelectedIncapacidades([]);
      fetchData();
    } catch (error) {
      console.error('Error al eliminar incapacidades:', error);
    }
  };

  const toggleSelectIncapacidad = (incapacidadId) => {
    if (selectedIncapacidades.includes(incapacidadId)) {
      setSelectedIncapacidades(selectedIncapacidades.filter(id => id !== incapacidadId));
    } else {
      setSelectedIncapacidades([...selectedIncapacidades, incapacidadId]);
    }
  };
  const handleCreateIncapacidad = async () => {
    try {
      await EmployeeDataService.createIncapacidad(newIncapacidad, token);
      toggleModal();
      setNewIncapacidad({
        empleado: "",
        fecha_inicio: "",
        fecha_fin: "",
        dias_incapacidad: 0,
        salario_diario: 0,
        porcentaje_pago: 0,
        monto_pago: 0,
      });
      fetchData();
    } catch (error) {
      console.error('Error al crear incapacidad:', error);
    }
  };

  return (
    <Container>
      <div className="tabla-datos-container">
      <br/><br/><br/>
      <h2>Incapacidades de Empleados</h2>
      <Button variant="primary" onClick={toggleModal}>Agregar Incapacidad</Button>

       {/* Modal */}
       <Modal show={showModal} onHide={toggleModal} style={{ overflowY: 'auto' }}>
          <Modal.Header closeButton>
            <Modal.Title>Agregar Incapacidad</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="formEmpleado">
                <Form.Label>Empleado</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Ingrese el ID del empleado"
                  value={newIncapacidad.empleado}
                  onChange={(e) => setNewIncapacidad({ ...newIncapacidad, empleado: e.target.value })}
                />
              </Form.Group>
              <Form.Group controlId="formFechaInicio">
                <Form.Label>Fecha Inicio</Form.Label>
                <Form.Control
                  type="date"
                  value={newIncapacidad.fecha_inicio}
                  onChange={(e) => setNewIncapacidad({ ...newIncapacidad, fecha_inicio: e.target.value })}
                />
              </Form.Group>
              <Form.Group controlId="formFechaInicio">
                <Form.Label>fecha_fin</Form.Label>
                <Form.Control
                  type="date"
                  value={newIncapacidad.fecha_fin}
                  onChange={(e) => setNewIncapacidad({ ...newIncapacidad, fecha_fin: e.target.value })}
                />
              </Form.Group>
              {/* ... (similar form controls for other fields) */}
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={toggleModal}>
              Cerrar
            </Button>
            <Button variant="primary" onClick={handleCreateIncapacidad}>
              Guardar Incapacidad
            </Button>
          </Modal.Footer>
        </Modal>

      <div style={{ overflowX: 'auto' }}>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Check</th>
              <th>ID</th>
              <th>Empleado</th>
              <th>Fecha Inicio</th>
              <th>Fecha Fin</th>
              <th>Días de Incapacidad</th>
              <th>Salario Diario</th>
              <th>Porcentaje de Pago</th>
              <th>Monto de Pago</th>
              {/* ... Agregar las demás columnas según tus requerimientos ... */}
            </tr>
          </thead>
          <tbody>
            {incapacidades.map(incapacidad => (
              <tr key={incapacidad.id}>
                <td>
                  <Form.Check
                    type="checkbox"
                    checked={selectedIncapacidades.includes(incapacidad.id)}
                    onChange={() => toggleSelectIncapacidad(incapacidad.id)}
                  />
                </td>
                <td>{incapacidad.id}</td>
                <td>{incapacidad.empleado}</td>
                <td>{incapacidad.fecha_inicio}</td>
                <td>{incapacidad.fecha_fin}</td>
                <td>{incapacidad.dias_incapacidad}</td>
                <td>{incapacidad.salario_diario}</td>
                <td>{incapacidad.porcentaje_pago}</td>
                <td>{incapacidad.monto_pago}</td>
                {/* ... Agregar las demás columnas según tus requerimientos ... */}
              </tr>
            ))}
          </tbody>
        </Table>
      </div>

      {selectedIncapacidades.length > 0 && (
        <InputGroup className="mb-3">
          <Button variant="danger" onClick={handleDeleteSelected}>
            Eliminar seleccionado
          </Button>
        </InputGroup>
      )}
      </div>
    </Container>
  );
};

export default EmployeesIncapacidades;
