import { Table, Button, Modal, Form, Toast } from 'react-bootstrap';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import TodoDataService from '../../services/todos';
import './ContactsInfo.css';

const ContactsInfo = () => {
  const [leads, setLeads] = useState([]);
  const [selectedLead, setSelectedLead] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [editedLead, setEditedLead] = useState({
    // Campos originales
    description: '',
    email: '',
    name: '',
    number: '',
    priority: '',
    status: '',

    // Campos adicionales, ordenados alfabéticamente
    avoidable_graphic_elements: '',
    brand_category: '',
    brand_description: '',
    brand_differentiation: '',
    brand_necessity: '',
    brand_perception_keywords: '',
    brand_personality: '',
    brand_slogan_or_motto: '',
    brand_style_preference: '',
    brand_values: '',
    brand_virtues: '',
    business_experience_duration: '',
    business_type: '',
    colors: '',
    commercial_information_details: '',
    company_address: '',
    company_email: '',
    company_logo: null, // Puede ser null o un objeto según cómo manejes las imágenes
    company_name: '',
    company_website_or_social_media: '',
    contact_person_name: '',
    contact_person_phone: '',
    contact_person_position: '',
    contact_reason: '',
    current_business_goals: '',
    facebook_comment: '',
    facebook_link: '',
    fan_page_category: '',
    fan_page_comments: '',
    fan_page_information_details: '',
    industry_leaders: '',
    logo_and_visual_identity_communication: '',
    logo_applications: '',
    main_competitors: '',
    market_positioning_strategy: '',
    opening_hours_location_maps: '',
    payment_information: '',
    payment_method: '',
    preferred_brands_references: '',
    preferred_color_palette: '',
    primary_logo_usage: '',
    products_or_services: '',
    project_objective: '',
    purchase_behavior: '',
    symbol_or_icon_preference: '',
    target_age_range: '',
    target_gender: '',
    target_interests: '',
    target_lifecycle_stage: '',
    target_socioeconomic_level: '',
    unique_value_proposition: '',
    visual_identity_communication: '',
    visual_identity_goals: '',
    wsb_phone_number: '',
    wsb_predefined_message: '',
    whatsapp_details: '',
  });
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [newLead, setNewLead] = useState({
    name: '',
    email: '',
    description: '',
    priority: '',
    status: '',
    number: '',
    
    
  });
  const [errorToast, setErrorToast] = useState(null);
  const [showCommentsModal, setShowCommentsModal] = useState(false);
  const [selectedLeadComments, setSelectedLeadComments] = useState([]);
  const [selectedLeadId, setSelectedLeadId] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [leadsPerPage, setLeadsPerPage] = useState(4);
  const [currentPage, setCurrentPage] = useState(1);
  const [showDeleteToast, setShowDeleteToast] = useState(false);
  const [deleteToastMessage, setDeleteToastMessage] = useState('');
  const token = useSelector(state => state.authentication.token);
  const user = useSelector(state => state.authentication.user);
  const [currentUser, setCurrentUser] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [isEditModal, setIsEditModal] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);

  useEffect(() => {
    const currentUserData = localStorage.getItem('currentUser');
    if (currentUserData) {
      try {
        const parsedData = JSON.parse(currentUserData);
        setCurrentUser(parsedData);
      } catch (error) {
        console.error('Error parsing currentUser data:', error);
      }
    }
  }, []);

  useEffect(() => {
    const storedUsername = localStorage.getItem('user');
    if (storedUsername) {
      loadLeads();
    }
  }, []);

  useEffect(() => {
    setCurrentPage(1);
  }, [leadsPerPage]);

  const loadLeads = () => {
    TodoDataService.getAllLeads(localStorage.getItem('token'))
      .then(response => {
        const updatedLeads = response.data.map(lead => {
          const lastComment = lead && lead.comments && lead.comments.length > 0 ? lead.comments[lead.comments.length - 1] : null;


          return {
            ...lead,
            lastComment,
          };
        });

        setLeads(updatedLeads);
      })
      .catch(error => {
        console.error(error);
      });
  };

  const handleDelete = (id) => {
    TodoDataService.deleteLead(id, localStorage.getItem('token'))
      .then(response => {
        loadLeads();
      })
      .catch(error => {
        console.error(error);
      });
      // Configurar el mensaje de Toast y mostrar el Toast
    setDeleteToastMessage('Lead eliminado exitosamente.');
    setShowDeleteToast(false);

    // Restablecer otros estados según sea necesario
    setSelectedLead(null);
  };

  const handleEdit = (lead) => {
    setSelectedLead(lead);
    setShowModal(true);
    setEditedLead({
      name: lead.name,
      email: lead.email,
      description: lead.description,
      priority: lead.priority,
      status: lead.status,
      number: lead.number,

      // Campos adicionales, ordenados alfabéticamente
      avoidable_graphic_elements: lead.avoidable_graphic_elements || '',
      brand_category: lead.brand_category || '',
      brand_description: lead.brand_description || '',
      brand_differentiation: lead.brand_differentiation || '',
      brand_necessity: lead.brand_necessity || '',
      brand_perception_keywords: lead.brand_perception_keywords || '',
      brand_personality: lead.brand_personality || '',
      brand_slogan_or_motto: lead.brand_slogan_or_motto || '',
      brand_style_preference: lead.brand_style_preference || '',
      brand_values: lead.brand_values || '',
      brand_virtues: lead.brand_virtues || '',
      business_experience_duration: lead.business_experience_duration || '',
      business_type: lead.business_type || '',
      colors: lead.colors || '',
      commercial_information_details: lead.commercial_information_details || '',
      company_address: lead.company_address || '',
      company_email: lead.company_email || '',
      company_logo: lead.company_logo || null, // Puede ser null o un objeto según cómo manejes las imágenes
      company_name: lead.company_name || '',
      company_website_or_social_media: lead.company_website_or_social_media || '',
      contact_person_name: lead.contact_person_name || '',
      contact_person_phone: lead.contact_person_phone || '',
      contact_person_position: lead.contact_person_position || '',
      contact_reason: lead.contact_reason || '',
      current_business_goals: lead.current_business_goals || '',
      facebook_comment: lead.facebook_comment || '',
      facebook_link: lead.facebook_link || '',
      fan_page_category: lead.fan_page_category || '',
      fan_page_comments: lead.fan_page_comments || '',
      fan_page_information_details: lead.fan_page_information_details || '',
      industry_leaders: lead.industry_leaders || '',
      logo_and_visual_identity_communication: lead.logo_and_visual_identity_communication || '',
      logo_applications: lead.logo_applications || '',
      main_competitors: lead.main_competitors || '',
      market_positioning_strategy: lead.market_positioning_strategy || '',
      opening_hours_location_maps: lead.opening_hours_location_maps || '',
      payment_information: lead.payment_information || '',
      payment_method: lead.payment_method || '',
      preferred_brands_references: lead.preferred_brands_references || '',
      preferred_color_palette: lead.preferred_color_palette || '',
      primary_logo_usage: lead.primary_logo_usage || '',
      products_or_services: lead.products_or_services || '',
      project_objective: lead.project_objective || '',
      purchase_behavior: lead.purchase_behavior || '',
      symbol_or_icon_preference: lead.symbol_or_icon_preference || '',
      target_age_range: lead.target_age_range || '',
      target_gender: lead.target_gender || '',
      target_interests: lead.target_interests || '',
      target_lifecycle_stage: lead.target_lifecycle_stage || '',
      target_socioeconomic_level: lead.target_socioeconomic_level || '',
      unique_value_proposition: lead.unique_value_proposition || '',
      visual_identity_communication: lead.visual_identity_communication || '',
      visual_identity_goals: lead.visual_identity_goals || '',
      wsb_phone_number: lead.wsb_phone_number || '',
      wsb_predefined_message: lead.wsb_predefined_message || '',
      whatsapp_details: lead.whatsapp_details || '',
      comment: '', // Puedes inicializarlo con un valor predeterminado si es necesario
    });
    setIsEditMode(true); // Activa el modo de edición
  };


  const handleSave = () => {
    const updatedLead = {
      ...selectedLead,
      name: editedLead.name,
      email: editedLead.email,
      description: editedLead.description,
      priority: editedLead.priority,
      status: editedLead.status,
      number: editedLead.number,

      // Campos adicionales, ordenados alfabéticamente
      avoidable_graphic_elements: editedLead.avoidable_graphic_elements || '',
      brand_category: editedLead.brand_category || '',
      brand_description: editedLead.brand_description || '',
      brand_differentiation: editedLead.brand_differentiation || '',
      brand_necessity: editedLead.brand_necessity || '',
      brand_perception_keywords: editedLead.brand_perception_keywords || '',
      brand_personality: editedLead.brand_personality || '',
      brand_slogan_or_motto: editedLead.brand_slogan_or_motto || '',
      brand_style_preference: editedLead.brand_style_preference || '',
      brand_values: editedLead.brand_values || '',
      brand_virtues: editedLead.brand_virtues || '',
      business_experience_duration: editedLead.business_experience_duration || '',
      business_type: editedLead.business_type || '',
      colors: editedLead.colors || '',
      commercial_information_details: editedLead.commercial_information_details || '',
      company_address: editedLead.company_address || '',
      company_email: editedLead.company_email || '',
      company_logo: editedLead.company_logo || null, // Puede ser null o un objeto según cómo manejes las imágenes
      company_name: editedLead.company_name || '',
      company_website_or_social_media: editedLead.company_website_or_social_media || '',
      contact_person_name: editedLead.contact_person_name || '',
      contact_person_phone: editedLead.contact_person_phone || '',
      contact_person_position: editedLead.contact_person_position || '',
      contact_reason: editedLead.contact_reason || '',
      current_business_goals: editedLead.current_business_goals || '',
      facebook_comment: editedLead.facebook_comment || '',
      facebook_link: editedLead.facebook_link || '',
      fan_page_category: editedLead.fan_page_category || '',
      fan_page_comments: editedLead.fan_page_comments || '',
      fan_page_information_details: editedLead.fan_page_information_details || '',
      industry_leaders: editedLead.industry_leaders || '',
      logo_and_visual_identity_communication: editedLead.logo_and_visual_identity_communication || '',
      logo_applications: editedLead.logo_applications || '',
      main_competitors: editedLead.main_competitors || '',
      market_positioning_strategy: editedLead.market_positioning_strategy || '',
      opening_hours_location_maps: editedLead.opening_hours_location_maps || '',
      payment_information: editedLead.payment_information || '',
      payment_method: editedLead.payment_method || '',
      preferred_brands_references: editedLead.preferred_brands_references || '',
      preferred_color_palette: editedLead.preferred_color_palette || '',
      primary_logo_usage: editedLead.primary_logo_usage || '',
      products_or_services: editedLead.products_or_services || '',
      project_objective: editedLead.project_objective || '',
      purchase_behavior: editedLead.purchase_behavior || '',
      symbol_or_icon_preference: editedLead.symbol_or_icon_preference || '',
      target_age_range: editedLead.target_age_range || '',
      target_gender: editedLead.target_gender || '',
      target_interests: editedLead.target_interests || '',
      target_lifecycle_stage: editedLead.target_lifecycle_stage || '',
      target_socioeconomic_level: editedLead.target_socioeconomic_level || '',
      unique_value_proposition: editedLead.unique_value_proposition || '',
      visual_identity_communication: editedLead.visual_identity_communication || '',
      visual_identity_goals: editedLead.visual_identity_goals || '',
      wsb_phone_number: editedLead.wsb_phone_number || '',
      wsb_predefined_message: editedLead.wsb_predefined_message || '',
      whatsapp_details: editedLead.whatsapp_details || '',
    };

    TodoDataService.updateLead(selectedLead.id, updatedLead, localStorage.getItem('token'))
      .then(response => {
        if (editedLead.comment) {
          const commentData = {
            lead: selectedLead.id,
            comment: editedLead.comment,
          };
          return TodoDataService.createCommentlead(commentData, localStorage.getItem('token'));
        } else {
          return Promise.resolve();
        }
      })
      .then(() => {
        setShowModal(false);
        loadLeads();
      })
      .catch(error => {
        console.error(error);
        showErrorToast('Se produjo un error al guardar los cambios.');
      });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setEditedLead((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedLead(null);
    setEditedLead({
      name: '',
      email: '',
      description: '',
      priority: '',
      status: '',
      number: '',
      comment: '',
    });
  };

  const handleCreateLead = () => {
    setShowCreateModal(true);
  };

  const handleNewLeadInputChange = (event) => {
    const { name, value } = event.target;
    setNewLead(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSaveNewLead = () => {
    if (!newLead.name || !newLead.email || !newLead.description || !newLead.priority || !newLead.status || !newLead.number) {
      showErrorToast('Todos los campos son obligatorios.');
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(newLead.email)) {
      showErrorToast('Formato de correo inválido.');
      return;
    }

    const leadData = {
      ...newLead,
      number: newLead.number,
    };
console.log(leadData)
    TodoDataService.createLead(leadData, localStorage.getItem('token'))
      .then(response => {
        setShowCreateModal(false);
        setNewLead({
          // Campos originales
    name: '',
    email: '',
    description: '',
    number: '',
    priority: '',
    status: '',
    // Campos adicionales, ordenados alfabéticamente
    avoidable_graphic_elements: '',
    brand_category: '',
    brand_description: '',
    brand_differentiation: '',
    brand_necessity: '',
    brand_perception_keywords: '',
    brand_personality: '',
    brand_slogan_or_motto: '',
    brand_style_preference: '',
    brand_values: '',
    brand_virtues: '',
    business_experience_duration: '',
    business_type: '',
    colors: '',
    commercial_information_details: '',
    company_address: '',
    company_email: '',
    company_logo: null,
    company_name: '',
    company_website_or_social_media: '',
    contact_person_name: '',
    contact_person_phone: '',
    contact_person_position: '',
    contact_reason: '',
    current_business_goals: '',
    facebook_comment: '',
    facebook_link: '',
    fan_page_category: '',
    fan_page_comments: '',
    fan_page_information_details: '',
    industry_leaders: '',
    logo_and_visual_identity_communication: '',
    logo_applications: '',
    main_competitors: '',
    market_positioning_strategy: '',
    opening_hours_location_maps: '',
    payment_information: '',
    payment_method: '',
    preferred_brands_references: '',
    preferred_color_palette: '',
    primary_logo_usage: '',
    products_or_services: '',
    project_objective: '',
    purchase_behavior: '',
    symbol_or_icon_preference: '',
    target_age_range: '',
    target_gender: '',
    target_interests: '',
    target_lifecycle_stage: '',
    target_socioeconomic_level: '',
    unique_value_proposition: '',
    visual_identity_communication: '',
    visual_identity_goals: '',
    wsb_phone_number: '',
    wsb_predefined_message: '',
    whatsapp_details: '',

        });
        loadLeads();
      })
      .catch(error => {
        console.error(error);
        const errorMessage = error.response?.data?.error || 'An error occurred';
        showErrorToast(errorMessage);
      });
  };


  const handleCloseCreateModal = () => {
    setShowCreateModal(false);
    setNewLead({
      name: '',
      email: '',
      description: '',
      priority: '',
      status: '',
      number: '',

      // Agrega aquí los campos adicionales con sus valores predeterminados
      avoidable_graphic_elements: '',
      brand_category: '',
      brand_description: '',
      brand_differentiation: '',
      brand_necessity: '',
      brand_perception_keywords: '',
      brand_personality: '',
      brand_slogan_or_motto: '',
      brand_style_preference: '',
      brand_values: '',
      brand_virtues: '',
      business_experience_duration: '',
      business_type: '',
      colors: '',
      commercial_information_details: '',
      company_address: '',
      company_email: '',
      company_logo: null, // Puede ser null o un objeto según cómo manejes las imágenes
      company_name: '',
      company_website_or_social_media: '',
      contact_person_name: '',
      contact_person_phone: '',
      contact_person_position: '',
      contact_reason: '',
      current_business_goals: '',
      facebook_comment: '',
      facebook_link: '',
      fan_page_category: '',
      fan_page_comments: '',
      fan_page_information_details: '',
      industry_leaders: '',
      logo_and_visual_identity_communication: '',
      logo_applications: '',
      main_competitors: '',
      market_positioning_strategy: '',
      opening_hours_location_maps: '',
      payment_information: '',
      payment_method: '',
      preferred_brands_references: '',
      preferred_color_palette: '',
      primary_logo_usage: '',
      products_or_services: '',
      project_objective: '',
      purchase_behavior: '',
      symbol_or_icon_preference: '',
      target_age_range: '',
      target_gender: '',
      target_interests: '',
      target_lifecycle_stage: '',
      target_socioeconomic_level: '',
      unique_value_proposition: '',
      visual_identity_communication: '',
      visual_identity_goals: '',
      wsb_phone_number: '',
      wsb_predefined_message: '',
      whatsapp_details: '',
    });
  };


  const showErrorToast = (message) => {
    setErrorToast(message);
    setTimeout(() => {
      setErrorToast(null);
    }, 5000);
  };

  const handleDownloadCSV = () => {
    // Obtén las claves (nombres de las propiedades) de un lead para usarlas como encabezados CSV
    const leadKeys = Object.keys(leads[0]);

    // Convierte los leads a datos CSV con todas las claves
    const csvData = convertToCSV(leads, leadKeys);

    // Crea un Blob con los datos CSV
    const blob = new Blob([csvData], { type: 'text/csv' });

    // Crea una URL del Blob
    const url = window.URL.createObjectURL(blob);

    // Crea un elemento <a> para descargar el archivo CSV
    const a = document.createElement('a');
    a.href = url;
    a.download = 'clientes.csv';

    // Simula un clic en el enlace para iniciar la descarga
    a.click();

    // Libera la URL del Blob
    window.URL.revokeObjectURL(url);
  };


  const convertToCSV = (data, fields) => {
    const columns = fields;
    const header = columns.join(';');
    const rows = data.map((lead) => columns.map((column) => lead[column]).join(';'));
    return header + '\n' + rows.join('\n');
  };

  const handleLastCommentClick = (lead) => {
    TodoDataService.getLeadComments(lead.id, localStorage.getItem('token'))
      .then(response => {
        setSelectedLeadComments(response.data);
        setSelectedLeadId(lead.id);
        setShowCommentsModal(true);
      })
      .catch(error => {
        console.error(error);
      });
  };

  const handleDeleteComment = (commentId) => {
    if (!selectedLeadId) {
      console.error('No lead selected.');
      return;
    }

    TodoDataService.deleteCommentContact(selectedLeadId, commentId, localStorage.getItem('token'))
      .then(response => {
        setSelectedLeadComments(comments => comments.filter(comment => comment.id !== commentId));
      })
      .catch(error => {
        console.error(error);
        showErrorToast('Se produjo un error al eliminar el comentario..');
      });
  };

  const handleDeleteConfirmation = (lead) => {
    setSelectedLead(lead);
    setDeleteToastMessage(`¿Estás segura de que quieres eliminar? ${lead.name}?`);
    setShowDeleteToast(true);
  };

  const handleDeleteConfirmationClose = () => {
    setShowDeleteToast(false);
  };

  const filteredLeads = leads.filter((lead) =>
    lead.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    lead.email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const indexOfLastLead = currentPage * leadsPerPage;
  const indexOfFirstLead = indexOfLastLead - leadsPerPage;
  const currentLeads = filteredLeads.slice(indexOfFirstLead, indexOfLastLead);

  const totalPages = Math.ceil(filteredLeads.length / leadsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleLeadsPerPageChange = (event) => {
    const value = parseInt(event.target.value, 10);
    setLeadsPerPage(value);
  };

  const [columnSort, setColumnSort] = useState({
    column: '',
    direction: 'asc', // 'asc' or 'desc'
  });

  const handleSort = (columnName) => {
    setColumnSort((prevSort) => {
      if (prevSort.column === columnName) {
        // Cambiar la dirección si ya está ordenada por esa columna
        return {
          ...prevSort,
          direction: prevSort.direction === 'asc' ? 'desc' : 'asc',
        };
      } else {
        // Ordenar por una nueva columna en orden ascendente
        return {
          column: columnName,
          direction: 'asc',
        };
      }
    });
  };

  const sortedLeads = [...filteredLeads].sort((a, b) => {
    const columnA = (a[columnSort.column] || '').toLowerCase();
    const columnB = (b[columnSort.column] || '').toLowerCase();

    if (columnSort.direction === 'asc') {
      return columnA.localeCompare(columnB);
    } else {
      return columnB.localeCompare(columnA);
    }
  });



  return (
    <div style={{ width: '100%' }}>
      <br /><br /><br /><br />
      <h1>Clientes</h1>
     
       
      

      <div
        className="header-buttons"
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '50%',
          marginLeft: '50%',
          marginTop: '-3%'
        }}
      >
        {currentUser && currentUser.staff_status === 'administrator' && (
        <>
        <Button variant="primary" onClick={handleCreateLead} style={{
          color: 'blue',
          backgroundColor: 'white',
          border: '2px solid black',
          boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
          transition: 'background-color 0.3s, border-color 0.3s',
        }}>Nuevo cliente</Button>
        <Button variant="primary" onClick={handleDownloadCSV} style={{
          color: 'blue',
          backgroundColor: 'white',
          border: '2px solid black',
          boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
          transition: 'background-color 0.3s, border-color 0.3s',
        }}>Descargar</Button>
         </>
      )}
      {currentUser && currentUser.staff_status === 'sales' && (
        <>
        <Button variant="primary" onClick={handleCreateLead} style={{
          color: 'blue',
          backgroundColor: 'white',
          border: '2px solid black',
          boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
          transition: 'background-color 0.3s, border-color 0.3s',
        }}>Nuevo cliente</Button>
         </>
      )}
      </div>
      
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <Form.Group controlId="searchTerm" style={{ marginBottom: '10px' }}>
          <Form.Control
            type="text"
            placeholder="Buscar por nombre o correo electrónico"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            style={{
              borderRadius: '5px',
              boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
              transition: 'box-shadow 0.3s',
              outline: 'none',
              padding: '6px',
              width: '30%',
            }}
            onFocus={(e) => {
              e.target.style.boxShadow = '0 0 6px rgba(0, 0, 255, 0.5)';
            }}
            onBlur={(e) => {
              e.target.style.boxShadow = '2px 2px 4px rgba(0, 0, 0, 0.5)';
            }}
          />
        </Form.Group>
        <Form.Group controlId="leadsPerPage" style={{ marginBottom: '10px' }}>
          <Form.Label>Clientes por pagina:</Form.Label>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Form.Control
              as="input"
              type="number"
              min="1"
              value={leadsPerPage}
              onChange={handleLeadsPerPageChange}
              style={{
                borderRadius: '5px',
                boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
                transition: 'box-shadow 0.3s',
                outline: 'none',
                padding: '6px',
                marginLeft: '5px',
                marginRight: '5px',
                width: '50px',
                textAlign: 'center',
              }}
              onFocus={(e) => {
                e.target.style.boxShadow = '0 0 6px rgba(0, 0, 255, 0.5)';
              }}
              onBlur={(e) => {
                e.target.style.boxShadow = '2px 2px 4px rgba(0, 0, 0, 0.5)';
              }}
            />
          </div>
        </Form.Group>
      </div>
      
      <Table striped bordered hover className="leads-table" style={{ width: '100%' }}>
        <thead>
          <tr>
            <th onClick={() => handleSort('name')}>Nombre del Vendedor</th>
            <th onClick={() => handleSort('name')}>Nombre de la Empresa</th>
            <th onClick={() => handleSort('email')}>Correo electronico</th>
            <th>Numero</th>
            <th>Descripción</th>
            <th onClick={() => handleSort('priority')}>Prioridad</th>
            <th onClick={() => handleSort('status')}>Estado</th>
            <th>Último comentario</th>
            <th>Comportamiento</th>
          </tr>
        </thead>
        <tbody>
          {sortedLeads.map((lead) => (
            <React.Fragment key={lead.id}>
              <tr>

                <td>
                  <a
                    href="#"
                    style={{
                      display: 'inline-block',
                      padding: '6px 12px',
                      fontSize: '14px',
                      fontWeight: '400',
                      lineHeight: '1.42857143',
                      textAlign: 'center',
                      whiteSpace: 'nowrap',
                      verticalAlign: 'middle',
                      msTouchAction: 'manipulation',
                      touchAction: 'manipulation',
                      cursor: 'pointer',
                      userSelect: 'none',
                      backgroundImage: 'none',
                      // border: '1px solid transparent',
                      borderRadius: '4px',
                      textDecoration: 'none',
                      color: '#333',
                      backgroundColor: '#fff',
                      borderColor: '#ccc',
                    }}
                    onClick={() => {
                      handleEdit(lead);
                      setIsEditMode(false); // Desactiva el modo de edición al hacer clic en el nombre
                    }}
                  >
                    {lead.name}
                  </a>
                </td>
                <td>{lead.company_name}</td>

                <td>{lead.email}</td>
                <td>{lead.number}</td>
                <td>{lead.description}</td>
                <td>{lead.priority}</td>
                <td>{lead.status}</td>
                <td>
                  {lead.lastComment && (
                    <Button variant="link" onClick={() => handleLastCommentClick(lead)}>
                      Ver
                    </Button>
                  )}
                </td>
                <td>
                {currentUser && currentUser.staff_status === 'administrator' && (
        <>
                  <Button variant="danger" onClick={() => handleDeleteConfirmation(lead)} className="action-button">Eliminar</Button>
                  <Button variant="primary" onClick={() => handleEdit(lead)} className="action-button">Editar</Button>
                  </>
                 )}
                                 {currentUser && currentUser.staff_status === 'sales' && (
        <>   
                  <Button variant="primary" onClick={() => handleEdit(lead)} className="action-button">Editar</Button>
                  </>
                 )}
                </td>
                
              </tr>
              {selectedLead === lead && selectedLeadComments.length > 0 && (
                <tr>
                  <td colSpan="8" style={{ padding: "0" }}>
                    <Table bordered hover className="comments-table">
                      <thead>
                        <tr>
                          <th>Comentario</th>
                          <th>Usuario</th>
                          <th>Accion</th>
                        </tr>
                      </thead>
                      <tbody>
                        {selectedLeadComments.map(comment => (
                          <tr key={comment.id}>
                            <td>{comment.comment}</td>
                            <td>{comment.user}</td>
                            <td>
                              <Button variant="danger" onClick={() => handleDeleteComment(comment.id)} className="action-button">Eliminar</Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </Table>

      <div>
        {totalPages > 1 && (
          <div className="pagination-container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Button
              variant="primary"
              disabled={currentPage === 1}
              onClick={() => handlePageChange(currentPage - 1)}
              style={{ marginRight: '5px' }}
            >
              Anterior
            </Button>
            <div className="page-info" style={{ margin: '0 5px' }}>
              Pagina {currentPage}
            </div>
            <Button
              variant="primary"
              disabled={currentPage === totalPages}
              onClick={() => handlePageChange(currentPage + 1)}
              style={{ marginLeft: '5px' }}
            >
              Siguiente
            </Button>
          </div>
        )}
      </div>


      {/* Modal de creación (Create New Lead) */}
      <Modal show={showCreateModal} onHide={handleCloseCreateModal} centered className="modal-above-table">
        <Modal.Header closeButton>
          <Modal.Title>Crear nuevo cliente potencial</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <div style={{ maxHeight: 'calc(100vh - 410px)', overflowY: 'auto' }}>
            <Form>
              {/* Datos Generales */}
              <Form.Group controlId="newName">
                <Form.Label>Nombre Vendedor</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  value={newLead.name}
                  onChange={handleNewLeadInputChange}
                />
              </Form.Group>
              <Form.Group controlId="newEmail">
                <Form.Label>Correo Electrónico del Vendedor</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  value={newLead.email}
                  onChange={handleNewLeadInputChange}
                />
              </Form.Group>
              <Form.Group controlId="newNumber">
              <Form.Label>Numero</Form.Label>
              <Form.Control
                type="text"
                name="number"
                value={newLead.number}
                onChange={handleNewLeadInputChange}
              />
            </Form.Group>
              <Form.Group controlId="newDescription">
                <Form.Label>Descripción</Form.Label>
                <Form.Control
                  as="textarea"
                  name="description"
                  value={newLead.description}
                  onChange={handleNewLeadInputChange}
                />
              </Form.Group>
             <Form.Group controlId="newPriority">
              <Form.Label>Prioridad</Form.Label>
              <Form.Control
                as="select"
                name="priority"
                value={newLead.priority}
                onChange={handleNewLeadInputChange}
              >
                <option value="">Seleccionar prioridad</option>
                <option value="bajo">Baja</option>
                <option value="medio">Medio</option>
                <option value="alto">Alta</option>
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="newStatus">
              <Form.Label>Status</Form.Label>
              <Form.Control
                as="select"
                name="status"
                value={newLead.status}
                onChange={handleNewLeadInputChange}
              >
                <option value="">Seleccionar estado</option>
                <option value="nuevo">Nuevo</option>
                <option value="contactado">Contactado</option>
                <option value="ganado">Cliente Ganado</option>
              </Form.Control>
            </Form.Group>
              {/* Sección: Información Comercial */}
              <Form.Group controlId="Newcompany_name">
                <Form.Label>Nombre de la Empresa</Form.Label>
                <Form.Control
                  type="text"
                  name="company_name"
                  value={newLead.company_name}
                  onChange={handleNewLeadInputChange}
                />
              </Form.Group>
              <Form.Group controlId="newCommercial_information_details">
                <Form.Label>Detalles de Información Comercial</Form.Label>
                <Form.Control
                  as="textarea"
                  name="commercial_information_details"
                  value={newLead.commercial_information_details}
                  onChange={handleNewLeadInputChange}
                />
              </Form.Group>

              {/* Sección: Información de la Empresa */}
              <Form.Group controlId="newCompany_address">
                <Form.Label>Dirección de la Empresa</Form.Label>
                <Form.Control
                  as="textarea"
                  name="company_address"
                  value={newLead.company_address}
                  onChange={handleNewLeadInputChange}
                />
              </Form.Group>

              
              <Form.Group controlId="newcompany_website_or_social_media">
                <Form.Label>Sitio Web o Redes Sociales de la Empresa</Form.Label>
                <Form.Control
                  type="url"
                  name="company_website_or_social_media"
                  value={newLead.company_website_or_social_media}
                  onChange={handleNewLeadInputChange}
                />
              </Form.Group>

              {/* Sección: Contacto */}
              <Form.Group controlId="newcontact_person_name">
                <Form.Label>Nombre de la Persona de Contacto</Form.Label>
                <Form.Control
                  type="text"
                  name="contact_person_name"
                  value={newLead.contact_person_name}
                  onChange={handleNewLeadInputChange}
                />
              </Form.Group>
              <Form.Group controlId="newcontact_person_phone">
                <Form.Label>Teléfono de la Persona de Contacto</Form.Label>
                <Form.Control
                  type="text"
                  name="contact_person_phone"
                  value={newLead.contact_person_phone}
                  onChange={handleNewLeadInputChange}
                />
              </Form.Group>
              <Form.Group controlId="newcontact_person_position">
                <Form.Label>Posición de la Persona de Contacto</Form.Label>
                <Form.Control
                  type="text"
                  name="contact_person_position"
                  value={newLead.contact_person_position}
                  onChange={handleNewLeadInputChange}
                />
              </Form.Group>
              <Form.Group controlId="newcontact_reason">
                <Form.Label>Razón de Contacto</Form.Label>
                <Form.Control
                  as="textarea"
                  name="contact_reason"
                  value={newLead.contact_reason}
                  onChange={handleNewLeadInputChange}
                />
              </Form.Group>

              {/* Sección: Objetivos de Negocio */}
              <Form.Group controlId="newcurrent_business_goals">
                <Form.Label>Objetivos Actuales del Negocio</Form.Label>
                <Form.Control
                  as="textarea"
                  name="current_business_goals"
                  value={newLead.current_business_goals}
                  onChange={handleNewLeadInputChange}
                />
              </Form.Group>

              {/* Sección: Facebook */}
              <Form.Group controlId="newfacebook_comment">
                <Form.Label>Comentario de Facebook</Form.Label>
                <Form.Control
                  as="textarea"
                  name="facebook_comment"
                  value={newLead.facebook_comment}
                  onChange={handleNewLeadInputChange}
                />
              </Form.Group>

              <Form.Group controlId="newfan_page_information_details">
                <Form.Label>Detalles de la Página de Fans</Form.Label>
                <Form.Control
                  as="textarea"
                  name="fan_page_information_details"
                  value={newLead.fan_page_information_details}
                  onChange={handleNewLeadInputChange}
                />
              </Form.Group>

              {/* Sección: Comunicación de Logo e Identidad Visual */}
              <Form.Group controlId="newlogo_and_visual_identity_communication">
                <Form.Label>Comunicación de Logo e Identidad Visual</Form.Label>
                <Form.Control
                  as="textarea"
                  name="logo_and_visual_identity_communication"
                  value={newLead.logo_and_visual_identity_communication}
                  onChange={handleNewLeadInputChange}
                />
              </Form.Group>

              {/* Sección: Aplicaciones de Logo */}
              <Form.Group controlId="newlogo_applications">
                <Form.Label>Aplicaciones de Logo</Form.Label>
                <Form.Control
                  as="textarea"
                  name="logo_applications"
                  value={newLead.logo_applications}
                  onChange={handleNewLeadInputChange}
                />
              </Form.Group>

              {/* Sección: Competidores Principales */}
              <Form.Group controlId="newmain_competitors">
                <Form.Label>Principales Competidores</Form.Label>
                <Form.Control
                  as="textarea"
                  name="main_competitors"
                  value={newLead.main_competitors}
                  onChange={handleNewLeadInputChange}
                />
              </Form.Group>

              {/* Sección: Estrategia de Posicionamiento en el Mercado */}
              <Form.Group controlId="newmarket_positioning_strategy">
                <Form.Label>Estrategia de Posicionamiento en el Mercado</Form.Label>
                <Form.Control
                  as="textarea"
                  name="market_positioning_strategy"
                  value={newLead.market_positioning_strategy}
                  onChange={handleNewLeadInputChange}
                />
              </Form.Group>

              {/* Sección: Horarios de Apertura y Mapa de Ubicación */}
              <Form.Group controlId="newopening_hours_location_maps">
                <Form.Label>Horarios de Apertura y Mapa de Ubicación</Form.Label>
                <Form.Control
                  as="textarea"
                  name="opening_hours_location_maps"
                  value={newLead.opening_hours_location_maps}
                  onChange={handleNewLeadInputChange}
                />
              </Form.Group>

              {/* Sección: Información de Pago */}
              <Form.Group controlId="newpayment_information">
                <Form.Label>Información de Pago</Form.Label>
                <Form.Control
                  as="textarea"
                  name="payment_information"
                  value={newLead.payment_information}
                  onChange={handleNewLeadInputChange}
                />
              </Form.Group>

              {/* Sección: Método de Pago */}
              <Form.Group controlId="newpayment_method">
                <Form.Label>Método de Pago</Form.Label>
                <Form.Control
                  type="text"
                  name="payment_method"
                  value={newLead.payment_method}
                  onChange={handleNewLeadInputChange}
                />
              </Form.Group>

              {/* Sección: Enlace de Facebook */}
              <Form.Group controlId="newfacebook_link">
                <Form.Label>Enlace de Facebook</Form.Label>
                <Form.Control
                  type="text"
                  name="facebook_link"
                  value={newLead.facebook_link}
                  onChange={handleNewLeadInputChange}
                />
              </Form.Group>

              {/* Sección: Categoría de la Página de Fans */}
              <Form.Group controlId="newfan_page_category">
                <Form.Label>Categoría de la Página de Fans</Form.Label>
                <Form.Control
                  type="text"
                  name="fan_page_category"
                  value={newLead.fan_page_category}
                  onChange={handleNewLeadInputChange}
                />
              </Form.Group>

              {/* Sección: Comentarios de la Página de Fans */}
              <Form.Group controlId="newfan_page_comments">
                <Form.Label>Comentarios de la Página de Fans</Form.Label>
                <Form.Control
                  as="textarea"
                  name="fan_page_comments"
                  value={newLead.fan_page_comments}
                  onChange={handleNewLeadInputChange}
                />
              </Form.Group>

              {/* Sección: Líderes de la Industria */}
              <Form.Group controlId="newindustry_leaders">
                <Form.Label>Líderes de la Industria</Form.Label>
                <Form.Control
                  as="textarea"
                  name="industry_leaders"
                  value={newLead.industry_leaders}
                  onChange={handleNewLeadInputChange}
                />
              </Form.Group>

              {/* Sección: Referencias de Marcas Preferidas */}
              <Form.Group controlId="newpreferred_brands_references">
                <Form.Label>Referencias de Marcas Preferidas</Form.Label>
                <Form.Control
                  as="textarea"
                  name="preferred_brands_references"
                  value={newLead.preferred_brands_references}
                  onChange={handleNewLeadInputChange}
                />
              </Form.Group>

              {/* Sección: Paleta de Colores Preferida */}
              <Form.Group controlId="newpreferred_color_palette">
                <Form.Label>Paleta de Colores Preferida</Form.Label>
                <Form.Control
                  type="text"
                  name="preferred_color_palette"
                  value={newLead.preferred_color_palette}
                  onChange={handleNewLeadInputChange}
                />
              </Form.Group>

              {/* Sección: Uso Principal del Logotipo */}
              <Form.Group controlId="newprimary_logo_usage">
                <Form.Label>Uso Principal del Logotipo</Form.Label>
                <Form.Control
                  as="textarea"
                  name="primary_logo_usage"
                  value={newLead.primary_logo_usage}
                  onChange={handleNewLeadInputChange}
                />
              </Form.Group>
              {/* Sección: Productos o Servicios */}
              <Form.Group controlId="newproducts_or_services">
                <Form.Label>Productos o Servicios</Form.Label>
                <Form.Control
                  as="textarea"
                  name="products_or_services"
                  value={newLead.products_or_services}
                  onChange={handleNewLeadInputChange}
                />
              </Form.Group>

              {/* Sección: Objetivo del Proyecto */}
              <Form.Group controlId="newproject_objective">
                <Form.Label>Objetivo del Proyecto</Form.Label>
                <Form.Control
                  as="textarea"
                  name="project_objective"
                  value={newLead.project_objective}
                  onChange={handleNewLeadInputChange}
                />
              </Form.Group>

              {/* Sección: Comportamiento de Compra */}
              <Form.Group controlId="newpurchase_behavior">
                <Form.Label>Comportamiento de Compra</Form.Label>
                <Form.Control
                  as="textarea"
                  name="purchase_behavior"
                  value={newLead.purchase_behavior}
                  onChange={handleNewLeadInputChange}
                />
              </Form.Group>

              {/* Sección: Preferencia de Símbolo o Icono */}
              <Form.Group controlId="newsymbol_or_icon_preference">
                <Form.Label>Preferencia de Símbolo o Icono</Form.Label>
                <Form.Control
                  as="textarea"
                  name="symbol_or_icon_preference"
                  value={newLead.symbol_or_icon_preference}
                  onChange={handleNewLeadInputChange}
                />
              </Form.Group>

              {/* Sección: Rango de Edad Objetivo */}
              <Form.Group controlId="newtarget_age_range">
                <Form.Label>Rango de Edad Objetivo</Form.Label>
                <Form.Control
                  type="text"
                  name="target_age_range"
                  value={newLead.target_age_range}
                  onChange={handleNewLeadInputChange}
                />
              </Form.Group>

              {/* Sección: Género Objetivo */}
              <Form.Group controlId="newtarget_gender">
                <Form.Label>Género Objetivo</Form.Label>
                <Form.Control
                  type="text"
                  name="target_gender"
                  value={newLead.target_gender}
                  onChange={handleNewLeadInputChange}
                />
              </Form.Group>

              {/* Sección: Intereses Objetivo */}
              <Form.Group controlId="newtarget_interests">
                <Form.Label>Intereses Objetivo</Form.Label>
                <Form.Control
                  as="textarea"
                  name="target_interests"
                  value={newLead.target_interests}
                  onChange={handleNewLeadInputChange}
                />
              </Form.Group>

              {/* Sección: Etapa del Ciclo de Vida Objetivo */}
              <Form.Group controlId="newtarget_lifecycle_stage">
                <Form.Label>Etapa del Ciclo de Vida Objetivo</Form.Label>
                <Form.Control
                  type="text"
                  name="target_lifecycle_stage"
                  value={newLead.target_lifecycle_stage}
                  onChange={handleNewLeadInputChange}
                />
              </Form.Group>

              {/* Sección: Nivel Socioeconómico Objetivo */}
              <Form.Group controlId="newtarget_socioeconomic_level">
                <Form.Label>Nivel Socioeconómico Objetivo</Form.Label>
                <Form.Control
                  type="text"
                  name="target_socioeconomic_level"
                  value={newLead.target_socioeconomic_level}
                  onChange={handleNewLeadInputChange}
                />
              </Form.Group>

              {/* Sección: Proposición Única de Valor */}
              <Form.Group controlId="newunique_value_proposition">
                <Form.Label>Proposición Única de Valor</Form.Label>
                <Form.Control
                  as="textarea"
                  name="unique_value_proposition"
                  value={newLead.unique_value_proposition}
                  onChange={handleNewLeadInputChange}
                />
              </Form.Group>

              {/* Sección: Comunicación de Identidad Visual */}
              <Form.Group controlId="newvisual_identity_communication">
                <Form.Label>Comunicación de Identidad Visual</Form.Label>
                <Form.Control
                  as="textarea"
                  name="visual_identity_communication"
                  value={newLead.visual_identity_communication}
                  onChange={handleNewLeadInputChange}
                />
              </Form.Group>
              {/* Sección: Metas de Identidad Visual */}
              <Form.Group controlId="newvisual_identity_goals">
                <Form.Label>Metas de Identidad Visual</Form.Label>
                <Form.Control
                  as="textarea"
                  name="visual_identity_goals"
                  value={newLead.visual_identity_goals}
                  onChange={handleNewLeadInputChange}
                />
              </Form.Group>

              {/* Sección: Número de Teléfono de WSB */}
              <Form.Group controlId="newwsb_phone_number">
                <Form.Label>Número de Teléfono de WSB</Form.Label>
                <Form.Control
                  type="text"
                  name="wsb_phone_number"
                  value={newLead.wsb_phone_number}
                  onChange={handleNewLeadInputChange}
                />
              </Form.Group>

              {/* Sección: Mensaje Predefinido de WSB */}
              <Form.Group controlId="newwsb_predefined_message">
                <Form.Label>Mensaje Predefinido de WSB</Form.Label>
                <Form.Control
                  as="textarea"
                  name="wsb_predefined_message"
                  value={newLead.wsb_predefined_message}
                  onChange={handleNewLeadInputChange}
                />
              </Form.Group>

              {/* Sección: Detalles de WhatsApp */}
              <Form.Group controlId="newwhatsapp_details">
                <Form.Label>Detalles de WhatsApp</Form.Label>
                <Form.Control
                  as="textarea"
                  name="whatsapp_details"
                  value={newLead.whatsapp_details}
                  onChange={handleNewLeadInputChange}
                />
              </Form.Group>

            </Form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleCloseCreateModal}>
            Cerrar
          </Button>
          <Button variant="primary" onClick={handleSaveNewLead}>
            Crear Cliente
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showCommentsModal} onHide={() => setShowCommentsModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Comentarios</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedLeadComments.length > 0 ? (
            <Table>
              <thead>
                <tr>
                  <th>Comentario</th>
                  <th>Usuario</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {selectedLeadComments.map(comment => (
                  <tr key={comment.id}>
                    <td>{comment.comment}</td>
                    <td>{comment.user}</td>
                    <td>
                      <Button variant="danger" onClick={() => handleDeleteComment(comment.id)} className="action-button">Eliminar</Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <p>No hay comentarios disponibles.</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => setShowCommentsModal(false)}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>


      {/* Modal de edición (Edit Lead) */}
      <Modal show={showModal} onHide={handleCloseModal} centered className="modal-above-table">
        <Modal.Header closeButton>
          <Modal.Title>Editar Cliente</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ maxHeight: 'calc(100vh - 410px)', overflowY: 'auto' }}>
            <Form>

              {/* Datos Generales */}
              <Form.Group controlId="name">
                <Form.Label>Nombre del Vendedor</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  value={editedLead.name}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group controlId="email">
                <Form.Label>Correo Electrónico del Vendedor</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  value={editedLead.email}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group controlId="description">
                <Form.Label>Descripción del Vendedor</Form.Label>
                <Form.Control
                  as="textarea"
                  name="description"
                  value={editedLead.description}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group controlId="newPriority">
              <Form.Label>Prioridad</Form.Label>
              <Form.Control
                as="select"
                name="priority"
                value={newLead.priority}
                onChange={handleNewLeadInputChange}
              >
                <option value="">Seleccionar prioridad</option>
                <option value="low">Baja</option>
                <option value="medium">Medio</option>
                <option value="high">Alta</option>
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="newStatus">
              <Form.Label>Status</Form.Label>
              <Form.Control
                as="select"
                name="status"
                value={newLead.status}
                onChange={handleNewLeadInputChange}
              >
                <option value="">Seleccionar estado</option>
                <option value="new">Nuevo</option>
                <option value="contacted">Contactado</option>
                <option value="winner">Cliente Ganado</option>
              </Form.Control>
              </Form.Group>
              <Form.Group controlId="comment">
              <Form.Label>Comentario</Form.Label>
              <Form.Control
                as="textarea"
                name="comment"
                value={editedLead.comment}
                onChange={handleInputChange}
              />
            </Form.Group>        

              {/* Sección: Información de la Empresa */}
              <Form.Group controlId="company_name">
                <Form.Label>Nombre de la Empresa</Form.Label>
                <Form.Control
                  type="text"
                  name="company_name"
                  value={editedLead.company_name}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group controlId="company_address">
                <Form.Label>Dirección de la Empresa</Form.Label>
                <Form.Control
                  as="textarea"
                  name="company_address"
                  value={editedLead.company_address}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group controlId="commercial_information_details">
                <Form.Label>Detalles de Información Comercial</Form.Label>
                <Form.Control
                  as="textarea"
                  name="commercial_information_details"
                  value={editedLead.commercial_information_details}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group controlId="company_email">
                <Form.Label>Correo Electrónico de la Empresa</Form.Label>
                <Form.Control
                  type="email"
                  name="company_email"
                  value={editedLead.company_email}
                  onChange={handleInputChange}
                />
              </Form.Group>
              {/* <Form.Group controlId="company_logo">
                <Form.Label>Logo de la Empresa</Form.Label>
                <Form.Control
                  type="file"
                  name="company_logo"
                  onChange={handleInputChange}
                />
              </Form.Group> */}
              
              <Form.Group controlId="company_website_or_social_media">
                <Form.Label>Sitio Web o Redes Sociales de la Empresa</Form.Label>
                <Form.Control
                  type="url"
                  name="company_website_or_social_media"
                  value={editedLead.company_website_or_social_media}
                  onChange={handleInputChange}
                />
              </Form.Group>

              {/* Sección: Contacto */}
              <Form.Group controlId="contact_person_name">
                <Form.Label>Nombre de la Persona de Contacto</Form.Label>
                <Form.Control
                  type="text"
                  name="contact_person_name"
                  value={editedLead.contact_person_name}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group controlId="contact_person_phone">
                <Form.Label>Teléfono de la Persona de Contacto</Form.Label>
                <Form.Control
                  type="text"
                  name="contact_person_phone"
                  value={editedLead.contact_person_phone}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group controlId="contact_person_position">
                <Form.Label>Posición de la Persona de Contacto</Form.Label>
                <Form.Control
                  type="text"
                  name="contact_person_position"
                  value={editedLead.contact_person_position}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group controlId="contact_reason">
                <Form.Label>Razón de Contacto</Form.Label>
                <Form.Control
                  as="textarea"
                  name="contact_reason"
                  value={editedLead.contact_reason}
                  onChange={handleInputChange}
                />
              </Form.Group>

              {/* Sección: Objetivos de Negocio */}
              <Form.Group controlId="current_business_goals">
                <Form.Label>Objetivos Actuales del Negocio</Form.Label>
                <Form.Control
                  as="textarea"
                  name="current_business_goals"
                  value={editedLead.current_business_goals}
                  onChange={handleInputChange}
                />
              </Form.Group>

              {/* Sección: Facebook */}
              <Form.Group controlId="facebook_comment">
                <Form.Label>Comentario de Facebook</Form.Label>
                <Form.Control
                  as="textarea"
                  name="facebook_comment"
                  value={editedLead.facebook_comment}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group controlId="facebook_link">
                <Form.Label>Enlace de Facebook</Form.Label>
                <Form.Control
                  type="url"
                  name="facebook_link"
                  value={editedLead.facebook_link}
                  onChange={handleInputChange}
                />
              </Form.Group>

              <Form.Group controlId="fan_page_comments">
                <Form.Label>Comentarios de la Página de Fans</Form.Label>
                <Form.Control
                  as="textarea"
                  name="fan_page_comments"
                  value={editedLead.fan_page_comments}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group controlId="fan_page_information_details">
                <Form.Label>Detalles de la Página de Fans</Form.Label>
                <Form.Control
                  as="textarea"
                  name="fan_page_information_details"
                  value={editedLead.fan_page_information_details}
                  onChange={handleInputChange}
                />
              </Form.Group>

              {/* Sección: Industria */}
              <Form.Group controlId="industry_leaders">
                <Form.Label>Líderes de la Industria</Form.Label>
                <Form.Control
                  as="textarea"
                  name="industry_leaders"
                  value={editedLead.industry_leaders}
                  onChange={handleInputChange}
                />
              </Form.Group>

              {/* Sección: Comunicación de Logo e Identidad Visual */}
              <Form.Group controlId="logo_and_visual_identity_communication">
                <Form.Label>Comunicación de Logo e Identidad Visual</Form.Label>
                <Form.Control
                  as="textarea"
                  name="logo_and_visual_identity_communication"
                  value={editedLead.logo_and_visual_identity_communication}
                  onChange={handleInputChange}
                />
              </Form.Group>

              {/* Sección: Aplicaciones de Logo */}
              <Form.Group controlId="logo_applications">
                <Form.Label>Aplicaciones de Logo</Form.Label>
                <Form.Control
                  as="textarea"
                  name="logo_applications"
                  value={editedLead.logo_applications}
                  onChange={handleInputChange}
                />
              </Form.Group>

              {/* Sección: Competidores Principales */}
              <Form.Group controlId="main_competitors">
                <Form.Label>Principales Competidores</Form.Label>
                <Form.Control
                  as="textarea"
                  name="main_competitors"
                  value={editedLead.main_competitors}
                  onChange={handleInputChange}
                />
              </Form.Group>

              {/* Sección: Estrategia de Posicionamiento en el Mercado */}
              <Form.Group controlId="market_positioning_strategy">
                <Form.Label>Estrategia de Posicionamiento en el Mercado</Form.Label>
                <Form.Control
                  as="textarea"
                  name="market_positioning_strategy"
                  value={editedLead.market_positioning_strategy}
                  onChange={handleInputChange}
                />
              </Form.Group>

              {/* Sección: Horarios de Apertura y Mapa de Ubicación */}
              <Form.Group controlId="opening_hours_location_maps">
                <Form.Label>Horarios de Apertura y Mapa de Ubicación</Form.Label>
                <Form.Control
                  as="textarea"
                  name="opening_hours_location_maps"
                  value={editedLead.opening_hours_location_maps}
                  onChange={handleInputChange}
                />
              </Form.Group>

              {/* Sección: Información de Pago */}
              <Form.Group controlId="payment_information">
                <Form.Label>Información de Pago</Form.Label>
                <Form.Control
                  as="textarea"
                  name="payment_information"
                  value={editedLead.payment_information}
                  onChange={handleInputChange}
                />
              </Form.Group>

              {/* Sección: Método de Pago */}
              <Form.Group controlId="payment_method">
                <Form.Label>Método de Pago</Form.Label>
                <Form.Control
                  type="text"
                  name="payment_method"
                  value={editedLead.payment_method}
                  onChange={handleInputChange}
                />
              </Form.Group>

              {/* Sección: Comentario en Facebook */}
              <Form.Group controlId="facebook_comment">
                <Form.Label>Comentario en Facebook</Form.Label>
                <Form.Control
                  as="textarea"
                  name="facebook_comment"
                  value={editedLead.facebook_comment}
                  onChange={handleInputChange}
                />
              </Form.Group>

              {/* Sección: Enlace de Facebook */}
              <Form.Group controlId="facebook_link">
                <Form.Label>Enlace de Facebook</Form.Label>
                <Form.Control
                  type="text"
                  name="facebook_link"
                  value={editedLead.facebook_link}
                  onChange={handleInputChange}
                />
              </Form.Group>

              {/* Sección: Categoría de la Página de Fans */}
              <Form.Group controlId="fan_page_category">
                <Form.Label>Categoría de la Página de Fans</Form.Label>
                <Form.Control
                  type="text"
                  name="fan_page_category"
                  value={editedLead.fan_page_category}
                  onChange={handleInputChange}
                />
              </Form.Group>

              {/* Sección: Comentarios de la Página de Fans */}
              <Form.Group controlId="fan_page_comments">
                <Form.Label>Comentarios de la Página de Fans</Form.Label>
                <Form.Control
                  as="textarea"
                  name="fan_page_comments"
                  value={editedLead.fan_page_comments}
                  onChange={handleInputChange}
                />
              </Form.Group>

              {/* Sección: Detalles de Información de la Página de Fans */}
              <Form.Group controlId="fan_page_information_details">
                <Form.Label>Detalles de Información de la Página de Fans</Form.Label>
                <Form.Control
                  as="textarea"
                  name="fan_page_information_details"
                  value={editedLead.fan_page_information_details}
                  onChange={handleInputChange}
                />
              </Form.Group>

              {/* Sección: Líderes de la Industria */}
              <Form.Group controlId="industry_leaders">
                <Form.Label>Líderes de la Industria</Form.Label>
                <Form.Control
                  as="textarea"
                  name="industry_leaders"
                  value={editedLead.industry_leaders}
                  onChange={handleInputChange}
                />
              </Form.Group>

              {/* Sección: Comunicación de Logo e Identidad Visual */}
              <Form.Group controlId="logo_and_visual_identity_communication">
                <Form.Label>Comunicación de Logo e Identidad Visual</Form.Label>
                <Form.Control
                  as="textarea"
                  name="logo_and_visual_identity_communication"
                  value={editedLead.logo_and_visual_identity_communication}
                  onChange={handleInputChange}
                />
              </Form.Group>

              {/* Sección: Referencias de Marcas Preferidas */}
              <Form.Group controlId="preferred_brands_references">
                <Form.Label>Referencias de Marcas Preferidas</Form.Label>
                <Form.Control
                  as="textarea"
                  name="preferred_brands_references"
                  value={editedLead.preferred_brands_references}
                  onChange={handleInputChange}
                />
              </Form.Group>

              {/* Sección: Paleta de Colores Preferida */}
              <Form.Group controlId="preferred_color_palette">
                <Form.Label>Paleta de Colores Preferida</Form.Label>
                <Form.Control
                  type="text"
                  name="preferred_color_palette"
                  value={editedLead.preferred_color_palette}
                  onChange={handleInputChange}
                />
              </Form.Group>

              {/* Sección: Uso Principal del Logotipo */}
              <Form.Group controlId="primary_logo_usage">
                <Form.Label>Uso Principal del Logotipo</Form.Label>
                <Form.Control
                  as="textarea"
                  name="primary_logo_usage"
                  value={editedLead.primary_logo_usage}
                  onChange={handleInputChange}
                />
              </Form.Group>
              {/* Sección: Productos o Servicios */}
              <Form.Group controlId="products_or_services">
                <Form.Label>Productos o Servicios</Form.Label>
                <Form.Control
                  as="textarea"
                  name="products_or_services"
                  value={editedLead.products_or_services}
                  onChange={handleInputChange}
                />
              </Form.Group>

              {/* Sección: Objetivo del Proyecto */}
              <Form.Group controlId="project_objective">
                <Form.Label>Objetivo del Proyecto</Form.Label>
                <Form.Control
                  as="textarea"
                  name="project_objective"
                  value={editedLead.project_objective}
                  onChange={handleInputChange}
                />
              </Form.Group>

              {/* Sección: Comportamiento de Compra */}
              <Form.Group controlId="purchase_behavior">
                <Form.Label>Comportamiento de Compra</Form.Label>
                <Form.Control
                  as="textarea"
                  name="purchase_behavior"
                  value={editedLead.purchase_behavior}
                  onChange={handleInputChange}
                />
              </Form.Group>

              {/* Sección: Preferencia de Símbolo o Icono */}
              <Form.Group controlId="symbol_or_icon_preference">
                <Form.Label>Preferencia de Símbolo o Icono</Form.Label>
                <Form.Control
                  as="textarea"
                  name="symbol_or_icon_preference"
                  value={editedLead.symbol_or_icon_preference}
                  onChange={handleInputChange}
                />
              </Form.Group>

              {/* Sección: Rango de Edad Objetivo */}
              <Form.Group controlId="target_age_range">
                <Form.Label>Rango de Edad Objetivo</Form.Label>
                <Form.Control
                  type="text"
                  name="target_age_range"
                  value={editedLead.target_age_range}
                  onChange={handleInputChange}
                />
              </Form.Group>

              {/* Sección: Género Objetivo */}
              <Form.Group controlId="target_gender">
                <Form.Label>Género Objetivo</Form.Label>
                <Form.Control
                  type="text"
                  name="target_gender"
                  value={editedLead.target_gender}
                  onChange={handleInputChange}
                />
              </Form.Group>

              {/* Sección: Intereses Objetivo */}
              <Form.Group controlId="target_interests">
                <Form.Label>Intereses Objetivo</Form.Label>
                <Form.Control
                  as="textarea"
                  name="target_interests"
                  value={editedLead.target_interests}
                  onChange={handleInputChange}
                />
              </Form.Group>

              {/* Sección: Etapa del Ciclo de Vida Objetivo */}
              <Form.Group controlId="target_lifecycle_stage">
                <Form.Label>Etapa del Ciclo de Vida Objetivo</Form.Label>
                <Form.Control
                  type="text"
                  name="target_lifecycle_stage"
                  value={editedLead.target_lifecycle_stage}
                  onChange={handleInputChange}
                />
              </Form.Group>

              {/* Sección: Nivel Socioeconómico Objetivo */}
              <Form.Group controlId="target_socioeconomic_level">
                <Form.Label>Nivel Socioeconómico Objetivo</Form.Label>
                <Form.Control
                  type="text"
                  name="target_socioeconomic_level"
                  value={editedLead.target_socioeconomic_level}
                  onChange={handleInputChange}
                />
              </Form.Group>

              {/* Sección: Proposición Única de Valor */}
              <Form.Group controlId="unique_value_proposition">
                <Form.Label>Proposición Única de Valor</Form.Label>
                <Form.Control
                  as="textarea"
                  name="unique_value_proposition"
                  value={editedLead.unique_value_proposition}
                  onChange={handleInputChange}
                />
              </Form.Group>

              {/* Sección: Comunicación de Identidad Visual */}
              <Form.Group controlId="visual_identity_communication">
                <Form.Label>Comunicación de Identidad Visual</Form.Label>
                <Form.Control
                  as="textarea"
                  name="visual_identity_communication"
                  value={editedLead.visual_identity_communication}
                  onChange={handleInputChange}
                />
              </Form.Group>
              {/* Sección: Metas de Identidad Visual */}
              <Form.Group controlId="visual_identity_goals">
                <Form.Label>Metas de Identidad Visual</Form.Label>
                <Form.Control
                  as="textarea"
                  name="visual_identity_goals"
                  value={editedLead.visual_identity_goals}
                  onChange={handleInputChange}
                />
              </Form.Group>

              {/* Sección: Número de Teléfono de WSB */}
              <Form.Group controlId="wsb_phone_number">
                <Form.Label>Número de Teléfono de WSB</Form.Label>
                <Form.Control
                  type="text"
                  name="wsb_phone_number"
                  value={editedLead.wsb_phone_number}
                  onChange={handleInputChange}
                />
              </Form.Group>

              {/* Sección: Mensaje Predefinido de WSB */}
              <Form.Group controlId="wsb_predefined_message">
                <Form.Label>Mensaje Predefinido de WSB</Form.Label>
                <Form.Control
                  as="textarea"
                  name="wsb_predefined_message"
                  value={editedLead.wsb_predefined_message}
                  onChange={handleInputChange}
                />
              </Form.Group>

              {/* Sección: Detalles de WhatsApp */}
              <Form.Group controlId="whatsapp_details">
                <Form.Label>Detalles de WhatsApp</Form.Label>
                <Form.Control
                  as="textarea"
                  name="whatsapp_details"
                  value={editedLead.whatsapp_details}
                  onChange={handleInputChange}
                />
              </Form.Group>



            </Form>
          </div>
        </Modal.Body>


        <Modal.Footer>
          <Button variant="primary" onClick={handleCloseModal}>
            Cerrar
          </Button>
          {isEditMode && selectedLead ? (
            <Button variant="primary" onClick={handleSave}>
              Guardar cambios
            </Button>
          ) : null}
        </Modal.Footer>
      </Modal>

      {/* Toast de error */}
      {errorToast && (
        <Toast
          show={errorToast}
          onClose={() => setErrorToast(null)}
          style={{
            position: 'fixed',
            top: '10px',
            right: '10px',
            minWidth: '200px',
          }}
        >
          <Toast.Header>
            <strong className="mr-auto">Error</strong>
          </Toast.Header>
          <Toast.Body>{errorToast}</Toast.Body>
        </Toast>
      )}

      {/* Toast de confirmación de eliminación */}
      <Toast show={showDeleteToast} onClose={handleDeleteConfirmationClose} style={{ position: 'fixed', top: '50%', right: '50%', background: 'white' }}>
        <Toast.Header>
          <strong className="mr-auto">Confirmar eliminación</strong>
        </Toast.Header>
        <Toast.Body>
          {deleteToastMessage}
          <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
            <Button variant="secondary" onClick={handleDeleteConfirmationClose} style={{ marginRight: '5px' }}>
              Cancelar
            </Button>
            <Button variant="danger" onClick={() => handleDelete(selectedLead.id)}>
              Eliminar
            </Button>
          </div>
        </Toast.Body>
      </Toast>
     
    </div>
    
  );
};

export default ContactsInfo;
