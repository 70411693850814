import React from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

function ModalProducto(props) {
    const {
        showModal,
        handleCloseModal,
        newProductData,
        setNewProductData,
        handleImageChange,
        handleCreateOrEditProduct,
        handleCategoryChange,
        handleSubcategoryChange
    } = props;

    return (
        <Modal show={showModal} onHide={handleCloseModal} style={{ overflowX: 'auto', overflowY: 'auto' }}>
            <Modal.Header closeButton>
                <Modal.Title>Crear Hoteles y Turismo</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <button

                    style={{
                        background: "red",
                        color: "white",
                        padding: "8px",
                        borderRadius: "5px",
                        border: "none",
                        cursor: "pointer",
                        position: "absolute",
                        top: "4px",
                        right: "4px",
                    }}
                    onClick={handleCloseModal}
                >
                    X
                </button>
                <Form>
                    {/* Agregar aquí los campos del formulario */}
                    {/* Ejemplo para el campo 'Nombre' */}
                    <Form.Group controlId="formName">
                        <Form.Label>Nombre</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Ingrese el nombre"
                            value={newProductData.name}
                            onChange={(e) =>
                                setNewProductData({ ...newProductData, name: e.target.value })
                            }
                        />
                    </Form.Group>


                    <Form.Group controlId="formBrand">
                        <Form.Label>Marca</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Ingrese la marca"
                            value={newProductData.brand}
                            onChange={(e) => setNewProductData({ ...newProductData, brand: e.target.value })}
                        />
                    </Form.Group>

                    <Form.Group controlId="formCategory">
                        <Form.Label>Categoría</Form.Label>
                        <Form.Select
                            value={newProductData.category}
                            onChange={handleCategoryChange}
                        >
                            <option value="">Seleccione una categoría</option>
                            <optgroup label="VENTA">
                                <option value="Hoteles">Hoteles</option>
                                <option value="Turismo de Aventura">Turismo de Aventura</option>
                                <option value="Turismo Ecológico">Turismo Ecológico</option>
                                <option value="Turismo Cultural">Turismo Cultural</option>
                                <option value="Paquetes de Viaje">Paquetes de Viaje</option>
                            </optgroup>

                        </Form.Select>


                    </Form.Group>
                    <Form.Group controlId="formSubcategory">
                        <Form.Label>Subcategoría</Form.Label>
                        <Form.Select
                            multiple  // Habilita la selección múltiple
                            value={newProductData.subcategory}
                            onChange={handleSubcategoryChange}
                            style={{ height: '50vh' }}
                        >
                            <option value="">Seleccione una Subcategoría</option>
                            <optgroup label="Hoteles">
                                <option value="Hoteles de Lujo">Hoteles de Lujo</option>
                                <option value="Hoteles Boutique">Hoteles Boutique</option>
                                <option value="Hoteles de Playa">Hoteles de Playa</option>
                                <option value="Hoteles en la Selva">Hoteles en la Selva</option>
                                <option value="Hoteles Económicos">Hoteles Económicos</option>
                            </optgroup>

                            <optgroup label="Turismo de Aventura">
                                <option value="Tours de Canopy">Tours de Canopy</option>
                                <option value="Rafting y Kayak">Rafting y Kayak</option>
                                <option value="Senderismo y Trekking">Senderismo y Trekking</option>
                                <option value="Observación de Aves">Observación de Aves</option>
                            </optgroup>

                            <optgroup label="Turismo Ecológico">
                                <option value="Reservas Naturales">Reservas Naturales</option>
                                <option value="Recorridos en Selva">Recorridos en Selva</option>
                                <option value="Observación de Vida Silvestre">Observación de Vida Silvestre</option>
                            </optgroup>

                            <optgroup label="Turismo Cultural">
                                <option value="Visitas a Sitios Históricos">Visitas a Sitios Históricos</option>
                                <option value="Museos y Galerías de Arte">Museos y Galerías de Arte</option>
                                <option value="Tours Gastronómicos">Tours Gastronómicos</option>
                            </optgroup>

                            <optgroup label="Paquetes de Viaje">
                                <option value="Paquetes Todo Incluido">Paquetes Todo Incluido</option>
                                <option value="Paquetes de Aventura">Paquetes de Aventura</option>
                                <option value="Paquetes Románticos">Paquetes Románticos</option>
                            </optgroup>

                        </Form.Select>
                    </Form.Group>




                    <Form.Group controlId="formDescription">
                        <Form.Label>Descripción</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            placeholder="Ingrese la descripción"
                            value={newProductData.description}
                            onChange={(e) => setNewProductData({ ...newProductData, description: e.target.value })}
                        />
                    </Form.Group>

                    <Form.Group controlId="formPrice">
                        <Form.Label>Precio</Form.Label>
                        <Form.Control
                            type="number"
                            placeholder="Ingrese el precio"
                            value={newProductData.price}
                            onChange={(e) => setNewProductData({ ...newProductData, price: e.target.value })}
                        />
                    </Form.Group>

                    <Form.Group controlId="formDiscount">
                        <Form.Label>Descuento</Form.Label>
                        <Form.Control
                            type="number"
                            placeholder="Ingrese el descuento"
                            value={newProductData.discount}
                            onChange={(e) => setNewProductData({ ...newProductData, discount: e.target.value })}
                        />
                    </Form.Group>

                    <Form.Group controlId="formCountInStock">
                        <Form.Label>Cantidad en Stock</Form.Label>
                        <Form.Control
                            type="number"
                            placeholder="Ingrese la cantidad en stock"
                            value={newProductData.countInStock}
                            onChange={(e) => setNewProductData({ ...newProductData, countInStock: e.target.value })}
                        />
                    </Form.Group>

                    <Form.Group controlId="formImage">
                        <Form.Label>Imagen</Form.Label>
                        <Form.Control type="file" onChange={handleImageChange} />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseModal}>
                    Cerrar
                </Button>
                <Button variant="primary" onClick={handleCreateOrEditProduct}>
                    Guardar
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default ModalProducto;
