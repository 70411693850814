import React from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

function ModalProducto(props) {
    const {
        showModal,
        handleCloseModal,
        newProductData,
        setNewProductData,
        handleImageChange,
        handleCreateOrEditProduct,
        handleCategoryChange,
        handleSubcategoryChange
    } = props;

    return (
        <Modal show={showModal} onHide={handleCloseModal} style={{ overflowX: 'auto', overflowY: 'auto' }}>
            <Modal.Header closeButton>
                <Modal.Title>Crear Polizas y Seguros</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <button

                    style={{
                        background: "red",
                        color: "white",
                        padding: "8px",
                        borderRadius: "5px",
                        border: "none",
                        cursor: "pointer",
                        position: "absolute",
                        top: "4px",
                        right: "4px",
                    }}
                    onClick={handleCloseModal}
                >
                    X
                </button>
                <Form>
                    {/* Agregar aquí los campos del formulario */}
                    {/* Ejemplo para el campo 'Nombre' */}
                    <Form.Group controlId="formName">
                        <Form.Label>Nombre</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Ingrese el nombre"
                            value={newProductData.name}
                            onChange={(e) =>
                                setNewProductData({ ...newProductData, name: e.target.value })
                            }
                        />
                    </Form.Group>


                    <Form.Group controlId="formBrand">
                        <Form.Label>Marca</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Ingrese la marca"
                            value={newProductData.brand}
                            onChange={(e) => setNewProductData({ ...newProductData, brand: e.target.value })}
                        />
                    </Form.Group>

                    <Form.Group controlId="formCategory">
                        <Form.Label>Categoría</Form.Label>
                        <Form.Select
                            value={newProductData.category}
                            onChange={handleCategoryChange}
                        >
                            <option value="">Seleccione una categoría</option>
                            <optgroup >
                                <option value="Pólizas de Seguros">Pólizas de Seguros</option>
                                <option value="Seguros Comerciales">Seguros Comerciales</option>
                                <option value="Seguros Especiales">Seguros Especiales</option>
                                <option value="Servicios de Asesoría">Servicios de Asesoría</option>

                            </optgroup>

                        </Form.Select>


                    </Form.Group>
                    <Form.Group controlId="formSubcategory">
                        <Form.Label>Subcategoría</Form.Label>
                        <Form.Select
                            multiple  // Habilita la selección múltiple
                            value={newProductData.subcategory}
                            onChange={handleSubcategoryChange}
                            style={{ height: '50vh' }}
                        >
                            <option value="">Seleccione una Subcategoría</option>
                            <optgroup label="Pólizas de Seguros">
                                <option value="Seguro de Vida">Seguro de Vida</option>
                                <option value="Seguro de Salud">Seguro de Salud</option>
                                <option value="Seguro de Automóvil">Seguro de Automóvil</option>
                                <option value="Seguro de Hogar">Seguro de Hogar</option>
                                <option value="Seguro de Viaje">Seguro de Viaje</option>
                            </optgroup>

                            <optgroup label="Seguros Comerciales">
                                <option value="Seguro de Responsabilidad Civil">Seguro de Responsabilidad Civil</option>
                                <option value="Seguro de Propiedad Comercial">Seguro de Propiedad Comercial</option>
                                <option value="Seguro de Negocio">Seguro de Negocio</option>
                                <option value="Seguro de Flota de Vehículos">Seguro de Flota de Vehículos</option>
                            </optgroup>

                            <optgroup label="Seguros Especiales">
                                <option value="Seguro de Mascotas">Seguro de Mascotas</option>
                                <option value="Seguro de Embarcaciones">Seguro de Embarcaciones</option>
                                <option value="Seguro de Arte y Colecciones">Seguro de Arte y Colecciones</option>
                                <option value="Seguro de Joyería">Seguro de Joyería</option>
                            </optgroup>

                            <optgroup label="Servicios de Asesoría">
                                <option value="Asesoría de Seguros">Asesoría de Seguros</option>
                                <option value="Reclamaciones y Siniestros">Reclamaciones y Siniestros</option>
                                <option value="Evaluación de Riesgos">Evaluación de Riesgos</option>
                            </optgroup>


                        </Form.Select>
                    </Form.Group>




                    <Form.Group controlId="formDescription">
                        <Form.Label>Descripción</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            placeholder="Ingrese la descripción"
                            value={newProductData.description}
                            onChange={(e) => setNewProductData({ ...newProductData, description: e.target.value })}
                        />
                    </Form.Group>

                    <Form.Group controlId="formPrice">
                        <Form.Label>Precio</Form.Label>
                        <Form.Control
                            type="number"
                            placeholder="Ingrese el precio"
                            value={newProductData.price}
                            onChange={(e) => setNewProductData({ ...newProductData, price: e.target.value })}
                        />
                    </Form.Group>

                    <Form.Group controlId="formDiscount">
                        <Form.Label>Descuento</Form.Label>
                        <Form.Control
                            type="number"
                            placeholder="Ingrese el descuento"
                            value={newProductData.discount}
                            onChange={(e) => setNewProductData({ ...newProductData, discount: e.target.value })}
                        />
                    </Form.Group>

                    <Form.Group controlId="formCountInStock">
                        <Form.Label>Cantidad en Stock</Form.Label>
                        <Form.Control
                            type="number"
                            placeholder="Ingrese la cantidad en stock"
                            value={newProductData.countInStock}
                            onChange={(e) => setNewProductData({ ...newProductData, countInStock: e.target.value })}
                        />
                    </Form.Group>

                    <Form.Group controlId="formImage">
                        <Form.Label>Imagen</Form.Label>
                        <Form.Control type="file" onChange={handleImageChange} />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseModal}>
                    Cerrar
                </Button>
                <Button variant="primary" onClick={handleCreateOrEditProduct}>
                    Guardar
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default ModalProducto;
