import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { AiFillCaretRight } from 'react-icons/ai';
import { Dialog, DialogContent } from '@mui/material';
import { Close } from '@mui/icons-material';
import Products from '../../components/home/products';
import WhyTDM from '../../components/home/why_tdm'; 
import Footer from '../../components/navigation/Footer';
import Navbar from '../../components/navigation/Navbar';
import './technology.css';
import FileDataService from '../../services/files';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

function Services() {
  const videoRef = useRef(null);
  const [isVideoDialogOpen, setIsVideoDialogOpen] = useState(false);
  const [HeaderImage, setHeaderImage] = useState('');
  const [VisionImage, setVisionImage] = useState('');
  const [Video, setVideo] = useState('');
  const token = useSelector(state => state.authentication.token);

  const handleVideoClick = () => {
    setIsVideoDialogOpen(true);
  };
  const navigate = useNavigate();

  const scrollToForm = () => {
    navigate('/contact');
    
    setTimeout(() => {
      const contactForm = document.getElementById('contact-form');
      if (contactForm) {
        const yOffset = contactForm.getBoundingClientRect().top + window.pageYOffset - 150;
        window.scrollTo({ top: yOffset, behavior: 'smooth' });
      }
    }, 300); // Espera 300 milisegundos antes de desplazarse
  };
  const handleCloseVideo = () => {
    setIsVideoDialogOpen(false);
  };

  useEffect(() => {
    fetchImages();
  }, []);

  const fetchImages = () => {
    FileDataService.getAll(token)
      .then(response => {
        const HeaderImage = response.data.find(file => file.name === 'SERVICES_3');
        const VisionImage = response.data.find(file => file.name === 'SERVICES_2');
        const Video = response.data.find(file => file.name === 'SERVICES_1');
        if (HeaderImage) {
          setHeaderImage(HeaderImage.file);
        }
        if (VisionImage) {
          setVisionImage(VisionImage.file);
        }
        if (Video) {
          setVideo(Video.file);
        }
      })
      .catch(error => {
        console.error(error);
      });
  };

  const dialogStyle = {
    background: `url(${HeaderImage}) no-repeat center center fixed`,
    backgroundSize: 'cover',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };


  return (
    <div>
      <Navbar />
      <section className="about__story">
        <div className="container about__story-container">
          <div className="about__section-image">
          <div className="about__section-image">
            <img src={HeaderImage} alt="Vision" />
          </div>
          </div>

          <div className="about__section-content">
            <h2 style={{ fontSize: '2rem', fontWeight: 'bold' }}>
            Sobre Nosotros
            </h2>
            <br/><br/>
            <p>
            Somos una plataforma virtual que reúne a numerosos proveedores cuidadosamente seleccionados, quienes ofrecen sus servicios con altos estándares de excelencia.
            </p>
            <p>Aspiramos a ser una empresa socialmente responsable, comprometida con el bienestar de nuestras comunidades. Buscamos generar un impacto positivo al apoyar iniciativas y causas que promuevan el desarrollo sostenible, la igualdad y la inclusión.</p>
          </div>
        </div>
      </section>

      <section className="about__Vision">
        <div className="container about__vision-container">
          <div className="about__section-content">
            <h2 style={{ fontSize: '2rem', fontWeight: 'bold' }}>Quiénes somos</h2>

            <p>
            En ABcupon.com, nos esforzamos por hacer que su experiencia de compra sea cómoda, segura y satisfactoria. Nuestra plataforma está diseñada pensando en usted, para que puedas navegar fácilmente y encontrar los servicios que mejor se adapten a sus necesidades.
            </p>
            <h3 style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>MISIÓN</h3>

            <p>
            Nuestra misión es facilitar su vida al ofrecerle una amplia variedad de servicios, desde bienestar y belleza hasta actividades de ocio, todo en un solo lugar. Trabajamos diligentemente para garantizar que cada proveedor cumpla con los más altos estándares de calidad y confiabilidad, para que pueda disfrutar de una experiencia excepcional en cada transacción.
            </p>
            <h3 style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>VISIÓN</h3>

            <p>
            Nuestra visión es convertirnos en el referente principal para aquellos que buscan servicios confiables y de calidad en un entorno virtual. Nos esforzamos por ser reconocidos como el destino preferido, donde tanto proveedores como clientes puedan encontrar una plataforma segura, transparente y conveniente para satisfacer sus necesidades.
            </p>
          </div>
          <div className="about__section-image">
            <img src={VisionImage} alt="Vision" />
          </div>

        </div>
        
        <button onClick={scrollToForm} className="btn xl"
        style={{
          width: '80%',
          height: '100%',
          fontSize: '1.3rem',
        }}>
        CONSULTA SOBRE NUESTRAS PROMOCIONES<AiFillCaretRight />
      </button>
        <br/><br/>
      </section>

      <Products />
      {/* <WhyTDM /> */}

      <Footer />

      <Dialog open={isVideoDialogOpen} onClose={handleCloseVideo} fullScreen>
        <DialogContent style={dialogStyle}>
          <video
            ref={videoRef}
            src={Video}
            autoPlay
            controls
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
            }}
          />
          <Close onClick={handleCloseVideo} style={{ position: 'absolute', top: 20, right: 20, color: 'white' }} />
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default Services;