import React, { useState, useEffect } from 'react';
import { Container, Table, Form, Button, InputGroup, Modal, FormControl } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import EmployeeDataService from '../../services/employee';
import './TablaDatos.css';

const EmployeesRolesPuesto = () => {
  const [rolesPuesto, setRolesPuesto] = useState([]);
  const [selectedRolesPuesto, setSelectedRolesPuesto] = useState([]);
  const token = useSelector(state => state.authentication.token);
  const [showModal, setShowModal] = useState(false);
  const [newRolPuesto, setNewRolPuesto] = useState('');

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const fetchData = async () => {
    try {
      const responseRolesPuesto = await EmployeeDataService.getAllRolesPuesto(token);
      setRolesPuesto(responseRolesPuesto.data);
    } catch (error) {
      console.error('Error al obtener datos:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [token]);

  const handleDeleteSelected = async () => {
    try {
      await EmployeeDataService.deleteRolPuesto(selectedRolesPuesto, token);
      setSelectedRolesPuesto([]);
      fetchData();
    } catch (error) {
      console.error('Error al eliminar roles de puesto:', error);
    }
  };

  const toggleSelectRolPuesto = (rolPuestoId) => {
    if (selectedRolesPuesto.includes(rolPuestoId)) {
      setSelectedRolesPuesto(selectedRolesPuesto.filter(id => id !== rolPuestoId));
    } else {
      setSelectedRolesPuesto([...selectedRolesPuesto, rolPuestoId]);
    }
  };

  const handleCreateRolPuesto = async () => {
    try {
      await EmployeeDataService.createRolPuesto({ nombre: newRolPuesto }, token);
      setNewRolPuesto('');
      toggleModal();
      fetchData();
    } catch (error) {
      console.error('Error al crear nuevo rol de puesto:', error);
    }
  };

  return (
    <Container>
      <div className="tabla-datos-container">
      <br/><br/><br/>
      <h2>Roles en Puestos</h2>
      <Button variant="primary" onClick={toggleModal}>Agregar Rol en Puesto</Button>

      {/* Modal */}
      <Modal show={showModal} onHide={toggleModal} style={{ overflowY: 'auto' }}>
        <Modal.Header closeButton>
          <Modal.Title>Agregar Nuevo Rol en Puesto</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Label>Nombre del Rol:</Form.Label>
            <FormControl
              type="text"
              placeholder="Ingrese el nombre del rol"
              value={newRolPuesto}
              onChange={(e) => setNewRolPuesto(e.target.value)}
            />
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={toggleModal}>Cancelar</Button>
          <Button variant="primary" onClick={handleCreateRolPuesto}>Guardar</Button>
        </Modal.Footer>
      </Modal>

      <div style={{ overflowX: 'auto' }}>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Check</th>
              <th>ID</th>
              <th>Nombre</th>
              {/* ... Agregar las demás columnas según tus requerimientos ... */}
            </tr>
          </thead>
          <tbody>
            {rolesPuesto.map(rolPuesto => (
              <tr key={rolPuesto.id}>
                <td>
                  <Form.Check
                    type="checkbox"
                    checked={selectedRolesPuesto.includes(rolPuesto.id)}
                    onChange={() => toggleSelectRolPuesto(rolPuesto.id)}
                  />
                </td>
                <td>{rolPuesto.id}</td>
                <td>{rolPuesto.nombre}</td>
                {/* ... Agregar las demás columnas según tus requerimientos ... */}
              </tr>
            ))}
          </tbody>
        </Table>
      </div>

      {selectedRolesPuesto.length > 0 && (
        <InputGroup className="mb-3">
          <Button variant="danger" onClick={handleDeleteSelected}>
            Eliminar seleccionado
          </Button>
        </InputGroup>
      )}
      </div>
    </Container>
  );
};

export default EmployeesRolesPuesto;
