import React, { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import {
  FaClock,
  FaBuilding,
  FaMapMarkedAlt,
  FaBriefcase,
  FaWhatsapp,
  FaLink,
  FaFacebook,
  FaImages,
  FaVideo,
} from "react-icons/fa";
import "./precios.css";

const WhatsappModal = ({
  isOpen,
  onClose,
  packageData,
  setConsulta: setConsultaModal,
  cotizacionData,
}) => {
  const [nombre, setNombre] = useState("");
  const [apellido, setApellido] = useState("");
  const [correo, setCorreo] = useState("");
  const [telefono, setTelefono] = useState("");
  const [consulta, setConsulta] = useState("");
  if (!isOpen) {
    return null;
  }
  const handleSendMessage = () => {
    let mensaje = `Mensaje por plataforma de ABCupon:
        Combo: "Estoy interesad@ en" ${packageData.title}
        Nombre: ${nombre}
        Apellido: ${apellido}
        Correo: ${correo}
        Teléfono: ${telefono}
        Consulta: ${consulta}
    `;

    // Agrega los datos de cotización si se han proporcionado
    if (
      cotizacionData.cantidadPublicaciones !== undefined &&
      cotizacionData.redesSociales.length > 0 &&
      cotizacionData.cantidadClasificados !== undefined
    ) {
      mensaje += `
        
        Datos de Cotización:
        Cantidad de Publicaciones: ${cotizacionData.cantidadPublicaciones}
        Redes Sociales: ${cotizacionData.redesSociales.join(", ")}
        Cantidad de Clasificados: ${cotizacionData.cantidadClasificados}
        Clasificados con Imagen: ${cotizacionData.conImagen ? "Sí" : "No"}`;
    }

    // const whatsappMessage = encodeURIComponent(mensaje);

    // Número de teléfono del distribuidor (reemplaza con el número real)
    const distributorPhoneNumber = "+50687886767";

    // Construye el enlace de WhatsApp con los parámetros del mensaje
    const whatsappLink = `https://api.whatsapp.com/send?phone=${distributorPhoneNumber}&text=${encodeURIComponent(
      mensaje
    )}`;

    // Abre una nueva ventana o pestaña del navegador con el enlace de WhatsApp
    window.open(whatsappLink, "_blank");

    // Cierra el modal después de enviar el mensaje
    onClose();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleSendMessage();
  };
  const modalCloseStyle = {
    background: "none",
    border: "none",
    fontSize: "1.5rem",
    cursor: "pointer",
    position: "absolute",
    top: "10px",
    right: "10px", // Ajusta según tu preferencia
  };
  // Estilos para el modal de WhatsApp
  const whatsappModalStyle = {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    background: "white",
    borderRadius: "8px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
    width: "90%",
    maxWidth: "600px",
    padding: "20px",
    overflowY: "auto",
    maxHeight: "400px",
  };

  const formStyle = {
    display: "flex",
    flexDirection: "column",
  };

  const inputStyle = {
    marginBottom: "10px",
    padding: "8px",
    borderRadius: "4px",
    border: "1px solid #ddd",
  };

  const sendButtonStyle = {
    background: "#25d366",
    color: "white",
    padding: "10px",
    borderRadius: "4px",
    cursor: "pointer",
  };

  return (
    <div style={whatsappModalStyle}>
      <h2>Contactar por WhatsApp</h2>
      <button style={modalCloseStyle} onClick={onClose}>
        X
      </button>
      <form style={formStyle}>
        <input
          style={inputStyle}
          type="text"
          placeholder="Nombre"
          value={nombre}
          onChange={(e) => setNombre(e.target.value)}
        />
        <input
          style={inputStyle}
          type="text"
          placeholder="Apellido"
          value={apellido}
          onChange={(e) => setApellido(e.target.value)}
        />
        <input
          style={inputStyle}
          type="email"
          placeholder="Correo electrónico"
          value={correo}
          onChange={(e) => setCorreo(e.target.value)}
        />
        <input
          style={inputStyle}
          type="tel"
          placeholder="Teléfono"
          value={telefono}
          onChange={(e) => setTelefono(e.target.value)}
        />
        <textarea
          style={inputStyle}
          placeholder="Consulta"
          value={consulta}
          onChange={(e) => setConsulta(e.target.value)}
        ></textarea>
        <button style={sendButtonStyle} type="button" onClick={handleSubmit}>
          Enviar
        </button>
      </form>
    </div>
  );
};

const TuComponente = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState({});
  const [consulta, setConsulta] = useState("");
  const isSmallScreen = useMediaQuery({ maxWidth: 767 });
  const isMediumScreen = useMediaQuery({ minWidth: 768, maxWidth: 1023 });
  const [cantidadPublicaciones, setCantidadPublicaciones] = useState(1);
  const [redesSociales, setRedesSociales] = useState([]);
  const [cantidadClasificados, setCantidadClasificados] = useState(1);
  const [conImagen, setConImagen] = useState(false);
  // Nuevo estado para el ancho de la pantalla
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  // Calcular el ancho de la pantalla al montar el componente
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    // Agregar un listener para el evento de cambio de tamaño de la ventana
    window.addEventListener("resize", handleResize);

    // Limpiar el listener cuando el componente se desmonte
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const openModal = (packageData) => {
    setConsulta(`Consulta para ${packageData.title}: `);
    setSelectedPackage(packageData);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };
  const [cotizacionData, setCotizacionData] = useState({
    cantidadPublicaciones: 1,
    redesSociales: [],
    cantidadClasificados: 1,
    conImagen: false,
  });
  const handleEnviarCotizacion = () => {
    // Aquí puedes realizar acciones con los datos de cotización antes de enviarlos a WhatsApp
    const cotizacion = {
      cantidadPublicaciones,
      redesSociales,
      cantidadClasificados,
      conImagen,
    };

    // Muestra la cotización en la consola (puedes eliminar esto en producción)
    // console.log("Cotización:", cotizacion);

    // Actualiza el estado cotizacionData
    setCotizacionData(cotizacion);

    // Abre el modal de WhatsApp
    setModalIsOpen(true);
  };

  return (
    <section className="precio">
      <div className="contenedor">
        <h1 className="seccion-titulo">
          <strong>Combos Disponibles</strong>
        </h1>
        <div className="seccion-divisor"></div>
      </div>

      <div className="contenedor-grid contenedor md:flex">
        {isSmallScreen && (
          <div
            className="columnas1-3 md:w-1/3 md:pr-4 precio-caja"
            style={{ width: screenWidth * 0.8 + "px" }}
          >
            <div className="precio-contenido general">
              <h3>Combo #1</h3>
              <h2>
                <strong style={{ color: "red" }}>
                  $0<sup>.Gratis C1</sup>
                </strong>{" "}
                <br />
                <small> Informacion de empresa en pagina web ABCupon</small>
              </h2>
              <ul>
                <li>
                  1- Datos de empresa. <FaClock style={{ color: "black" }} />
                </li>
                <li>
                  2- Nombre - Telefono - correo - ubicacion - giro comercial.{" "}
                  <FaBuilding style={{ color: "black" }} />
                </li>
              </ul>

              <div className="precio-boton">
                {/* Modifica el botón Consultar para abrir el modal */}
                <button
                  className="boton amarillo"
                  onClick={() =>
                    openModal({
                      package: 1,
                      title: "Combo #1",
                      price: "$0.free",
                    })
                  }
                >
                  Consultar
                </button>
              </div>
            </div>
            <br />
            <div className="precio-contenido ofrecido">
              <h3>Combo #2 </h3>
              <div className="flex justify-between">
                <h2>
                  <strong style={{ color: "red" }}>
                    $30<sup>.00</sup>
                    <sup>/mes</sup>
                  </strong>{" "}
                  <br />
                  <small>Extensión de empresas con servicios</small>
                </h2>
                <h2>
                  {" "}
                  <strong style={{ color: "red" }}> C2</strong>
                </h2>
              </div>
              <ul>
                <li>
                  1- Datos de Empresa. <FaClock style={{ color: "black" }} />
                </li>
                <li>
                  2- Nombre - Telefono - correo - ubicacion - giro comercial.{" "}
                  <FaBuilding style={{ color: "black" }} />
                </li>
                <li>
                  3- Servicios, descripcion y precios.{" "}
                  <FaBriefcase style={{ color: "gold" }} />
                </li>
                <li>
                  4- Ingreso de ubicacion en plataforma waze y google maps{" "}
                  <FaMapMarkedAlt style={{ color: "blue" }} />
                </li>
                <li>
                  5- Creacion de Wa Link, enlace del sitio{" "}
                  <FaLink style={{ color: "green" }} />
                </li>
              </ul>

              <div className="precio-boton">
                {/* Modifica el botón Consultar para abrir el modal */}
                <button
                  className="boton amarillo"
                  onClick={() =>
                    openModal({ package: 2, title: "Combo #2", price: "$30" })
                  }
                >
                  Consultar
                </button>
              </div>
            </div>
            <br />
            <div className="precio-contenido">
              <h3>Combo #3</h3>
              <div className="flex justify-between">
                <h2>
                  <strong style={{ color: "red" }}>
                    $123<sup>.00</sup>
                    <sup>/mes</sup>
                  </strong>{" "}
                  <br />
                  <small>Publiembudo ABCupon</small>
                </h2>
                <h2>
                  {" "}
                  <strong style={{ color: "red" }}> C3</strong>
                </h2>
              </div>
              <ul>
                <li>
                  1- Datos de empresa. <FaClock style={{ color: "black" }} />
                </li>
                <li>
                  2- Nombre - Telefono - correo - ubicacion - giro comercial.{" "}
                  <FaBuilding style={{ color: "black" }} />
                </li>
                <li>
                  3- Servicios, descripcion y precios.{" "}
                  <FaBriefcase style={{ color: "gold" }} />
                </li>
                <li>
                  4- Ingreso de ubicacion en plataforma waze y google maps{" "}
                  <FaMapMarkedAlt style={{ color: "blue" }} />
                </li>
                <li>
                  5- Configuracion de Whatsapp Business{" "}
                  <FaWhatsapp style={{ color: "green" }} />
                </li>
                <li>
                  6- Creacion de Wa Link, enlace del sitio{" "}
                  <FaLink style={{ color: "green" }} />
                </li>
                <li>
                  7- Creacion de Fan Page{" "}
                  <FaFacebook style={{ color: "blue" }} />
                </li>
                <li>
                  8- Creacion de dos publicaciones con imagen publicitaria
                  incluida. <FaImages style={{ color: "green" }} />
                </li>
                <li>
                  9- Creacion de un video de publicidad a la semana.{" "}
                  <FaVideo style={{ color: "red" }} />
                </li>
              </ul>

              <div className="precio-boton">
                {/* Modifica el botón Consultar para abrir el modal */}
                <button
                  className="boton amarillo"
                  onClick={() =>
                    openModal({ package: 3, title: "Combo #3", price: "$123" })
                  }
                >
                  Consultar
                </button>
              </div>
            </div>
          </div>
        )}

        {isMediumScreen && (
          <>
            {/* Paquete 1 */}
            <div
              className="columnas1-3 md:w-1/3 md:pr-4 precio-caja"
              style={{ width: screenWidth * 0.4 + "px" }}
            >
              <div className="precio-contenido general">
                <h3>Combo #1</h3>
                <div className="flex justify-between">
                  <h2>
                    <strong style={{ color: "red" }}>
                      $0<sup>.Gratis</sup>
                      <sup></sup>
                    </strong>{" "}
                    <br />
                    <small> Informacion de empresa en pagina web ABCupon</small>
                  </h2>
                  <h2>
                    {" "}
                    <strong style={{ color: "red" }}> C1</strong>
                  </h2>
                </div>
                <ul>
                  <li>
                    1- Datos de cooperativa.{" "}
                    <FaClock style={{ color: "black" }} />
                  </li>
                  <li>
                    2- Nombre - Telefono - correo - ubicacion - giro comercial.{" "}
                    <FaBuilding style={{ color: "black" }} />
                  </li>
                </ul>

                <div className="precio-boton">
                  {/* Modifica el botón Consultar para abrir el modal */}
                  <button
                    className="boton amarillo"
                    onClick={() =>
                      openModal({
                        package: 1,
                        title: "Combo #1",
                        price: "$0.free",
                      })
                    }
                  >
                    Consultar
                  </button>
                </div>
              </div>
            </div>
            {/* Paquete 2 */}
            <div className="columnas1-3 md:w-1/3 md:pr-4 precio-caja">
              <div className="precio-contenido ofrecido">
                <h3>Combo #2 </h3>
                <div className="flex justify-between">
                  <h2>
                    <strong style={{ color: "red" }}>
                      $30<sup>.00</sup>
                      <sup>/mes</sup>
                    </strong>{" "}
                    <br />
                    <small>Extensión de empresas con servicios</small>
                  </h2>
                  <h2>
                    {" "}
                    <strong style={{ color: "red" }}> C2</strong>
                  </h2>
                </div>
                <ul>
                  <li>
                    1- Datos de Empresa. <FaClock style={{ color: "black" }} />
                  </li>
                  <li>
                    2- Nombre - Telefono - correo - ubicacion - giro comercial.{" "}
                    <FaBuilding style={{ color: "black" }} />
                  </li>
                  <li>
                    3- Servicios, descripcion y precios.{" "}
                    <FaBriefcase style={{ color: "gold" }} />
                  </li>
                  <li>
                    4- Ingreso de ubicacion en plataforma waze y google maps{" "}
                    <FaMapMarkedAlt style={{ color: "blue" }} />
                  </li>
                  <li>
                    5- Creacion de Wa Link, enlace del sitio{" "}
                    <FaLink style={{ color: "green" }} />
                  </li>
                </ul>

                <div className="precio-boton">
                  {/* Modifica el botón Consultar para abrir el modal */}
                  <button
                    className="boton amarillo"
                    onClick={() =>
                      openModal({ package: 2, title: "Combo #2", price: "$30" })
                    }
                  >
                    Consultar
                  </button>
                </div>
              </div>
            </div>

            <div className="precio-contenido">
              <h3>Combo #3</h3>
              <div className="flex justify-between">
                <h2>
                  <strong style={{ color: "red" }}>
                    $123<sup>.00</sup>
                    <sup>/mes</sup>
                  </strong>{" "}
                  <br />
                  <small>Publiembudo ABCupon</small>
                </h2>
                <h2>
                  {" "}
                  <strong style={{ color: "red" }}> C3</strong>
                </h2>
              </div>

              <ul>
                <li>
                  1- Datos de empresa. <FaClock style={{ color: "black" }} />
                </li>
                <li>
                  2- Nombre - Telefono - correo - ubicacion - giro comercial.{" "}
                  <FaBuilding style={{ color: "black" }} />
                </li>
                <li>
                  3- Servicios, descripcion y precios.{" "}
                  <FaBriefcase style={{ color: "gold" }} />
                </li>
                <li>
                  4- Ingreso de ubicacion en plataforma waze y google maps{" "}
                  <FaMapMarkedAlt style={{ color: "blue" }} />
                </li>
                <li>
                  5- Configuracion de Whatsapp Business{" "}
                  <FaWhatsapp style={{ color: "green" }} />
                </li>
                <li>
                  6- Creacion de Wa Link, enlace del sitio{" "}
                  <FaLink style={{ color: "green" }} />
                </li>
                <li>
                  7- Creacion de Fan Page{" "}
                  <FaFacebook style={{ color: "blue" }} />
                </li>
                <li>
                  8- Creacion de dos publicaciones con imagen publicitaria
                  incluida. <FaImages style={{ color: "green" }} />
                </li>
                <li>
                  9- Creacion de un video de publicidad a la semana.{" "}
                  <FaVideo style={{ color: "red" }} />
                </li>
              </ul>

              <div className="precio-boton">
                {/* Modifica el botón Consultar para abrir el modal */}
                <button
                  className="boton amarillo"
                  onClick={() =>
                    openModal({ package: 3, title: "Combo #3", price: "$123" })
                  }
                >
                  Consultar
                </button>
              </div>
            </div>
          </>
        )}
        {!isSmallScreen && !isMediumScreen && (
          <>
            {/* Paquete 1 */}
            <div className="columnas1-3 md:w-1/3 md:pr-4 precio-caja">
              <div className="precio-contenido general">
                <h3>Combo #1</h3>
                <div className="flex justify-between">
                  <h2>
                    <strong style={{ color: "red" }}>
                      $0<sup>.Gratis</sup>
                      <sup></sup>
                    </strong>{" "}
                    <br />
                    <small> Informacion de empresa en pagina web ABCupon</small>
                  </h2>
                  <h2>
                    {" "}
                    <strong style={{ color: "red" }}> C1</strong>
                  </h2>
                </div>
                <ul>
                  <li>
                    1- Datos de empresa. <FaClock style={{ color: "black" }} />
                  </li>
                  <li>
                    2- Nombre - Telefono - correo - ubicacion - giro comercial.{" "}
                    <FaBuilding style={{ color: "black" }} />
                  </li>
                </ul>

                <div className="precio-boton">
                  {/* Modifica el botón Consultar para abrir el modal */}
                  <button
                    className="boton amarillo"
                    onClick={() =>
                      openModal({
                        package: 1,
                        title: "Combo #1",
                        price: "$0.free",
                      })
                    }
                  >
                    Consultar
                  </button>
                </div>
              </div>
            </div>

            {/* Paquete 2 */}
            <div className="columnas1-3 md:w-1/3 md:pr-4 precio-caja">
              <div className="precio-contenido ofrecido">
                <h3>Combo #2 </h3>
                <div className="flex justify-between">
                  <h2>
                    <strong style={{ color: "red" }}>
                      $30<sup>.00</sup>
                      <sup>/mes</sup>
                    </strong>{" "}
                    <br />
                    <small>Extensión de empresas con servicios</small>
                  </h2>
                  <h2>
                    {" "}
                    <strong style={{ color: "red" }}> C2</strong>
                  </h2>
                </div>
                <ul>
                  <li>
                    1- Datos de Empresa. <FaClock style={{ color: "black" }} />
                  </li>
                  <li>
                    2- Nombre - Telefono - correo - ubicacion - giro comercial.{" "}
                    <FaBuilding style={{ color: "black" }} />
                  </li>
                  <li>
                    3- Servicios, descripcion y precios.{" "}
                    <FaBriefcase style={{ color: "gold" }} />
                  </li>
                  <li>
                    4- Ingreso de ubicacion en plataforma waze y google maps{" "}
                    <FaMapMarkedAlt style={{ color: "blue" }} />
                  </li>
                  <li>
                    5- Creacion de Wa Link, enlace del sitio{" "}
                    <FaLink style={{ color: "green" }} />
                  </li>
                </ul>

                <div className="precio-boton">
                  {/* Modifica el botón Consultar para abrir el modal */}
                  <button
                    className="boton amarillo"
                    onClick={() =>
                      openModal({ package: 2, title: "Combo #2", price: "$30" })
                    }
                  >
                    Consultar
                  </button>
                </div>
              </div>
            </div>

            {/* Paquete 3 */}
            <div className="columnas1-3 md:w-1/3 precio-caja">
              <div className="precio-contenido">
                <h3>Combo #3</h3>
                <div className="flex justify-between">
                  <h2>
                    <strong style={{ color: "red" }}>
                      $123<sup>.00</sup>
                      <sup>/mes</sup>
                    </strong>{" "}
                    <br />
                    <small>Publiembudo ABCupon.com</small>
                  </h2>
                  <h2>
                    {" "}
                    <strong style={{ color: "red" }}> C3</strong>
                  </h2>
                </div>

                <ul>
                  <li>
                    1- Datos de empresa. <FaClock style={{ color: "black" }} />
                  </li>
                  <li>
                    2- Nombre - Telefono - correo - ubicacion - giro comercial.{" "}
                    <FaBuilding style={{ color: "black" }} />
                  </li>
                  <li>
                    3- Servicios, descripcion y precios.{" "}
                    <FaBriefcase style={{ color: "gold" }} />
                  </li>
                  <li>
                    4- Ingreso de ubicacion en plataforma waze y google maps{" "}
                    <FaMapMarkedAlt style={{ color: "blue" }} />
                  </li>
                  <li>
                    5- Configuracion de Whatsapp Business{" "}
                    <FaWhatsapp style={{ color: "green" }} />
                  </li>
                  <li>
                    6- Creacion de Wa Link, enlace del sitio{" "}
                    <FaLink style={{ color: "green" }} />
                  </li>
                  <li>
                    7- Creacion de Fan Page{" "}
                    <FaFacebook style={{ color: "blue" }} />
                  </li>
                  <li>
                    8- Creacion de dos publicaciones con imagen publicitaria
                    incluida. <FaImages style={{ color: "green" }} />
                  </li>
                  <li>
                    9- Creacion de un video de publicidad a la semana.{" "}
                    <FaVideo style={{ color: "red" }} />
                  </li>
                </ul>

                <div className="precio-boton">
                  {/* Modifica el botón Consultar para abrir el modal */}
                  <button
                    className="boton amarillo"
                    onClick={() =>
                      openModal({
                        package: 3,
                        title: "Combo #3",
                        price: "$123",
                      })
                    }
                  >
                    Consultar
                  </button>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <WhatsappModal
        isOpen={modalIsOpen}
        onClose={closeModal}
        packageData={selectedPackage}
        setConsulta={setConsulta}
        cotizacionData={cotizacionData}
      />
      <div
        style={{
          backgroundColor: "#ffffff",
          borderRadius: "10px",
          boxShadow: "0 0 10px rgba(255, 0, 0, 0.5)",
          padding: "20px",
          maxWidth: "600px" /* Ajusta el ancho según tus necesidades */,
          margin: "0 auto",
        }}
        className="formulario-cotizacion"
      >
        <h2>Cotizador</h2>
        <br />
        <br />
        <br />
        <form>
          <div style={{ marginBottom: "15px" }} className="formulario-item">
            <label>
              Cantidad de Publicaciones en redes sociales:
              <input
                type="number"
                value={cantidadPublicaciones}
                onChange={(e) => setCantidadPublicaciones(e.target.value)}
              />
            </label>
          </div>

          <div style={{ marginBottom: "15px" }} className="formulario-item">
            <label>
              Redes Sociales:
              <select
                multiple
                value={redesSociales}
                onChange={(e) =>
                  setRedesSociales(
                    Array.from(
                      e.target.selectedOptions,
                      (option) => option.value
                    )
                  )
                }
              >
                <option value="facebook">Facebook</option>
                <option value="instagram">Instagram</option>
                <option value="tiktok">TikTok</option>
                <option value="tienda-en-linea">Tienda en línea</option>
              </select>
            </label>
          </div>

          <div style={{ marginBottom: "15px" }} className="formulario-item">
            <label>
              Cantidad de Clasificados:
              <input
                type="number"
                value={cantidadClasificados}
                onChange={(e) => setCantidadClasificados(e.target.value)}
              />
            </label>
          </div>

          <div style={{ marginBottom: "15px" }} className="formulario-item">
            <label>
              Clasificados con Imagen:
              <input
                type="checkbox"
                checked={conImagen}
                onChange={(e) => setConImagen(e.target.checked)}
              />
              Con Imagen
            </label>
          </div>

          <div className="formulario-item">
            <button
              style={{
                backgroundColor: "#007bff",
                color: "#fff",
                padding: "10px 15px",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
              }}
              type="button"
              onClick={handleEnviarCotizacion}
            >
              Enviar al Vendedor
            </button>
          </div>
        </form>
      </div>
    </section>
  );
};

export default TuComponente;
