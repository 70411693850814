import React from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

function ModalProducto(props) {
  const {
    showModal,
    handleCloseModal,
    newProductData,
    setNewProductData,
    handleImageChange,
    handleCreateOrEditProduct,
    handleCategoryChange,
    handleSubcategoryChange
  } = props;

  return (
    <Modal show={showModal} onHide={handleCloseModal} style={{ overflowX: 'auto', overflowY: 'auto' }}>
      <Modal.Header closeButton>
        <Modal.Title>Crear Casas, Lotes y Boncre</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <button

style={{
    background: "red",
    color: "white",
    padding: "8px",
    borderRadius: "5px",
    border: "none",
    cursor: "pointer",
    position: "absolute",
    top: "4px",
    right: "4px",
}}
onClick={handleCloseModal}
>
X
</button>
        <Form>
          {/* Agregar aquí los campos del formulario */}
          {/* Ejemplo para el campo 'Nombre' */}
          <Form.Group controlId="formName">
            <Form.Label>Nombre</Form.Label>
            <Form.Control
              type="text"
              placeholder="Ingrese el nombre"
              value={newProductData.name}
              onChange={(e) =>
                setNewProductData({ ...newProductData, name: e.target.value })
              }
            />
          </Form.Group>


                        <Form.Group controlId="formBrand">
                            <Form.Label>Marca</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Ingrese la marca"
                                value={newProductData.brand}
                                onChange={(e) => setNewProductData({ ...newProductData, brand: e.target.value })}
                            />
                        </Form.Group>

                        <Form.Group controlId="formCategory">
            <Form.Label>Categoría</Form.Label>
            <Form.Select
                value={newProductData.category}
                onChange={handleCategoryChange}
            >
                <option value="">Seleccione una categoría</option>
                                <optgroup label="PROVINCIAS">
                                    <option value="San Jose">San José</option>
                                    <option value="Alajuela">Alajuela</option>
                                    <option value="Heredia">Heredia</option>
                                    <option value="Cartago">Cartago</option>
                                    <option value="Guanacaste">Guanacaste</option>
                                    <option value="Puntarenas">Puntarenas</option>
                                    <option value="Limón">Limón</option>
                                </optgroup>
                                <optgroup label="PROPIEDADES">
                                    <option value="Venta de casas">Venta de casas</option>
                                    <option value="Alquiler de casa">Alquiler de casa</option>
                                    <option value="Venta de Lotes">Venta de Lotes</option>
                                    <option value="Alquiler de Terreno">Alquiler de Terreno</option>
                                    <option value="Bono Credito">Bono Credito</option>

                                </optgroup>
                               
                            </Form.Select>


                        </Form.Group>
                        <Form.Group controlId="formSubcategory">
            <Form.Label>Subcategoría</Form.Label>
            <Form.Select
                multiple  // Habilita la selección múltiple
                value={newProductData.subcategory}
                onChange={handleSubcategoryChange} 
                style={{ height: '50vh'}}
            >
                                <option value="">Seleccione una Subcategoría</option>
                                <optgroup label="PROVINCIA DE SAN JOSE">
                                    <option value="San José">San José</option>
                                    <option value="Escazú">Escazú</option>
                                    <option value="Desamparados">Desamparados</option>
                                    <option value="Puriscal">Puriscal</option>
                                    <option value="Tarrazú">Tarrazú</option>
                                    <option value="Aserrí">Aserrí</option>
                                    <option value="Mora">Mora</option>
                                    <option value="Goicoechea">Goicoechea</option>
                                    <option value="Santa Ana">Santa Ana</option>
                                    <option value="Alajuelita">Alajuelita</option>
                                    <option value="Vázquez de Coronado">Vázquez de Coronado</option>
                                    <option value="Acosta">Acosta</option>
                                    <option value="Tibás">Tibás</option>
                                    <option value="Moravia">Moravia</option>
                                    <option value="Montes de Oca">Montes de Oca</option>
                                    <option value="Turrubares">Turrubares</option>
                                    <option value="Dota">Dota</option>
                                    <option value="Curridabat">Curridabat</option>
                                    <option value="Pérez Zeledón">Pérez Zeledón</option>
                                    <option value="León Cortés Castro">León Cortés Castro</option>
                                    
                                </optgroup>
                                <optgroup label="PROVINCIA DE ALAJUELA">
                                   
                                   <option value="Alajuela">Alajuela</option>
                                   <option value="San Ramón">San Ramón</option>
                                   <option value="Grecia">Grecia</option>
                                   <option value="San Mateo">San Mateo</option>
                                   <option value="Atenas">Atenas</option>
                                   <option value="Naranjo">Naranjo</option>
                                   <option value="Palmares">Palmares</option>
                                   <option value="Poas">Poás</option>
                                   <option value="Orotina">Orotina</option>
                                   <option value="San Carlos">San Carlos</option>
                                   <option value="Zarcero">Zarcero</option>
                                   <option value="Valverde Vega">Valverde Vega</option>
                                   <option value="Upala">Upala</option>
                                   <option value="Los Chiles">Los Chiles</option>
                                   <option value="Guatuso">Guatuso</option>
                                   <option value="Río Cuarto">Río Cuarto</option>
                           </optgroup>
                           <optgroup label="PROVINCIA DE HEREDIA">
                               <option value="Heredia">Heredia</option>
                               <option value="Barva">Barva</option>
                               <option value="Santo Domingo">Santo Domingo</option>
                               <option value="Santa Bárbara">Santa Bárbara</option>
                               <option value="San Rafael">San Rafael</option>
                               <option value="San Isidro">San Isidro</option>
                               <option value="Belén">Belén</option>
                               <option value="Flores">Flores</option>
                               <option value="San Pablos">San Pablo</option>
                               <option value="Sarapiqui">Sarapiquí</option>

                           </optgroup>
                           <optgroup label="PROVINCIA DE CARTAGO">
                               <option value="Cartago">Cartago</option>
                               <option value="Paraiso">Paraíso</option>
                               <option value="La Unión">La Unión</option>
                               <option value="Jiménez">Jiménez</option>
                               <option value="Turrialba">Turrialba</option>
                               <option value="Alvarado">Alvarado</option>
                               <option value="Oreamuno">Oreamuno</option>
                               <option value="El Guarco">El Guarco</option>
                           </optgroup>
                           <optgroup label="PROVINCIA DE GUANACASTE">
                               <option value="Liberia">Liberia</option>
                               <option value="Nicoya">Nicoya</option>
                               <option value="Santa Cruz">Santa Cruz</option>
                               <option value="Bagaces">Bagaces</option>
                               <option value="Carrillo">Carrillo</option>
                               <option value="Cañas">Cañas</option>
                               <option value="Abangares">Abangares</option>
                               <option value="Tilaran">Tilarán</option>
                               <option value="Nandayure">Nandayure</option>
                               <option value="La Cruz">La Cruz</option>
                               <option value="Hojancha">Hojancha</option>
                           </optgroup>
                           <optgroup label="PROVINCIA DE PUNTARENAS">
                               <option value="Puntarenas">Puntarenas</option>
                               <option value="Esparza">Esparza</option>
                               <option value="Buenos Aires">Buenos Aires</option>
                               <option value="Montes de Oro">Montes de Oro</option>
                               <option value="Osa">Osa</option>
                               <option value="Golfito">Golfito</option>
                               <option value="Coto Brus">Coto Brus</option>
                               <option value="Parrita">Parrita</option>
                               <option value="Corredores">Corredores</option>
                               <option value="Garabito">Garabito</option>
                           </optgroup>
                           <optgroup label="PROVINCIA DE LIMON">
                               <option value="Limón">Limón</option>
                               <option value="Pococí">Pococí</option>
                               <option value="Siquirres">Siquirres</option>
                               <option value="Talamanca">Talamanca</option>
                               <option value="Matina">Matina</option>
                               <option value="Guácimo">Guácimo</option>
                           </optgroup>

                       
                            </Form.Select>
                        </Form.Group>




                        <Form.Group controlId="formDescription">
                            <Form.Label>Descripción</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                placeholder="Ingrese la descripción"
                                value={newProductData.description}
                                onChange={(e) => setNewProductData({ ...newProductData, description: e.target.value })}
                            />
                        </Form.Group>

                        <Form.Group controlId="formPrice">
                            <Form.Label>Precio</Form.Label>
                            <Form.Control
                                type="number"
                                placeholder="Ingrese el precio"
                                value={newProductData.price}
                                onChange={(e) => setNewProductData({ ...newProductData, price: e.target.value })}
                            />
                        </Form.Group>

                        <Form.Group controlId="formDiscount">
                            <Form.Label>Descuento</Form.Label>
                            <Form.Control
                                type="number"
                                placeholder="Ingrese el descuento"
                                value={newProductData.discount}
                                onChange={(e) => setNewProductData({ ...newProductData, discount: e.target.value })}
                            />
                        </Form.Group>

                        <Form.Group controlId="formCountInStock">
                            <Form.Label>Cantidad en Stock</Form.Label>
                            <Form.Control
                                type="number"
                                placeholder="Ingrese la cantidad en stock"
                                value={newProductData.countInStock}
                                onChange={(e) => setNewProductData({ ...newProductData, countInStock: e.target.value })}
                            />
                        </Form.Group>

                        <Form.Group controlId="formImage">
                            <Form.Label>Imagen</Form.Label>
                            <Form.Control type="file" onChange={handleImageChange} />
                        </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseModal}>
          Cerrar
        </Button>
        <Button variant="primary" onClick={handleCreateOrEditProduct}>
          Guardar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalProducto;
