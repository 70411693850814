import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Container, Table, Form, Button, InputGroup, Modal, FormControl } from 'react-bootstrap';
import EmployeeDataService from '../../services/employee';
import './TablaDatos.css';

const EmployeesVacations = () => {
  const [vacaciones, setVacaciones] = useState([]);
  const [selectedVacations, setSelectedVacations] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [newVacationData, setNewVacationData] = useState({
    empleado: '',
    fecha_inicio: '',
    fecha_fin: '',
    dias_disfrutados: ''
  });

  const token = useSelector(state => state.authentication.token);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const fetchData = async () => {
    try {
      const responseVacaciones = await EmployeeDataService.getAllVacaciones(token);
      setVacaciones(responseVacaciones.data);
    } catch (error) {
      console.error('Error al obtener datos:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [token]);

  const handleCreateVacacion = async () => {
    try {
      await EmployeeDataService.createVacacion(newVacationData, token);
      toggleModal();
      fetchData();
    } catch (error) {
      console.error('Error al crear vacaciones:', error);
    }
  };

  const handleDeleteSelected = async () => {
    try {
      await EmployeeDataService.deleteVacacion(selectedVacations, token);
      setSelectedVacations([]);
      fetchData();
    } catch (error) {
      console.error('Error al eliminar vacaciones:', error);
    }
  };

  const toggleSelectVacation = (vacationId) => {
    if (selectedVacations.includes(vacationId)) {
      setSelectedVacations(selectedVacations.filter(id => id !== vacationId));
    } else {
      setSelectedVacations([...selectedVacations, vacationId]);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setNewVacationData({
      ...newVacationData,
      [name]: value
    });
  };

  return (
    <Container>
      <div className="tabla-datos-container">
        <br /><br /><br />
        <h2>Vacaciones de Empleados</h2>
        <Button variant="primary" onClick={toggleModal}>Crear Vacaciones</Button>

        {/* Modal */}
        <Modal show={showModal} onHide={toggleModal} style={{ overflowY: 'auto' }}>
          <Modal.Header closeButton>
            <Modal.Title>Crear Nueva Vacación</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="formEmpleado">
                <Form.Label>Empleado</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="ID del empleado"
                  name="empleado"
                  value={newVacationData.empleado}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group controlId="formFechaInicio">
                <Form.Label>Fecha de Inicio</Form.Label>
                <Form.Control
                  type="date"
                  name="fecha_inicio"
                  value={newVacationData.fecha_inicio}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group controlId="formFechaFin">
                <Form.Label>Fecha de Fin</Form.Label>
                <Form.Control
                  type="date"
                  name="fecha_fin"
                  value={newVacationData.fecha_fin}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group controlId="formDiasDisfrutados">
                <Form.Label>Días Disfrutados</Form.Label>
                <Form.Control
                  type="number"
                  name="dias_disfrutados"
                  value={newVacationData.dias_disfrutados}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={toggleModal}>
              Cancelar
            </Button>
            <Button variant="primary" onClick={handleCreateVacacion}>
              Crear Vacación
            </Button>
          </Modal.Footer>
        </Modal>

        {vacaciones.length > 0 ? (
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Check</th>
                <th>ID</th>
                <th>Empleado</th>
                <th>Fecha Inicio</th>
                <th>Fecha Fin</th>
                <th>Días Disfrutados</th>
              </tr>
            </thead>
            <tbody>
              {vacaciones.map(vacation => (
                <tr key={vacation.id}>
                  <td>
                    <Form.Check
                      type="checkbox"
                      checked={selectedVacations.includes(vacation.id)}
                      onChange={() => toggleSelectVacation(vacation.id)}
                    />
                  </td>
                  <td>{vacation.id}</td>
                  <td>{vacation.employeeName}</td>
                  <td>{vacation.fecha_inicio}</td>
                  <td>{vacation.fecha_fin}</td>
                  <td>{vacation.dias_disfrutados}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <p>No hay datos de vacaciones disponibles.</p>
        )}

        {selectedVacations.length > 0 && (
          <InputGroup className="mb-3">
            <Button variant="danger" onClick={handleDeleteSelected}>
              Eliminar seleccionado
            </Button>
          </InputGroup>
        )}
      </div>
    </Container>
  );
};

export default EmployeesVacations;
