import React, { useState } from "react";
import Prestamos from "./cotizadores/Cotizador_prestamo";
import Traspasos_autos from "./cotizadores/Traspasos_autos";
import Traspasos_bienes from "./cotizadores/Traspaso_bienes";
import Levantamiento_prendas from "./cotizadores/levantamiento_prendas";
import Levantamiento_hipoteca from "./cotizadores/levantamiento_hipoteca";
import Servicios_Notariales from "./cotizadores/servicios_notariales";
import Inscripcion_prenda from "./cotizadores/inscripcion_prendas";
import Inscripcion_hipoteca from "./cotizadores/inscripcion_hipoteca";
import Asuntos from "./cotizadores/asuntos";
import logo from "../../assets/logo_abcupon_3.jpg"
import logo1 from "../../assets/fondo.png"


const Cotizador = () => {
  const [prestamosOpen, setPrestamosOpen] = useState(false);
  const [traspasosAutosOpen, setTraspasosAutosOpen] = useState(false);
  const [traspasosBienesOpen, setTraspasosBienesOpen] = useState(false);
  const [levantamientoPrendasOpen, setLevantamientoPrendasOpen] = useState(false);
  const [levantamientoHipotecaOpen, setLevantamientoHipotecaOpen] = useState(false);
  const [serviciosNotarialesOpen, setServiciosNotarialesOpen] = useState(false);
  const [inscripcionPrendaOpen, setInscripcionPrendaOpen] = useState(false);
  const [inscripcionHipotecaOpen, setInscripcionHipotecaOpen] = useState(false);
  const [asuntoOpen, setAsuntoOpen] = useState(false);

  const closeAllSections = () => {
    setPrestamosOpen(false);
    setTraspasosAutosOpen(false);
    setTraspasosBienesOpen(false);
    setLevantamientoPrendasOpen(false);
    setLevantamientoHipotecaOpen(false);
    setServiciosNotarialesOpen(false);
    setInscripcionPrendaOpen(false);
    setInscripcionHipotecaOpen(false);
    setAsuntoOpen(false);
  };

  const toggleSection = (section) => {
    closeAllSections(); // Cerrar todas las secciones antes de abrir la seleccionada

    switch (section) {
      case "prestamos":
        setPrestamosOpen(!prestamosOpen);
        break;
      case "traspasosAutos":
        setTraspasosAutosOpen(!traspasosAutosOpen);
        break;
      case "traspasosBienes":
        setTraspasosBienesOpen(!traspasosBienesOpen);
        break;
      case "levantamientoPrendas":
        setLevantamientoPrendasOpen(!levantamientoPrendasOpen);
        break;
      case "levantamientoHipoteca":
        setLevantamientoHipotecaOpen(!levantamientoHipotecaOpen);
        break;
      case "serviciosNotariales":
        setServiciosNotarialesOpen(!serviciosNotarialesOpen);
        break;
      case "inscripcionPrenda":
        setInscripcionPrendaOpen(!inscripcionPrendaOpen);
        break;
      case "inscripcionHipoteca":
        setInscripcionHipotecaOpen(!inscripcionHipotecaOpen);
        break;
      case "asunto":
        setAsuntoOpen(!asuntoOpen);
        break;
      default:
        break;
    }
  };

  const renderButton = (section, label) => (
    <button
      style={{
        color: "white",
        backgroundColor: "red",
        fontWeight: "bold",
        margin: "5px",
        padding: "5px",
      }}
      onClick={() => toggleSection(section)}
    >
      {label}
    </button>
  );

  const isAnySectionOpen =
    prestamosOpen ||
    traspasosAutosOpen ||
    traspasosBienesOpen ||
    levantamientoPrendasOpen ||
    levantamientoHipotecaOpen ||
    serviciosNotarialesOpen ||
    inscripcionPrendaOpen ||
    inscripcionHipotecaOpen ||
    asuntoOpen;
    
  return (
    <div>
      <br />
      <br />
      <br />
      <br />
      <br />
     <img src={logo} alt="Logo" />
      <h2
        style={{
          color: "red",
          fontWeight: "bold",
          textShadow: "2px 2px 4px #000", // Sombra roja
          textTransform: "none",
        }}
      >
        {" "}
        Calculadora por modelos
      </h2>
      <p style={{
          fontWeight: "bold",
          textTransform: "none",
          fontSize: "1.5rem",
        }}>
          Advertencia: </p>
      <p  style={{

          textTransform: "none",
          fontSize: "1.2rem",
        }}>
       Los valores mostrados son aproximados, para un cálculo personalizado escríbanos a nuestro whatsapp 87886767 y con gusto le atenderemos.
      </p>

      <div style={{ marginTop: "4%" }}>
        {renderButton(
          "prestamos",
          prestamosOpen ? "Cerrar Prestamos" : "Abrir Prestamos"
        )}

        {renderButton(
          "traspasosAutos",
          traspasosAutosOpen
            ? "Cerrar Traspasos Autos"
            : "Abrir Traspasos Autos"
        )}

        {renderButton(
          "traspasosBienes",
          traspasosBienesOpen
            ? "Cerrar Traspasos Bienes"
            : "Abrir Traspasos Bienes"
        )}

        {renderButton(
          "levantamientoPrendas",
          levantamientoPrendasOpen
            ? "Cerrar Levantamiento Prendas"
            : "Abrir Levantamiento Prendas"
        )}

        {renderButton(
          "levantamientoHipoteca",
          levantamientoHipotecaOpen
            ? "Cerrar Levantamiento Hipotecas"
            : "Abrir Levantamiento Hipotecas"
        )}

        {renderButton(
          "serviciosNotariales",
          serviciosNotarialesOpen
            ? "Cerrar Servicios Notariales"
            : "Abrir Servicios Notariales"
        )}

        {renderButton(
          "inscripcionPrenda",
          inscripcionPrendaOpen
            ? "Cerrar Inscripción Prenda"
            : "Abrir Inscripción Prenda"
        )}

        {renderButton(
          "inscripcionHipoteca",
          inscripcionHipotecaOpen
            ? "Cerrar Inscripción Hipoteca"
            : "Abrir Inscripción Hipoteca"
        )}

        {renderButton("asunto", asuntoOpen ? "Cerrar Asunto" : "Abrir Asunto")}

        {prestamosOpen && <Prestamos />}
        {traspasosBienesOpen && <Traspasos_bienes />}
        {traspasosAutosOpen && <Traspasos_autos />}
        {levantamientoPrendasOpen && <Levantamiento_prendas />}
        {levantamientoHipotecaOpen && <Levantamiento_hipoteca />}
        {serviciosNotarialesOpen && <Servicios_Notariales />}
        {inscripcionPrendaOpen && <Inscripcion_prenda />}
        {inscripcionHipotecaOpen && <Inscripcion_hipoteca />}
        {asuntoOpen && <Asuntos />}
        <div
        style={{
          textAlign: "center",
          margin: "auto",
          width: "50%",
          position: "relative",
        }}
      >
        {isAnySectionOpen ? null : (
          <img
            src={logo1}
            alt="Logo"
            style={{
              border: "2px solid red",
              borderRadius: "10px",
              boxShadow: "2px 2px 4px #000",
            }}
          />
        )}
      </div>
      </div>
    </div>
  );
};

export default Cotizador;
