import React, { useState, useEffect } from 'react';
import { Container, Table, Form, Button, InputGroup, Modal, FormControl, FormGroup, FormLabel } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import EmployeeDataService from '../../services/employee';
import './TablaDatos.css';

const EmployeesExtras = () => {
  const [extras, setExtras] = useState([]);
  const [selectedExtras, setSelectedExtras] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [newExtra, setNewExtra] = useState({
    planilla: 1, // Replace with the actual planilla ID
    nombre_extra: '',
    salario_mensual: 0,
    salario_diario: 0,
    salario_hora: 0,
    monto_extra: 0,
  });

  const token = useSelector(state => state.authentication.token);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const fetchData = async () => {
    try {
      const responseExtras = await EmployeeDataService.getAllExtras(token);
      setExtras(responseExtras.data);
    } catch (error) {
      console.error('Error al obtener datos:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [token]);

  const handleDeleteSelected = async () => {
    try {
      await EmployeeDataService.deleteExtras(selectedExtras, token);
      setSelectedExtras([]);
      fetchData();
    } catch (error) {
      console.error('Error al eliminar extras:', error);
    }
  };

  const toggleSelectExtra = (extraId) => {
    if (selectedExtras.includes(extraId)) {
      setSelectedExtras(selectedExtras.filter(id => id !== extraId));
    } else {
      setSelectedExtras([...selectedExtras, extraId]);
    }
  };

  const handleCreateExtra = async () => {
    try {
      await EmployeeDataService.createExtras(newExtra, token);
      toggleModal();
      fetchData();
    } catch (error) {
      console.error('Error al crear extra:', error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewExtra({ ...newExtra, [name]: value });
  };

  return (
    <Container>
      <div className="tabla-datos-container">
        <br/><br/><br/>
        <h2>Extras de Empleados</h2>
        <Button variant="primary" onClick={toggleModal}>Agregar Extra</Button>

        {/* Modal */}
        <Modal show={showModal} onHide={toggleModal} style={{ overflowY: 'auto' }}>
          <Modal.Header closeButton>
            <Modal.Title>Agregar Extra</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormGroup>
              <FormLabel>Nombre Extra</FormLabel>
              <FormControl
                type="text"
                name="nombre_extra"
                value={newExtra.nombre_extra}
                onChange={handleInputChange}
              />
            </FormGroup>
            <FormGroup>
              <FormLabel>Salario Mensual</FormLabel>
              <FormControl
                type="number"
                name="salario_mensual"
                value={newExtra.salario_mensual}
                onChange={handleInputChange}
              />
            </FormGroup>
            <FormGroup>
              <FormLabel>Salario Diario</FormLabel>
              <FormControl
                type="number"
                name="salario_diario"
                value={newExtra.salario_diario}
                onChange={handleInputChange}
              />
            </FormGroup>
            <FormGroup>
              <FormLabel>Salario Hora</FormLabel>
              <FormControl
                type="number"
                name="salario_hora"
                value={newExtra.salario_hora}
                onChange={handleInputChange}
              />
            </FormGroup>
            <FormGroup>
              <FormLabel>Monto Extra</FormLabel>
              <FormControl
                type="number"
                name="monto_extra"
                value={newExtra.monto_extra}
                onChange={handleInputChange}
              />
            </FormGroup>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={toggleModal}>
              Cancelar
            </Button>
            <Button variant="primary" onClick={handleCreateExtra}>
              Guardar
            </Button>
          </Modal.Footer>
        </Modal>

        <div style={{ overflowX: 'auto' }}>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Check</th>
                <th>ID</th>
                <th>Planillas</th>
                <th>Nombre Extra</th>
                <th>Salario Mensual</th>
                <th>Salario Diario</th>
                <th>Salario Hora</th>
                <th>Monto Extra</th>
                {/* ... Agregar las demás columnas según tus requerimientos ... */}
              </tr>
            </thead>
            <tbody>
              {extras.map(extra => (
                <tr key={extra.id}>
                  <td>
                    <Form.Check
                      type="checkbox"
                      checked={selectedExtras.includes(extra.id)}
                      onChange={() => toggleSelectExtra(extra.id)}
                    />
                  </td>
                  <td>{extra.id}</td>
                  <td>{extra.planillas}</td>
                  <td>{extra.nombre_extra}</td>
                  <td>{extra.salario_mensual}</td>
                  <td>{extra.salario_diario}</td>
                  <td>{extra.salario_hora}</td>
                  <td>{extra.monto_extra}</td>
                  {/* ... Agregar las demás columnas según tus requerimientos ... */}
                </tr>
              ))}
            </tbody>
          </Table>
        </div>

        {selectedExtras.length > 0 && (
          <InputGroup className="mb-3">
            <Button variant="danger" onClick={handleDeleteSelected}>
              Eliminar seleccionado
            </Button>
          </InputGroup>
        )}
      </div>
    </Container>
  );
};

export default EmployeesExtras;
