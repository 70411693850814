import React, { useState, useEffect, useRef } from 'react';
import Footer from '../../components/navigation/Footer';
import Navbar from '../../components/navigation/Navbar';
import { Link } from 'react-router-dom';

import axios from 'axios';


function Contact() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [company, setCompany] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [recipients, setRecipients] = useState([]);
  const [attachment, setAttachment] = useState(null); 
  const [validCaptcha, setValidCaptcha] = useState(false);
  const [captcha, setCaptcha] = useState('');
  const contactFormRef = useRef(null); 

  const generateRandomCaptcha = () => {
    // Genera un captcha aleatorio con números y letras
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let captcha = '';
    for (let i = 0; i < 6; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      captcha += characters.charAt(randomIndex);
    }
    return captcha;
  };
  const generateCaptcha = () => {
    // Lógica para generar un captcha aleatorio
    const randomCaptcha = generateRandomCaptcha();
    setCaptcha(randomCaptcha);
    setValidCaptcha(false);
  };
  useEffect(() => {
    generateCaptcha();
  }, []);

  const handleCaptchaChange = (e) => {
    const inputCaptcha = e.target.value;
    if (inputCaptcha === captcha) {
      setValidCaptcha(true);
    } else {
      setValidCaptcha(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!validCaptcha) {
      alert("Please enter the correct captcha.");
      return;
    }
    const fullMessage = `Name: ${name}\nEmail: ${email}\nPhone: ${phone}\nCompany: ${company}\n\n${message}`;

    const formData = new FormData();
    formData.append('subject', "Website TDM send the following message" + subject);
    formData.append('message', fullMessage);
    formData.append('from_email', 'consultas@iriquiqui.com'); // Cambiar por tu correo electrónico
    formData.append('recipient_list', 'jpbcserviciotecnico@gmail.com,iriquiqui31@gmail.com'); // Lista de destinatarios separados por comas
  if (attachment) {
    formData.append('attachments', attachment); // Adjuntar el archivo adjunto
  }
    sendEmail(formData);
  };
  const handleFileChange = (e) => {
    setAttachment(e.target.files[0]);
  };
  const sendEmail = (formData) => {
    axios
      .post('http://localhost:8000/send-email/', formData)
      .then((response) => {
        // console.log(response.data);
        // Agregar cualquier mensaje de éxito o acción que desees aquí
      })
      .catch((error) => {
        console.error(error);
        // Manejar el error de manera apropiada
      });
  };


  return (
    <div>
      <Navbar />
      <div data-scroll-section className="pt-28 ">

        <div className="max-w-lg mx-auto text-center">
     
        <h2
        style={{
          color: "red",
          fontWeight: "bold",
          textShadow: "2px 2px 4px #000", // Sombra roja
          textTransform: "none",
        }}
      >Contacto</h2>
          <p className="text-lg mb-4">
          En ABCupon.com, estamos comprometidos en brindarle una experiencia única al alcance de su mano. Somos una plataforma virtual que reúne una amplia variedad de proveedores de servicios, todos ellos avalados en confiabilidad por nuestros estándares.
          </p>
        </div>
        <div>
          <div className="mt-8 space-y-6">
            <div className="shadow overflow-hidden sm:rounded-md">
              <div className="px-4 py-5 bg-white sm:p-6">
                
              <form id="contact-form" ref={contactFormRef} onSubmit={handleSubmit}>
                <div className="grid grid-cols-2 gap-6">
                  <div className="col-span-2 sm:col-span-1">
                  <label htmlFor="name" className="block text-xl font-bold text-red-700 shadow rounded-md p-2">
                      Nombre
                    </label>
                    <input
                      type="text"
                      name="name"
                      id="name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      className="mt-1 block w-full shadow-sm sm:text-sm border rounded-md"
                      style={{
                        borderColor: '#000',             
                        outline: 'none',                 
                        transition: 'border-color 0.3s' 
                      }}
                      onFocus={(e) => {
                        e.target.style.borderColor = 'blue'; 
                      }}
                      onBlur={(e) => {
                        e.target.style.borderColor = '#000';  
                      }}
                    />

                  </div>
                  <div className="col-span-2 sm:col-span-1">
                    <label htmlFor="email" className="block text-xl font-bold text-red-700 shadow rounded-md p-2">
                      Correo Electronico
                    </label>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      className="mt-1 block w-full shadow-sm sm:text-sm border rounded-md"
                      style={{
                        borderColor: '#000',             // Borde negro permanente
                        outline: 'none',                 // Elimina el contorno al hacer clic
                        transition: 'border-color 0.3s'  // Agrega una transición suave al cambio de color
                      }}
                      onFocus={(e) => {
                        e.target.style.borderColor = 'blue';  // Cambia a azul cuando se enfoca
                      }}
                      onBlur={(e) => {
                        e.target.style.borderColor = '#000';  // Vuelve al borde negro cuando pierde el foco
                      }}
                    />
                  </div>

                  <div className="col-span-2 sm:col-span-1">
                    <label htmlFor="phone" className="block text-xl font-bold text-red-700 shadow rounded-md p-2">
                      Numero de telefono
                    </label>
                    <input
                      type="text"
                      name="phone"
                      id="phone"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      className="mt-1 block w-full shadow-sm sm:text-sm border rounded-md"
                      style={{
                        borderColor: '#000',             
                        outline: 'none',                 
                        transition: 'border-color 0.3s' 
                      }}
                      onFocus={(e) => {
                        e.target.style.borderColor = 'blue'; 
                      }}
                      onBlur={(e) => {
                        e.target.style.borderColor = '#000';  
                      }}
                    />
                  </div>
                  <div className="col-span-2 sm:col-span-1">
                    <label htmlFor="company" className="block text-xl font-bold text-red-700 shadow rounded-md p-2">
                      Empresa
                    </label>
                    <input
                      type="text"
                      name="company"
                      id="company"
                      value={company}
                      onChange={(e) => setCompany(e.target.value)}
                      className="mt-1 block w-full shadow-sm sm:text-sm border rounded-md"
                      style={{
                        borderColor: '#000',             
                        outline: 'none',                 
                        transition: 'border-color 0.3s' 
                      }}
                      onFocus={(e) => {
                        e.target.style.borderColor = 'blue'; 
                      }}
                      onBlur={(e) => {
                        e.target.style.borderColor = '#000';  
                      }}
                    />
                  </div>
                  <div className="col-span-2 sm:col-span-1">
                    <label htmlFor="subject" className="block text-xl font-bold text-red-700 shadow rounded-md p-2">
                      Asunto
                    </label>
                    <input
                      type="text"
                      name="subject"
                      id="subject"
                      value={subject}
                      onChange={(e) => setSubject(e.target.value)}
                      className="mt-1 block w-full shadow-sm sm:text-sm border rounded-md"
                      style={{
                        borderColor: '#000',             
                        outline: 'none',                 
                        transition: 'border-color 0.3s' 
                      }}
                      onFocus={(e) => {
                        e.target.style.borderColor = 'blue'; 
                      }}
                      onBlur={(e) => {
                        e.target.style.borderColor = '#000';  
                      }}
                    />
                  </div>
                  <div className="col-span-2">
                    <label htmlFor="message" className="block text-xl font-bold text-red-700 shadow rounded-md p-2">
                      Mensaje
                    </label>
                    <textarea
                      name="message"
                      id="message"
                      rows={4}
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      className="mt-1 block w-full shadow-sm sm:text-sm border rounded-md"
                      style={{
                        borderColor: '#000',             
                        outline: 'none',                 
                        transition: 'border-color 0.3s' 
                      }}
                      onFocus={(e) => {
                        e.target.style.borderColor = 'blue'; 
                      }}
                      onBlur={(e) => {
                        e.target.style.borderColor = '#000';  
                      }}
                    ></textarea>
                  </div>
                  <div className="col-span-2 sm:col-span-1">
                    <label htmlFor="attachment" className="block text-xl font-bold text-red-700 shadow rounded-md p-2">
                      Archivos adjuntos
                    </label>
                    <input
                      type="file"
                      name="attachment"
                      id="attachment"
                      onChange={handleFileChange}
                      className="mt-1 block w-full shadow-sm sm:text-sm border rounded-md"
                      style={{
                        borderColor: '#000',             
                        outline: 'none',                 
                        transition: 'border-color 0.3s' 
                      }}
                      onFocus={(e) => {
                        e.target.style.borderColor = 'blue'; 
                      }}
                      onBlur={(e) => {
                        e.target.style.borderColor = '#000';  
                      }}
                    />
                  </div>
                 
                  <div className="col-span-2">
                  <div className="mb-4">
                    <div className="flex items-center mb-2">
                      <img src={`https://dummyimage.com/100x40/000000/ffffff&text=${captcha}`} alt="Captcha" className="mr-2" />
                      <button type="button" className="bg-blue-500 text-white px-4 py-2 rounded-md" onClick={generateCaptcha}>
                      Actualizar captcha
                      </button>
                    </div>
                    <input
                      type="text"
                    //   value={captcha}
                      onChange={handleCaptchaChange}
                      className="border border-gray-300 rounded-md p-2 w-full"
                      required
                    />
                    {!validCaptcha && <p className="text-red-500">Por favor ingresa el captcha correcto.</p>}
                    <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded-md" disabled={!validCaptcha}>
                      Comprobar
                    </button>
                  </div>
                    <button
                      onClick={handleSubmit}
                      type="submit"
                      disabled={!validCaptcha}
                      className="inline-flex justify-center py-4 px-8 border border-transparent shadow-sm text-sm font-large rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      Enviar Correo
                    </button>
                  </div>
                </div>
                </form>
              </div>
            </div>
          </div>
        </div>
         <div className="max-w-lg mx-auto mt-8">
         <iframe
            title="Google Maps"
            src="https://www.google.com/maps/embed?pb=!1m17!1m8!1m3!1d3929.824640014565!2d-84.1081463!3d9.948544!3m2!1i1024!2i768!4f13.1!4m6!3e6!4m0!4m3!3m2!1d9.948533399999999!2d-84.10564649999999!5e0!3m2!1ses-419!2scr!4v1707616204816!5m2!1ses-419!2scr"
            width="100%"
            height="400"
            frameBorder="0"
            style={{
              border: '2px solid black',      // Borde negro
              borderRadius: '10px',           // Esquinas redondeadas
              boxShadow: '0px 0px 10px red', // Sombreado negro
            }}
            allowFullScreen=""
            aria-hidden="false"
            tabIndex="0"
          ></iframe>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Contact;
