import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import TodoDataService from '../../services/todos';
import html2canvas from 'html2canvas';
import axios from 'axios';

const EmailForm = () => {
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [recipientList, setRecipientList] = useState('');
  const [attachments, setAttachments] = useState([]);
  const [fontFamily, setFontFamily] = useState('Arial');
  const [fontSize, setFontSize] = useState(16);
  const [fontColor, setFontColor] = useState('#000000');
  const [backgroundColor, setBackgroundColor] = useState('#FFFFFF');
  const [images, setImages] = useState([]);
  const [leads, setLeads] = useState([]);
  const [selectedLeads, setSelectedLeads] = useState([]);
  const token = useSelector(state => state.authentication.token);
  const user = useSelector(state => state.authentication.user);
  const [currentUser, setCurrentUser] = useState(null);
  const [showLeadsModal, setShowLeadsModal] = useState(false);
  const [recipients, setRecipients] = useState([]);
  const [showUploadImagesButton, setShowUploadImagesButton] = useState(true);
  const [attachEmailPreview, setAttachEmailPreview] = useState(false);

  useEffect(() => {
    const currentUserData = localStorage.getItem('currentUser');
    if (currentUserData) {
      try {
        const parsedData = JSON.parse(currentUserData);
        setCurrentUser(parsedData);
      } catch (error) {
        console.error('Error parsing currentUser data:', error);
      }
    }
  }, []);

  const handleToggleLeadsModal = () => {
    if (!showLeadsModal) {
      fetchLeads(token); // Realiza la solicitud al servidor para obtener los leads nuevamente al abrir el modal
    }
    setShowLeadsModal(!showLeadsModal);
  };

  const handleLeadSelection = (lead) => {
    const isSelected = selectedLeads.some((selectedLead) => selectedLead.email === lead.email);
    if (isSelected) {
      setSelectedLeads(selectedLeads.filter((selectedLead) => selectedLead.email !== lead.email));
    } else {
      setSelectedLeads([...selectedLeads, lead]);
    }
  };

  const handleSelectLeads = () => {
    const selectedEmails = selectedLeads.map((lead) => lead.email);
    setRecipients([...recipients, ...selectedEmails]);
    setSelectedLeads([]);
    setShowLeadsModal(false);
  };

  const fetchLeads = async () => {
    try {
      const response = await TodoDataService.getAllLeads(token); // Envía el token en la solicitud al servidor para obtener los leads
      setLeads(response.data); // Actualiza el estado "leads" con los leads obtenidos
    } catch (error) {
      console.error('Error fetching leads:', error);
    }
  };

  useEffect(() => {
    fetchLeads(token);
  }, []);


  const handleFileChange = (e) => {
    const files = e.target.files;
    setAttachments(files);
  };

  const handleFontFamilyChange = (e) => {
    setFontFamily(e.target.value);
  };

  const handleFontSizeChange = (e) => {
    setFontSize(parseInt(e.target.value));
  };

  const handleFontColorChange = (e) => {
    setFontColor(e.target.value);
  };

  const handleBackgroundColorChange = (e) => {
    setBackgroundColor(e.target.value);
  };

  const handleImageUpload = (e) => {
    const files = e.target.files;
    const updatedImages = [...images];

    for (let i = 0; i < files.length; i++) {
      updatedImages.push({
        file: files[i],
        width: 100, // Initial width (adjust as needed)
        height: 100, // Initial height (adjust as needed)
        position: { x: 50, y: 50 }, // Initial position (centered)
      });
    }

    setImages(updatedImages);
  };


  const handleSubmit = (e) => {
    e.preventDefault();
  
    const formData = new FormData();
    formData.append('subject', subject);
    formData.append('message', message);
    formData.append('from_email', 'consultas@iriquiqui.com'); // Replace with your email
    formData.append('recipient_list', recipients.join(','));
  
    for (let i = 0; i < attachments.length; i++) {
      formData.append('attachments', attachments[i]);
    }
  
    // If attachEmailPreview is true, attach the email preview image before sending
    if (attachEmailPreview) {
      // Capturamos el elemento HTML que queremos convertir en imagen
      const previewContainer = document.getElementById('previewContainer');
  
      // Utilizamos la librería html2canvas para crear una imagen del elemento
      html2canvas(previewContainer).then((canvas) => {
        // Convertimos el canvas a una URL de datos (base64)
        const dataURL = canvas.toDataURL('image/jpeg');
  
        // Convert the base64 dataURL to a Blob object
        const blob = dataURLToBlob(dataURL);
  
        // Append the email preview image as an attachment to the formData
        formData.append('attachments', blob);
  
        // Send the email with the email preview attachment
        sendEmail(formData);
      });
    } else {
      // If attachEmailPreview is false, simply send the email without the email preview attachment
      sendEmail(formData);
    }
  };

  // Function to convert a base64 dataURL to a Blob object
const dataURLToBlob = (dataURL) => {
  const parts = dataURL.split(';base64,');
  const contentType = parts[0].split(':')[1];
  const byteString = atob(parts[1]);
  const arrayBuffer = new ArrayBuffer(byteString.length);
  const uint8Array = new Uint8Array(arrayBuffer);

  for (let i = 0; i < byteString.length; i++) {
    uint8Array[i] = byteString.charCodeAt(i);
  }

  return new Blob([arrayBuffer], { type: contentType });
};

  const sendEmail = (formData) => {
    axios
      .post('http://localhost:8000/send-email/', formData)
      .then((response) => {
        console.log(response.data);
        // Add any success message or action you want here
      })
      .catch((error) => {
        console.error(error);
        // Handle the error appropriately
      });
  };

  const handleImageDragOver = (event) => {
    event.preventDefault();
  };

  const handleImageDrop = (event) => {
    event.preventDefault();
    const imageIndex = event.dataTransfer.getData('imageIndex');
    const updatedImages = [...images];
    const x = event.clientX;
    const y = event.clientY;
    const containerRect = event.currentTarget.getBoundingClientRect();
    const imageRect = event.currentTarget.children[imageIndex].getBoundingClientRect();
    const top = y - containerRect.top - imageRect.height / 2;
    const left = x - containerRect.left - imageRect.width / 2;
    const maxTop = containerRect.height - imageRect.height;
    const maxLeft = containerRect.width - imageRect.width;
    updatedImages[imageIndex].position = {
      x: Math.min(Math.max(0, left), maxLeft),
      y: Math.min(Math.max(0, top), maxTop),
    };
    setImages(updatedImages);
  };

  const handleImageDragStart = (event, index) => {
    event.dataTransfer.setData('imageIndex', index);
  };

  const handleImageResize = (event, index) => {
    const updatedImages = [...images];
    updatedImages[index].width = event.target.width;
    updatedImages[index].height = event.target.height;
    setImages(updatedImages);
  };

  const handleImageMove = (index, offsetX, offsetY) => {
    setImages((prevImages) => {
      const updatedImages = [...prevImages];
      const image = updatedImages[index];
      image.position = {
        x: (image.position?.x || 0) + offsetX,
        y: (image.position?.y || 0) + offsetY,
      };
      return updatedImages;
    });
  };

  const handleDeleteImage = (index) => {
    const updatedImages = [...images];
    updatedImages.splice(index, 1);
    setImages(updatedImages);
  };

  const handleDownloadScreenshot = () => {
    // Capturamos el elemento HTML que queremos convertir en imagen
    const previewContainer = document.getElementById('previewContainer');

    // Utilizamos la librería html2canvas para crear una imagen del elemento
    html2canvas(previewContainer).then((canvas) => {
      // Convertimos el canvas a una URL de datos (base64)
      const dataURL = canvas.toDataURL('image/jpeg');

      // Creamos un enlace temporal para descargar la imagen
      const downloadLink = document.createElement('a');
      downloadLink.href = dataURL;
      downloadLink.download = 'email_preview.jpg'; // Nombre del archivo
      downloadLink.click();
    });
  };

  return (
    <div className="bg-white px-4 pt-16 pb-20 sm:px-6 lg:px-8 lg:pt-24 lg:pb-28" style={{ width: '100%', height: '100vh' }}>
      <div className="max-w-screen-xl mx-auto grid grid-cols-2 gap-6">
        <div>
          <h2 className="text-3xl font-extrabold text-gray-900">Email Editor</h2>
          <div className="mt-8 space-y-6">
            <div className="shadow overflow-hidden sm:rounded-md">
              <div className="px-4 py-5 bg-white sm:p-6">
                <div className="grid grid-cols-2 gap-6">
                <div className="col-span-2">
                    <button
                      onClick={handleToggleLeadsModal}
                      type="button"
                      className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      Select Customers
                    </button>
                  </div>
                  <div className="col-span-2 sm:col-span-1">
                    <label htmlFor="recipients" className="block text-sm font-medium text-gray-700">
                      Recipients
                    </label>
                    <input
                      type="text"
                      name="recipients"
                      id="recipients"
                      value={recipients.join(', ')}
                      onChange={(e) => setRecipients(e.target.value.split(',').map(email => email.trim()))}
                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                  <div className="col-span-2 sm:col-span-1">
                    <label htmlFor="subject" className="block text-sm font-medium text-gray-700">
                      Subject
                    </label>
                    <input
                      type="text"
                      name="subject"
                      id="subject"
                      value={subject}
                      onChange={(e) => setSubject(e.target.value)}
                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                  <div className="col-span-2">
                    <label htmlFor="message" className="block text-sm font-medium text-gray-700">
                      Message
                    </label>
                    <textarea
                      name="message"
                      id="message"
                      rows={4}
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    ></textarea>
                  </div>
                  <div className="col-span-2">
                    <label htmlFor="fontFamily" className="block text-sm font-medium text-gray-700">
                      Font Family
                    </label>
                    <select
                      name="fontFamily"
                      id="fontFamily"
                      value={fontFamily}
                      onChange={handleFontFamilyChange}
                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    >
                      <option value="Arial">Arial</option>
                      <option value="Times New Roman">Times New Roman</option>
                      <option value="Courier New">Courier New</option>
                      <option value="Verdana">Verdana</option>
                    </select>
                  </div>
                  <div className="col-span-2">
                    <label htmlFor="fontSize" className="block text-sm font-medium text-gray-700">
                      Font Size
                    </label>
                    <input
                      type="number"
                      name="fontSize"
                      id="fontSize"
                      min="10"
                      max="48"
                      value={fontSize}
                      onChange={handleFontSizeChange}
                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                  <div className="col-span-2">
                    <label htmlFor="fontColor" className="block text-sm font-medium text-gray-700">
                      Font Color
                    </label>
                    <input
                      type="color"
                      name="fontColor"
                      id="fontColor"
                      value={fontColor}
                      onChange={handleFontColorChange}
                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                  <div className="col-span-2">
                    <label htmlFor="backgroundColor" className="block text-sm font-medium text-gray-700">
                      Background Color
                    </label>
                    <input
                      type="color"
                      name="backgroundColor"
                      id="backgroundColor"
                      value={backgroundColor}
                      onChange={handleBackgroundColorChange}
                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                  
                  <div className="col-span-2 flex space-x-4">
                  {/* Agregar el botón para subir imágenes */}
                  {showUploadImagesButton && (
                    <div>
                      <label
                        htmlFor="uploadPreviewImages"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Upload Preview Images
                      </label>
                      <input
                        type="file"
                        name="uploadPreviewImages"
                        id="uploadPreviewImages"
                        multiple
                        onChange={handleImageUpload}
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                  )}
                  <div>
                    <label htmlFor="imageUpload" className="block text-sm font-medium text-gray-700">
                      Upload Attachment Files
                    </label>
                    <input
                      type="file"
                      name="imageUpload"
                      id="imageUpload"
                      multiple
                      onChange={handleFileChange}
                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </div>

                <div className="col-span-2">
                <label htmlFor="attachEmailPreview" className="block text-sm font-medium text-gray-700">
                  Attach Email Preview
                </label>
                <input
                  type="checkbox"
                  name="attachEmailPreview"
                  id="attachEmailPreview"
                  checked={attachEmailPreview}
                  onChange={() => setAttachEmailPreview(!attachEmailPreview)}
                  className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
              </div>

                  <div className="col-span-2">
                    <button
                      onClick={handleDownloadScreenshot}
                      type="button"
                      className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      Download
                    </button>
                  </div>
                  <br/><br/>
                  <div className="col-span-2">
                  <button
                    onClick={handleSubmit}
                    type="submit"
                    className="inline-flex justify-center py-4 px-8 border border-transparent shadow-sm text-sm font-large rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Send Email
                  </button>
                </div>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <h2 className="text-3xl font-extrabold text-gray-900">Email Preview</h2>
          <div
            id="previewContainer"
            className="mt-8 p-4 border border-gray-200 bg-white relative"
            style={{
              fontFamily,
              fontSize: `${fontSize}px`,
              color: fontColor,
              backgroundColor,
              height: 'calc(100vh - 220px)',
              overflow: 'auto',
            }}
            onDragOver={handleImageDragOver}
            onDrop={handleImageDrop}
          >
            {images.map((image, index) => (
              <div key={index} className="relative">
                <img
                  id={`image-${index}`}
                  src={image.file && URL.createObjectURL(image.file)}
                  alt={`Image ${index + 1}`}
                  className="absolute"
                  style={{
                    top: image.position?.y || '50px',
                    left: image.position?.x || '50px',
                    width: `${image.width * (image.scale || 1)}px`,
                    height: `${image.height * (image.scale || 1)}px`,
                  }}
                  draggable="true"
                  onDragStart={(e) => handleImageDragStart(e, index)}
                  onDragEnd={handleImageDrop}
                  onLoad={(e) => handleImageResize(e, index)}
                />
                <div className="absolute top-0 right-0">
                  <button
                    className="p-1 bg-white border border-gray-200 rounded-full shadow hover:bg-gray-100 focus:outline-none"
                    onClick={() => handleImageMove(index, 10, 0)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5 text-gray-500"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                    </svg>
                  </button>
                  <button
                    className="p-1 bg-white border border-gray-200 rounded-full shadow hover:bg-gray-100 focus:outline-none"
                    onClick={() => handleImageMove(index, -10, 0)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5 text-gray-500"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                    </svg>
                  </button>
                  <button
                    className="p-1 bg-white border border-gray-200 rounded-full shadow hover:bg-gray-100 focus:outline-none"
                    onClick={() => handleImageMove(index, 0, 10)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5 text-gray-500"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                    </svg>
                  </button>
                  <button
                    className="p-1 bg-white border border-gray-200 rounded-full shadow hover:bg-gray-100 focus:outline-none"
                    onClick={() => handleImageMove(index, 0, -0.1)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5 text-gray-500"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 15l7-7 7 7" />
                    </svg>
                  </button>
                  <button
                    className="p-1 bg-white border border-gray-200 rounded-full shadow hover:bg-gray-100 focus:outline-none"
                    onClick={() => handleDeleteImage(index)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5 text-gray-500"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </button>
                </div>
              </div>
            ))}
            <textarea
              className="w-full h-64 resize-none border-none bg-transparent"
              style={{ fontFamily, fontSize: `${fontSize}px`, color: fontColor, height: 'calc(100vh - 220px)' }}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            ></textarea>
          </div>
        </div>
        {showLeadsModal && (
        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 transition-opacity">
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>

            <span className="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;

            <div
              className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
              role="dialog"
              aria-modal="true"
              aria-labelledby="modal-headline"
            >
              <div>
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                  <svg
                    className="h-6 w-6 text-green-600"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                </div>
                <div className="mt-3 text-center sm:mt-5">
                  <h3
                    className="text-lg leading-6 font-medium text-gray-900"
                    id="modal-headline"
                  >
                    Select Customers
                  </h3>
                  <div className="mt-2">
                    <ul className="border border-gray-200 divide-y divide-gray-200">
                      {leads.map((lead) => (
                        <li key={lead.id} className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                          <div className="w-0 flex-1 flex items-center">
                            <span
                              className="ml-2 flex-1 w-0 truncate"
                            >
                              {lead.name} &lt;{lead.email}&gt;
                            </span>
                          </div>
                          <div className="ml-4 flex-shrink-0">
                            <input
                              type="checkbox"
                              className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                              checked={selectedLeads.some((selectedLead) => selectedLead.email === lead.email)}
                              onChange={() => handleLeadSelection(lead)}
                            />
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-6">
                <button
                  onClick={handleSelectLeads}
                  type="button"
                  className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                >
                  Select
                </button>
                <button
                  onClick={handleToggleLeadsModal}
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      </div>
    </div>
  );
};

export default EmailForm;
