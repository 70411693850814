import React from 'react';
import { Link } from 'react-router-dom';

const ClasificadoDetail = ({ clasificado, onCloseModal }) => {
  return (
    <div className="modal" style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}>
      <div className="modal-content" style={{
        maxWidth: '80%', // Máximo ancho del 80% de la pantalla
        maxHeight: '80vh', // Máximo 80% de la altura de la pantalla
        overflowX: 'auto', // Agregar scroll horizontal si es necesario
        overflowY: 'auto', // Agregar scroll vertical si es necesario
        borderRadius: '10px', // Bordes redondeados
        margin: '2% 2% 2% 2%', // Margen superior, derecho, inferior e izquierdo del 2%
        boxShadow: '0 0 10px 0px rgba(255, 68, 68, 0.75)', // Sombreado rojo
        padding: '20px', // Espaciado interno
      }}>
        {clasificado.content && clasificado.content_type === 'clasificado_imagen' && (
          <img src={clasificado.content} alt={clasificado.title} />
        )}
        <table>
          <tbody>
            <tr>
              <h2 style={{
                color: 'red',
                fontWeight: 'bold',
                textShadow: '2px 2px 4px #000', // Sombra roja
              }}>{clasificado.title}</h2>
            </tr>
            <tr>
              <td>Descripción:</td>
              <td>{clasificado.description}</td>
            </tr>
            <tr>
              <td>Tipo de contenido:</td>
              <td>{clasificado.content_type}</td>
            </tr>
            <tr>
              <td>Teléfono:</td>
              <td>{clasificado.phone_number}</td>
            </tr>
            <tr>
              <td>Whatsapp:</td>
              <td>{clasificado.whatsapp}</td>
            </tr>
            <tr>
              <td>Página Web:</td>
              <td>
                {clasificado.url && (
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      window.open(clasificado.url, '_blank');
                    }}
                    style={{
                      color: 'black',
                      fontWeight: 'bold',
                      textShadow: '2px 2px 4px #ff4444', // Sombra roja
                    }}
                  >
                    {clasificado.url}
                  </a>
                )}
              </td>
            </tr>
          </tbody>
        </table>

        <button onClick={onCloseModal}>Cerrar</button>
      </div>
    </div>
  );
};

export default ClasificadoDetail;
