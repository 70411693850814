import React, { useState, useEffect } from 'react';
import { Container, Table, Form, Button, InputGroup, Modal, FormControl, FormGroup, FormLabel } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import EmployeeDataService from '../../services/employee';
import './TablaDatos.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format } from 'date-fns';


const EmployeesOfertasTrabajo = () => {
  const [ofertasTrabajo, setOfertasTrabajo] = useState([]);
  const [selectedOfertasTrabajo, setSelectedOfertasTrabajo] = useState([]);
  const token = useSelector(state => state.authentication.token);
  const [showModal, setShowModal] = useState(false);
  const [allPuesto, setAllPuesto] = useState([]);
  const [ofertaTrabajoData, setOfertaTrabajoData] = useState({
    puesto: "",
    empresa_nombre: '',
    numero_empresa: '',
    correo_empresa: '',
    nombre_contacto: '',
    ubicacion: '',
    tipo_contrato: '',
    beneficios_adicionales: '',
    responsabilidades_clave: '',
    requisitos:  '',
    fecha_limite_aplicacion: null,
    // Add other fields as needed
  });

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const fetchPuestos = async () => {
    try {
      const responsePuestos = await EmployeeDataService.getAllPuestos(token);
      setAllPuesto(responsePuestos.data);
      console.log(responsePuestos.data)

    } catch (error) {
      console.error('Error al obtener roles:', error);
    }
  };

  const fetchData = async () => {
    try {
      const responseOfertasTrabajo = await EmployeeDataService.getAllOfertasTrabajo(token);
      setOfertasTrabajo(responseOfertasTrabajo.data);
    } catch (error) {
      console.error('Error al obtener datos:', error);
    }
  };

  useEffect(() => {
    fetchData();
    fetchPuestos();
  }, [token]);

  const handleDeleteSelected = async () => {
    try {
      await EmployeeDataService.deleteOfertaTrabajo(selectedOfertasTrabajo, token);
      setSelectedOfertasTrabajo([]);
      fetchData();
    } catch (error) {
      console.error('Error al eliminar ofertas de trabajo:', error);
    }
  };

  const toggleSelectOfertaTrabajo = (ofertaTrabajoId) => {
    if (selectedOfertasTrabajo.includes(ofertaTrabajoId)) {
      setSelectedOfertasTrabajo(selectedOfertasTrabajo.filter(id => id !== ofertaTrabajoId));
    } else {
      setSelectedOfertasTrabajo([...selectedOfertasTrabajo, ofertaTrabajoId]);
    }
  };

  const handleCreateOfertaTrabajo = async () => {
    try {
      const formattedDate = ofertaTrabajoData.fecha_limite_aplicacion
        ? format(new Date(ofertaTrabajoData.fecha_limite_aplicacion), 'yyyy-MM-dd')
        : null;
  
      const requestData = {
        ...ofertaTrabajoData,
        fecha_limite_aplicacion: formattedDate,
      };
  
      await EmployeeDataService.createOfertaTrabajo(requestData, token);
  
      toggleModal();
      fetchData();
    } catch (error) {
      console.error('Error al crear oferta de trabajo:', error);
    }
  };

  const handleDateChange = (date) => {
    setOfertaTrabajoData({
      ...ofertaTrabajoData,
      fecha_limite_aplicacion: date,
    });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setOfertaTrabajoData({
      ...ofertaTrabajoData,
      [name]: value
    });
  };

  return (
    <Container>
      <div className="tabla-datos-container">
        <br/><br/><br/>
        <h2>Ofertas de Trabajo</h2>
        <Button variant="primary" onClick={toggleModal}>Agregar Oferta de Trabajo</Button>

        {/* Modal */}
        <Modal show={showModal} onHide={toggleModal} style={{ overflowY: 'auto' }}>
          <Modal.Header closeButton>
            <Modal.Title>Agregar Oferta de Trabajo</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormGroup>
              <FormLabel>Puesto</FormLabel>
              <FormControl
                type="text"
                name="puesto"
                value={ofertaTrabajoData.puesto}
                onChange={handleInputChange}
              />
            </FormGroup>
            <FormGroup>
              <FormLabel>Nombre de la Empresa</FormLabel>
              <FormControl
                type="text"
                name="empresa_nombre"
                value={ofertaTrabajoData.empresa_nombre}
                onChange={handleInputChange}
              />
            </FormGroup>
            <FormGroup>
              <FormLabel>Número de Empresa</FormLabel>
              <FormControl
                type="text"
                name="numero_empresa"
                value={ofertaTrabajoData.numero_empresa}
                onChange={handleInputChange}
              />
            </FormGroup>
            <FormGroup>
              <FormLabel>Correo de Empresa</FormLabel>
              <FormControl
                type="text"
                name="correo_empresa"
                value={ofertaTrabajoData.correo_empresa}
                onChange={handleInputChange}
              />
            </FormGroup>
            <FormGroup>
              <FormLabel>Nombre de Contacto</FormLabel>
              <FormControl
                type="text"
                name="nombre_contacto"
                value={ofertaTrabajoData.nombre_contacto}
                onChange={handleInputChange}
              />
            </FormGroup>
            <FormGroup>
              <FormLabel>Ubicación</FormLabel>
              <FormControl
                type="text"
                name="ubicacion"
                value={ofertaTrabajoData.ubicacion}
                onChange={handleInputChange}
              />
            </FormGroup>
            <FormGroup>
              <FormLabel>Tipo de Contrato</FormLabel>
              <FormControl
                type="text"
                name="tipo_contrato"
                value={ofertaTrabajoData.tipo_contrato}
                onChange={handleInputChange}
              />
            </FormGroup>
            <FormGroup>
              <FormLabel>Beneficios Adicionales</FormLabel>
              <FormControl
                type="text"
                name="beneficios_adicionales"
                value={ofertaTrabajoData.beneficios_adicionales}
                onChange={handleInputChange}
              />
            </FormGroup>
            <FormGroup>
              <FormLabel>Responsabilidades Clave</FormLabel>
              <FormControl
                as="textarea"
                rows={3}
                name="responsabilidades_clave"
                value={ofertaTrabajoData.responsabilidades_clave}
                onChange={handleInputChange}
              />
            </FormGroup>
            <FormGroup>
              <FormLabel>Requisitos</FormLabel>
              <FormControl
                as="textarea"
                rows={3}
                name="requisitos"
                value={ofertaTrabajoData.requisitos}
                onChange={handleInputChange}
              />
            </FormGroup>
            <FormGroup>
              <FormLabel>Fecha Límite de Aplicación</FormLabel>
              <DatePicker
                selected={ofertaTrabajoData.fecha_limite_aplicacion}
                onChange={handleDateChange}
                dateFormat="yyyy-MM-dd"
                isClearable
                placeholderText="Seleccione fecha"
              />
            </FormGroup>
            {/* Add other form groups for additional fields as needed */}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={toggleModal}>
              Cancelar
            </Button>
            <Button variant="primary" onClick={handleCreateOfertaTrabajo}>
              Crear Oferta de Trabajo
            </Button>
          </Modal.Footer>
        </Modal>

      <div style={{ overflowX: 'auto' }}>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Check</th>
              <th>ID</th>
              <th>Puesto</th>
              <th>Empresa Nombre</th>
              <th>Número de Empresa</th>
              <th>Correo de Empresa</th>
              <th>Nombre de Contacto</th>
              <th>Ubicación</th>
              <th>Tipo de Contrato</th>
              <th>Beneficios Adicionales</th>
              <th>Responsabilidades Clave</th>
              <th>Requisitos</th>
              <th>Fecha Límite de Aplicación</th>
              {/* ... Agregar las demás columnas según tus requerimientos ... */}
            </tr>
          </thead>
          <tbody>
            {ofertasTrabajo.map(ofertaTrabajo => (
              <tr key={ofertaTrabajo.id}>
                <td>
                  <Form.Check
                    type="checkbox"
                    checked={selectedOfertasTrabajo.includes(ofertaTrabajo.id)}
                    onChange={() => toggleSelectOfertaTrabajo(ofertaTrabajo.id)}
                  />
                </td>
                <td>{ofertaTrabajo.id}</td>
                <td>
                    {Array.isArray(ofertaTrabajo.puesto) ? (
                      ofertaTrabajo.puesto.map(roleId => {
                        const role = allPuesto.find(r => r.id === roleId);
                        return role ? role.nombre : '';
                      })
                    ) : (
                      // Si puesto.roles_puesto no es un array, intentamos encontrar el nombre directamente
                      // en el array de roles
                      allPuesto.find(r => r.id === ofertaTrabajo.puesto)?.nombre || ''
                    )}
                  </td>
                <td>{ofertaTrabajo.empresa_nombre}</td>
                <td>{ofertaTrabajo.numero_empresa}</td>
                <td>{ofertaTrabajo.correo_empresa}</td>
                <td>{ofertaTrabajo.nombre_contacto}</td>
                <td>{ofertaTrabajo.ubicacion}</td>
                <td>{ofertaTrabajo.tipo_contrato}</td>
                <td>{ofertaTrabajo.beneficios_adicionales}</td>
                <td>{ofertaTrabajo.responsabilidades_clave}</td>
                <td>{ofertaTrabajo.requisitos}</td>
                <td>{ofertaTrabajo.fecha_limite_aplicacion}</td>
                {/* ... Agregar las demás columnas según tus requerimientos ... */}
              </tr>
            ))}
          </tbody>
        </Table>
      </div>

      {selectedOfertasTrabajo.length > 0 && (
        <InputGroup className="mb-3">
          <Button variant="danger" onClick={handleDeleteSelected}>
            Eliminar seleccionado
          </Button>
        </InputGroup>
      )}
     </div>
    </Container>
  );
};

export default EmployeesOfertasTrabajo;
