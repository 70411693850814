import React from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import ShippingDataService from "../../../services/shipping";

function ModalProducto(props) {
    const {
        showModal,
        handleCloseModal,
        newProductData,
        setNewProductData,
        handleImageChange,
        handleCreateOrEditProduct,
        handleCategoryChange,
        handleSubcategoryChange
    } = props;

    return (
        <Modal show={showModal} onHide={handleCloseModal} style={{ overflowX: 'auto', overflowY: 'auto' }}>
            <Modal.Header closeButton>
                <Modal.Title>Crear Clinica salud y estetica</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <button

                    style={{
                        background: "red",
                        color: "white",
                        padding: "8px",
                        borderRadius: "5px",
                        border: "none",
                        cursor: "pointer",
                        position: "absolute",
                        top: "4px",
                        right: "4px",
                    }}
                    onClick={handleCloseModal}
                >
                    X
                </button>
                <Form>
                    {/* Agregar aquí los campos del formulario */}
                    {/* Ejemplo para el campo 'Nombre' */}
                    <Form.Group controlId="formName">
                        <Form.Label>Nombre</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Ingrese el nombre"
                            value={newProductData.name}
                            onChange={(e) =>
                                setNewProductData({ ...newProductData, name: e.target.value })
                            }
                        />
                    </Form.Group>


                    <Form.Group controlId="formBrand">
                        <Form.Label>Marca</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Ingrese la marca"
                            value={newProductData.brand}
                            onChange={(e) => setNewProductData({ ...newProductData, brand: e.target.value })}
                        />
                    </Form.Group>

                    <Form.Group controlId="formCategory">
                        <Form.Label>Categoría</Form.Label>
                        <Form.Select
                            value={newProductData.category}
                            onChange={handleCategoryChange}
                        >
                            <option value="">Seleccione una categoría</option>
                            <optgroup label="Clinicas">
                                <option value="Medicina General">MEDICINA GENERAL </option>
                                <option value="Odontologia">ODONTOLOGIA </option>
                                <option value="Ortodoncia">ORTODONCIA </option>
                                <option value="Oftalmologia">OFTALMOLOGIA</option>
                                <option value="Pediatria">PEDIATRIA </option>
                                <option value="Geriatria">GERIATRIA</option>
                                <option value="Ginecologia">GINECOLOGIA</option>
                            </optgroup>

                        </Form.Select>


                    </Form.Group>
                    <Form.Group controlId="formSubcategory">
                        <Form.Label>Subcategoría</Form.Label>
                        <Form.Select
                            multiple  // Habilita la selección múltiple
                            value={newProductData.subcategory}
                            onChange={handleSubcategoryChange}
                            style={{ height: '50vh' }}
                        >

                            <option value="">Seleccione una Subcategoría</option>
                            <optgroup label="MEDICINA GENERAL">
                                <option value="Consulta médica">Consulta médica</option>
                                <option value="Exámenes de laboratorio">Exámenes de laboratorio</option>
                                <option value="Cirugía menor">Cirugía menor</option>
                            </optgroup>

                            <optgroup label="ODONTOLOGIA ">
                                <option value="Limpieza dental">Limpieza dental</option>
                                <option value="Extracción de dientes">Extracción de dientes</option>
                                <option value="Ortodoncia">Ortodoncia</option>
                            </optgroup>

                            <optgroup label="ORTODONCIA">
                                <option value="Tratamiento de maloclusión">Tratamiento de maloclusión</option>
                                <option value="Colocación de brackets">Colocación de brackets</option>
                                <option value="Aparatos ortodónticos">Aparatos ortodónticos</option>
                            </optgroup>

                            <optgroup label="OFTALMOLOGIA">
                                <option value="Examen de la vista">Examen de la vista</option>
                                <option value="Cirugía de cataratas">Cirugía de cataratas</option>
                                <option value="Tratamiento de glaucoma">Tratamiento de glaucoma</option>
                            </optgroup>

                            <optgroup label="PEDIATRIA">
                                <option value="Control de crecimiento">Control de crecimiento</option>
                                <option value="Vacunación infantil">Vacunación infantil</option>
                                <option value="Pediatra de emergencia">Pediatra de emergencia</option>
                            </optgroup>

                            <optgroup label="GERIATRIA">
                                <option value="Cuidado de ancianos">Cuidado de ancianos</option>
                                <option value="Rehabilitación geriátrica">Rehabilitación geriátrica</option>
                                <option value="Atención domiciliaria">Atención domiciliaria</option>
                            </optgroup>

                            <optgroup label="GINECOLOGIA">
                                <option value="Control prenatal">Control prenatal</option>
                                <option value="Cirugía ginecológica">Cirugía ginecológica</option>
                                <option value="Planificación familiar">Planificación familiar</option>
                            </optgroup>




                        </Form.Select>
                    </Form.Group>




                    <Form.Group controlId="formDescription">
                        <Form.Label>Descripción</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            placeholder="Ingrese la descripción"
                            value={newProductData.description}
                            onChange={(e) => setNewProductData({ ...newProductData, description: e.target.value })}
                        />
                    </Form.Group>

                    <Form.Group controlId="formPrice">
                        <Form.Label>Precio</Form.Label>
                        <Form.Control
                            type="number"
                            placeholder="Ingrese el precio"
                            value={newProductData.price}
                            onChange={(e) => setNewProductData({ ...newProductData, price: e.target.value })}
                        />
                    </Form.Group>

                    <Form.Group controlId="formDiscount">
                        <Form.Label>Descuento</Form.Label>
                        <Form.Control
                            type="number"
                            placeholder="Ingrese el descuento"
                            value={newProductData.discount}
                            onChange={(e) => setNewProductData({ ...newProductData, discount: e.target.value })}
                        />
                    </Form.Group>

                    <Form.Group controlId="formCountInStock">
                        <Form.Label>Cantidad en Stock</Form.Label>
                        <Form.Control
                            type="number"
                            placeholder="Ingrese la cantidad en stock"
                            value={newProductData.countInStock}
                            onChange={(e) => setNewProductData({ ...newProductData, countInStock: e.target.value })}
                        />
                    </Form.Group>

                    <Form.Group controlId="formImage">
                        <Form.Label>Imagen</Form.Label>
                        <Form.Control type="file" onChange={handleImageChange} />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseModal}>
                    Cerrar
                </Button>
                <Button variant="primary" onClick={handleCreateOrEditProduct}>
                    Guardar
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default ModalProducto;
