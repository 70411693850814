import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import Boton from './iframe';
import HomeScreen from '../ecommerce/Homescreen';
import Footer from "../../components/navigation/Footer";
import { useMediaQuery } from 'react-responsive';
import logo from './imagenes/logo.jpg';
import image1 from './imagenes/1.jpg';
import image2 from './imagenes/2.jpg';
import image3 from './imagenes/3.jpg';
import image4 from './imagenes/4.jpg';
import image5 from './imagenes/5.jpg';
import image6 from './imagenes/6.jpg';
import image7 from './imagenes/7.jpg';
import image8 from './imagenes/8.jpg';
import image9 from './imagenes/9.jpg';
import image10 from './imagenes/10.jpg';
import image11 from './imagenes/11.jpg';
import image12 from './imagenes/12.jpg';
import image13 from './imagenes/13.jpg';
import image14 from './imagenes/14.jpg';
import image15 from './imagenes/15.jpg';
import image16 from './imagenes/16.jpg';
import image17 from './imagenes/17.jpg';
import image18 from './imagenes/18.jpg';
import image19 from './imagenes/19.jpg';
import image20 from './imagenes/20.jpg';
import image21 from './imagenes/21.jpg';
import image22 from './imagenes/22.jpg';
import image23 from './imagenes/23.jpg';
import image24 from './imagenes/24.jpg';


const Tienda = () => {
  const [allowedCategories, setAllowedCategories] = useState([
    'Vehiculos Nuevos',
    'Venta de Motocicletas',
    'Venta de Camiones',
    'Venta de Cuadriciclos',
    'Venta de Lanchas, Botes, Jetsky', 
    'Venta de Campers y Carretas', 
    'Venta de Aeronáutica', 
    'Taller de Vehículos',
    'Taller de Motocicletas', 
    'Taller de Lanchas, Botes',
    'Taller de Aeronáutica', 
    'Repuestos de Vehículos', 
    'Repuestos de Motocicletas', 
    'Repuestos de Camiones', 
    'Repuestos de Cuadriciclos', 
    'Repuestos de Lanchas, Botes, Jetsky', 
    'Repuestos de Campers y Carretas', 
    'Servicio de Grua', 
    'San Jose',
    'Alajuela',
    'Heredia',
    'Cartago',
    'Guanacaste', 
    'Puntarenas', 
    'Limón', 
    'Venta de casas',
    'Alquiler de casa', 
    'Venta de Lotes',
    'Alquiler de Terreno', 
    'Bono Credito',
    'Medicina General',
    'Odontologia',
    'Ortodoncia',
    'Oftalmologia',
    'Pediatria', 
    'Geriatria', 
    'Ginecologia',
    'Telecomunicaciones',
    'Servicios de Internet',
    'Radio y Televisión',
    'Publicidad y Marketing',
    'Desarrollo de Software', 
    'Hardware y Componentes', 
    'Servicios de TI',
    'Empresas de Tecnología',
    'Energía Solar',
    'Energía Eólica',
    'Servicios de Electricidad',
    'Constructoras',
    'Remodelación de Interiores',
    'Construcción de Viviendas',
    'Construcción de Obras Públicas',
    'Diseño de Interiores', 
    'Diseño Gráfico', 
    'Diseño de Moda',
    'Diseño de Sitios Web',
    'Supervisión de Obras',
    'Supervisión de Proyectos de Construcción',
    'Supervisión de Diseño Gráfico',
    'Supervisión de Diseño de Interiores',
    'Inversiones en Bienes Raíces',
    'Inversiones en Startups',
    'Inversiones en Mercados Financieros',
    'Inversiones en Criptomonedas',
    'Intercambio de Productos', 
    'Intercambio de Servicios Profesionales', 
    'Intercambio de Experiencias',
    'Intercambio de Habilidades',
    'Descuentos en Restaurantes',
    'Ofertas en Viajes y Alojamiento',
    'Descuentos en Entretenimiento',
    'Promociones en Tiendas Locales',
    'Desarrollo Web',
    'Desarrollo de Software',
    'Desarrollo de Aplicaciones',
    'Entretenimiento',
    'Diversión',
    'Restaurantes',
    'Ferretería',
    'Suministros de Limpieza',
    'Depósito',
    'Productos para el Hogar',
    'Tiendas de Electrónica',
    'Supermercado',
    'Inversiones en Propiedades',
    'Inversiones en Desarrollo Inmobiliario',
    'Inversiones en Bienes Raíces Comerciales',
    'Inversiones en Propiedades Especiales',
    'Traspaso',
    'Inscripcion de Hipoteca',
    'Inversiones en Bienes Raíces Comerciales',
    'Levantamiento de Hipoteca',
    'Servicios Notariales',
    'Financiamiento de notariado',
    'Estudio para traspaso',
    'Estudio para Mortuales',
    'Estudio para Redaccion',
    'Libros',
    'Material Escolar',
    'Catálogo',
    'Ofertas',
    'Subastas' ,
    'Pólizas de Seguros',
    'Seguros Comerciales',
    'Seguros Especiales',
    'Servicios de Asesoría',
    'Prestamo privado sobre propiedades',
    'Prestamo banco sobre propiedades',
    'Inspeccion de inmuebles'  ,
    'Productos Cooperativos',
    'Servicios Cooperativos',
    'Cooperativas de Trabajo',
    'Apoyo y Asesoramiento'  ,
    'Diseño Gráfico',
    'Desarrollo Web',
    'Publicidad en Línea',
    'Marketing y Estrategia'  ,
    'Eventos y Donaciones',
    'Productos y Servicios',
    'Educación y Programas',
    'Arte y Cultura',
    'Servicios de Consultoría' ,
    'Hoteles',
    'Turismo de Aventura',
    'Turismo Ecológico',
    'Turismo Cultural',
    'Paquetes de Viaje' ,
    'Transporte de Carga',
    'Transporte de Pasajeros',
    'Servicios de Mensajería',
    'Logística y Distribución',
    'Transporte de Carga Especializada',
  ]);
  const allowedCategories_Abcupon = [
    'Vehiculos Nuevos',
    'Venta de Motocicletas',
    'Venta de Camiones',
    'Venta de Cuadriciclos',
    'Venta de Lanchas, Botes, Jetsky', 
    'Venta de Campers y Carretas', 
    'Venta de Aeronáutica', 
    'Taller de Vehículos',
    'Taller de Motocicletas', 
    'Taller de Lanchas, Botes',
    'Taller de Aeronáutica', 
    'Repuestos de Vehículos', 
    'Repuestos de Motocicletas', 
    'Repuestos de Camiones', 
    'Repuestos de Cuadriciclos', 
    'Repuestos de Lanchas, Botes, Jetsky', 
    'Repuestos de Campers y Carretas', 
    'Servicio de Grua', 
    'San Jose',
    'Alajuela',
    'Heredia',
    'Cartago',
    'Guanacaste', 
    'Puntarenas', 
    'Limón', 
    'Venta de casas',
    'Alquiler de casa', 
    'Venta de Lotes',
    'Alquiler de Terreno', 
    'Bono Credito',
    'Medicina General',
    'Odontologia',
    'Ortodoncia',
    'Oftalmologia',
    'Pediatria', 
    'Geriatria', 
    'Ginecologia',
    'Telecomunicaciones',
    'Servicios de Internet',
    'Radio y Televisión',
    'Publicidad y Marketing',
    'Desarrollo de Software', 
    'Hardware y Componentes', 
    'Servicios de TI',
    'Empresas de Tecnología',
    'Energía Solar',
    'Energía Eólica',
    'Servicios de Electricidad',
    'Constructoras',
    'Remodelación de Interiores',
    'Construcción de Viviendas',
    'Construcción de Obras Públicas',
    'Diseño de Interiores', 
    'Diseño Gráfico', 
    'Diseño de Moda',
    'Diseño de Sitios Web',
    'Supervisión de Obras',
    'Supervisión de Proyectos de Construcción',
    'Supervisión de Diseño Gráfico',
    'Supervisión de Diseño de Interiores',
    'Inversiones en Bienes Raíces',
    'Inversiones en Startups',
    'Inversiones en Mercados Financieros',
    'Inversiones en Criptomonedas',
    'Intercambio de Productos', 
    'Intercambio de Servicios Profesionales', 
    'Intercambio de Experiencias',
    'Intercambio de Habilidades',
    'Descuentos en Restaurantes',
    'Ofertas en Viajes y Alojamiento',
    'Descuentos en Entretenimiento',
    'Promociones en Tiendas Locales',
    'Desarrollo Web',
    'Desarrollo de Software',
    'Desarrollo de Aplicaciones',
    'Entretenimiento',
    'Diversión',
    'Restaurantes',
    'Ferretería',
    'Suministros de Limpieza',
    'Depósito',
    'Productos para el Hogar',
    'Tiendas de Electrónica',
    'Supermercado',
    'Inversiones en Propiedades',
    'Inversiones en Desarrollo Inmobiliario',
    'Inversiones en Bienes Raíces Comerciales',
    'Inversiones en Propiedades Especiales',
    'Traspaso',
    'Inscripcion de Hipoteca',
    'Inversiones en Bienes Raíces Comerciales',
    'Levantamiento de Hipoteca',
    'Servicios Notariales',
    'Financiamiento de notariado',
    'Estudio para traspaso',
    'Estudio para Mortuales',
    'Estudio para Redaccion',
    'Libros',
    'Material Escolar',
    'Catálogo',
    'Ofertas',
    'Subastas' ,
    'Pólizas de Seguros',
    'Seguros Comerciales',
    'Seguros Especiales',
    'Servicios de Asesoría',
    'Prestamo privado sobre propiedades',
    'Prestamo banco sobre propiedades',
    'Inspeccion de inmuebles'  ,
    'Productos Cooperativos',
    'Servicios Cooperativos',
    'Cooperativas de Trabajo',
    'Apoyo y Asesoramiento'  ,
    'Diseño Gráfico',
    'Desarrollo Web',
    'Publicidad en Línea',
    'Marketing y Estrategia'  ,
    'Eventos y Donaciones',
    'Productos y Servicios',
    'Educación y Programas',
    'Arte y Cultura',
    'Servicios de Consultoría' ,
    'Hoteles',
    'Turismo de Aventura',
    'Turismo Ecológico',
    'Turismo Cultural',
    'Paquetes de Viaje' ,
    'Transporte de Carga',
    'Transporte de Pasajeros',
    'Servicios de Mensajería',
    'Logística y Distribución',
    'Transporte de Carga Especializada',
  ];
  const allowedCategories_Autos = [
    'Vehiculos Nuevos',
    'Venta de Motocicletas',
    'Venta de Camiones',
    'Venta de Cuadriciclos',
    'Venta de Lanchas, Botes, Jetsky', 
    'Venta de Campers y Carretas', 
    'Venta de Aeronáutica', 
    'Taller de Vehículos',
    'Taller de Motocicletas', 
    'Taller de Lanchas, Botes',
    'Taller de Aeronáutica', 
    'Repuestos de Vehículos', 
    'Repuestos de Motocicletas', 
    'Repuestos de Camiones', 
    'Repuestos de Cuadriciclos', 
    'Repuestos de Lanchas, Botes, Jetsky', 
    'Repuestos de Campers y Carretas', 
    'Servicio de Grua', 
  ];
  const allowedCategories_Casas = [
    'San Jose',
    'Alajuela',
    'Heredia',
    'Cartago',
    'Guanacaste', 
    'Puntarenas', 
    'Limón', 
    'Venta de casas',
    'Alquiler de casa', 
    'Venta de Lotes',
    'Alquiler de Terreno', 
    'Bono Credito'
  ];
  const allowedCategories_Salud = [
    'Medicina General',
    'Odontologia',
    'Ortodoncia',
    'Oftalmologia',
    'Pediatria', 
    'Geriatria', 
    'Ginecologia'
  ];
  const allowedCategories_Comunicacion = [
    'Telecomunicaciones',
    'Servicios de Internet',
    'Radio y Televisión',
    'Publicidad y Marketing',
    'Desarrollo de Software', 
    'Hardware y Componentes', 
    'Servicios de TI',
    'Empresas de Tecnología',
    'Energía Solar',
    'Energía Eólica',
    'Servicios de Electricidad',
  ];
  const allowedCategories_Construccion = [
    'Constructoras',
    'Remodelación de Interiores',
    'Construcción de Viviendas',
    'Construcción de Obras Públicas',
    'Diseño de Interiores', 
    'Diseño Gráfico', 
    'Diseño de Moda',
    'Diseño de Sitios Web',
    'Supervisión de Obras',
    'Supervisión de Proyectos de Construcción',
    'Supervisión de Diseño Gráfico',
    'Supervisión de Diseño de Interiores',
  ];
  const allowedCategories_Cupones = [
    'Inversiones en Bienes Raíces',
    'Inversiones en Startups',
    'Inversiones en Mercados Financieros',
    'Inversiones en Criptomonedas',
    'Intercambio de Productos', 
    'Intercambio de Servicios Profesionales', 
    'Intercambio de Experiencias',
    'Intercambio de Habilidades',
    'Descuentos en Restaurantes',
    'Ofertas en Viajes y Alojamiento',
    'Descuentos en Entretenimiento',
    'Promociones en Tiendas Locales'
  ];
  const allowedCategories_Centros_Educativos = [
    'Desarrollo Web',
    'Desarrollo de Software',
    'Desarrollo de Aplicaciones'
  ];
  const allowedCategories_Entretenimiento = [
    'Entretenimiento',
    'Diversión',
    'Restaurantes'
  ];
  const allowedCategories_Ferreteria = [
    'Ferretería',
    'Suministros de Limpieza',
    'Depósito'
  ];
  const allowedCategories_Hogar = [
    'Productos para el Hogar',
    'Tiendas de Electrónica',
    'Supermercado'
  ];
  const allowedCategories_Planes = [
    'Inversiones en Propiedades',
    'Inversiones en Desarrollo Inmobiliario',
    'Inversiones en Bienes Raíces Comerciales',
    'Inversiones en Propiedades Especiales'
  ];
  const allowedCategories_Legal = [
    'Traspaso',
    'Inscripcion de Hipoteca',
    'Inversiones en Bienes Raíces Comerciales',
    'Levantamiento de Hipoteca',
    'Servicios Notariales',
    'Financiamiento de notariado',
    'Estudio para traspaso',
    'Estudio para Mortuales',
    'Estudio para Redaccion'
  ];
  const allowedCategories_Libreria = [
    'Libros',
    'Material Escolar',
  ];
  const allowedCategories_Ofertas = [
    'Catálogo',
    'Ofertas',
    'Subastas'   
  ];
  const allowedCategories_Poliza = [
    'Pólizas de Seguros',
    'Seguros Comerciales',
    'Seguros Especiales',
    'Servicios de Asesoría'    
  ];
  const allowedCategories_Prestamo = [
    'Prestamo privado sobre propiedades',
    'Prestamo banco sobre propiedades',
    'Inspeccion de inmuebles' 
  ];
  const allowedCategories_Producto = [
    'Productos Cooperativos',
    'Servicios Cooperativos',
    'Cooperativas de Trabajo',
    'Apoyo y Asesoramiento'  
  ];
  const allowedCategories_Publicidad = [
    'Diseño Gráfico',
    'Desarrollo Web',
    'Publicidad en Línea',
    'Marketing y Estrategia'  
  ];
  const allowedCategories_Fundaciones = [
    'Eventos y Donaciones',
    'Productos y Servicios',
    'Educación y Programas',
    'Arte y Cultura',
    'Servicios de Consultoría' 
  ];
  const allowedCategories_Hoteles = [
    'Hoteles',
    'Turismo de Aventura',
    'Turismo Ecológico',
    'Turismo Cultural',
    'Paquetes de Viaje'  
  ];
  const allowedCategories_Transporte = [
    'Transporte de Carga',
    'Transporte de Pasajeros',
    'Servicios de Mensajería',
    'Logística y Distribución',
    'Transporte de Carga Especializada'  
  ];
  const images = [
    { src: image1, name: 'allowedCategories_Autos' },
    { src: image2, name: 'bolsadeempleo' },
    { src: image3, name: 'allowedCategories_Casas' },
    { src: image4, name: 'allowedCategories_Salud' },
    { src: image5, name: 'allowedCategories_Comunicacion' },
    { src: image6, name: 'allowedCategories_Construccion' },
    { src: image7, name: 'allowedCategories_Cupones' },
    { src: image8, name: 'allowedCategories_Centros_Educativos' },
    { src: image9, name: 'allowedCategories_Entretenimiento' },
    { src: image10, name: 'allowedCategories_Ferreteria' },
    { src: image11, name: 'allowedCategories_Hogar' },
    { src: image12, name: 'allowedCategories_Planes' },
    { src: image13, name: 'allowedCategories_Legal' },
    { src: image14, name: 'allowedCategories_Libreria' },
    { src: image15, name: 'allowedCategories_Ofertas' },
    { src: image16, name: 'https://www.noticias.abcupon.com/' },
    { src: image17, name: 'allowedCategories_Poliza' },
    { src: image18, name: 'allowedCategories_Prestamo' },
    { src: image19, name: 'allowedCategories_Producto' },
    { src: image20, name: 'allowedCategories_Publicidad' },
    { src: image21, name: 'allowedCategories_Fundaciones' },
    { src: image22, name: 'allowedCategories_Hoteles' },
    { src: image23, name: 'allowedCategories_Transporte' },
    { src: image24, name: 'directorio_comercial' },
  ];


  const [inputValue, setInputValue] = useState('');
  const [botones, setBotones] = useState([]);
  const [selectedImageName, setSelectedImageName] = useState('');
   const intervalIdRef = useRef();
   const [isAutoMode, setIsAutoMode] = useState(true);
   const [autoModeTimeout, setAutoModeTimeout] = useState(null);
   const isMobile = useMediaQuery({ maxWidth: 768 });
   
  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleImageClick = (image) => {
    console.log('Nombre de la imagen:', image.name);

    switch (image.name) {
      case 'allowedCategories_Abcupon':
        setAllowedCategories(allowedCategories_Abcupon);
        break;
      case 'allowedCategories_Autos':
        setAllowedCategories(allowedCategories_Autos);
        break;
      case 'allowedCategories_Casas':
        setAllowedCategories(allowedCategories_Casas);
        break;
      case 'allowedCategories_Salud':
        setAllowedCategories(allowedCategories_Salud);
        break;
      case 'allowedCategories_Comunicacion':
        setAllowedCategories(allowedCategories_Comunicacion);
        break;
      case 'allowedCategories_Construccion':
        setAllowedCategories(allowedCategories_Construccion);
        break;
      case 'allowedCategories_Cupones':
        setAllowedCategories(allowedCategories_Cupones);
        break;
      case 'allowedCategories_Centros_Educativos':
        setAllowedCategories(allowedCategories_Centros_Educativos);
        break;
      case 'allowedCategories_Entretenimiento':
        setAllowedCategories(allowedCategories_Entretenimiento);
        break;
      case 'allowedCategories_Ferreteria':
        setAllowedCategories(allowedCategories_Ferreteria);
        break;
      case 'allowedCategories_Hogar':
        setAllowedCategories(allowedCategories_Hogar);
        break;
      case 'allowedCategories_Planes':
        setAllowedCategories(allowedCategories_Planes);
        break;
      case 'allowedCategories_Legal':
        setAllowedCategories(allowedCategories_Legal);
        break;
      case 'allowedCategories_Libreria':
        setAllowedCategories(allowedCategories_Libreria);
        break;
      case 'allowedCategories_Ofertas':
        setAllowedCategories(allowedCategories_Ofertas);
        break;
      case 'allowedCategories_Poliza':
        setAllowedCategories(allowedCategories_Poliza);
        break;
      case 'allowedCategories_Prestamo':
        setAllowedCategories(allowedCategories_Prestamo);
        break;
      case 'allowedCategories_Producto':
        setAllowedCategories(allowedCategories_Producto);
        break;
      case 'allowedCategories_Publicidad':
        setAllowedCategories(allowedCategories_Publicidad);
        break;
      case 'allowedCategories_Fundaciones':
        setAllowedCategories(allowedCategories_Fundaciones);
        break;
      case 'allowedCategories_Hoteles':
        setAllowedCategories(allowedCategories_Hoteles);
        break;
      case 'allowedCategories_Transporte':
        setAllowedCategories(allowedCategories_Transporte);
        break;
      case 'https://www.noticias.abcupon.com/':
        // Abre una nueva pestaña con el sitio web noticias.abcupon.com
        window.open('https://www.noticias.abcupon.com/', '_blank');
        break;
        case 'bolsadeempleo':
          window.location.href = '#/bolsadeempleo';
          break;
      case 'directorio_comercial':
        window.location.href = '#/directorio_comercial';
        break;
      default:
        // Si el nombre de la imagen no coincide con ninguno de los casos anteriores, no hagas nada
        break;
    }
  };

  const filteredBotones = botones.filter((boton) =>
    boton.label.toLowerCase().includes(inputValue.toLowerCase())
  );

  // Hook useEffect para manejar el tamaño de la ventana
  useEffect(() => {
    const handleWindowResize = () => {
      const windowWidth = window.innerWidth;
      const marginTop = windowWidth <= 768 ? '20vh' : '15vh'; // Cambio de valores en dispositivos móviles
      const marginBottom = windowWidth <= 768 ? '10vh' : '0'; // Cambio de valores en dispositivos móviles
      document.documentElement.style.setProperty('--marginTop', marginTop);
      document.documentElement.style.setProperty('--marginBottom', marginBottom);
    };

    // Agregar el listener para el cambio de tamaño de ventana
    window.addEventListener('resize', handleWindowResize);

    // Llamada inicial para establecer el valor correcto
    handleWindowResize();

    // Limpieza del listener al desmontar el componente
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

   const [currentImages, setCurrentImages] = useState(images);
   const [currentIndex, setCurrentIndex] = useState(0);
   
  useEffect(() => {
    const intervalId = setInterval(() => {
      // Obtener la primera imagen y moverla al final del array
      const updatedImages = [...currentImages];
      const firstImage = updatedImages.shift();
      updatedImages.push(firstImage);

      // Actualizar el estado con el nuevo orden de imágenes
      setCurrentImages(updatedImages);
    }, 3000); // Cambia el valor del intervalo según tus preferencias

    // Limpiar el intervalo al desmontar el componente
    return () => clearInterval(intervalId);
  }, [currentImages]);

  

  const handlePrevClick = () => {
    setIsAutoMode(false); // Cambia al modo manual al presionar el botón
    clearTimeout(autoModeTimeout);
    const updatedIndex = (currentIndex - 1 + images.length) % images.length;
    setCurrentIndex(updatedIndex);
    updateImagesOrder(updatedIndex);
    restartAutoSlider();
  };

  const handleNextClick = () => {
    setIsAutoMode(false); // Cambia al modo manual al presionar el botón
    clearTimeout(autoModeTimeout);
    const updatedIndex = (currentIndex + 1) % images.length;
    setCurrentIndex(updatedIndex);
    updateImagesOrder(updatedIndex);
    restartAutoSlider();
  };


  const updateImagesOrder = (index) => {
    // Actualizar el orden de las imágenes basado en el índice actual
    const updatedImages = [...images.slice(index), ...images.slice(0, index)];
    setCurrentImages(updatedImages);
  };
  const restartAutoSlider = () => {
    // Reiniciar el intervalo para cambiar automáticamente las imágenes después de 30 segundos de inactividad
    clearTimeout(autoModeTimeout);
    setAutoModeTimeout(setTimeout(() => {
      setIsAutoMode(true); // Vuelve al modo automático
      handleNextClick(); // Avanza automáticamente al siguiente después de 30 segundos
    }, 30000));
  };

  useEffect(() => {
    // Intervalo para cambiar automáticamente las imágenes
    const intervalId = setInterval(() => {
      if (isAutoMode) {
        handleNextClick();
      }
    }, 3000);

    // Limpiar el intervalo al desmontar el componente
    return () => clearInterval(intervalId);
  }, [isAutoMode]);
  
  return (
    <div style={{ marginTop: 'var(--marginTop)' }}>

        <br/>
          {/* Imágenes en la parte superior ocupando la mitad de la pantalla */}
          <div style={{ display: 'flex', justifyContent: 'left', height: '10vh', marginBottom: 'var(--marginBottom)', overflowX: 'auto' }}>
  <img onClick={() => handleImageClick({ name: 'allowedCategories_Abcupon' })} src={logo} alt="Logo" style={{ flex: '0 0 auto', maxWidth: '50%', maxHeight: '100%', objectFit: 'cover' }} />
  <div style={{ display: 'flex', flexWrap: 'nowrap', overflow: 'hidden' }}>
    {currentImages.slice(0, 5).map((image, index) => (
      <img
        onClick={() => handleImageClick(image)}
        key={index}
        src={image.src}
        alt={`Imagen ${index + 1}`}
        style={{ flex: '0 0 auto', maxWidth: '50%', maxHeight: '100%', objectFit: 'cover' }}
      />
    ))}
  </div>
</div>


      <br/>
      <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 'var(--marginBottom)', marginTop: isMobile ? '-50%' : '0' }}>
  {!isMobile && (
    <>
      <button
        onClick={handlePrevClick}
        style={{
          backgroundColor: 'red',
          color: 'white',
          padding: '10px 20px',
          borderRadius: '10px',
          marginRight: '10px',
        }}
      >
        Anterior
      </button>
      <button
        onClick={handleNextClick}
        style={{
          backgroundColor: 'red',
          color: 'white',
          padding: '10px 20px',
          borderRadius: '10px',
        }}
      >
        Siguiente
      </button>
    </>
  )}
</div>

       <br/>
      <input
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        placeholder="Buscar pagina..."
        style={{
          marginRight: "10px",
          padding: "8px",
          borderRadius: "5px",
          border: "1px solid red",
        }}
      />
<br/><br/>
      {filteredBotones.map((boton) => (
        <Boton key={boton.label} url={boton.url} label={boton.label} />
      ))}

      <div >
       

        {/* Renderizar el componente HomeScreen debajo de las imágenes */}
        <HomeScreen allowedCategories={allowedCategories} />
        <Footer />
      </div>
    </div>
  );
};

export default Tienda;
