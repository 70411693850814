import React from 'react';

const Noticias = () => {
  const containerStyle = {
    width: '100%',
    height: '135vh',
    marginTop: "8%",
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflow: 'hidden',
  };

  const iframeStyle = {
    width: '100%',
    flex: 1, // Cambiado a flex: 1 para ocupar todo el espacio disponible en el contenedor
    borderRadius: '10px',
    boxShadow: '0 0 10px rgba(255, 0, 0, 0.5)',
    overflow: 'hidden',
    transform: 'translateY(-12%)', 
  };

  const buttonStyle = {
    margin: '16px',
    padding: '10px',
    backgroundColor: '#FF0000', // Color rojo
    color: '#ffffff', // Letras blancas
    borderRadius: '10px', // Orillas redondeadas
    boxShadow: '0 0 10px rgba(255, 0, 0, 0.5)', // Sombreado rojo
    cursor: 'pointer',
  };

  const redirectToNoticias = () => {
    window.location.href = 'https://www.noticias.abcupon.com/';
  };

  return (
    <div style={containerStyle}>
      <iframe
        src="https://www.noticias.abcupon.com/"
        title="Noticias ABCupon"
        style={iframeStyle}
        allowFullScreen
      ></iframe>
      <button style={buttonStyle} onClick={redirectToNoticias}>
        Ir a Noticias de ABCupon
      </button>
    </div>
  );
};

export default Noticias;
