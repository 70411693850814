import React from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

function ModalProducto(props) {
    const {
        showModal,
        handleCloseModal,
        newProductData,
        setNewProductData,
        handleImageChange,
        handleCreateOrEditProduct,
        handleCategoryChange,
        handleSubcategoryChange
    } = props;

    return (
        <Modal show={showModal} onHide={handleCloseModal} style={{ overflowX: 'auto', overflowY: 'auto' }}>
            <Modal.Header closeButton>
                <Modal.Title>Crear Cupones de Descuento de Inversion e Intercambio</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <button

                    style={{
                        background: "red",
                        color: "white",
                        padding: "8px",
                        borderRadius: "5px",
                        border: "none",
                        cursor: "pointer",
                        position: "absolute",
                        top: "4px",
                        right: "4px",
                    }}
                    onClick={handleCloseModal}
                >
                    X
                </button>
                <Form>
                    {/* Agregar aquí los campos del formulario */}
                    {/* Ejemplo para el campo 'Nombre' */}
                    <Form.Group controlId="formName">
                        <Form.Label>Nombre</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Ingrese el nombre"
                            value={newProductData.name}
                            onChange={(e) =>
                                setNewProductData({ ...newProductData, name: e.target.value })
                            }
                        />
                    </Form.Group>


                    <Form.Group controlId="formBrand">
                        <Form.Label>Marca</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Ingrese la marca"
                            value={newProductData.brand}
                            onChange={(e) => setNewProductData({ ...newProductData, brand: e.target.value })}
                        />
                    </Form.Group>

                    <Form.Group controlId="formCategory">
                        <Form.Label>Categoría</Form.Label>
                        <Form.Select
                            value={newProductData.category}
                            onChange={handleCategoryChange}
                        >
                            <option value="">Seleccione una Categoría</option>

                            <optgroup label="Cupones de Inversión">
                                <option value="Inversiones en Bienes Raíces">Inversiones en Bienes Raíces</option>
                                <option value="Inversiones en Startups">Inversiones en Startups</option>
                                <option value="Inversiones en Mercados Financieros">Inversiones en Mercados Financieros</option>
                                <option value="Inversiones en Criptomonedas">Inversiones en Criptomonedas</option>
                            </optgroup>

                            <optgroup label="Cupones de Intercambio">
                                <option value="Intercambio de Productos">Intercambio de Productos</option>
                                <option value="Intercambio de Servicios Profesionales">Intercambio de Servicios Profesionales</option>
                                <option value="Intercambio de Experiencias">Intercambio de Experiencias</option>
                                <option value="Intercambio de Habilidades">Intercambio de Habilidades</option>
                            </optgroup>

                            <optgroup label="Ofertas Especiales">
                                <option value="Descuentos en Restaurantes">Descuentos en Restaurantes</option>
                                <option value="Ofertas en Viajes y Alojamiento">Ofertas en Viajes y Alojamiento</option>
                                <option value="Descuentos en Entretenimiento">Descuentos en Entretenimiento</option>
                                <option value="Promociones en Tiendas Locales">Promociones en Tiendas Locales</option>
                            </optgroup>
                        </Form.Select>


                    </Form.Group>
                    <Form.Group controlId="formSubcategory">
                        <Form.Label>Subcategoría</Form.Label>
                        <Form.Select
                            multiple  // Habilita la selección múltiple
                            value={newProductData.subcategory}
                            onChange={handleSubcategoryChange}
                            style={{ height: '50vh' }}
                        >
                            <option value="">Seleccione una Subcategoría</option>

                            <optgroup label="Inversiones en Bienes Raíces">
                                <option value="Inmuebles Comerciales">Inmuebles Comerciales</option>
                                <option value="Propiedades Residenciales">Propiedades Residenciales</option>
                                <option value="Desarrollo de Terrenos">Desarrollo de Terrenos</option>
                            </optgroup>

                            <optgroup label="Inversiones en Startups">
                                <option value="Inversiones en Tecnología">Inversiones en Tecnología</option>
                                <option value="Inversiones en Empresas Emergentes">Inversiones en Empresas Emergentes</option>
                                <option value="Inversiones en Innovación">Inversiones en Innovación</option>
                            </optgroup>

                            <optgroup label="Inversiones en Mercados Financieros">
                                <option value="Acciones y Valores">Acciones y Valores</option>
                                <option value="Fondos Mutuos">Fondos Mutuos</option>
                                <option value="Trading de Forex">Trading de Forex</option>
                            </optgroup>

                            <optgroup label="Inversiones en Criptomonedas">
                                <option value="Bitcoin">Bitcoin</option>
                                <option value="Ethereum">Ethereum</option>
                                <option value="Ripple">Ripple</option>
                            </optgroup>

                            <optgroup label="Intercambio de Productos">
                                <option value="Intercambio de Electrónicos">Intercambio de Electrónicos</option>
                                <option value="Intercambio de Ropa">Intercambio de Ropa</option>
                                <option value="Intercambio de Juguetes">Intercambio de Juguetes</option>
                            </optgroup>

                            <optgroup label="Intercambio de Servicios Profesionales">
                                <option value="Servicios Legales">Servicios Legales</option>
                                <option value="Servicios de Consultoría">Servicios de Consultoría</option>
                                <option value="Servicios de Diseño Gráfico">Servicios de Diseño Gráfico</option>
                            </optgroup>

                            <optgroup label="Intercambio de Experiencias">
                                <option value="Intercambio de Viajes">Intercambio de Viajes</option>
                                <option value="Intercambio de Eventos Culturales">Intercambio de Eventos Culturales</option>
                                <option value="Intercambio de Experiencias Gastronómicas">Intercambio de Experiencias Gastronómicas</option>
                            </optgroup>

                            <optgroup label="Descuentos en Restaurantes">
                                <option value="Comida Rápida">Comida Rápida</option>
                                <option value="Restaurantes de Lujo">Restaurantes de Lujo</option>
                                <option value="Comida Internacional">Comida Internacional</option>
                            </optgroup>

                            <optgroup label="Ofertas en Viajes y Alojamiento">
                                <option value="Paquetes de Viajes">Paquetes de Viajes</option>
                                <option value="Hoteles y Alojamiento">Hoteles y Alojamiento</option>
                                <option value="Ofertas de Vuelos">Ofertas de Vuelos</option>
                            </optgroup>

                            <optgroup label="Descuentos en Entretenimiento">
                                <option value="Cine y Teatro">Cine y Teatro</option>
                                <option value="Parques de Diversiones">Parques de Diversiones</option>
                                <option value="Eventos Deportivos">Eventos Deportivos</option>
                            </optgroup>

                            <optgroup label="Promociones en Tiendas Locales">
                                <option value="Ropa y Moda">Ropa y Moda</option>
                                <option value="Electrónicos">Electrónicos</option>
                                <option value="Productos para el Hogar">Productos para el Hogar</option>
                            </optgroup>

                        </Form.Select>
                    </Form.Group>




                    <Form.Group controlId="formDescription">
                        <Form.Label>Descripción</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            placeholder="Ingrese la descripción"
                            value={newProductData.description}
                            onChange={(e) => setNewProductData({ ...newProductData, description: e.target.value })}
                        />
                    </Form.Group>

                    <Form.Group controlId="formPrice">
                        <Form.Label>Precio</Form.Label>
                        <Form.Control
                            type="number"
                            placeholder="Ingrese el precio"
                            value={newProductData.price}
                            onChange={(e) => setNewProductData({ ...newProductData, price: e.target.value })}
                        />
                    </Form.Group>

                    <Form.Group controlId="formDiscount">
                        <Form.Label>Descuento</Form.Label>
                        <Form.Control
                            type="number"
                            placeholder="Ingrese el descuento"
                            value={newProductData.discount}
                            onChange={(e) => setNewProductData({ ...newProductData, discount: e.target.value })}
                        />
                    </Form.Group>

                    <Form.Group controlId="formCountInStock">
                        <Form.Label>Cantidad en Stock</Form.Label>
                        <Form.Control
                            type="number"
                            placeholder="Ingrese la cantidad en stock"
                            value={newProductData.countInStock}
                            onChange={(e) => setNewProductData({ ...newProductData, countInStock: e.target.value })}
                        />
                    </Form.Group>

                    <Form.Group controlId="formImage">
                        <Form.Label>Imagen</Form.Label>
                        <Form.Control type="file" onChange={handleImageChange} />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseModal}>
                    Cerrar
                </Button>
                <Button variant="primary" onClick={handleCreateOrEditProduct}>
                    Guardar
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default ModalProducto;
