import React from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

function ModalProducto(props) {
    const {
        showModal,
        handleCloseModal,
        newProductData,
        setNewProductData,
        handleImageChange,
        handleCreateOrEditProduct,
        handleCategoryChange,
        handleSubcategoryChange
    } = props;

    return (
        <Modal show={showModal} onHide={handleCloseModal} style={{ overflowX: 'auto', overflowY: 'auto' }}>
            <Modal.Header closeButton>
                <Modal.Title>Crear Comunicacion, Tecnologia y energia</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <button

                    style={{
                        background: "red",
                        color: "white",
                        padding: "8px",
                        borderRadius: "5px",
                        border: "none",
                        cursor: "pointer",
                        position: "absolute",
                        top: "4px",
                        right: "4px",
                    }}
                    onClick={handleCloseModal}
                >
                    X
                </button>
                <Form>
                    {/* Agregar aquí los campos del formulario */}
                    {/* Ejemplo para el campo 'Nombre' */}
                    <Form.Group controlId="formName">
                        <Form.Label>Nombre</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Ingrese el nombre"
                            value={newProductData.name}
                            onChange={(e) =>
                                setNewProductData({ ...newProductData, name: e.target.value })
                            }
                        />
                    </Form.Group>


                    <Form.Group controlId="formBrand">
                        <Form.Label>Marca</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Ingrese la marca"
                            value={newProductData.brand}
                            onChange={(e) => setNewProductData({ ...newProductData, brand: e.target.value })}
                        />
                    </Form.Group>

                    <Form.Group controlId="formCategory">
                        <Form.Label>Categoría</Form.Label>
                        <Form.Select
                            value={newProductData.category}
                            onChange={handleCategoryChange}
                        >
                            <option value="">Seleccione una Categoría</option>
                            <optgroup label="COMUNICACIÓN">
                                <option value="Telecomunicaciones">Telecomunicaciones</option>
                                <option value="Servicios de Internet">Servicios de Internet</option>
                                <option value="Radio y Televisión">Radio y Televisión</option>
                                <option value="Publicidad y Marketing">Publicidad y Marketing</option>
                            </optgroup>

                            <optgroup label="TECNOLOGÍA">
                                <option value="Desarrollo de Software">Desarrollo de Software</option>
                                <option value="Hardware y Componentes">Hardware y Componentes</option>
                                <option value="Servicios de TI">Servicios de TI</option>
                                <option value="Empresas de Tecnología">Empresas de Tecnología</option>
                            </optgroup>

                            <optgroup label="ENERGÍA">
                                <option value="Energía Solar">Energía Solar</option>
                                <option value="Energía Eólica">Energía Eólica</option>
                                <option value="Servicios de Electricidad">Servicios de Electricidad</option>
                                <option value="Eficiencia Energética">Eficiencia Energética</option>
                            </optgroup>
                        </Form.Select>


                    </Form.Group>
                    <Form.Group controlId="formSubcategory">
                        <Form.Label>Subcategoría</Form.Label>
                        <Form.Select
                            multiple  // Habilita la selección múltiple
                            value={newProductData.subcategory}
                            onChange={handleSubcategoryChange}
                            style={{ height: '50vh' }}
                        >
                            <option value="">Seleccione una Subcategoría</option>

                            <optgroup label="Telecomunicaciones">
                                <option value="Telefonía Móvil">Telefonía Móvil</option>
                                <option value="Internet de Alta Velocidad">Internet de Alta Velocidad</option>
                                <option value="Televisión por Cable">Televisión por Cable</option>
                                <option value="Proveedores de Internet">Proveedores de Internet</option>
                            </optgroup>

                            <optgroup label="Radio y Televisión">
                                <option value="Estaciones de Radio">Estaciones de Radio</option>
                                <option value="Estaciones de Televisión">Estaciones de Televisión</option>
                                <option value="Producción de Contenido Multimedia">Producción de Contenido Multimedia</option>
                            </optgroup>

                            <optgroup label="Desarrollo de Software">
                                <option value="Aplicaciones Móviles">Aplicaciones Móviles</option>
                                <option value="Software Empresarial">Software Empresarial</option>
                                <option value="Desarrollo de Videojuegos">Desarrollo de Videojuegos</option>
                            </optgroup>

                            <optgroup label="Hardware y Componentes">
                                <option value="Venta de Equipos de Computación">Venta de Equipos de Computación</option>
                                <option value="Componentes de Hardware">Componentes de Hardware</option>
                                <option value="Accesorios de Computadora">Accesorios de Computadora</option>
                            </optgroup>

                            <optgroup label="Servicios de TI">
                                <option value="Soporte Técnico">Soporte Técnico</option>
                                <option value="Servicios de Redes">Servicios de Redes</option>
                                <option value="Seguridad Informática">Seguridad Informática</option>
                            </optgroup>

                            <optgroup label="Empresas de Tecnología">
                                <option value="Consultoría Tecnológica">Consultoría Tecnológica</option>
                                <option value="Integración de Sistemas">Integración de Sistemas</option>
                                <option value="Desarrollo de Hardware">Desarrollo de Hardware</option>
                            </optgroup>

                            <optgroup label="Energía Solar">
                                <option value="Paneles Solares">Paneles Solares</option>
                                <option value="Instalación de Energía Solar">Instalación de Energía Solar</option>
                                <option value="Mantenimiento de Sistemas Solares">Mantenimiento de Sistemas Solares</option>
                            </optgroup>

                            <optgroup label="Energía Eólica">
                                <option value="Generadores Eólicos">Generadores Eólicos</option>
                                <option value="Instalación de Energía Eólica">Instalación de Energía Eólica</option>
                                <option value="Mantenimiento de Sistemas Eólicos">Mantenimiento de Sistemas Eólicos</option>
                            </optgroup>

                            <optgroup label="Servicios de Electricidad">
                                <option value="Instalaciones Eléctricas">Instalaciones Eléctricas</option>
                                <option value="Reparación de Equipos Eléctricos">Reparación de Equipos Eléctricos</option>
                                <option value="Generación de Energía Eléctrica">Generación de Energía Eléctrica</option>
                            </optgroup>

                            <optgroup label="Eficiencia Energética">
                                <option value="Auditoría Energética">Auditoría Energética</option>
                                <option value="Sistemas de Iluminación Eficiente">Sistemas de Iluminación Eficiente</option>
                                <option value="Optimización de Consumo de Energía">Optimización de Consumo de Energía</option>
                            </optgroup>

                        </Form.Select>
                    </Form.Group>




                    <Form.Group controlId="formDescription">
                        <Form.Label>Descripción</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            placeholder="Ingrese la descripción"
                            value={newProductData.description}
                            onChange={(e) => setNewProductData({ ...newProductData, description: e.target.value })}
                        />
                    </Form.Group>

                    <Form.Group controlId="formPrice">
                        <Form.Label>Precio</Form.Label>
                        <Form.Control
                            type="number"
                            placeholder="Ingrese el precio"
                            value={newProductData.price}
                            onChange={(e) => setNewProductData({ ...newProductData, price: e.target.value })}
                        />
                    </Form.Group>

                    <Form.Group controlId="formDiscount">
                        <Form.Label>Descuento</Form.Label>
                        <Form.Control
                            type="number"
                            placeholder="Ingrese el descuento"
                            value={newProductData.discount}
                            onChange={(e) => setNewProductData({ ...newProductData, discount: e.target.value })}
                        />
                    </Form.Group>

                    <Form.Group controlId="formCountInStock">
                        <Form.Label>Cantidad en Stock</Form.Label>
                        <Form.Control
                            type="number"
                            placeholder="Ingrese la cantidad en stock"
                            value={newProductData.countInStock}
                            onChange={(e) => setNewProductData({ ...newProductData, countInStock: e.target.value })}
                        />
                    </Form.Group>

                    <Form.Group controlId="formImage">
                        <Form.Label>Imagen</Form.Label>
                        <Form.Control type="file" onChange={handleImageChange} />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseModal}>
                    Cerrar
                </Button>
                <Button variant="primary" onClick={handleCreateOrEditProduct}>
                    Guardar
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default ModalProducto;
