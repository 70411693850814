import React, { useState, useEffect } from 'react';
import { Container, Table, Form, Button, InputGroup, FormControl, Modal } from 'react-bootstrap';
import FileDataService from '../../services/files';
import { useDispatch, useSelector } from 'react-redux';
import { Document, Page, pdfjs } from 'react-pdf';
import Category from './categorias/category.json'
import {
  FaSearch,
} from "react-icons/fa";
const FilesNews = () => {
  const [newsPosts, setNewsPosts] = useState([]);
  const [title, setTitle] = useState('');
  const [category, setCategory] = useState('');
  const [subcategory, setSubcategory] = useState('');
  const [subsubcategory, setSubsubcategory] = useState('');
  const [country, setCountry] = useState('');
  const [province, setProvince] = useState('');
  const [phone_number, setPhoneNumber] = useState('');
  const [whatsapp, setWhatsapp] = useState('');
  const [url, setUrl] = useState('');
  const [description, setDescription] = useState('');
  const [contentType, setContentType] = useState('clasificado');
  const [contentFile, setContentFile] = useState(null);
  const [selectedNews, setSelectedNews] = useState([]); // To store selected news post IDs
  const token = useSelector(state => state.authentication.token);
  const [numPages, setNumPages] = useState(null);
  const [showModal, setShowModal] = useState(false); // State for modal visibility
  const [editMode, setEditMode] = useState(false);
  const [editNewsId, setEditNewsId] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  // Function to toggle modal visibility
  const toggleModal = (editMode, postId) => {
    // Si está en modo edición, obtener los detalles del clasificado seleccionado
    if (editMode && postId) {
      const selectedNews = newsPosts.find(post => post.id === postId);
      if (selectedNews) {
        // Establecer los detalles en el formulario de edición
        setTitle(selectedNews.title);
        setCategory(selectedNews.category);
        setSubcategory(selectedNews.subcategory);
        setSubsubcategory(selectedNews.subsubcategory);
        setCountry(selectedNews.country);
        setProvince(selectedNews.province);
        setPhoneNumber(selectedNews.phone_number);
        setWhatsapp(selectedNews.whatsapp);
        setUrl(selectedNews.url);
        setDescription(selectedNews.description);
        setContentType(selectedNews.content_type);
        setEditMode(true);
        setEditNewsId(postId);
      }
    } else {
      // En modo creación, restablecer los campos del formulario
      setTitle('');
      setCategory('');
      setSubcategory('');
      setSubsubcategory('');
      setCountry('');
      setProvince('');
      setPhoneNumber('');
      setWhatsapp('');
      setUrl('');
      setDescription('');
      setContentType('');
      setEditMode(false);
      setEditNewsId(null);
    }

    // Mostrar u ocultar el modal según sea necesario
    setShowModal(!showModal);
  };
  // Function to handle form submission
  const handleFormSubmit = () => {
    // ... Your form submission logic ...
    toggleModal(); // Close the modal after form submission
  };

  useEffect(() => {
    fetchNewsPosts();
  }, []);

  const fetchNewsPosts = () => {
    FileDataService.getAllPost()
      .then(response => {
        setNewsPosts(response.data);
      })
      .catch(error => {
        console.error(error);
      });
  };

  const renderPreview = (contentType, content) => {
    if (contentType === 'clasificado_logo') {
      return <img src={content} alt="Preview" style={{ maxWidth: '100px', maxHeight: '100px' }} />;
    } else if (contentType === 'clasificado_imagen') {
      return <img src={content} alt="Preview" style={{ maxWidth: '100px', maxHeight: '100px' }} />;
    } else {
      return <span>No hay vista previa disponible</span>;
    }
  };

  const handleCreateNews = async () => {
    if (!title || !category || !description) {
      console.error('Por favor, complete los campos obligatorios.');
      return;
    }

    const formData = new FormData();
    formData.append('title', title);
    formData.append('category', category);
    formData.append('subcategory', subcategory);
    formData.append('subsubcategory', subsubcategory);
    formData.append('country', country);
    formData.append('province', province);
    formData.append('description', description);
    formData.append('content_type', contentType);
    formData.append('content', contentFile);
    formData.append('phone_number', phone_number);
    formData.append('whatsapp', whatsapp);
    formData.append('url', url);


    // Agregar contenido solo si contentFile no es null
    if (contentFile) {
      formData.append('content', contentFile);
    }
    if (contentType !== 'clasificado') {
      formData.append('content', contentFile || ''); // Puedes ajustar esto según tus necesidades, por ejemplo, enviando una cadena vacía si contentFile es nulo
    }

    try {
      const response = await FileDataService.createNewsPost(formData, token);
      setNewsPosts([...newsPosts, response.data]);
      setTitle('');
      setCategory('');
      setDescription('');
      setContentType('');
      setContentFile(null);
      toggleModal();
    } catch (error) {
      console.error('Error creating news post:', error);
    }
  };

  const handleDeleteSelected = async () => {
    // Deleting selected news posts
    try {
      await FileDataService.deleteNewsPost(selectedNews, token);
      // Clear the selectedNews array and refresh news posts
      setSelectedNews([]);
      fetchNewsPosts();
    } catch (error) {
      console.error('Error deleting news posts:', error);
    }
  };

  const toggleSelectNews = (postId) => {
    // Toggle selection of a news post
    if (selectedNews.includes(postId)) {
      setSelectedNews(selectedNews.filter(id => id !== postId));
    } else {
      setSelectedNews([...selectedNews, postId]);
    }
  };
  const generateOptions = (categories) => {
    return categories.map((category) => (
      <option key={category.nombre} value={category.nombre}>
        {category.nombre}
      </option>
    ));
  };

  const handleEditNews = async () => {
    if (!title || !category || !description || !editNewsId) {
      console.error('Por favor, complete los campos obligatorios y seleccione un clasificado para editar.');
      return;
    }

    const formData = new FormData();
    formData.append('title', title);
    formData.append('category', category);
    formData.append('subcategory', subcategory);
    formData.append('subsubcategory', subsubcategory);
    formData.append('country', country);
    formData.append('province', province);
    formData.append('description', description);
    formData.append('content_type', contentType);
    formData.append('phone_number', phone_number);
    formData.append('whatsapp', whatsapp);
    formData.append('url', url);

    // Agregar contenido solo si contentFile no es null y contentType no es 'clasificado'
    if (contentType !== 'clasificado') {
      formData.append('content', contentFile || ''); // Puedes ajustar esto según tus necesidades, por ejemplo, enviando una cadena vacía si contentFile es nulo
    }

    try {

      await FileDataService.updateNewsPost(editNewsId, formData, token);
      // Actualizar el estado de los clasificados después de la edición
      fetchNewsPosts();
      setEditMode(false);
      setEditNewsId(null);
      // Restablecer los campos del formulario
      setTitle('');
      setCategory('');
      setSubcategory('');
      setSubsubcategory('');
      setCountry('');
      setProvince('');
      setDescription('');
      setContentType('');
      setContentFile(null);
      toggleModal();
    } catch (error) {
      console.error('Error updating news post:', error);
    }
  };

  const handleEditClick = (postId) => {
    // Obtener los detalles del clasificado seleccionado
    const selectedNews = newsPosts.find(post => post.id === postId);
    if (selectedNews) {
      // Establecer los detalles en el formulario de edición
      setTitle(selectedNews.title);
      setCategory(selectedNews.category);
      setSubcategory(selectedNews.subcategory);
      setSubsubcategory(selectedNews.subsubcategory);
      setCountry(selectedNews.country);
      setProvince(selectedNews.province);
      setDescription(selectedNews.description);
      setContentType(selectedNews.content_type);
      setPhoneNumber(selectedNews.phone_number);
      setWhatsapp(selectedNews.whatsapp);
      setUrl(selectedNews.url);
      // Establecer el modo de edición y el ID del clasificado
      setEditMode(true);
      setEditNewsId(postId);
      // Mostrar el modal de edición
      toggleModal();
    }
  };
  const handleDownload = async (fileUrl, title) => {
    try {
      const response = await fetch(fileUrl);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(new Blob([blob]));

      const link = document.createElement('a');
      link.href = url;
      link.download = `${title}.png`;  // Utiliza el título del clasificado como nombre del archivo con extensión .png
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // Liberar recursos del objeto URL
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error al descargar la imagen:', error);
    }
  };

  const filteredNewsPosts = newsPosts.filter(post => {
    // Filtrar por título o categoría
    return (
      post.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      post.category.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });


  return (
    <Container>
      <br /><br /><br />
      <h1>Archivos de pagina Clasificados</h1>
      <div style={{ display: "flex" }}>
        <input
          type="text"
          placeholder="Buscar por Título o Categoría"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          style={{
            marginRight: "10px",
            padding: "8px",
            borderRadius: "5px",
            border: "1px solid red",
          }}
        />
        <FaSearch size={20} style={{ alignSelf: "center" }} />
      </div>
      <Button variant="primary" onClick={() => toggleModal(false, null)}>
        Crear clasificados
      </Button>
      {/* Modal */}
      <Modal show={showModal} onHide={toggleModal}
        style={{
          overflow: "auto",
          overflowY: "auto",
        }}>
        <Modal.Header closeButton>
          <Modal.Title>{editMode ? 'Editar clasificado' : 'Crear clasificado'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="title">
              <Form.Label>Titulo</Form.Label>
              <Form.Control
                type="text"
                value={title}
                onChange={e => setTitle(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="category">
              <Form.Label>Categoría</Form.Label>
              <Form.Control
                as="select"
                value={category}
                onChange={(e) => setCategory(e.target.value)}
              >
                <option value="">Seleccionar Categoría</option>
                {generateOptions(Category.categorias)}
              </Form.Control>
            </Form.Group>
            {category && (
              <Form.Group controlId="subcategory">
                <Form.Label>Subcategoría</Form.Label>
                <Form.Control
                  as="select"
                  value={subcategory}
                  onChange={(e) => setSubcategory(e.target.value)}
                >
                  <option value="">Seleccionar Subcategoría</option>
                  {Category.categorias
                    .find((cat) => cat.nombre === category)
                    ?.subcategorias.map((subcat) => (
                      <option key={subcat.nombre} value={subcat.nombre}>
                        {subcat.nombre}
                      </option>
                    ))}
                </Form.Control>
              </Form.Group>
            )}
            {subcategory && (
              <Form.Group controlId="subsubcategory">
                <Form.Label>Subsubcategoría</Form.Label>
                <Form.Control
                  as="select"
                  value={subsubcategory}
                  onChange={(e) => setSubsubcategory(e.target.value)}
                >
                  <option value="">Seleccionar Subsubcategoría</option>
                  {Category.categorias
                    .find((cat) => cat.nombre === category)
                    ?.subcategorias.find((subcat) => subcat.nombre === subcategory)
                    ?.subsubcategorias.map((subsubcat) => (
                      <option key={subsubcat} value={subsubcat}>
                        {subsubcat}
                      </option>
                    ))}
                </Form.Control>
              </Form.Group>
            )}


            <Form.Group controlId="country">
              <Form.Label>País</Form.Label>
              <Form.Control
                type="text"
                value={country}
                onChange={(e) => setCountry(e.target.value)}
              />
            </Form.Group>

            <Form.Group controlId="province">
              <Form.Label>Provincia</Form.Label>
              <Form.Control
                type="text"
                value={province}
                onChange={(e) => setProvince(e.target.value)}
              />
            </Form.Group>

            <Form.Group controlId="phone_number">
              <Form.Label>Número de teléfono</Form.Label>
              <Form.Control
                type="text"
                value={phone_number}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
            </Form.Group>

            <Form.Group controlId="whatsapp">
              <Form.Label>WhatsApp</Form.Label>
              <Form.Control
                type="text"
                value={whatsapp}
                onChange={(e) => setWhatsapp(e.target.value)}
              />
            </Form.Group>

            <Form.Group controlId="url">
              <Form.Label>URL</Form.Label>
              <Form.Control
                type="url"
                value={url}
                onChange={(e) => setUrl(e.target.value)}
              />
            </Form.Group>

            <Form.Group controlId="description">
              <Form.Label>Descripción</Form.Label>
              <Form.Control
                type="text"
                value={description}
                onChange={e => setDescription(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="contentType">
              <Form.Label>Tipo de contenido</Form.Label>
              <Form.Control
                as="select"
                value={contentType}
                onChange={e => setContentType(e.target.value)}
              >
                <option value="clasificado">Clasificado</option>
                <option value="clasificado_logo">Clasificado con logo</option>
                <option value="clasificado_imagen">Clasificado con Imagen</option>
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="content" style={{ display: contentType === 'clasificado' ? 'none' : 'block' }}>
              <Form.Label>Contenido</Form.Label>
              <Form.Control
                type="file"
                onChange={e => setContentFile(e.target.files[0])}
              />
            </Form.Group>
            <Button onClick={editMode ? handleEditNews : handleCreateNews}>
              {editMode ? 'Editar clasificado' : 'Crear clasificado'}
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      {newsPosts.length > 0 ? (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Seleccionar</th>
              <th>Titulo</th>
              <th>Categoria</th>
              <th>Descripcion</th>
              {/* <th>Tipo de anuncio</th> */}
              <th>Descargar</th>
              <th>Previsualizacion</th>
            </tr>
          </thead>
          <tbody>
            {filteredNewsPosts.map(post => (
              <tr key={post.id}>
                <td>
                  <Form.Check
                    type="checkbox"
                    checked={selectedNews.includes(post.id)}
                    onChange={() => toggleSelectNews(post.id)}
                  />
                </td>
                <td>
                  {/* Agregar evento onClick para abrir el modal de edición */}
                  <span style={{ cursor: 'pointer' }} onClick={() => toggleModal(true, post.id)}>
                    {post.title}
                  </span>
                </td>
                <td>{post.category}</td>
                <td>{post.description}</td>
                {/* <td>{post.content_type}</td> */}
                <td>
                  <button onClick={() => handleDownload(post.content, post.title)} style={{ color: "black", cursor: "pointer", border: "none", background: "none", padding: "0" }}>
                    Download
                  </button>
                </td>

                <td>{renderPreview(post.content_type, post.content)}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <p>No hay publicaciones de noticias disponibles.</p>
      )}
      {selectedNews.length > 0 && (
        <InputGroup className="mb-3">
          <Button variant="danger" onClick={handleDeleteSelected}>
            Eliminar seleccionado
          </Button>
        </InputGroup>
      )}
    </Container>
  );
};

export default FilesNews;
