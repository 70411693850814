import React, { useState } from 'react';

const Boton = ({ url, label }) => {
  const [iframeVisible, setIframeVisible] = useState(false);
  const [iframeSrc, setIframeSrc] = useState('');

  const toggleIframeVisibility = () => {
    setIframeSrc(url);
    setIframeVisible(!iframeVisible);
  };

  return (
    <div style={{ marginBottom: '20px' }}>
      <button
        onClick={toggleIframeVisibility}
        style={{
          backgroundColor: iframeVisible && iframeSrc === url ? 'black' : 'red',
          color: 'white',
          padding: '10px 20px',
          fontSize: '16px',
          borderRadius: '5px',
          border: 'none',
          cursor: 'pointer',
          transition: 'background-color 0.3s',
          marginTop: '20px',
        }}
        onMouseOver={() => {
          if (!iframeVisible || (iframeVisible && iframeSrc !== url)) {
            document.getElementById(`toggleButton-${url}`).style.backgroundColor = 'black';
          }
        }}
        onMouseOut={() => {
          if (!iframeVisible || (iframeVisible && iframeSrc !== url)) {
            document.getElementById(`toggleButton-${url}`).style.backgroundColor = 'red';
          }
        }}
        id={`toggleButton-${url}`}
      >
        {iframeVisible && iframeSrc === url ? `Ocultar iframe ${label}` : ` ${label}`}
      </button>

      {iframeVisible && (
        <iframe
          title={`IframeVisible-${url}`}
          src={iframeSrc}
          style={{ width: '100vw', height: '90vh', border: 'none', marginTop: '20px' }}
        />
      )}
    </div>
  );
};

export default Boton;
